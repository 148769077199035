import {CERTIFICATION_TYPES, Initiative, SustainabilityActionType} from '@/domain/entities/Initiative';
import {round} from '@/utils/round';
import {Collaborator} from '@/domain/entities/Collaborator';

export type SummaryInitiativeData = {
  initiativesNumber: number,
  sustainabilityActions: Array<SustainabilityActionType>,
  certificationValues: Array<CERTIFICATION_TYPES>,
  totalArea: number,
  collaborators: Array<Collaborator>
}

export const getSummaryInitiativesData = (initiatives: Array<Initiative>): SummaryInitiativeData => {

  /*PLACES SUMMARY*/
  const totalArea: number = initiatives.reduce((suma, initiative) => suma + initiative.area, 0);
  
  /*CERTIFICATION SUMMARY*/
  const certificates: Array<CERTIFICATION_TYPES> = [];

  initiatives
    .filter(initiative => initiative.certification_type)
    .forEach(initiative => {
      if(initiative.certification_type && initiative.certification_type.length > 0) {
        initiative.certification_type?.forEach(certification => {
          certificates.push(certification);
        });
      }
    });

  const certificationValues = Array
    .from(new Set(certificates))
    .sort();
  
  /*SUSTAINABILITY_ACTIONS SUMMARY*/
  const sustainabilityActions: Array<SustainabilityActionType> = [];

  initiatives
    .forEach(initiative => {
      if(initiative.sustainability_actions && initiative.sustainability_actions.length > 0) {
        initiative.sustainability_actions?.forEach(action => {
          sustainabilityActions.push(action);
        });
      }
    });

  const actions = Array
    .from(new Map(sustainabilityActions
      .map(item => [item.id, item]))
      .values())
    .sort((a, b) => a.name.localeCompare(b.name));


  /*COLLABORATORS*/
  const collaboratorsValues: Array<Collaborator> = [];

  initiatives.forEach(initiative => {
    if(initiative.collaborators && initiative.collaborators.length > 0) {
      initiative.collaborators?.forEach(collaborator => {
        collaboratorsValues.push(collaborator);
      });
    }
  });

  const collaborators = [...new Map(collaboratorsValues.map(collaborator => [collaborator.id, collaborator])).values()];

  return {
    initiativesNumber: initiatives.length,
    totalArea: round(totalArea),
    certificationValues,
    sustainabilityActions: [...actions],
    collaborators
  };
};