import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';

export interface StandOutDataProps {
  title: string,
  data: number,
  units: string
}

const containerSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  p: 2
};

const StandOutData: FC<StandOutDataProps> = ({title, data, units}) => {

  return <Box sx={containerSx}>
    <Typography variant='subtitle' align='center'>
      {title}
    </Typography>
    <Typography variant='supertitle' align='center' color='primary.main'>{data}</Typography>
    <Typography variant='subtitle' align='center' color='primary.main'>{units}</Typography>
  </Box>;
};

export default StandOutData;