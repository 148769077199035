import React, {FC, ReactElement} from 'react';
import SquareIcon from '@mui/icons-material/Square';
import Carousel from 'react-material-ui-carousel';

//TYPES
export type CarouselGreenBonusProps = {
  children: Array<ReactElement> | Array<Array<ReactElement>>,
  dots?: boolean
  autoplay?: boolean
};

//UTILS
import '@/utils/reactCarousel.css';
import Box from '@mui/material/Box';

const CarouselGreenBonus: FC<CarouselGreenBonusProps> = ({children, dots=true, autoplay=false}) => {

  return <Box sx={{width: '100%', flexGrow: 1}}>
    <Carousel
      autoPlay={autoplay}
      navButtonsAlwaysInvisible={true}
      animation='fade'
      duration={100}
      indicators={dots}
      IndicatorIcon={<SquareIcon sx={{fontSize:'14px', mx:0.25}}/>}
    >
      {children}
    </Carousel>
  </Box>;
};
export default CarouselGreenBonus;