import React, {FC} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {CERTIFICATES} from '@/config';
import {useTranslation} from 'react-i18next';
import {CERTIFICATION_TYPES} from '@/domain/entities/Initiative';
import {useMediaQuery} from '@mui/material';

//STYLES
const iconWrapperSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

//TYPES
export type CertificatesProps = {
  certificates: Array<CERTIFICATION_TYPES>
};

const Certificates: FC<CertificatesProps> = ({certificates}) => {

  const {t} = useTranslation();
  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});
  
  const certificationsWrapperSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: widescreen ? 'space-between' : 'center',
    mx: 0,
    mt: 2,
    p: 0,
    width: '100%',
    flexGrow: 1,
  };
  
  const certificateSx: SxProps = {
    display: 'flex',
    flexDirection: widescreen ? 'row' : 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    m: 1,
    gap: 2
  };
  
  const labelWrapperSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: widescreen ? 'flex-start' : 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    width: '100%'
  };
  
  return <Box sx={certificationsWrapperSx}>
    {
      certificates?.length ?
        certificates?.sort().map((certification, index) => {
          const currentCertification = CERTIFICATES.find(certificate => certificate.key === certification);
          return <Box key={index} sx={certificateSx}>
            <Box sx={{...iconWrapperSx, 
              width: widescreen ?
                currentCertification?.key === CERTIFICATION_TYPES.PEFC ? '100px' : '300px'
                : currentCertification?.key === CERTIFICATION_TYPES.PEFC ? '100px' : '200px'
            }}>
              <img src={`/img/certificates/${currentCertification?.src}`} alt={currentCertification?.key} 
                style={{width: '100%', padding: currentCertification?.key === CERTIFICATION_TYPES.PEFC ? 12 : 0}}/>
            </Box>
            <Box sx={labelWrapperSx}>
              <Typography variant="paragraph" color="text.primary" textAlign={widescreen ? 'left' : 'center'} sx={{display: 'inline-block'}}>
                {currentCertification?.label}
              </Typography>
              <Typography variant="paragraph" textAlign={widescreen ? 'left' : 'center'} sx={{color: 'primary.grey'}}>
                {currentCertification?.sublabel}
              </Typography>
            </Box>
          </Box>;
        }) 
        : <Typography variant='paragraph' color='error' sx={{m: 2}}>{t('initiatives.withoutCertificates')}</Typography>
    }
  </Box>;
};
export default Certificates;