import React, {FC} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '@mui/material';
import Link from '@mui/material/Link';
import IsotipoBikenta from '@/components/logos/IsotipoBikenta';

type CustomLinkProps = {
  url: string
  title: string
}

const CustomLink: FC<CustomLinkProps> = ({url, title}) => {
  return <Link href={url} underline="none" target="_blank">
    <Typography variant="paragraphBold" color="text.primary">
      {title}
    </Typography>
  </Link>;
};

const Footer: FC = () => {
  
  const {t} = useTranslation();
  
  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});

  const divider = <Typography variant="paragraph" color="secondary.grey" sx={{mx: 2}}>|</Typography>;

  const containerSx: SxProps = {
    position: 'relative',
    display: 'flex',
    flexDirection: widescreen ? 'row' : 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1200,
    bgcolor: 'common.white',
    p: 1,
    pr: widescreen ? 4 : 0,
    borderTop: '1px solid lightgrey'
  };
  
  return <Box sx={containerSx}>
    <Box sx={{height: '70px'}}>
      <IsotipoBikenta/>
    </Box>
    {
      widescreen 
        ? <Box>
          <CustomLink url="https://www.gestionforestal.es/contacta/" title={t('footer.help')}/> {divider}
          <CustomLink url="https://www.gestionforestal.es/nosotros/" title={t('footer.contact')}/> {divider}
          <CustomLink url="https://www.gestionforestal.es/gf-producto/bikenta-rsc/" title={t('footer.bikenta')}/> {divider}
          <CustomLink url="https://www.gestionforestal.es/politica-de-privacidad/" title={t('privacyPolicy')}/>
        </Box>
        : <>
          <CustomLink url="https://www.gestionforestal.es/contacta/" title={t('footer.help')}/>
          <CustomLink url="https://www.gestionforestal.es/nosotros/" title={t('footer.contact')}/>
          <CustomLink url="https://www.gestionforestal.es/gf-producto/bikenta-rsc/" title={t('footer.bikenta')}/>
          <CustomLink url="https://www.gestionforestal.es/politica-de-privacidad/" title={t('privacyPolicy')}/>
        </>
    }
    
  </Box>;
};

export default Footer;