import React, {FC, SVGProps} from 'react';

const PinusPinaster: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 808.108 711.158"
    height="100%"
    fill="none"
    {...props}
  >
    <path fill="#9BA098" d="M359.558 443.753h22.892v22.892h-22.892z"/>
    <path fill="#868B83" d="M382.338 443.753h22.892v22.892h-22.892z"/>
    <path
      fill="#9BA098"
      d="M336.554 443.753h22.892v22.892h-22.892zM313.661 443.753h22.892v22.892h-22.892z"
    />
    <path fill="#28361E" d="M290.769 443.753h22.892v22.892h-22.892z"/>
    <path fill="#868B83" d="M404.89 443.753h22.892v22.892H404.89z"/>
    <path fill="#9BA098" d="M426.797 443.753h22.892v22.892h-22.892z"/>
    <path
      fill="#F1F2F1"
      d="M449.689 443.753h22.892v22.892h-22.892zM495.662 443.753h22.892v22.892h-22.892z"
    />
    <path fill="#CCCFCA" d="M517.721 443.753h22.892v22.892h-22.892z"/>
    <path fill="#65685F" d="M359.558 421.375h22.892v22.892h-22.892z"/>
    <path fill="#424A3D" d="M382.338 421.375h22.892v22.892h-22.892z"/>
    <path
      fill="#202A1A"
      d="M336.554 421.375h22.892v22.892h-22.892zM313.661 421.375h22.892v22.892h-22.892z"
    />
    <path fill="#868B83" d="M290.769 421.375h22.892v22.892h-22.892z"/>
    <path fill="#545D4D" d="M404.89 421.375h22.892v22.892H404.89z"/>
    <path fill="#424A3D" d="M426.797 421.375h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M472.581 421.375h22.892v22.892h-22.892z"/>
    <path fill="#65685F" d="M359.558 398.377h22.892v22.892h-22.892z"/>
    <path fill="#65685F" d="M382.338 398.377h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M336.554 398.377h22.892v22.892h-22.892z"/>
    <path fill="#202A1A" d="M313.661 398.377h22.892v22.892h-22.892z"/>
    <path fill="#424A3D" d="M404.89 398.377h22.892v22.892H404.89z"/>
    <path fill="#424A3D" d="M426.797 398.377h22.892v22.892h-22.892z"/>
    <path fill="#545D4D" d="M449.689 398.377h22.892v22.892h-22.892z"/>
    <path
      fill="#28361E"
      d="M472.581 398.377h22.892v22.892h-22.892zM359.558 376h22.892v22.892h-22.892z"
    />
    <path fill="#424A3D" d="M382.338 376h22.892v22.892h-22.892z"/>
    <path
      fill="#28361E"
      d="M336.554 376h22.892v22.892h-22.892zM313.661 376h22.892v22.892h-22.892zM290.769 376h22.892v22.892h-22.892z"
    />
    <path fill="#424A3D" d="M404.89 376h22.892v22.892H404.89z"/>
    <path
      fill="#545D4D"
      d="M426.797 376h22.892v22.892h-22.892zM449.689 376h22.892v22.892h-22.892zM472.581 376h22.892v22.892h-22.892z"
    />
    <path fill="#747D6E" d="M495.662 376h22.892v22.892h-22.892z"/>
    <path fill="#747D6E" d="M517.721 376h22.892v22.892h-22.892z"/>
    <path fill="#65685F" d="M359.558 353.108h22.892V376h-22.892z"/>
    <path
      fill="#424A3D"
      d="M382.338 353.108h22.892V376h-22.892zM336.554 353.108h22.892V376h-22.892z"
    />
    <path
      fill="#28361E"
      d="M313.661 353.108h22.892V376h-22.892zM290.769 353.108h22.892V376h-22.892z"
    />
    <path fill="#28361E" d="M267.878 353.108h22.892V376h-22.892z"/>
    <path fill="#747D6E" d="M404.89 353.108h22.892V376H404.89z"/>
    <path fill="#28361E" d="M426.797 353.108h22.892V376h-22.892z"/>
    <path fill="#747D6E" d="M449.689 353.108h22.892V376h-22.892z"/>
    <path fill="#28361E" d="M472.581 353.108h22.892V376h-22.892z"/>
    <path fill="#747D6E" d="M495.662 353.108h22.892V376h-22.892z"/>
    <path fill="#65685F" d="M517.721 353.108h22.892V376h-22.892z"/>
    <path fill="#747D6E" d="M359.558 330.731h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M382.338 330.731h22.892v22.892h-22.892z"/>
    <path fill="#65685F" d="M336.554 330.731h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M313.661 330.731h22.892v22.892h-22.892z"/>
    <path fill="#475241" d="M290.769 330.731h22.892v22.892h-22.892z"/>
    <path fill="#475241" d="M267.878 330.731h22.892v22.892h-22.892z"/>
    <path fill="#545D4D" d="M244.986 330.731h22.892v22.892h-22.892z"/>
    <path fill="#CCCFCA" d="M222.094 330.731h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M404.89 330.731h22.892v22.892H404.89z"/>
    <path
      fill="#28361E"
      d="M426.797 330.731h22.892v22.892h-22.892zM449.689 330.731h22.892v22.892h-22.892zM472.581 330.731h22.892v22.892h-22.892z"
    />
    <path
      fill="#747D6E"
      d="M382.338 307.732h22.892v22.892h-22.892zM336.554 307.732h22.892v22.892h-22.892z"
    />
    <path fill="#28361E" d="M313.661 307.732h22.892v22.892h-22.892z"/>
    <path fill="#545D4D" d="M290.769 307.732h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M267.878 307.732h22.892v22.892h-22.892z"/>
    <path fill="#475241" d="M244.986 307.732h22.892v22.892h-22.892z"/>
    <path fill="#868B83" d="M222.094 307.732h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M404.89 307.732h22.892v22.892H404.89z"/>
    <path
      fill="#28361E"
      d="M426.797 307.732h22.892v22.892h-22.892zM449.689 307.732h22.892v22.892h-22.892zM472.581 307.732h22.892v22.892h-22.892z"
    />
    <path
      fill="#475241"
      d="M313.661 285.355h22.892v22.892h-22.892zM290.769 285.355h22.892v22.892h-22.892z"
    />
    <path fill="#28361E" d="M267.878 285.355h22.892v22.892h-22.892z"/>
    <path
      fill="#545D4D"
      d="M244.986 285.355h22.892v22.892h-22.892zM222.094 285.355h22.892v22.892h-22.892z"
    />
    <path fill="#CCCFCA" d="M199.202 285.355h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M404.89 285.355h22.892v22.892H404.89z"/>
    <path fill="#343431" d="M426.797 285.355h22.892v22.892h-22.892z"/>
    <path fill="#545D4D" d="M449.689 285.355h22.892v22.892h-22.892z"/>
    <path
      fill="#28361E"
      d="M472.581 285.355h22.892v22.892h-22.892zM495.662 285.355h22.892v22.892h-22.892z"
    />
    <path fill="#65685F" d="M517.721 285.355h22.892v22.892h-22.892z"/>
    <path fill="#65685F" d="M517.721 307.732h22.892v22.892h-22.892z"/>
    <path fill="#B4B6B1" d="M517.721 262.463h22.892v22.892h-22.892z"/>
    <path
      fill="#747D6E"
      d="M382.338 262.463h22.892v22.892h-22.892zM336.554 262.463h22.892v22.892h-22.892z"
    />
    <path
      fill="#CCCFCA"
      d="M336.554 285.355h22.892v22.892h-22.892zM381.945 285.355h22.892v22.892h-22.892zM359.053 262.463h22.892v22.892h-22.892z"
    />
    <path fill="#CCCFCA" d="M359.053 307.732h22.892v22.892h-22.892z"/>
    <path fill="#475241" d="M313.661 262.463h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M290.769 262.463h22.892v22.892h-22.892z"/>
    <path fill="#475241" d="M267.878 262.463h22.892v22.892h-22.892z"/>
    <path fill="#65685F" d="M244.986 262.463h22.892v22.892h-22.892z"/>
    <path fill="#747D6E" d="M222.094 262.463h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M404.89 262.463h22.892v22.892H404.89z"/>
    <path
      fill="#28361E"
      d="M426.797 262.463h22.892v22.892h-22.892zM449.689 262.463h22.892v22.892h-22.892zM472.581 262.463h22.892v22.892h-22.892zM495.662 262.463h22.892v22.892h-22.892z"
    />
    <path fill="#475241" d="M359.558 240.086h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M382.338 240.086h22.892v22.892h-22.892z"/>
    <path fill="#343431" d="M336.554 240.086h22.892v22.892h-22.892z"/>
    <path
      fill="#28361E"
      d="M313.661 240.086h22.892v22.892h-22.892zM290.769 240.086h22.892v22.892h-22.892z"
    />
    <path fill="#28361E" d="M267.878 240.086h22.892v22.892h-22.892z"/>
    <path fill="#DFE1DE" d="M222.094 240.086h22.892v22.892h-22.892z"/>
    <path fill="#343431" d="M404.89 240.086h22.892v22.892H404.89z"/>
    <path
      fill="#384231"
      d="M426.797 240.086h22.892v22.892h-22.892zM449.689 240.086h22.892v22.892h-22.892z"
    />
    <path fill="#9BA098" d="M472.581 240.086h22.892v22.892h-22.892z"/>
    <path
      fill="#28361E"
      d="M495.662 240.086h22.892v22.892h-22.892zM359.558 217.087h22.892v22.892h-22.892z"
    />
    <path
      fill="#28361E"
      d="M382.338 217.087h22.892v22.892h-22.892zM336.554 217.087h22.892v22.892h-22.892z"
    />
    <path fill="#343431" d="M313.661 217.087h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M290.769 217.087h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M267.878 217.087h22.892v22.892h-22.892z"/>
    <path fill="#475241" d="M404.89 217.087h22.892v22.892H404.89z"/>
    <path fill="#28361E" d="M426.797 217.087h22.892v22.892h-22.892z"/>
    <path fill="#545D4D" d="M449.689 217.087h22.892v22.892h-22.892z"/>
    <path fill="#65685F" d="M495.662 217.087h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M517.721 217.087h22.892v22.892h-22.892z"/>
    <path fill="#DFE1DE" d="M540.613 285.355h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M359.558 194.71h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M382.338 194.71h22.892v22.892h-22.892z"/>
    <path fill="#475241" d="M336.554 194.71h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M313.661 194.71h22.892v22.892h-22.892z"/>
    <path fill="#747D6E" d="M267.878 194.71h22.892v22.892h-22.892z"/>
    <path fill="#545D4D" d="M244.986 194.71h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M404.89 194.71h22.892v22.892H404.89z"/>
    <path
      fill="#28361E"
      d="M426.797 194.71h22.892v22.892h-22.892zM449.689 194.71h22.892v22.892h-22.892z"
    />
    <path fill="#545D4D" d="M472.581 194.71h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M495.662 194.71h22.892v22.892h-22.892z"/>
    <path fill="#475241" d="M359.558 171.818h22.892v22.892h-22.892z"/>
    <path fill="#343431" d="M382.338 171.818h22.892v22.892h-22.892z"/>
    <path
      fill="#28361E"
      d="M336.554 171.818h22.892v22.892h-22.892zM313.661 171.818h22.892v22.892h-22.892zM290.769 171.818h22.892v22.892h-22.892z"
    />
    <path
      fill="#747D6E"
      d="M244.986 171.818h22.892v22.892h-22.892zM222.094 171.818h22.892v22.892h-22.892z"
    />
    <path fill="#28361E" d="M404.89 171.818h22.892v22.892H404.89z"/>
    <path fill="#475241" d="M426.797 171.818h22.892v22.892h-22.892z"/>
    <path
      fill="#28361E"
      d="M449.689 171.818h22.892v22.892h-22.892zM472.581 171.818h22.892v22.892h-22.892zM495.662 171.818h22.892v22.892h-22.892z"
    />
    <path fill="#202A1A" d="M359.558 149.441h22.892v22.892h-22.892z"/>
    <path
      fill="#28361E"
      d="M382.338 149.441h22.892v22.892h-22.892zM336.554 149.441h22.892v22.892h-22.892z"
    />
    <path fill="#747D6E" d="M313.661 149.441h22.892v22.892h-22.892z"/>
    <path fill="#28361E" d="M290.769 149.441h22.892v22.892h-22.892z"/>
    <path fill="#384231" d="M404.89 149.441h22.892v22.892H404.89z"/>
    <path fill="#545D4D" d="M426.797 149.441h22.892v22.892h-22.892z"/>
    <path
      fill="#28361E"
      d="M449.689 149.441h22.892v22.892h-22.892zM359.558 126.442h22.892v22.892h-22.892z"
    />
    <path fill="#384231" d="M382.338 126.442h22.892v22.892h-22.892z"/>
    <path fill="#9BA098" d="M336.554 126.442h22.892v22.892h-22.892z"/>
    <path fill="#545D4D" d="M404.89 126.442h22.892v22.892H404.89z"/>
    <path fill="#F1F2F1" d="M426.797 126.442h22.892v22.892h-22.892z"/>
    <path fill="#747D6E" d="M359.558 104.065h22.892v22.892h-22.892z"/>
    <path fill="#B4B6B1" d="M382.338 104.065h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M404.837 580.088h22.892v22.892h-22.892z"/>
    <path fill="#343431" d="M381.945 580.088h22.892v22.892h-22.892z"/>
    <path
      fill="#343431"
      d="M381.945 557.196h22.892v22.892h-22.892zM381.945 534.304h22.892v22.892h-22.892zM381.945 511.412h22.892v22.892h-22.892zM381.945 488.52h22.892v22.892h-22.892zM404.837 522.858h22.892v22.892h-22.892z"
    />
    <path fill="#343431" d="M427.263 500.432h22.892v22.892h-22.892z"/>
    <path fill="#706C6B" d="M449.607 478.088h22.892v22.892h-22.892z"/>
    <path
      fill="#DFE1DE"
      d="M449.689 500.432h22.892v22.892h-22.892zM426.797 466.861h22.892v22.892h-22.892z"
    />
    <path fill="#9BA098" d="M426.797 522.858h22.892v22.892h-22.892z"/>
    <path fill="#706C6B" d="M359.053 488.52h22.892v22.892h-22.892z"/>
    <path fill="#9BA098" d="M359.053 465.628h22.892v22.892h-22.892z"/>
    <path fill="#343431" d="M381.945 465.628h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M427.729 580.088h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M290.377 602.98h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M313.269 602.98h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M336.161 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M359.053 602.98h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M381.945 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M404.837 602.98h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M427.729 602.98h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M450.621 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M473.514 602.98h22.892v22.892h-22.892z"/>
    <path fill="#A7C56C" d="M496.406 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M519.297 602.98h22.892v22.892h-22.892z"/>
    <path fill="#E4EACD" d="M542.189 602.98h22.892v22.892h-22.892z"/>
  </svg>
);
export default PinusPinaster;