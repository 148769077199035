import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';

import MeteorologicalData from '@/components/MeteorologicalData';
import Fauna from '@/components/Fauna';
import OwnersCarrousel from '@/components/admin/PlacesDetail/OwnersCarrousel';

import {EnvironmentalImpact, Meteo} from '@/domain/entities/Initiative';
import {Owner} from '@/domain/entities/Owner';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '@mui/material';

//STYLES
const containerSx: SxProps = {
  display: 'grid',
  gridTemplateAreas: {
    xs: `
      "header header" 
      "meteo fauna" 
      "owners owners"  
        `,
    md: `
      "header header header"
      "meteo fauna owners"
      `
  },
  gridTemplateColumns: {
    xs: '1fr 1fr',
    md: '1fr 1fr 2fr'
  },
  gridTemplateRows: {
    xs: '200px 1fr auto',
    md: '150px auto'
  },
  gap: '4px',
  width: {
    xs: '100%',
    md: '60%'
  }
};

const headerSx: SxProps = {
  gridArea: 'header',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  bgcolor: 'secondary.main',
  px: 4,
  py: 6
};

const meteoSx: SxProps = {
  gridArea: 'meteo',
  bgcolor: 'secondary.main',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  px: 2,
  py: 4
};

const faunaSx: SxProps = {
  gridArea: 'fauna',
  bgcolor: 'secondary.main',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  px: 2,
  py: 4
};
  
const ownersSx: SxProps = {
  gridArea: 'owners',
  bgcolor: 'secondary.main',
  px: 2,
  py: 4
};

//TYPES
export type TerritoryProps = {
  meteo: Meteo,
  environmentalImpacts: Array<EnvironmentalImpact>,
  owners: Array<Owner>
};

const Territory: FC<TerritoryProps> = ({meteo, environmentalImpacts, owners}) => {

  const {t} = useTranslation();
  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});

  return <Box sx={containerSx}>
    <Box sx={headerSx}>
      <Typography variant="title" textAlign="center" color="primary.main">{t('territory.title').toUpperCase()}</Typography>
      <Typography variant='paragraph' textAlign="center" color="common.white">{t('territory.description')}</Typography>
    </Box>
    <Box sx={meteoSx}>
      <MeteorologicalData meteo={meteo} isHorizontal={false}/>
    </Box>
    <Box sx={faunaSx}>
      <Fauna environmentalImpacts={environmentalImpacts} isHorizontal={false}/>
    </Box>
    <Box sx={ownersSx}>
      <OwnersCarrousel owners={owners} isDetail={true} orientation={widescreen ? 'horizontal' : 'vertical'}/>
    </Box>
  </Box>;
};
export default Territory;