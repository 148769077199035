import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import {SummaryInitiativeData} from '@/utils/getSummaryInitiativesData';
import {useTranslation} from 'react-i18next';
import SustainabilityActionList from '@/components/admin/InitiativesSummary/SustainabilityActionList';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

//TYPES
export type SustainabilityActionCardProps = {
  initiativesSummary: SummaryInitiativeData
};

const SustainabilityActionCard: FC<SustainabilityActionCardProps> = ({initiativesSummary}) => {

  const {t} = useTranslation();

  return <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}} elevation={3}>
    <Typography variant="subtitle" align="center"
      sx={{mt: 4, mb: 5, fontWeight: 900, color: 'secondary.main'}}>{t('initiatives.sustainability_actions').toUpperCase()}</Typography>
    <Box mx={8}>
      <SustainabilityActionList sustainabilityActions={initiativesSummary.sustainabilityActions}/>
    </Box>
  </Paper>;
};
export default SustainabilityActionCard;