import React, {FC, SVGProps} from 'react';

const PinusRadiata: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 808.108 711.158"
    height="100%"
    fill="none"
    {...props}
  >
    <path fill="#0F7540" d="M404.837 580.088h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M381.945 580.088h22.892v22.892h-22.892z"/>
    <path
      fill="#36321E"
      d="M381.945 557.196h22.892v22.892h-22.892zM381.945 534.304h22.892v22.892h-22.892zM381.945 511.412h22.892v22.892h-22.892zM381.945 488.52h22.892v22.892h-22.892z"
    />
    <path fill="#89A96B" d="M404.837 488.52h22.892v22.892h-22.892z"/>
    <path
      fill="#36321E"
      d="M381.945 465.628h22.892v22.892h-22.892zM358.461 442.966h22.892v22.892h-22.892z"
    />
    <path
      fill="#B1C3A4"
      d="M381.242 442.966h22.892v22.892h-22.892zM335.457 442.966h22.892v22.892h-22.892zM289.673 442.966h22.892v22.892h-22.892z"
    />
    <path fill="#7F9B6B" d="M403.793 442.966h22.892v22.892h-22.892z"/>
    <path fill="#8EA27F" d="M425.7 442.966h22.892v22.892H425.7z"/>
    <path fill="#B1C3A4" d="M448.592 442.966h22.892v22.892h-22.892z"/>
    <path fill="#6B8657" d="M358.461 421.251h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M381.242 421.251h22.892v22.892h-22.892z"/>
    <path
      fill="#8EA27F"
      d="M335.457 421.251h22.892v22.892h-22.892zM312.565 421.251h22.892v22.892h-22.892z"
    />
    <path fill="#6B8657" d="M289.673 421.251h22.892v22.892h-22.892z"/>
    <path fill="#8EA27F" d="M266.781 421.251h22.892v22.892h-22.892z"/>
    <path
      fill="#A2B592"
      d="M243.889 421.251h22.892v22.892h-22.892zM220.997 421.251h22.892v22.892h-22.892z"
    />
    <path fill="#8EA27F" d="M403.793 421.251h22.892v22.892h-22.892z"/>
    <path
      fill="#6B8657"
      d="M425.7 421.251h22.892v22.892H425.7zM448.592 421.251h22.892v22.892h-22.892z"
    />
    <path fill="#8EA27F" d="M471.484 421.251h22.892v22.892h-22.892z"/>
    <path fill="#A2B592" d="M494.566 421.251h22.892v22.892h-22.892z"/>
    <path fill="#6B8657" d="M358.461 399.041h22.892v22.892h-22.892z"/>
    <path fill="#7D8568" d="M381.242 399.041h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M335.457 399.041h22.892v22.892h-22.892z"/>
    <path fill="#B1C3A4" d="M312.565 399.041h22.892v22.892h-22.892z"/>
    <path
      fill="#A2B592"
      d="M289.673 399.041h22.892v22.892h-22.892zM266.781 399.041h22.892v22.892h-22.892z"
    />
    <path fill="#C6D2B8" d="M243.889 399.041h22.892v22.892h-22.892z"/>
    <path fill="#A2B592" d="M403.793 399.041h22.892v22.892h-22.892z"/>
    <path fill="#C1D6AB" d="M425.7 399.041h22.892v22.892H425.7z"/>
    <path
      fill="#89A96B"
      d="M448.592 399.041h22.892v22.892h-22.892zM471.484 399.041h22.892v22.892h-22.892z"
    />
    <path fill="#C6D2B8" d="M494.566 399.041h22.892v22.892h-22.892z"/>
    <path fill="#C6D2B8" d="M516.624 399.041h22.892v22.892h-22.892z"/>
    <path fill="#7D8568" d="M358.461 377.083h22.892v22.892h-22.892z"/>
    <path fill="#6B8657" d="M381.242 377.083h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M335.457 377.083h22.892v22.892h-22.892z"/>
    <path fill="#B1C3A4" d="M312.565 377.083h22.892v22.892h-22.892z"/>
    <path fill="#A2B592" d="M289.673 377.083h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M266.781 377.083h22.892v22.892h-22.892z"/>
    <path
      fill="#C6D2B8"
      d="M243.889 377.083h22.892v22.892h-22.892zM220.997 377.083h22.892v22.892h-22.892z"
    />
    <path fill="#7F9B6B" d="M403.793 377.083h22.892v22.892h-22.892z"/>
    <path
      fill="#A2B592"
      d="M425.7 377.083h22.892v22.892H425.7zM448.592 377.083h22.892v22.892h-22.892zM471.484 377.083h22.892v22.892h-22.892z"
    />
    <path fill="#C1D6AB" d="M494.566 377.083h22.892v22.892h-22.892z"/>
    <path fill="#ABC696" d="M516.624 377.083h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M358.461 354.675h22.892v22.892h-22.892z"/>
    <path
      fill="#B1C3A4"
      d="M381.242 354.675h22.892v22.892h-22.892zM335.457 354.675h22.892v22.892h-22.892z"
    />
    <path fill="#C6D2B8" d="M312.565 354.675h22.892v22.892h-22.892z"/>
    <path fill="#9EBA83" d="M289.673 354.675h22.892v22.892h-22.892z"/>
    <path fill="#B1C3A4" d="M266.781 354.675h22.892v22.892h-22.892z"/>
    <path fill="#89A96B" d="M243.889 354.675h22.892v22.892h-22.892z"/>
    <path fill="#8EA27F" d="M403.793 354.675h22.892v22.892h-22.892z"/>
    <path fill="#8EA27F" d="M425.7 354.675h22.892v22.892H425.7z"/>
    <path
      fill="#C6D2B8"
      d="M448.592 354.675h22.892v22.892h-22.892zM471.484 354.675h22.892v22.892h-22.892z"
    />
    <path fill="#ABC696" d="M405.612 332.592h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M428.392 332.592h22.892v22.892h-22.892z"/>
    <path fill="#6B8657" d="M382.608 332.592H405.5v22.892h-22.892z"/>
    <path fill="#C6D2B8" d="M359.716 332.592h22.892v22.892h-22.892z"/>
    <path fill="#9EBA83" d="M336.824 332.592h22.892v22.892h-22.892z"/>
    <path
      fill="#C1D6AB"
      d="M313.932 332.592h22.892v22.892h-22.892zM291.04 332.592h22.892v22.892H291.04z"
    />
    <path fill="#7F9B6B" d="M450.944 332.592h22.892v22.892h-22.892z"/>
    <path fill="#ABC696" d="M472.851 332.592h22.892v22.892h-22.892z"/>
    <path fill="#D5DFCA" d="M495.743 332.592h22.892v22.892h-22.892z"/>
    <path fill="#ABC696" d="M382.72 310.229h22.892v22.892H382.72z"/>
    <path fill="#7F9B6B" d="M405.5 310.229h22.892v22.892H405.5z"/>
    <path fill="#6B8657" d="M359.716 310.229h22.892v22.892h-22.892z"/>
    <path fill="#C6D2B8" d="M336.824 310.229h22.892v22.892h-22.892z"/>
    <path fill="#6B8657" d="M313.932 310.229h22.892v22.892h-22.892z"/>
    <path
      fill="#D5DFCA"
      d="M291.04 310.229h22.892v22.892H291.04zM268.148 310.229h22.892v22.892h-22.892z"
    />
    <path fill="#7F9B6B" d="M428.052 310.229h22.892v22.892h-22.892z"/>
    <path fill="#C1D6AB" d="M449.959 310.229h22.892v22.892h-22.892z"/>
    <path fill="#D5DFCA" d="M472.851 310.229h22.892v22.892h-22.892z"/>
    <path fill="#7D8568" d="M382.72 288.098h22.892v22.892H382.72z"/>
    <path fill="#89A96B" d="M405.5 288.098h22.892v22.892H405.5z"/>
    <path fill="#6B8657" d="M359.716 288.098h22.892v22.892h-22.892z"/>
    <path fill="#9EBA83" d="M336.824 288.098h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M313.932 288.098h22.892v22.892h-22.892z"/>
    <path fill="#9EBA83" d="M291.04 288.098h22.892v22.892H291.04z"/>
    <path fill="#D5DFCA" d="M268.148 288.098h22.892v22.892h-22.892z"/>
    <path fill="#C6D2B8" d="M428.052 288.098h22.892v22.892h-22.892z"/>
    <path
      fill="#7F9B6B"
      d="M449.959 288.098h22.892v22.892h-22.892zM472.851 288.098h22.892v22.892h-22.892z"
    />
    <path fill="#ABC696" d="M496.406 288.098h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M382.72 265.751h22.892v22.892H382.72z"/>
    <path fill="#B1C3A4" d="M405.5 265.751h22.892v22.892H405.5z"/>
    <path fill="#89A96B" d="M359.716 265.751h22.892v22.892h-22.892z"/>
    <path fill="#9EBA83" d="M336.824 265.751h22.892v22.892h-22.892z"/>
    <path fill="#C1D6AB" d="M313.932 265.751h22.892v22.892h-22.892z"/>
    <path fill="#9EBA83" d="M291.04 265.751h22.892v22.892H291.04z"/>
    <path fill="#B1C3A4" d="M428.052 265.751h22.892v22.892h-22.892z"/>
    <path fill="#7D8568" d="M382.72 243.759h22.892v22.892H382.72z"/>
    <path fill="#C6D2B8" d="M405.5 243.759h22.892v22.892H405.5z"/>
    <path
      fill="#89A96B"
      d="M359.716 243.759h22.892v22.892h-22.892zM336.824 243.759h22.892v22.892h-22.892z"
    />
    <path fill="#7F9B6B" d="M313.932 243.759h22.892v22.892h-22.892z"/>
    <path fill="#D5DFCA" d="M291.04 243.759h22.892v22.892H291.04z"/>
    <path fill="#C6D2B8" d="M428.052 243.759h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M382.72 221.351h22.892v22.892H382.72z"/>
    <path fill="#ABC696" d="M405.5 221.351h22.892v22.892H405.5z"/>
    <path
      fill="#89A96B"
      d="M359.716 221.351h22.892v22.892h-22.892zM336.824 221.351h22.892v22.892h-22.892z"
    />
    <path fill="#A2B592" d="M313.932 221.351h22.892v22.892h-22.892z"/>
    <path fill="#9EBA83" d="M291.04 221.351h22.892v22.892H291.04z"/>
    <path fill="#89A96B" d="M428.052 221.351h22.892v22.892h-22.892z"/>
    <path fill="#ABC696" d="M450.944 221.351h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M382.72 199.419h22.892v22.892H382.72z"/>
    <path fill="#C6D2B8" d="M405.5 199.419h22.892v22.892H405.5z"/>
    <path fill="#6B8657" d="M359.716 199.419h22.892v22.892h-22.892z"/>
    <path fill="#89A96B" d="M336.824 199.419h22.892v22.892h-22.892z"/>
    <path
      fill="#7F9B6B"
      d="M313.932 199.419h22.892v22.892h-22.892zM382.72 177.434h22.892v22.892H382.72z"
    />
    <path fill="#7F9B6B" d="M405.5 177.434h22.892v22.892H405.5z"/>
    <path
      fill="#C6D2B8"
      d="M428.392 177.434h22.892v22.892h-22.892zM359.716 177.434h22.892v22.892h-22.892z"
    />
    <path fill="#8EA27F" d="M336.824 177.434h22.892v22.892h-22.892z"/>
    <path fill="#ABC696" d="M313.932 177.434h22.892v22.892h-22.892z"/>
    <path fill="#7D8568" d="M382.72 155.021h22.892v22.892H382.72z"/>
    <path
      fill="#C6D2B8"
      d="M405.5 155.021h22.892v22.892H405.5zM428.392 155.021h22.892v22.892h-22.892z"
    />
    <path fill="#6B8657" d="M359.716 155.021h22.892v22.892h-22.892z"/>
    <path fill="#8EA27F" d="M336.824 155.021h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M448.592 155.021h22.892v22.892h-22.892z"/>
    <path fill="#8EA27F" d="M382.72 132.945h22.892v22.892H382.72z"/>
    <path fill="#8EA27F" d="M405.5 132.945h22.892v22.892H405.5z"/>
    <path fill="#9EBA83" d="M428.392 132.945h22.892v22.892h-22.892z"/>
    <path fill="#6B8657" d="M359.716 132.945h22.892v22.892h-22.892z"/>
    <path fill="#8EA27F" d="M336.824 132.945h22.892v22.892h-22.892z"/>
    <path fill="#7D8568" d="M382.72 110.627h22.892v22.892H382.72z"/>
    <path fill="#8EA27F" d="M405.5 110.627h22.892v22.892H405.5z"/>
    <path fill="#A2B592" d="M428.392 110.627h22.892v22.892h-22.892z"/>
    <path fill="#C6D2B8" d="M448.592 110.627h22.892v22.892h-22.892z"/>
    <path fill="#6B8657" d="M359.716 110.627h22.892v22.892h-22.892z"/>
    <path fill="#8EA27F" d="M336.824 110.627h22.892v22.892h-22.892z"/>
    <path fill="#A2B592" d="M382.72 88.353h22.892v22.892H382.72z"/>
    <path fill="#A2B592" d="M405.5 88.353h22.892v22.892H405.5z"/>
    <path fill="#D5DFCA" d="M428.392 88.353h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M359.716 88.353h22.892v22.892h-22.892z"/>
    <path fill="#A2B592" d="M382.72 66.325h22.892v22.892H382.72z"/>
    <path fill="#C6D2B8" d="M405.5 66.325h22.892v22.892H405.5z"/>
    <path fill="#B1C3A4" d="M359.716 66.325h22.892v22.892h-22.892z"/>
    <path
      fill="#C6D2B8"
      d="M382.72 44.035h22.892v22.892H382.72zM382.72 21.143h22.892v22.892H382.72z"
    />
    <path fill="#D5DFCA" d="M405.5 44.035h22.892v22.892H405.5z"/>
    <path fill="#C6D2B8" d="M359.716 44.035h22.892v22.892h-22.892z"/>
    <path fill="#D5DFCA" d="M336.824 88.353h22.892v22.892h-22.892z"/>
    <path fill="#8EA27F" d="M313.932 110.627h22.892v22.892h-22.892z"/>
    <path fill="#C1D6AB" d="M450.944 199.419h22.892v22.892h-22.892z"/>
    <path fill="#7F9B6B" d="M472.851 265.751h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M427.729 580.088h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M290.377 602.98h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M313.269 602.98h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M336.161 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M359.053 602.98h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M381.945 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M404.837 602.98h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M427.729 602.98h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M450.621 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M473.514 602.98h22.892v22.892h-22.892z"/>
    <path fill="#A7C56C" d="M496.406 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M519.297 602.98h22.892v22.892h-22.892z"/>
    <path fill="#E4EACD" d="M542.189 602.98h22.892v22.892h-22.892z"/>
  </svg>
);
export default PinusRadiata;