import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//UTILS
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useMediaQuery} from '@mui/material';

//TYPES
export type OwnerProps = {
  title: string,
  text: string,
  image: string,
  orientation: 'vertical' | 'horizontal',
  isDetail?: boolean
};

const OwnerCard: FC<OwnerProps> = ({title, text, image, orientation, isDetail=false}) => {

  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});

  const containerSx: SxProps = {
    display: 'flex',
    flexDirection: orientation === 'vertical' ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    ml: 2
  };

  return <Box sx={containerSx}>
    <img src={image} alt={title} style={{
      marginTop: '48px',
      width: '140px',
      height:'140px',
      borderRadius: '150px',
      objectFit: 'cover'
    }}/>
    <Box sx={{display: 'flex', flexDirection: 'column', px: 1}}>
      <Typography variant='subtitle' textAlign={widescreen ? 'left' : 'center'} gutterBottom mt={3} mb={2} sx={{color: isDetail ? 'common.white' : 'primary.grey'}}>{title}</Typography>
      <Typography variant='paragraph' textAlign={widescreen ? 'left' : 'center'} sx={{minHeight: '72px', color: isDetail ? 'common.white' : 'primary.grey'}}>{text}</Typography>
    </Box>

  </Box>;
};
export default OwnerCard;