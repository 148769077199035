import {AnyLayer} from 'mapbox-gl';
import {BaseInitiative, Initiative} from '@/domain/entities/Initiative';

const buildLayers = (
  initiatives: Array<Initiative> | undefined,
  interested: Array<BaseInitiative> | undefined,
  available: Array<BaseInitiative> | undefined,
  purchased: Array<BaseInitiative> | undefined,
) => {
  const layers: Array<AnyLayer> = [];
  if (available) {
    layers.push(
      {
        'id': 'available-background',
        'source': 'available',
        'type': 'fill',
        'maxzoom': 20,
        'minzoom': 12,
        'paint': {
          'fill-color': 'rgb(255,255,255)',
          'fill-opacity': 0.8
        },
        'layout': {'visibility': 'visible'}
      }, 
      {
        'id': 'available-border',
        'source': 'available',
        'type': 'line',
        'maxzoom': 20,
        'minzoom': 12,
        'paint': {
          'line-color': 'rgb(87,157,29)',
          'line-dasharray': [2, 2],
          'line-width': 2
        },
        'layout': {'visibility': 'visible'}
      }
    );
  }
  if(purchased) {
    layers.push(
      {
        'id': 'purchased-background',
        'source': 'purchased',
        'type': 'fill',
        'maxzoom': 20,
        
        'paint': {
          'fill-color': 'rgb(255,255,255)',
          'fill-opacity': 0.8
        },
        'layout': {'visibility': 'visible'}
      }, 
      {
        'id': 'purchased-border',
        'source': 'purchased',
        'type': 'line',
        'maxzoom': 20,
        
        'paint': {
          'line-color': '#757575',
          'line-dasharray': [2, 2],
          'line-width': 2
        },
        'layout': {'visibility': 'visible'}
      }
    );
  }
  if(interested) {
    layers.push(
      {
        'id': 'interested-background',
        'source': 'interested',
        'type': 'fill',
        'maxzoom': 20,
        'paint': {
          'fill-color': 'rgb(255,255,255)',
          'fill-opacity': 0.8
        },
        'layout': {'visibility': 'visible'}
      }, 
      {
        'id': 'interested-border',
        'source': 'interested',
        'type': 'line',
        'maxzoom': 20,
        'paint': {
          'line-color': '#ff9800',
          'line-dasharray': [2, 2],
          'line-width': 2
        },
        'layout': {'visibility': 'visible'}
      }
    );
  }
  if (initiatives) {
    layers.push({
      'id': 'initiative-background',
      'source': 'initiatives',
      'type': 'fill',
      'maxzoom': 20,
      'paint': {
        'fill-color': 'rgb(87,157,29)',
        'fill-opacity': 0.5
      },
      'layout': {'visibility': 'visible'}
    }, 
    {
      'id': 'initiative-border',
      'source': 'initiatives',
      'type': 'line',
      'maxzoom': 20,
      'paint': {
        'line-color': 'rgb(223,241,193)',
        'line-dasharray': [4, 4],
        'line-width': 2
      },
      'layout': {'visibility': 'visible'}
    }
    );
  }
  return layers;
};

export default buildLayers;