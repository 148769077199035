import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import Box from '@mui/material/Box';

//GEOCOMPONENTS
import Map from '@geomatico/geocomponents/Map/Map';

import {NavigationControl} from 'react-map-gl';
import {AnyLayer, Sources, Style} from 'mapbox-gl';

import {MapRef} from 'react-map-gl';
import {Bbox} from '@/domain/commonTypes';
import {useMediaQuery} from '@mui/material';
import {MAPSTYLES} from '@/config';
import BaseMapPicker from '@geomatico/geocomponents/Map/BaseMapPicker';

export const DEFAULT_VIEWPORT = {
  zoom: 14.99,
  bearing: 0,
  pitch: 0
};

type Viewport = {
  zoom: number,
  bearing: number,
  pitch: number
}

export interface MiniMapProps {
  feature: GeoJSON.FeatureCollection,
  bbox: Bbox,
  mapStyle: Style | string,
  mapStyleId: string,
  onStyleChange: (mapStyleId: string) => void,
}

const MiniMap: FC<MiniMapProps> = ({
  feature,
  bbox,
  mapStyle,
  mapStyleId,
  onStyleChange,
}) => {

  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});
  
  const sources = useMemo<Sources | undefined>(() => 
    feature 
      ? { areas: {type: 'geojson', data: feature} }
      : undefined,
  [feature]);

  const layers = useMemo<Array<AnyLayer>>(() => 
    feature ? 
      [
        {
          'id': 'area',
          'source': 'areas',
          'type': 'fill',
          'maxzoom': 20,
          'paint': {
            'fill-color': 'rgb(4,115,47)',
            'fill-opacity': 0.5
          }
        },
        {
          'id': 'border',
          'type': 'line',
          'source': 'areas',
          'paint': {
            'line-color':  'rgb(255,255,255)',
            'line-dasharray': [3, 2],
            'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1, 16, 3]
          }
        }      
      ] : 
      [], [feature]);

  const mapRef = useRef<MapRef>(null);

  const zoomToFeature = () => {
    if (feature && mapRef.current) {
      // calculate the bounding box of the feature
      const {xmin, ymin, xmax, ymax} = bbox;

      mapRef.current.fitBounds(
        [
          [xmin, ymin],
          [xmax, ymax]
        ],
        {
          duration: 1000,
          padding: {
            top: widescreen ? 120 : 90,
            right: 50,
            bottom: widescreen ? 120 : 90,
            left: 50
          }
        }
      );
    }
  };

  useEffect(() => {
    zoomToFeature();
  }, [feature]);

  const [viewport, setViewport] = useState<Viewport>(DEFAULT_VIEWPORT);

  return <Box sx={{width: '100%', height: '100%'}}>
    <Map
      ref={mapRef}
      onLoad={zoomToFeature}
      cursor='auto'
      sources={sources}
      layers={layers}
      mapStyle={mapStyle}
      scrollZoom={true}
      viewport={viewport}
      onViewportChange={setViewport}
    >
      <BaseMapPicker styles={MAPSTYLES} selectedStyleId={mapStyleId} onStyleChange={onStyleChange}/>
      <NavigationControl
        showZoom={true}
        visualizePitch={false}
        position='top-right'
      />
    </Map>
  </Box>;
};

export default MiniMap;