import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';

//GREEN BONUS
import OwnerCard from '@/components/admin/PlacesDetail/OwnerCard';
import CarouselGreenBonus from '@/components/CarouselGreenBonus';

//TYPES
import {Owner} from '@/domain/entities/Owner';

export type OwnersCarrouselProps = {
  owners: Array<Owner>,
  isDetail?: boolean,
  orientation: 'vertical' | 'horizontal',
};

const OwnersCarrousel: FC<OwnersCarrouselProps> = ({owners, orientation, isDetail=false}) => {

  return <Box sx={{width: '100%'}}>
    {
      owners && owners.length !== 1
        ? <CarouselGreenBonus>
          {
            owners.map(({title, text, image}, index) =>
              <OwnerCard title={title} text={text} image={image} key={index} isDetail={isDetail} orientation={orientation}/>
            )
          }
        </CarouselGreenBonus>
        : <OwnerCard title={owners[0].title} text={owners[0].text} image={owners[0].image} isDetail={isDetail} orientation={orientation}/>
    }
  </Box>;
};

export default OwnersCarrousel;