export enum SkyState {
  SUNNY = 'SUNNY',
  HIGH_CLOUDS = 'HIGH_CLOUDS',
  PARTLY_CLOUDY = 'PARTLY_-CLOUDY',
  OVERCAST = 'OVERCAST',
  CLOUDY = 'CLOUDY',
  FOG = 'FOG',
  SHOWERS = 'SHOWERS',
  OVERCAST_AND_SHOWERS = 'OVERCAST_AND_SHOWERS',
  INTERMITENT_SNOW = 'INTERMITENT_SNOW',
  DRIZZLE = 'DRIZZLE',
  RAIN = 'RAIN',
  SNOW = 'SNOW',
  STORMS = 'STORMS',
  MIST = 'MIST',
  FOG_BANK = 'FOG_BANK',
  MID_CLOUDS = 'MID_CLOUDS',
  WEAK_RAIN = 'WEAK_RAIN',
  WEAK_SHOWERS = 'WEAK_SHOWERS',
  STORM_THEN_CLOUDY = 'STORM_THEN_CLOUDY',
  MELTED_SNOW = 'MELTED_SNOW',
  RAIN_HAYL = 'RAIN_HayL'
}

export type Meteo = {
  temperature: number,
  sky_state: SkyState | null,
}