import React, {FC, SVGProps} from 'react';

const Quercus: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 808.108 711.158"
    height="100%"
    fill="none"
    {...props}
  >
    <path
      fill="#475828"
      d="m376.663 587.407 113.436.227.002 22.338c-20.227.066-40.454.127-60.681.207-2.454.01-4.907.121-7.36.185l-22.63.016-45.67-.413.062-22.496c7.614-.023 15.228-.043 22.841-.064z"
    />
    <path
      fill="#2E3A19"
      d="m376.662 496.729-.313 45.291c-5.845.005-11.702.233-17.532-.062-4.076-.206-5.236 1.375-5.063 5.23.26 5.834.076 11.688.08 17.533l-.027.027-22.502-.017-.411-45.48.417.125c5.472-.022 10.975-.412 16.408.041 5.227.436 6.313-1.624 6.202-6.44-.3-13.014-.086-26.039-.076-39.06l22.643-.049.174 22.861z"
    />
    <path
      fill="#475828"
      d="m194.833 610.349-22.689-.381v-22.33l113.548.002.023 22.385-45.55.236-22.689.058-22.643.03zM626.338 609.952l-113.555.014v-22.328l113.555.01v22.304z"
    />
    <path
      fill="#222D12"
      d="m35.785 223.986.131-22.516c6.214-.058 12.434-.304 18.639-.098 3.264.109 4.152-.974 4.032-4.122-.237-6.209-.09-12.433-.102-18.65l.172.1 22.453-.207 22.841.096.002 22.902c-6.414-.041-12.832.036-19.24-.181-2.714-.092-3.576.734-3.49 3.471.203 6.41.098 12.829.117 19.245l-45.436.085-.119-.125z"
    />
    <path
      fill="#3C4A21"
      d="m285.715 610.025-.023-22.385c13.609-.067 27.22-.271 40.826-.109 3.697.044 4.843-.935 4.676-4.669-.269-6.031.043-12.087.11-18.133l22.502.017.016 22.722-.062 22.496.048.349-22.624.009-45.469-.297z"
    />
    <path
      fill="#222D12"
      d="m649.012 450.988-22.717.133.089.092c-.024-5.99-.24-11.987-.001-17.967.143-3.585-.851-4.985-4.651-4.801-5.975.288-11.976.051-17.966.037l-.261-22.675 23.039-.139 22.374.107.225 22.549-.131 22.664z"
    />
    <path
      fill="#B7B9AE"
      d="M353.864 42.245c-.016-6.043.06-12.089-.086-18.129-.072-2.966.647-4.513 4.056-4.495 20.019.107 40.039.106 60.058.002 3.358-.017 4.182 1.436 4.103 4.451-.158 6.04-.079 12.086-.098 18.129l.131.364-22.819-.008-22.623-.03-22.855.014.133-.298zM-9.41 360.171l.081-22.664c6.006.018 12.019-.131 18.015.117 3.531.146 4.548-1.218 4.453-4.58-.169-5.998.037-12.007.087-18.011l-.123-.278 22.809.242-.037 22.69-.18 22.593-22.455.069-22.65-.178z"
    />
    <path
      fill="#3C4A21"
      d="M739.88 292.375c6.415-.065 12.839.045 19.243-.267 3.3-.161 3.645 1.338 3.558 4.007-.16 4.904.127 9.826-.108 14.725-.149 3.102.664 4.282 3.992 4.165 6.218-.219 12.452.039 18.679.099l.068 22.536.003-.003-22.828.055-22.593-.255.002-22.53-.016-22.532z"
    />
    <path
      fill="#B7B9AE"
      d="M580.876 474.288c-6.391-.119-12.784-.167-19.171-.392-2.807-.099-3.549 1.202-3.483 3.747.118 4.516-.212 9.055.1 13.552.281 4.057-.788 5.821-5.237 5.521-5.812-.392-11.668-.129-17.506-.148l-.079-22.758.012-22.662 22.74-.188 22.644.12.066 22.652-.086.556z"
    />
    <path
      fill="#536031"
      d="m467.353 42.243 45.431.317-.026 22.495-22.785.11-22.577-.189.067-22.311-.11-.422z"
    />
    <path
      fill="#8B8F81"
      d="M580.944 133.052c6.178.071 12.359.293 18.532.148 3.109-.073 4.307.804 4.208 4.078-.186 6.173.036 12.358.088 18.537l-22.914.256-22.615-.166.12-22.797 22.581-.056z"
    />
    <path
      fill="#536031"
      d="m81.435 587.523 45.285.098v22.359l-45.285.098v-22.555zM717.017 269.501l.2 22.598c.045 7.609.09 15.217.133 22.826l-.303-.024-22.541.021-.055-22.607-.058-22.723c7.542-.032 15.083-.061 22.624-.091z"
    />
    <path
      fill="#0F1A09"
      d="m262.854 473.946-22.569.084.169-.259-.159-.265.015-22.381 22.798-.032 22.496-.013.146 22.891.002-.037-22.898.012z"
    />
    <path
      fill="#2E3A19"
      d="m172.031 496.461-22.598-.048-.014-22.498.002-22.712 22.754.033.062 22.799-.206 22.426z"
    />
    <path
      fill="#475828"
      d="m580.859 156.072 22.914-.256 22.55.282-.008 22.6-22.555-.014c-5.797-.012-11.605-.251-17.386.04-4.06.204-5.756-.902-5.507-5.27.329-5.774.024-11.586-.008-17.382z"
    />
    <path
      fill="#7A8263"
      d="m285.642 42.537 45.54.008-.245 22.497-22.583.115-22.651-.087-.061-22.533z"
    />
    <path
      fill="#5F694F"
      d="M126.738 496.735c-.005 6.206-.191 12.419.06 18.614.134 3.313-1.043 4.158-4.168 4.025-6.193-.263-12.402-.174-18.604-.23l-.102-22.428 22.835-.44-.021.459z"
    />
    <path
      fill="#3C4A21"
      d="M807.858 337.699c.062 6.419-.001 12.843.242 19.256.107 2.807-.909 3.494-3.553 3.442-6.418-.125-12.841.042-19.262.091l.109-.375-.078-22.477-.003.003 22.545.06z"
    />
    <path
      fill="#2E3A19"
      d="m353.823 587.469-.016-22.722.027-.027 22.513.017.317 22.669-22.841.063z"
    />
    <path
      fill="#3C4A21"
      d="M467.458 496.568c-.033 6.416-.184 12.834-.041 19.246.059 2.674-.708 3.906-3.466 3.476-.184-.029-.377 0-.566-.004-6.011-.102-13.892 2.062-17.516-.931-3.77-3.112-.812-11.303-1.173-17.259-.091-1.505-.077-3.017-.112-4.525l.256.147 22.361-.002.257-.148zM376.346 564.737l-22.513-.017c-.004-5.845.18-11.699-.08-17.533-.172-3.855.987-5.436 5.063-5.23 5.83.295 11.687.067 17.532.062l-.002 22.718z"
    />
    <path
      fill="#8B8F81"
      d="M13.227 315.033c-.049 6.004-.256 12.013-.087 18.011.095 3.361-.922 4.725-4.453 4.58-5.997-.248-12.009-.099-18.015-.117l-.054-22.7 22.609.226z"
    />
    <path
      fill="#222D12"
      d="m717.352 314.924-.135-22.826 22.662.277.017 22.53-22.544.019z"
    />
    <path
      fill="#7A8263"
      d="m807.858 337.699-22.545-.06-.068-22.536c6.419-.058 12.841-.019 19.255-.22 2.71-.085 3.636.853 3.555 3.558-.194 6.416-.147 12.839-.197 19.258z"
    />
    <path
      fill="#475828"
      d="M126.721 609.98v-22.359l22.689.013v22.336l-22.689.01z"
    />
    <path
      fill="#536031"
      d="M149.409 609.97v-22.336l22.735.004v22.329l-22.735.003z"
    />
    <path
      fill="#3C4A21"
      d="M512.782 587.638v22.328l-22.681.006-.002-22.338 22.683.004z"
    />
    <path
      fill="#222D12"
      d="M626.296 451.121c.014 6.55-.07 13.102.093 19.648.061 2.457-.701 3.218-3.165 3.176-6.545-.114-13.094.038-19.642.084l.172-.316.028-22.298 22.604-.202-.09-.092z"
    />
    <path
      fill="#475828"
      d="m512.843 474.053-22.676-.258.031.027.067-22.574 22.405.267.048 22.19.125.348z"
    />
    <path
      fill="#2E3A19"
      d="m285.75 473.971-.146-22.891-.041.04 22.808.035-.016 22.378-.182.278.213.256-22.636-.096z"
    />
    <path
      fill="#8B8F81"
      d="m58.546 473.884-22.595-.175.053-22.428-.087.024 22.481-.335.503 23.133-.355-.219z"
    />
    <path
      fill="#5F694F"
      d="m694.694 428.344-.337 22.696-22.616-.063-.084-22.656.001.101 22.714.062.322-.14z"
    />
    <path
      fill="#536031"
      d="m671.657 428.321.084 22.656-22.728.011.131-22.664 22.513-.003z"
    />
    <path
      fill="#8B8F81"
      d="m399.083 473.826.044 22.725-22.465.177-.174-22.863.191.128 22.404-.167z"
    />
    <path
      fill="#5F694F"
      d="m-9.458 383.224 22.559-.254.065 22.798-22.621.042-.003-22.586z"
    />
    <path
      fill="#7A8263"
      d="m103.923 496.718.103 22.427-22.644.129-.128-22.461c-.156-.204-.153-.406.009-.605 7.57.882 15.161.288 22.743.331l-.083.179z"
    />
    <path
      fill="#3C4A21"
      d="m149.419 473.915.014 22.498-22.695.322.02-.456-.028-22.384 22.689.02z"
    />
    <path
      fill="#8B8F81"
      d="m172.031 496.461.205-22.427 22.76.057-.245 22.472-22.72-.102z"
    />
    <path
      fill="#3C4A21"
      d="m217.692 496.544-.267-22.552 22.87-.486.159.265-.169.259c-.008 6.382-.138 12.767.033 19.145.073 2.709-.779 3.556-3.482 3.475-6.377-.191-12.762-.089-19.144-.106z"
    />
    <path
      fill="#475828"
      d="m-9.455 405.809 22.621-.042-.018-.018-.073 22.514.136.399-22.624-.319-.246-22.659.204.125z"
    />
    <path
      fill="#222D12"
      d="m262.854 473.946 22.897-.012c-.022 6.416-.172 12.835-.004 19.246.071 2.729-.765 3.585-3.488 3.473-6.196-.257-14.118 2.045-18.112-.969-3.785-2.857-.794-11.263-1.164-17.215-.093-1.505-.089-3.015-.129-4.523z"
    />
    <path
      fill="#8B8F81"
      d="m330.969 496.714-22.509-.25-.075-22.396-.213-.256.182-.278 22.661.453-.046 22.727zM81.111 178.494l-22.453.207-.036-22.66 22.735-.387-.088.443-.158 22.397z"
    />
    <path
      fill="#5F694F"
      d="m626.314 178.698.009-22.6c6.224-.062 12.454.025 18.668-.247 3.147-.138 4.305.728 4.169 4.044-.254 6.217-.066 12.453-.062 18.68l-.032.118-22.887.11.135-.105z"
    />
    <path
      fill="#222D12"
      d="m217.683 65.146 22.622-.283-.26.2.133.3.123 22.29-22.716.119.098-22.626z"
    />
    <path
      fill="#B7B9AE"
      d="m194.676 65.185.255 22.36-.164.481-22.73.044.1-.409c.022-6.206.154-12.414.017-18.616-.065-2.922.951-4.006 3.905-3.927 6.202.164 12.411.061 18.617.067z"
    />
    <path
      fill="#8B8F81"
      d="M535.434 42.537c6.425-.062 12.852-.033 19.273-.228 2.695-.082 3.667.856 3.585 3.569-.193 6.421-.159 12.849-.217 19.274l-22.549-.082-.092-22.533z"
    />
    <path
      fill="#637037"
      d="m535.434 42.537.093 22.534.014.018-22.696.053-.086-.088.026-22.494 22.649-.023z"
    />
    <path
      fill="#475828"
      d="m421.898 42.203 22.789.052-.124.423-.017 22.304c-6.022.037-12.049-.063-18.064.164-3.4.128-4.727-1.02-4.591-4.513.234-6.014.111-12.043.138-18.065l-.131-.365z"
    />
    <path
      fill="#3C4A21"
      d="m330.936 65.041.246-22.496 22.682-.3-.133.298-.131 22.627-22.309-.05-.355-.079z"
    />
    <path
      fill="#536031"
      d="m285.642 42.537.061 22.533.026.043-22.802.048.035-.202.117-22.481 22.563.059z"
    />
    <path
      fill="#B7B9AE"
      d="m263.08 42.478-.117 22.481-22.784.404-.133-.3.26-.2c-.04-6.006.057-12.018-.173-18.017-.127-3.3.773-4.776 4.373-4.606 6.18.292 12.381.18 18.574.238zM444.687 42.255c.019-6.027.218-12.061-.01-18.078-.133-3.518 1.139-4.642 4.553-4.463 4.508.236 9.047.235 13.555 0 3.402-.177 4.692.92 4.558 4.452-.227 6.017-.017 12.051.009 18.077l.111.421-22.901.013.125-.422z"
    />
    <path
      fill="#637037"
      d="m126.654 110.349 22.616.12.279-.185.295.158-.594 22.793-22.558.004.089-22.562-.127-.328z"
    />
    <path
      fill="#B7B9AE"
      d="m580.832 110.336-22.538.163-.299.166-.038-22.99.38.154c6.211.011 12.425.128 18.632-.014 2.902-.066 4.032.91 3.949 3.888-.173 6.208-.073 12.422-.086 18.633z"
    />
    <path
      fill="#222D12"
      d="M149.27 110.468c.05-6.422.237-12.846.086-19.263-.065-2.767.834-3.516 3.519-3.459 6.418.138 12.842-.037 19.264-.086l-.101.41.099 22.436-22.293-.065-.295-.158-.279.185z"
    />
    <path
      fill="#536031"
      d="m535.526 65.071 22.549.082.263 22.677-.38-.154-22.469.147.052-22.734-.015-.018z"
    />
    <path
      fill="#2E3A19"
      d="m694.342 224.021.167 22.729-.08-.011-22.684-.069.101-22.53 22.496-.119z"
    />
    <path
      fill="#3C4A21"
      d="m13.227 315.033-22.609-.226.012-22.581 22.529-.018-.056 22.547.124.278z"
    />
    <path
      fill="#536031"
      d="m13.16 292.208-22.529.018-.106-22.592 22.621-.366-.03.536.023 22.368.021.036z"
    />
    <path
      fill="#637037"
      d="m717.017 269.501-22.623.089.082-.138-.047-22.712.08.011c6.418.005 12.839.129 19.253-.041 2.749-.072 3.581.867 3.491 3.55-.215 6.409-.174 12.827-.236 19.241z"
    />
    <path
      fill="#536031"
      d="M649.098 178.575c6.405.025 12.812.168 19.213.02 2.689-.062 3.596.754 3.525 3.488-.165 6.401-.031 12.809-.019 19.214l-.053.134-22.779-.133.08-22.606.033-.117z"
    />
    <path
      fill="#222D12"
      d="m13.166 224.242 22.618-.256.123.125-.174 22.839-22.685-.02.126-.187-.008-22.501z"
    />
    <path
      fill="#3C4A21"
      d="m694.342 224.021-22.496.119-.082-.079.001-22.629.053-.134 22.557.115.34 22.752-.373-.144z"
    />
    <path
      fill="#2E3A19"
      d="m13.166 224.242.008 22.5-22.659-.093c-.028-6.226.043-12.454-.129-18.676-.074-2.67.255-4.151 3.552-3.99 6.398.314 12.818.197 19.228.259z"
    />
    <path
      fill="#7A8263"
      d="m103.913 155.718.189-45.169 22.68.127-.089 22.562-.008 22.525-22.6.491a.831.831 0 0 1-.172-.536z"
    />
    <path
      fill="#222D12"
      d="m330.969 496.714.046-22.728.249-.251 22.542.141.039.039c-.01 13.021-.225 26.046.076 39.06.111 4.816-.975 6.876-6.202 6.44-5.433-.453-10.936-.062-16.408-.041l-.342-22.66z"
    />
    <path
      fill="#222D12"
      d="m376.488 473.866-22.643.049-.039-.038.017-22.694 22.597-.032.259 22.844-.191-.129z"
    />
    <path
      fill="#3C4A21"
      d="m35.733 246.951.174-22.84 45.436-.085.054-.097-.043 22.892-.053 22.647-.087 22.828-22.557-.013-22.782-.08-.033.009-.122-22.883.072.13c6.037.01 12.077-.096 18.109.078 3.225.093 4.939-.69 4.75-4.366-.242-4.704-.187-9.434-.014-14.144.116-3.148-1.015-4.404-4.234-4.307-6.22.189-12.449.101-18.674.128l.004.103z"
    />
    <path
      fill="#2E3A19"
      d="m103.955 201.492-.002-22.902-.008.028 22.747.02.056 22.802.024 22.595-22.368.139a.697.697 0 0 0-.558-.17l.109-22.512z"
    />
    <path
      fill="#536031"
      d="m81.27 156.097 22.643-.379a.834.834 0 0 0 .171.536l-.14 22.365s.009-.027.008-.028l-22.842-.096.16-22.398z"
    />
    <path
      fill="#3C4A21"
      d="M81.253 496.814c-6.41-.071-12.823-.274-19.23-.151-2.751.053-3.541-.849-3.476-3.534.155-6.411.018-12.83-.001-19.245l.354.218 22.415-.158-.053 22.265c-.162.198-.165.4-.009.605z"
    />
    <path
      fill="#2E3A19"
      d="m626.544 405.668-23.039.139-22.494.031-.142-.14.056-22.655 45.198-.044.421 22.669z"
    />
    <path
      fill="#8B8F81"
      d="m626.385 451.213-22.604.202a16.252 16.252 0 0 1-.287-.494l-.217-22.557.292-.137.197.256c5.99.015 11.991.252 17.966-.037 3.8-.183 4.794 1.216 4.651 4.801-.238 5.979-.022 11.976.002 17.966z"
    />
    <path
      fill="#B7B9AE"
      d="m626.544 405.668-.421-22.669.278-.06.246.143 22.431.023.404 22.678-.284.159-.279-.167-22.375-.107z"
    />
    <path
      fill="#637037"
      d="M422.029 42.567c-.027 6.023.097 12.051-.138 18.065-.136 3.494 1.191 4.642 4.591 4.513 6.015-.227 12.042-.127 18.065-.163l.195.19-.002 22.615-22.859.045-22.539.152-22.891-.253-.18-22.61.419-.046c6.205.001 12.412-.087 18.615.044 2.9.061 4.045-1.013 3.977-3.946-.142-6.202-.062-12.409-.073-18.615l22.82.009z"
    />
    <path
      fill="#475828"
      d="M399.21 42.559c.011 6.205-.07 12.412.073 18.615.067 2.933-1.078 4.007-3.977 3.946-6.203-.131-12.41-.043-18.616-.044-.035-7.515-.069-15.031-.102-22.547l22.622.03z"
    />
    <path
      fill="#7A8263"
      d="m376.587 42.529.102 22.547-.416.046-22.248-.026-.425.074.131-22.627 22.856-.014z"
    />
    <path
      fill="#5F694F"
      d="m376.678 473.994-.259-22.844.02-22.658 22.764-.029c6.056.005 12.116-.127 18.165.068 3.37.109 4.8-.941 4.655-4.501-.245-6.046-.123-12.107-.154-18.163l22.734-.145.107 22.688-.066 22.775-22.805.042c-6.199-.02-12.404.129-18.593-.129-3.267-.136-4.161.986-4.069 4.142.181 6.189-.041 12.39-.096 18.586l-22.403.168z"
    />
    <path
      fill="#536031"
      d="m421.841 451.227 22.805-.042.012.013-.108 22.329c-.154.21-.12.383.101.52-6.412-.046-12.825-.209-19.234-.081-2.69.054-3.598-.768-3.536-3.503.147-6.408-.008-12.824-.04-19.236z"
    />
    <path
      fill="#8B8F81"
      d="m467.627 473.82-.426 22.897-22.361.002-.19-22.673c-.221-.137-.255-.311-.1-.519l22.623.392.454-.099z"
    />
    <path
      fill="#475828"
      d="m13.166 405.768-.065-22.798.14-22.621 22.455-.069 22.858.074-.059 22.805c-6.399-.062-12.801-.033-19.196-.228-2.711-.082-3.625.917-3.551 3.586.177 6.397.137 12.8.184 19.201l-22.784.031.018.019z"
    />
    <path
      fill="#3C4A21"
      d="m35.876 337.687 22.634-.215.043 22.882-22.857-.074.18-22.593z"
    />
    <path
      fill="#2E3A19"
      d="m13.104 314.755.056-22.547-.021-.035 22.704.039.033-.009-.07 22.592.108.202-22.81-.242z"
    />
    <path
      fill="#536031"
      d="m762.708 360.184-.22-22.493 22.828-.055.078 22.477-22.686.071z"
    />
    <path
      fill="#2E3A19"
      d="m739.895 337.435-22.57.345-.277-22.88.303.024 22.546-.018-.002 22.529zM581.011 405.838l.122 22.595-.296 22.757.059-.109-22.644-.12c-.01-5.984-.158-11.973.024-17.952.102-3.333-.94-4.729-4.453-4.6-5.976.218-11.967.059-17.951.058l-.497-22.666 22.816-.082 22.678-.023.142.142z"
    />
    <path
      fill="#7A8263"
      d="m603.753 473.713-22.79.021-.066-22.652-.059.109 22.656-.269c.093.166.189.331.287.494l-.028 22.297z"
    />
    <path
      fill="#475828"
      d="M535.873 428.468c5.984.001 11.975.16 17.951-.058 3.513-.129 4.555 1.268 4.453 4.6-.182 5.979-.034 11.967-.024 17.952l-22.74.187.055.065-.215-22.71.268.099.252-.135z"
    />
    <path
      fill="#5F694F"
      d="m535.5 473.811-22.782-.105-.048-22.191.216-.492 22.681.191-.055-.065-.012 22.662z"
    />
    <path
      fill="#637037"
      d="m444.563 42.677 22.901-.013-.067 22.312-.053.325-22.6-.127-.196-.191c.004-7.436.009-14.871.015-22.306zM489.973 65.165l22.785-.11.085.087-.019 22.625-22.612.097-.239-22.699z"
    />
    <path
      fill="#7A8263"
      d="m489.973 65.164.238 22.698-.246-.024-22.628.006.006-22.544.053-.325 22.577.189z"
    />
    <path
      fill="#222D12"
      d="M580.859 156.072c.032 5.796.337 11.608.007 17.383-.25 4.368 1.447 5.474 5.507 5.27 5.781-.291 11.59-.053 17.386-.041l-.043 22.604-22.802.088-22.66-.138-.05-22.612.031-22.665.01-.056 22.614.167z"
    />
    <path
      fill="#2E3A19"
      d="m558.294 110.499.07 22.609-.12 22.797-.01.056c-6.179-.012-12.362-.141-18.537.012-3.155.078-4.319-1.078-4.245-4.239.146-6.175.003-12.357-.02-18.536l.106-22.725 22.458.193.298-.167z"
    />
    <path
      fill="#5F694F"
      d="m717.048 314.9.277 22.88.039-.097-22.888-.067-.111-22.823.142.128 22.541-.021z"
    />
    <path
      fill="#2E3A19"
      d="m694.452 292.313-22.716.068.125-.393-.108-22.389 22.723-.148-.081.138c.018 7.576.037 15.15.057 22.724z"
    />
    <path
      fill="#3C4A21"
      d="m263.108 451.093-22.798.032.006.028-.033-22.715-.025-22.64c13.532-.012 27.065-.124 40.596.03 3.6.041 5.072-.965 4.906-4.757-.263-6.003-.081-12.026-.087-18.04l22.638.108.075 45.37-22.802-.014c-6.39-.002-12.784.12-19.168-.064-2.732-.079-3.548.805-3.463 3.496.201 6.385.12 12.777.155 19.166z"
    />
    <path
      fill="#222D12"
      d="m240.295 473.505-22.87.486.097-.037c.011-6.186.14-12.376-.016-18.558-.08-3.168 1.096-4.318 4.252-4.242 6.182.15 12.371.015 18.558-.002l-.006-.028-.015 22.381zM149.419 473.915l-22.689-.02-.024-.024-.012-22.677.048-22.481c7.584-.025 15.168-.048 22.751-.07l-.073 22.561-.001 22.711z"
    />
    <path
      fill="#0F1A09"
      d="m149.421 451.203.073-22.561-.077-.268 22.699.072.028 22.746.031.045-22.754-.034z"
    />
    <path
      fill="#475828"
      d="m194.996 474.091-22.76-.057-.062-22.799-.03-.045 22.628.083.053 22.569.171.249z"
    />
    <path
      fill="#536031"
      d="m308.354 65.156 22.583-.115.353.078c-.067 7.576-.132 15.153-.198 22.729l-22.672-.005-.066-22.687z"
    />
    <path
      fill="#3C4A21"
      d="m308.354 65.156.066 22.686.055.078-22.562-.047-.184-22.761-.027-.044c7.551.031 15.102.06 22.652.088z"
    />
    <path
      fill="#536031"
      d="m126.73 473.895.028 22.384c-7.611.144-15.222.29-22.835.438l.082-.177-.029-22.637 22.729-.032.025.024z"
    />
    <path
      fill="#637037"
      d="m13.147 405.749 22.784-.031-.037-.03-.076 22.629.125.375-22.87-.429c.026-7.505.05-15.009.074-22.514z"
    />
    <path
      fill="#7A8263"
      d="m490.265 451.249-.168-22.838-.088.069 22.808.04-.073-.074.142 22.578-.216.492-22.405-.267z"
    />
    <path
      fill="#222D12"
      d="m331.015 473.986-22.661-.453.017-22.378.006.001 22.705-.005.041.042.141 22.542-.249.251z"
    />
    <path
      fill="#0F1A09"
      d="m285.563 451.12.019-22.623 22.803.013-.021-.012.013 22.658-.006-.001-22.808-.035z"
    />
    <path
      fill="#7A8263"
      d="m81.315 473.943-22.415.158-.502-23.132.21.217 22.665-.071-.012 22.728.054.1z"
    />
    <path
      fill="#3C4A21"
      d="m694.481 405.701-.109 22.783-22.714-.062.201-22.595-.027.026 22.695-.03-.046-.122z"
    />
    <path
      fill="#2E3A19"
      d="m103.977 473.903.029 22.637c-7.582-.044-15.173.55-22.743-.332l.053-22.265-.054-.098 22.729.069-.014-.011z"
    />
    <path
      fill="#0F1A09"
      d="m194.825 473.843-.052-22.57c.002-7.615.003-15.229.006-22.842l.052-22.673h22.733c.002 6.171.125 12.346-.043 18.513-.087 3.18 1.114 4.289 4.249 4.207 6.167-.162 12.341-.042 18.513-.039l.033 22.715c-6.186.017-12.375.151-18.557.002-3.156-.076-4.331 1.074-4.252 4.242.156 6.182.027 12.372.016 18.558-7.567-.039-15.133-.076-22.698-.113z"
    />
    <path
      fill="#7A8263"
      d="m626.313 201.142-.134-22.339 22.887-.11-.08 22.606.041.068-22.675.305-.039-.53z"
    />
    <path
      fill="#2E3A19"
      d="m240.178 65.364 22.784-.404-.035.202-.149 22.537-22.552.181.074-.228-.122-22.288z"
    />
    <path
      fill="#637037"
      d="M217.585 87.772c7.572-.039 15.144-.078 22.716-.119l-.074.226.026 22.599-22.828-.085-.098-22.748.258.127z"
    />
    <path
      fill="#8B8F81"
      d="m217.327 87.645.099 22.749.114.207-22.525-.184-.247-22.391.164-.481 22.395.1z"
    />
    <path
      fill="#3C4A21"
      d="M194.768 88.026c.082 7.464.165 14.927.246 22.391l-22.873.094-.004-.004-.099-22.436c7.576-.016 15.153-.031 22.73-.045z"
    />
    <path
      fill="#7A8263"
      d="m512.824 87.766.019-22.625 22.697-.052-.052 22.734.027-.026-22.684-.017-.007-.014z"
    />
    <path
      fill="#2E3A19"
      d="M331.092 87.849c.065-7.576.131-15.153.198-22.729l22.311.05.424-.075-.095 22.667-22.877.079.039.008z"
    />
    <path
      fill="#637037"
      d="m262.777 87.698.15-22.537 22.802-.048.183 22.76-.385-.059-22.344.099-.406-.215z"
    />
    <path
      fill="#536031"
      d="m126.685 155.761.008-22.524 22.559-.004.297-.018-.074 22.69-22.834.155.044-.299z"
    />
    <path
      fill="#2E3A19"
      d="m149.844 110.441 22.293.065.004.004-.021 22.704-22.571.002-.297.018c.196-7.598.394-15.195.592-22.793z"
    />
    <path
      fill="#7A8263"
      d="m557.995 110.666-22.458-.193.055-.105-.077-22.572-.027.026 22.468-.147.039 22.991z"
    />
    <path
      fill="#8B8F81"
      d="m13.049 246.929 22.685.02-.003-.102c.022 7.537.042 15.074.062 22.611l-.071-.129-22.607.475.03-.536-.096-22.339z"
    />
    <path
      fill="#7A8263"
      d="m694.476 269.451-22.723.148.096-.204-.001-22.515-.103-.21 22.684.07.047 22.711z"
    />
    <path
      fill="#3C4A21"
      d="m671.846 224.14-.101 22.53.103.209-22.803-.129-.094-22.707.127.024 22.686-.006.082.079z"
    />
    <path
      fill="#475828"
      d="m13.115 269.805 22.607-.475.121 22.882-22.704-.039-.024-22.368z"
    />
    <path
      fill="#637037"
      d="m671.764 224.061-22.686.006-.051-22.7-.041-.068 22.779.133-.001 22.629z"
    />
    <path
      fill="#222D12"
      d="m103.945 178.618.14-22.365 22.601-.491c-.001-.001-.045.299-.044.298l.051 22.578-22.748-.02z"
    />
    <path
      fill="#0F1A09"
      d="m331.263 473.735-.141-22.542 22.695-.016.006.006-.017 22.694-22.543-.142zM376.44 428.491l-.02 22.659-22.597.032-.005-.006.041-22.831 22.68.134-.099.012z"
    />
    <path
      fill="#2E3A19"
      d="m81.214 292.297.087-22.828c6.179.01 12.362-.099 18.536.081 3.182.093 4.314-1.098 4.205-4.244-.215-6.171-.167-12.351-.229-18.528l.393-.016 22.539.045c-.022 6.177.071 12.357-.113 18.529-.094 3.141 1.022 4.333 4.204 4.244 6.172-.172 12.353-.049 18.531-.049l.059 22.677-22.728.055-22.672-.055-22.738.018-.074.071z"
    />
    <path
      fill="#637037"
      d="M103.812 246.778c.061 6.176.014 12.357.229 18.528.11 3.146-1.022 4.336-4.205 4.244-6.174-.18-12.357-.07-18.536-.081l.053-22.648 22.459-.043z"
    />
    <path
      fill="#222D12"
      d="m35.792 269.459-.061-22.611c6.224-.026 12.453.061 18.673-.127 3.219-.098 4.35 1.159 4.234 4.307-.173 4.71-.228 9.44.014 14.144.189 3.677-1.525 4.46-4.75 4.366-6.033-.175-12.073-.069-18.11-.079z"
    />
    <path
      fill="#8B8F81"
      d="m58.657 292.283 22.557.013.072-.069.044 22.725-22.852-.039.055.099.124-22.729z"
    />
    <path
      fill="#5F694F"
      d="m58.657 292.283-.123 22.729-22.729-.217.07-22.592 22.782.08z"
    />
    <path
      fill="#536031"
      d="m103.812 246.778-22.459.042.044-22.891 22.449.075a.697.697 0 0 1 .558.17c-.72 7.524-.384 15.057-.199 22.588l-.393.016z"
    />
    <path
      fill="#3C4A21"
      d="m126.692 178.638-.051-22.578 22.833-.154-.052-.016.12 22.888-.194 22.336-22.6.326-.056-22.802z"
    />
    <path
      fill="#0F1A09"
      d="M104.205 246.762c-.185-7.532-.521-15.065.199-22.588l22.369-.138 22.694.332.005 22.218-22.727.222-22.54-.046z"
    />
    <path
      fill="#B7B9AE"
      d="m149.466 224.368-22.694-.332-.024-22.595 22.6-.326.138.479-.083 22.321.207.202-.144.251z"
    />
    <path
      fill="#3C4A21"
      d="m626.123 382.999-45.198.044.008.008.141-22.88 22.404.118 23.014.173.154 22.62-.246-.143-.277.06z"
    />
    <path
      fill="#3C4A21"
      d="m580.87 405.698-22.677.023.002-22.635 22.739-.035-.008-.008-.056 22.655z"
    />
    <path
      fill="#8B8F81"
      d="m467.343 65.301-.006 22.543-.065.064-22.55-.071.019-.05.002-22.614 22.6.128zM353.929 87.762l.095-22.667 22.249.026c.058 7.537.118 15.074.178 22.611l.096.117-22.618-.087z"
    />
    <path
      fill="#7A8263"
      d="m421.881 87.832 22.859-.045-.018.05-.034 22.666-22.82-.043.013-22.628z"
    />
    <path
      fill="#0F1A09"
      d="m421.881 87.832-.013 22.627.024-.053-22.688-.045.137-22.377 22.54-.152z"
    />
    <path
      fill="#2E3A19"
      d="m399.342 87.984-.137 22.378.095.241-22.748-.111-.005-22.643-.096-.117 22.891.252z"
    />
    <path
      fill="#B7B9AE"
      d="m444.711 428.409-.107-22.688.085.056 22.787.033-.116 22.737.092-.057-22.741-.081z"
    />
    <path
      fill="#7A8263"
      d="M421.87 405.867c.032 6.055-.091 12.116.155 18.162.145 3.56-1.285 4.61-4.655 4.501-6.049-.196-12.109-.063-18.165-.068l.085-22.52 22.663-.29-.083.215z"
    />
    <path
      fill="#3C4A21"
      d="m399.289 405.943-.085 22.52-22.764.029.098-.012-.125-22.637 22.793-.308.083.408z"
    />
    <path
      fill="#7A8263"
      d="m444.711 428.409 22.74.08-.3 22.722-22.494-.014-.012-.013c.023-7.591.045-15.183.066-22.775z"
    />
    <path
      fill="#536031"
      d="m444.604 405.721-22.734.145.081-.213.071-22.56 22.441-.173.225 22.856-.084-.055z"
    />
    <path
      fill="#5F694F"
      d="m444.658 451.198 22.494.014.022 22.708c-7.541-.13-15.082-.26-22.623-.392l.107-22.33z"
    />
    <path
      fill="#2E3A19"
      d="m58.479 314.914 22.851.038-.048-.07.015 22.657.018 22.892-.042 22.62-22.656.006-.123.103.059-22.806-.043-22.881-.031-22.559z"
    />
    <path
      fill="#B7B9AE"
      d="M35.932 405.718c-.047-6.401-.007-12.804-.184-19.201-.074-2.669.84-3.669 3.551-3.586 6.395.194 12.797.166 19.196.228l.122-.103-.031 22.683-22.691-.05.037.029z"
    />
    <path
      fill="#222D12"
      d="m694.476 337.616 22.887.067.067 22.715-.427-.135-22.487.078.021-22.63-.061-.095z"
    />
    <path
      fill="#8B8F81"
      d="m535.375 405.803.497 22.666-.252.135-.268-.099-22.608-.058.073.074-.107-22.647 22.79-.058-.125-.013z"
    />
    <path
      fill="#475828"
      d="m558.194 383.086-.002 22.635-22.816.082.124.012-.037-22.751 22.76-.023-.029.045z"
    />
    <path
      fill="#536031"
      d="m535.537 110.473-.105 22.725-22.639.041-22.657.038-.167-.169.106-22.608-.11-22.663.246.024 22.613-.096.007.013c-.009 6.187.098 12.377-.074 18.559-.089 3.195 1.154 4.311 4.282 4.213 6.18-.194 12.369-.136 18.554-.183l-.056.106z"
    />
    <path
      fill="#8B8F81"
      d="m489.965 87.838.11 22.663-22.679-.084.06.06-.184-22.57.065-.064 22.628-.005z"
    />
    <path
      fill="#475828"
      d="m512.793 133.239 22.639-.041c.022 6.179.165 12.361.019 18.536-.075 3.161 1.09 4.317 4.245 4.239 6.175-.153 12.358-.024 18.537-.012l-.03 22.665-22.788.126-22.599-.019-.02-22.785-.003-22.709z"
    />
    <path
      fill="#2E3A19"
      d="m580.913 201.376 22.802-.088.021-.068-.128 22.879-22.585.095-.11-22.818z"
    />
    <path
      fill="#3C4A21"
      d="m580.913 201.376.11 22.817-.009-.085-22.776-.025-.057-22.637.073-.21 22.659.14z"
    />
    <path
      fill="#2E3A19"
      d="m535.415 178.753 22.788-.127.05 22.612-.072.207-22.777-.427.011-22.265z"
    />
    <path
      fill="#8B8F81"
      d="m694.365 314.793.111 22.823.062.095-22.788-.081.022-22.405a.819.819 0 0 1-.006-.571l22.599.139z"
    />
    <path
      fill="#475828"
      d="m671.86 291.989-22.826.151.053.121-.205-22.638 22.967-.228-.096.204.107 22.39z"
    />
    <path
      fill="#222D12"
      d="m353.858 428.345-.041 22.831-22.695.016-.041-.041c.008-6.174-.098-12.351.071-18.521.087-3.179-1.141-4.301-4.268-4.217-6.17.167-12.347.07-18.521.083l.021.012-.074-45.371c7.582-.044 15.163-.087 22.745-.129.022 6.175.158 12.352.017 18.522-.071 3.124 1.03 4.363 4.215 4.269 6.17-.182 12.347-.092 18.521-.116l.041.132.009 22.53z"
    />
    <path
      fill="#0F1A09"
      d="M285.672 383.032c.006 6.014-.176 12.037.087 18.04.166 3.792-1.306 4.798-4.906 4.757-13.53-.154-27.064-.042-40.596-.031l.037-.078.007-22.789 45.306.036.065.065zM331.055 383.009l-22.745.13c-7.547-.035-15.092-.071-22.638-.108l-.065-.064.214-22.565 22.42-.22 22.944.159.037 22.685-.167-.017z"
    />
    <path
      fill="#2E3A19"
      d="M240.282 428.438c-6.171-.003-12.346-.123-18.513.039-3.135.082-4.335-1.027-4.249-4.207.168-6.167.045-12.341.042-18.513l22.732-.037-.037.078.025 22.64zM126.742 428.714l-.048 22.479-22.548-.057-.229.039.141-22.714-.065.068 22.685-.155.064.34z"
    />
    <path
      fill="#3C4A21"
      d="m104.146 451.136 22.548.057.012 22.677-22.729.032.013.011.156-22.777z"
    />
    <path
      fill="#475828"
      d="m149.494 428.642-22.751.07-.063-.338-.05-22.552 22.735-.12.053 22.671.076.269z"
    />
    <path
      fill="#222D12"
      d="m194.777 428.431-.005 22.843-22.629-.082-.028-22.746-.077-.067 22.739.052z"
    />
    <path
      fill="#5F694F"
      d="m149.417 428.373-.053-22.67.031.036 22.664.062.141-.08-.161 22.656.077.067-22.699-.071z"
    />
    <path
      fill="#3C4A21"
      d="m603.608 224.099.129-22.88 22.576-.077.039.531.049 22.388-.103.02-22.751.072.061-.054z"
    />
    <path
      fill="#0F1A09"
      d="m308.42 87.843 22.672.005-.04-.007.053 22.644-22.616-.154-.014-22.41-.055-.078z"
    />
    <path
      fill="#2E3A19"
      d="m308.475 87.921.014 22.411-22.906.14-.057-22.657.385.059 22.564.047z"
    />
    <path
      fill="#475828"
      d="m35.895 405.688 22.691.05 22.611.005 22.868-.026-.041-.039-.031 22.85.065-.068-22.692.029-22.891.012-22.656-.185.076-22.628z"
    />
    <path
      fill="#2E3A19"
      d="m58.476 428.502 22.891-.012-.144 22.688.051-.062-22.665.071-.133-22.685z"
    />
    <path
      fill="#536031"
      d="m717.505 382.997-.262 22.811-22.763-.106.047.122-.145-22.693 22.639-.039.484-.095z"
    />
    <path
      fill="#3C4A21"
      d="m467.36 428.547.116-22.736-.039.035 22.795-.059-.221 22.694.088-.069c-7.581.044-15.16.089-22.739.135z"
    />
    <path
      fill="#2E3A19"
      d="M308.363 428.498c6.174-.013 12.351.084 18.521-.083 3.127-.084 4.355 1.037 4.268 4.217-.168 6.17-.063 12.347-.071 18.521l-22.705.004-.013-22.659z"
    />
    <path
      fill="#536031"
      d="m104.146 451.136-.156 22.777-22.729-.068.012-22.729-.05.062 22.695-.003.228-.039z"
    />
    <path
      fill="#222D12"
      d="m240.294 405.72-22.732.037h-22.733l-22.63-.036-.14.079.083-22.755 22.578-.131c6.217.038 12.438-.054 18.649.168 3.185.113 4.319-.974 4.221-4.19-.19-6.212-.04-12.434-.03-18.652l22.743.055-.001 22.636-.008 22.789z"
    />
    <path
      fill="#2E3A19"
      d="m172.199 405.722 22.63.036-.052 22.673-22.739-.053.161-22.656z"
    />
    <path
      fill="#222D12"
      d="m626.4 224.061-.049-22.388 22.676-.305.051 22.7-.126-.024-22.552.017z"
    />
    <path
      fill="#536031"
      d="m240.253 110.478-.026-22.599 22.552-.181.404.216c-.037 7.5-.071 15.001-.105 22.503l-22.825.061z"
    />
    <path
      fill="#2E3A19"
      d="m240.253 110.478 22.825-.061-.076.072-.067 22.697-22.677.05-22.745-.146.028-22.488-.114-.207 22.826.083z"
    />
    <path
      fill="#222D12"
      d="m217.539 110.601-.027 22.49-45.382.133-.01-.01.021-22.704 22.873-.094 22.525.185z"
    />
    <path
      fill="#637037"
      d="M535.592 110.368c-6.185.046-12.373-.011-18.554.183-3.128.098-4.371-1.017-4.282-4.213.172-6.182.065-12.372.074-18.559l22.684.017.078 22.572z"
    />
    <path
      fill="#3C4A21"
      d="m331.106 110.485-.053-22.645 22.877-.078c7.54.029 15.079.057 22.618.087l.004 22.644c-6.189.009-12.381.137-18.565-.022-3.209-.083-4.276 1.185-4.206 4.299.138 6.185 0 12.376-.023 18.563l-22.648-.059-.004-22.789z"
    />
    <path
      fill="#8B8F81"
      d="m263.078 110.417.104-22.503 22.345-.099.057 22.657.073-.045-22.656.062.077-.072z"
    />
    <path
      fill="#222D12"
      d="m149.549 133.216 22.571-.002.01.01-.014 22.667-22.694-.001.052.016.075-22.69z"
    />
    <path
      fill="#637037"
      d="m671.849 269.395-22.966.227.128-.002.019-22.858.014-.013 22.804.13.001 22.516z"
    />
    <path
      fill="#2E3A19"
      d="m626.4 224.061 22.552-.018.093 22.707-.014.013-22.634-.022-.101-22.66.104-.02z"
    />
    <path
      fill="#B7B9AE"
      d="m353.858 428.345-.009-22.528 22.564.026.125 22.637-22.68-.135z"
    />
    <path
      fill="#475828"
      d="m126.696 292.264 22.728-.055-.007-.008.099 22.458-22.658.09-.162-22.485z"
    />
    <path
      fill="#536031"
      d="m126.696 292.264.161 22.484-.253.272-22.466.052-.276-.481.163-22.381 22.671.054z"
    />
    <path
      fill="#222D12"
      d="m104.024 292.209-.163 22.382-22.58.291.048.07-.044-22.725 22.739-.018z"
    />
    <path
      fill="#637037"
      d="m126.744 246.807 22.727-.222-.099.265.037 22.647-.044.034c-6.177-.001-12.358-.123-18.53.049-3.182.089-4.298-1.103-4.204-4.244.184-6.172.091-12.352.113-18.529z"
    />
    <path
      fill="#5F694F"
      d="m149.424 292.208-.059-22.677.044-.034 22.692.005.076 22.726-22.76-.029.007.009z"
    />
    <path
      fill="#637037"
      d="m149.422 155.89 22.694.001-.009.065-.111 22.801-22.454.021-.12-22.888z"
    />
    <path
      fill="#8B8F81"
      d="m149.542 178.778 22.455-.022.182-.116-.182 22.835-22.511.118-.138-.479.194-22.336z"
    />
    <path
      fill="#222D12"
      d="m149.471 246.586-.005-22.218.143-.251-.207-.202 22.655.054.274.463-.113 22.262-.117.117-22.73.039.1-.264z"
    />
    <path
      fill="#B7B9AE"
      d="m603.478 360.288-22.404-.117-.072.128-.224-22.618 22.942-.001-.242 22.608z"
    />
    <path
      fill="#2E3A19"
      d="m603.478 360.288.242-22.609-.004.077 22.663-.085.032 22.773.081.017-23.014-.173z"
    />
    <path
      fill="#222D12"
      d="m580.933 383.05-22.739.035.028-.045-.025-22.675 22.804-.068.072-.128-.14 22.881z"
    />
    <path
      fill="#475828"
      d="m671.75 337.631 22.788.081-.022 22.63-.07-.028c-5.84.009-11.688.207-17.517-.041-3.841-.164-5.436.974-5.236 5.055.286 5.825.039 11.676.02 17.516l.115.406-22.804-.141.005-22.632-.013-22.794c7.579-.019 15.157-.035 22.734-.052z"
    />
    <path
      fill="#222D12"
      d="m649.017 337.682.013 22.793-22.619-.031-.032-22.773-.078-.109 22.741.084-.025.036z"
    />
    <path
      fill="#0F1A09"
      d="M467.272 87.908c.061 7.523.123 15.046.183 22.57l-22.768.025.034-22.666 22.551.071z"
    />
    <path
      fill="#536031"
      d="m444.687 110.503 22.768-.025-.059-.06c-.001 5.985.164 11.975-.066 17.951-.137 3.559.88 5.08 4.692 4.896 5.971-.288 11.964-.127 17.947-.156l.167.168.071 22.698-.132-.03-22.659-.006-22.736-.017c-.004-6.183-.131-12.369.039-18.546.088-3.186-1.104-4.282-4.247-4.211-6.178.139-12.362-.022-18.543-.06l-.035-22.699-.024.053 22.817.044z"
    />
    <path
      fill="#5F694F"
      d="m421.892 110.406.035 22.7-22.651.193.023-22.696-.095-.241 22.688.044z"
    />
    <path
      fill="#475828"
      d="m399.3 110.603-.023 22.695.022-.078-22.777.009-22.755.005-.011.099c.024-6.188.161-12.379.024-18.564-.069-3.114.997-4.381 4.206-4.299 6.184.159 12.376.031 18.565.022l22.749.111z"
    />
    <path
      fill="#7A8263"
      d="m444.689 405.777-.225-22.856.22.135 22.709-.029.043 22.817.039-.035-22.786-.032z"
    />
    <path
      fill="#222D12"
      d="m422.023 383.094-.071 22.56-22.663.289-.083-.406.029-22.496 22.788.053z"
    />
    <path
      fill="#0F1A09"
      d="m399.235 383.04-.029 22.496c-7.598.101-15.195.204-22.793.306l-22.564-.026-.041-.132.043-22.587-.029-.067 22.673-.059 22.751.076-.011-.007z"
    />
    <path
      fill="#0F1A09"
      d="m422.023 383.094-22.788-.054.011.009-.025-22.711c6.19 0 12.383-.118 18.568.048 3.208.086 4.276-1.178 4.208-4.295-.136-6.186.007-12.377.034-18.566l22.59.051.112 22.894-.048 22.585-.22-.135-22.442.174z"
    />
    <path
      fill="#5F694F"
      d="m81.282 314.882 22.58-.291.276.481-.07 22.491-22.77-.024-.016-22.657z"
    />
    <path
      fill="#0F1A09"
      d="m81.297 337.539 22.77.024-.033.024-.042 22.705-.002.054-22.675.084-.018-22.891zM81.197 405.744l-22.611-.005.031-22.683 22.656-.006.083-.075-.159 22.769z"
    />
    <path
      fill="#2E3A19"
      d="m717.022 383.091-22.639.039.058-.013.006-22.804.07.028 22.487-.078.018 22.828z"
    />
    <path
      fill="#0F1A09"
      d="m535.462 383.064.038 22.751-22.79.058.118-.174V383.06l-.02-.037 22.69.07-.036-.029z"
    />
    <path
      fill="#2E3A19"
      d="m558.198 360.367.025 22.675-22.76.023.035.028.019-22.761 22.681.035z"
    />
    <path
      fill="#637037"
      d="m490.206 155.975-.071-22.698 22.658-.038.003 22.71-22.59.026z"
    />
    <path
      fill="#222D12"
      d="M489.969 133.109c-5.983.029-11.976-.132-17.947.156-3.812.184-4.829-1.337-4.692-4.896.23-5.975.065-11.966.066-17.951l22.679.083-.106 22.608z"
    />
    <path
      fill="#3C4A21"
      d="m490.206 155.975 22.59-.026.02 22.784.115 22.322-45.505.361-.125-22.743.126-.074c6.414.021 12.831-.074 19.239.118 2.741.082 3.567-.842 3.494-3.533-.176-6.409-.075-12.826-.085-19.24l.131.031z"
    />
    <path
      fill="#0F1A09"
      d="m512.932 201.054-.116-22.321 22.599.019-.011 22.266.074.454-22.671-.023.125-.395z"
    />
    <path
      fill="#7A8263"
      d="m581.023 224.193 22.585-.095-.062.054c.028 6.229-.038 12.462.126 18.688.077 2.919-.975 4.042-3.935 3.974-6.227-.143-12.46-.032-18.691-.025l-.032-22.681.009.085z"
    />
    <path
      fill="#637037"
      d="m535.404 201.018 22.777.427.057 22.638-.095-.051-22.614.053-.051-22.612-.074-.455z"
    />
    <path
      fill="#222D12"
      d="m581.014 224.108.032 22.681-.222-.073-22.618.059-.063-22.744.095.051c7.592.01 15.184.018 22.776.026z"
    />
    <path
      fill="#2E3A19"
      d="m649.034 292.139.15 22.78-.363-.077-22.115-.047-.273-.129-.188.237.022-22.712 22.82.069-.053-.121z"
    />
    <path
      fill="#637037"
      d="m671.75 337.631-22.733.051.025-.037-.224-22.803.365.078 22.588.306-.021 22.405z"
    />
    <path
      fill="#B7B9AE"
      d="m649.088 292.261-22.821-.069.092.097.121-22.738-.008.14 22.539-.069-.128.001.205 22.638z"
    />
    <path
      fill="#2E3A19"
      d="m353.851 383.099-.043 22.587c-6.174.023-12.352-.066-18.521.116-3.185.094-4.286-1.146-4.215-4.269.141-6.171.005-12.347-.017-18.522l.167.015 22.629.073zM331.185 360.34l-22.944-.158.112-22.543 22.669-.016 22.793-.015.013 22.669-.034.146-22.609-.083z"
    />
    <path
      fill="#5F694F"
      d="m308.352 337.64-.111 22.542-22.42.219-.158-22.628-.033-.419c7.556.104 15.112.205 22.667.307l.055-.021z"
    />
    <path
      fill="#536031"
      d="M331.185 360.34c7.537.028 15.073.056 22.61.082l.027 22.609.029.067-22.63-.073-.036-22.685z"
    />
    <path
      fill="#7A8263"
      d="m103.918 451.175-22.695.003c.048-7.563.095-15.126.144-22.688l22.692-.029-.141 22.714z"
    />
    <path
      fill="#7A8263"
      d="m103.994 428.529.031-22.849 22.604.143.05 22.552-22.685.154z"
    />
    <path
      fill="#222D12"
      d="m126.629 405.822-22.604-.143.041.039-.157-22.784.081-22.589.002-.054 22.69.039 22.644-.044.134 22.746-.065 22.706-.031-.036-22.735.12z"
    />
    <path
      fill="#536031"
      d="m149.395 405.739.065-22.706 22.814-.103-.131.117-.083 22.755-22.665-.063z"
    />
    <path
      fill="#475828"
      d="M581.046 246.79c6.231-.007 12.464-.118 18.691.025 2.96.068 4.012-1.055 3.935-3.974-.164-6.226-.097-12.459-.126-18.688l22.751-.071.099 22.66.076 22.949.007-.14c-5.999-.014-12.004.136-17.993-.103-3.585-.143-5.069.932-4.907 4.728.255 5.987.055 11.994.048 17.992l.16.209-22.98-.504.105-22.355-.089-22.8.223.072z"
    />
    <path
      fill="#637037"
      d="m331.106 110.485.003 22.788.037-.06-45.46.036-.028-22.823-.073.045 22.905-.14 22.616.154zM103.909 382.935l.157 22.784-22.868.025.159-22.769 22.552-.04z"
    />
    <path
      fill="#8B8F81"
      d="m467.436 405.845-.043-22.817.058-.058 22.573.151-.01-.005.088 22.74.129-.069-22.795.058z"
    />
    <path
      fill="#2E3A19"
      d="m490.102 405.855-.088-22.74 22.814-.056v22.639l-22.726.157z"
    />
    <path
      fill="#3C4A21"
      d="m694.447 360.313-.006 22.804-22.727-.274c.019-5.84.266-11.691-.02-17.516-.2-4.081 1.394-5.219 5.236-5.055 5.828.248 11.677.05 17.517.041z"
    />
    <path
      fill="#7A8263"
      d="m194.72 382.915.046-22.38.132-.521 22.741.32-.08-.094c-.009 6.218-.16 12.44.03 18.652.098 3.216-1.036 4.303-4.221 4.19-6.21-.221-12.431-.129-18.648-.167z"
    />
    <path
      fill="#B7B9AE"
      d="m240.302 360.296-22.743-.055.079.093-.012-22.759 22.626.039-.007 22.788.057-.106z"
    />
    <path
      fill="#536031"
      d="m172.116 155.891.014-22.667 45.382-.133c7.581.047 15.163.096 22.745.145-.062 7.59-.124 15.18-.184 22.77-7.516-.007-15.033-.012-22.551-.018l-22.681-.04-22.735.008.01-.065z"
    />
    <path
      fill="#3C4A21"
      d="m240.071 156.006.186-22.771 22.677-.049.06.059.007 22.657-22.719.043-.211.061zM285.658 110.427l.028 22.822.032-.015-22.723.01-.06-.059.067-22.697 22.656-.061z"
    />
    <path
      fill="#7A8263"
      d="m331.109 133.273 22.648.059.01-.098.098 22.642-22.841-.088.122-22.575-.037.06z"
    />
    <path
      fill="#3C4A21"
      d="m626.473 269.691-.076-22.949 22.634.022-.019 22.858c-7.514.022-15.027.045-22.539.069z"
    />
    <path
      fill="#536031"
      d="M126.857 314.748c7.553-.03 15.106-.06 22.657-.088l-.041.353.016 22.545-22.517.327-.403-.406.035-22.459.253-.272z"
    />
    <path
      fill="#2E3A19"
      d="m149.417 292.2 22.76.028-.037-.052-.071 22.813-22.595.024s.039-.351.041-.353l-.098-22.46z"
    />
    <path
      fill="#475828"
      d="m149.372 246.85 22.73-.039-.001 22.692-22.692-.005-.037-22.648z"
    />
    <path
      fill="#222D12"
      d="m172.101 269.503.001-22.692.116-.116c7.475.013 14.951.024 22.427.035l.332 22.723-.114 22.769-22.723-.047.037.052-.076-22.724z"
    />
    <path
      fill="#475828"
      d="m172.107 155.955 22.735-.008-.386 22.783-22.277-.091-.182.116.11-22.8z"
    />
    <path
      fill="#2E3A19"
      d="m172.178 178.639 22.278.091.271.136.228-.2-.095 22.616-.009 22.809.013-.012-22.532.353-.274-.463-.061-22.494.181-22.836z"
    />
    <path
      fill="#0F1A09"
      d="m172.332 224.432 22.532-.353-.219 22.651-22.427-.035.114-22.263zM558.198 360.367l-22.682-.036-.004-.004.13-22.464 22.704-.333 22.557.211-.126-.061.224 22.618-22.803.069z"
    />
    <path
      fill="#7A8263"
      d="m603.72 337.679-22.942.001.125.06.327-22.808 22.449-.043-.012-.015.049 22.883.004-.078z"
    />
    <path
      fill="#8B8F81"
      d="m603.716 337.756-.049-22.883 22.578.03.188-.236.273.129-.404 22.765.078.109-22.664.086z"
    />
    <path
      fill="#536031"
      d="m626.301 337.562.404-22.765 22.115.047.222 22.802c-7.58-.029-15.16-.057-22.741-.084z"
    />
    <path
      fill="#2E3A19"
      d="m444.679 155.922 22.736.017.012 22.66-.126.074c-6.228-.008-12.457.074-18.682-.065-2.928-.066-4.04 1.048-3.973 3.975.143 6.225.065 12.454.077 18.683l-22.845.11-22.622.016.027-22.865c6.408.042 12.819-.027 19.221.18 2.701.087 3.604-.754 3.511-3.487-.217-6.401-.15-12.812-.198-19.22 7.621-.025 15.242-.051 22.862-.078z"
    />
    <path
      fill="#222D12"
      d="m444.679 155.922-22.861.079-22.601-.096.061.022.021-22.708-.022.078 22.651-.193c6.182.036 12.366.198 18.543.059 3.143-.071 4.335 1.025 4.247 4.211-.17 6.18-.043 12.366-.039 18.548z"
    />
    <path
      fill="#7A8263"
      d="m399.299 133.22-.021 22.707-22.756-.006v-22.692l22.777-.009z"
    />
    <path
      fill="#222D12"
      d="M376.522 133.23v22.692l.043-.044-22.79.123.091-.125-.098-22.642 22.754-.004z"
    />
    <path
      fill="#8B8F81"
      d="m444.684 383.056.048-22.585 22.514-.074.205 22.573-.058.058-22.709.028z"
    />
    <path
      fill="#2E3A19"
      d="m399.22 360.338.026 22.711-22.75-.075.012-22.558.032-.115 22.701.056-.021-.019z"
    />
    <path
      fill="#3C4A21"
      d="m376.507 360.416-.012 22.558-22.673.058-.027-22.609.034-.144 22.678.137z"
    />
    <path
      fill="#222D12"
      d="m467.246 360.397-22.514.074-.111-22.894-.115-.1c6.207.042 12.417-.023 18.62.172 3.148.099 4.36-1.025 4.287-4.227-.14-6.203.024-12.414.062-18.621l22.598.309c.003 6.02.162 12.046-.05 18.059-.124 3.501 1.219 4.606 4.604 4.513 6.013-.165 12.036.025 18.055.066l.156 22.642-.032 22.634.02.037-22.813.056.01.005c.02-6.201-.074-12.405.105-18.601.092-3.194-1.138-4.322-4.282-4.233-6.195.175-12.399.087-18.6.109zM422.031 337.525c-.028 6.189-.17 12.381-.034 18.566.068 3.117-1 4.381-4.208 4.295-6.185-.166-12.378-.048-18.568-.048l.02.019-.003-22.736 22.822-.048-.029-.048z"
    />
    <path
      fill="#8B8F81"
      d="m444.621 337.577-22.59-.051.029.048-.054-22.56 22.513-.005-.013 22.469.115.099z"
    />
    <path
      fill="#536031"
      d="m104.034 337.587 22.534-.108.403.406-.29 22.446-22.69-.039.043-22.705z"
    />
    <path
      fill="#475828"
      d="m512.807 383.023.031-22.634 22.674-.062.004.004-.019 22.761-22.69-.069z"
    />
    <path
      fill="#637037"
      d="M467.427 201.417c15.168-.12 30.336-.24 45.505-.362l-.124.394-.083 22.686-22.639-.027-22.805-.148.105-22.601.041.058z"
    />
    <path
      fill="#475828"
      d="M444.724 201.265c-.013-6.227.066-12.457-.078-18.682-.067-2.927 1.044-4.041 3.973-3.975 6.225.14 12.454.057 18.682.065l.125 22.743-.042-.057-22.715-.017.055-.077z"
    />
    <path
      fill="#B7B9AE"
      d="m512.724 224.135.083-22.686 22.671.023.05 22.612-.097-.082-22.639.071-.068.062z"
    />
    <path
      fill="#536031"
      d="m558.143 224.032.063 22.744-.076-.015-22.34.087-.358-22.845.097.082 22.614-.053z"
    />
    <path
      fill="#3C4A21"
      d="m580.823 246.717.089 22.799-22.692-.021-.093-22.734.077.015 22.619-.059z"
    />
    <path
      fill="#222D12"
      d="m626.359 292.289-22.732-.121c.007-5.998.207-12.005-.048-17.992-.161-3.796 1.322-4.871 4.907-4.728 5.989.239 11.994.089 17.993.103l-.12 22.738z"
    />
    <path
      fill="#3C4A21"
      d="m285.663 337.774-22.745-.209.084.086-.111-22.611.104-22.877 22.614-.069.079 22.837-.06 22.421.035.422z"
    />
    <path
      fill="#3C4A21"
      d="m240.246 360.402.007-22.788.086.067 22.663-.031-.084-.086c.02 6.431-.07 12.866.112 19.292.076 2.686-.732 3.624-3.492 3.553-6.426-.163-12.861-.023-19.292-.007z"
    />
    <path
      fill="#222D12"
      d="m376.507 360.416-22.679-.138-.013-22.67-.012.013 22.742.017-.007 22.663-.031.115z"
    />
    <path
      fill="#0F1A09"
      d="m353.816 337.609-22.793.015-.054-22.684 22.828-.002.007 22.683.012-.012z"
    />
    <path
      fill="#475828"
      d="m330.968 314.941.055 22.683-22.67.016-.055.022.102-22.62-.007-.182 22.575.081z"
    />
    <path
      fill="#536031"
      d="m308.399 315.041-.102 22.62c-7.556-.101-15.112-.203-22.668-.307l.059-22.424 22.711.111z"
    />
    <path
      fill="#0F1A09"
      d="m126.682 360.331.29-22.446 22.517-.327-.038.026-.018 22.756-.108-.054-22.643.045z"
    />
    <path
      fill="#637037"
      d="m172.181 360.426.093 22.504-22.814.103-.134-22.746.108.054 22.518-.324.229.409z"
    />
    <path
      fill="#8B8F81"
      d="m603.787 292.376-.107 22.512-22.45.044-.51-.065.202-22.527-.229-.192.116-.276 22.978.504z"
    />
    <path
      fill="#222D12"
      d="m558.221 269.495 22.692.021-.105 22.356-.116.276.229.193-22.655-.071-.054-.147.009-22.628z"
    />
    <path
      fill="#2E3A19"
      d="m331.146 133.214-.122 22.575.124.227-22.602.02-22.86-.061.031-22.74-.032.015 45.461-.036zM467.246 360.397c6.201-.022 12.405.066 18.601-.111 3.144-.09 4.374 1.039 4.282 4.233-.179 6.196-.085 12.4-.105 18.601l-22.573-.151-.205-22.572z"
    />
    <path
      fill="#536031"
      d="m194.766 360.535-22.585-.109-.229-.407.124-22.508-.078.036 22.896.105.004 22.362-.132.521z"
    />
    <path
      fill="#222D12"
      d="m194.898 360.014-.005-22.363-.048-.011 22.62.038.162-.103.012 22.759-22.741-.32z"
    />
    <path
      fill="#5F694F"
      d="m217.522 155.987 22.551.018.21-.06-.084 22.772-22.687.093.112-.369-.102-22.454z"
    />
    <path
      fill="#7A8263"
      d="m217.522 155.987.102 22.454-22.669.226-.228.2-.271-.136.385-22.783c7.561.012 15.121.026 22.681.039z"
    />
    <path
      fill="#536031"
      d="m285.717 133.234-.031 22.74.044-.043-22.767.02.038-.05-.007-22.657 22.723-.01z"
    />
    <path
      fill="#0F1A09"
      d="m240.198 178.717.084-22.772 22.719-.043-.038.049-.009 22.512-22.721.24-.035.014z"
    />
    <path
      fill="#536031"
      d="m172.14 292.176 22.723.047-.042-.042.035 22.735.025.035-22.894.047.081-.008.072-22.814z"
    />
    <path
      fill="#5F694F"
      d="m194.645 246.731.219-22.651-.012.012 22.693-.086.107 22.801-.267 22.631-22.406.017-.334-22.724z"
    />
    <path
      fill="#536031"
      d="m194.977 269.454 22.408-.017.126 22.76.038-.038-22.728.021.042.042.114-22.768z"
    />
    <path
      fill="#0F1A09"
      d="m194.86 201.282 22.75-.037-.135.268.019 22.542.051-.051-22.693.086.008-22.808z"
    />
    <path
      fill="#3C4A21"
      d="m558.346 337.529-22.705.332-.218-.375-.029-22.691-.012-22.549 22.83-.123.054.147.104 22.569-.024 22.69z"
    />
    <path
      fill="#2E3A19"
      d="m535.512 360.326-22.674.062-.155-22.643.199-.207 22.54-.053.218.375c-.041 7.49-.085 14.978-.128 22.466z"
    />
    <path
      fill="#B7B9AE"
      d="m558.346 337.529.024-22.692 22.349.029.51.065-.326 22.808-22.557-.21z"
    />
    <path
      fill="#222D12"
      d="m421.879 201.376 22.845-.111-.054.076.056 22.915.121 22.469-22.862.116-.091-22.817c-.006-7.549-.01-15.098-.015-22.648z"
    />
    <path
      fill="#3C4A21"
      d="m376.522 155.922 22.756.006-.061-.022.067 22.623-.027 22.864.018-.022-22.724-.033-.084-22.879.097-22.579-.042.042z"
    />
    <path
      fill="#536031"
      d="m421.879 201.376.014 22.648-22.673.018.056-22.672-.018.022 22.621-.016z"
    />
    <path
      fill="#B7B9AE"
      d="m376.565 155.878-.097 22.579-22.731.439-.129-.275.236-.192-.069-22.428 22.79-.123z"
    />
    <path
      fill="#0F1A09"
      d="m376.539 360.301.006-22.663 22.696-.013-.004-.003.003 22.736-22.701-.057z"
    />
    <path
      fill="#2E3A19"
      d="m490.073 315.109 22.758-.263-.149.229.201 22.463-.199.207c-6.02-.04-12.043-.23-18.056-.065-3.385.093-4.728-1.012-4.604-4.513.212-6.011.052-12.037.049-18.058z"
    />
    <path
      fill="#3C4A21"
      d="m444.506 337.477.013-22.469 22.997-.166-.041-.041c-.038 6.207-.202 12.418-.062 18.621.072 3.202-1.139 4.326-4.287 4.227-6.203-.196-12.413-.13-18.62-.172z"
    />
    <path
      fill="#0F1A09"
      d="m422.006 315.014.053 22.56-22.822.048.004.003-.063-22.773-.067-22.599.157-22.769 22.672.015.103-.078-.046 22.735c.004 7.619.006 15.238.009 22.858z"
    />
    <path
      fill="#222D12"
      d="m422.006 315.014-.008-22.857 22.707-.009 22.649.036.037.036.125 22.622-22.997.166-22.513.006z"
    />
    <path
      fill="#B7B9AE"
      d="m444.846 246.726-.121-22.469 22.555-.297 22.805.147.035 22.697-22.796.125-22.625-.253.147.05z"
    />
    <path
      fill="#0F1A09"
      d="m467.28 223.96-22.555.297-.056-22.915 22.715.016-.104 22.602zM490.12 246.804l-.035-22.697 22.639.028.069-.061-.15 22.564-22.577.082.054.084z"
    />
    <path
      fill="#7A8263"
      d="m512.643 246.638.15-22.564 22.639-.071.356 22.844-.387-.055-22.758-.154z"
    />
    <path
      fill="#475828"
      d="m558.212 292.122-22.83.123-22.659.167.126-.377-.069-22.381c6.404-.044 12.811-.191 19.212-.083 2.689.045 3.641-.869 3.566-3.567-.177-6.401-.121-12.808-.159-19.213l.387.055 22.341-.086.092 22.734c-.001 7.543-.004 15.086-.007 22.628z"
    />
    <path
      fill="#2E3A19"
      d="m308.399 315.041-22.711-.11-.078-22.836-.063-45.226 22.93-.043c-.036 6.179.048 12.361-.157 18.535-.107 3.197 1.104 4.283 4.242 4.19 6.175-.182 12.358-.079 18.538-.094l.106 22.678c-5.992.02-11.989.208-17.972-.003-3.595-.127-5.058.986-4.902 4.754.248 5.981.063 11.981.061 17.973l.006.182z"
    />
    <path
      fill="#5F694F"
      d="m285.546 246.87.064 45.225-22.615.068.021.03-.126-22.773.022-22.597 22.634.047z"
    />
    <path
      fill="#536031"
      d="m262.995 292.163-.104 22.877-22.769.033.145-.16.018-22.684.005-.006 22.727-.03-.022-.03z"
    />
    <path
      fill="#8B8F81"
      d="m240.122 315.072 22.769-.033.111 22.611-22.663.031-.217-22.609z"
    />
    <path
      fill="#2E3A19"
      d="m399.178 314.851.063 22.773-22.696.013-22.742-.016-.007-22.683.019-22.748-.012-22.699 22.736-.009.036 22.768c-.027 6.169.065 12.342-.129 18.507-.1 3.172 1.075 4.299 4.224 4.204 6.166-.186 12.339-.089 18.508-.11z"
    />
    <path
      fill="#222D12"
      d="m353.816 292.191-.019 22.748-22.828.002c-7.526-.027-15.051-.053-22.576-.081.002-5.992.187-11.992-.061-17.973-.156-3.768 1.307-4.881 4.902-4.754 5.983.211 11.981.023 17.972.004l22.61.054z"
    />
    <path
      fill="#3C4A21"
      d="m149.451 337.584 22.625-.073-.124 22.508c-7.507.106-15.013.214-22.519.322.007-7.586.013-15.171.018-22.757z"
    />
    <path
      fill="#5F694F"
      d="m308.546 156.036-.016 22.468-.331.39-22.556-.235.088-22.727-.044.043 22.859.061z"
    />
    <path
      fill="#475828"
      d="m171.988 314.997 22.893-.047-.036 22.69.048.011-22.895-.105-.01-22.549z"
    />
    <path
      fill="#5F694F"
      d="m194.845 337.641.036-22.69-.025-.034 22.643-.381a.637.637 0 0 1 .131.566l-.165 22.578-22.62-.039z"
    />
    <path
      fill="#3C4A21"
      d="m240.284 292.228-.018 22.684-22.637.188a.632.632 0 0 0-.131-.565l.049-22.376-.037.038 22.774.031z"
    />
    <path
      fill="#2E3A19"
      d="m285.73 155.931-.087 22.727.051-.027-22.66.294-.079-.463.009-22.511 22.766-.02z"
    />
    <path
      fill="#536031"
      d="m240.161 201.253.072-22.551 22.721-.239.079.461c.015 7.437.031 14.874.045 22.313l-22.583.353-.334-.337z"
    />
    <path
      fill="#222D12"
      d="m331.069 178.511 22.774-.081-.236.192.129.275.086 22.459-.092.092-22.538-.382.016-22.225-.139-.33zM217.548 292.159l-.049 22.376-22.643.381-.035-22.735 22.727-.022z"
    />
    <path
      fill="#222D12"
      d="m240.284 292.228-22.773-.03-.126-22.76.265-22.632c7.56.085 15.121.171 22.681.254l-.064 22.456.023 22.707-.006.005z"
    />
    <path
      fill="#2E3A19"
      d="m240.332 247.061-22.682-.256-.106-22.801-.05.05 22.731.081.12.005-.002 22.534-.011.387z"
    />
    <path
      fill="#536031"
      d="m240.225 224.136-22.731-.081-.019-22.542 22.686-.259.334.335c-.091 7.515-.181 15.031-.27 22.547z"
    />
    <path
      fill="#8B8F81"
      d="m512.682 315.075 22.712-.281.029 22.692-22.541.053-.2-22.464z"
    />
    <path
      fill="#637037"
      d="m421.983 246.842 22.862-.116-.146-.05-.059 22.84.037-.036-22.633-.059-.103.078.072-22.702-.03.045z"
    />
    <path
      fill="#3C4A21"
      d="m399.22 224.042 22.673-.018.09 22.817.03-.045-22.781-.025.022-22.733-.034.004z"
    />
    <path
      fill="#2E3A19"
      d="m353.823 201.355-.086-22.459 22.731-.439.083 22.88-.029.03-22.699-.012zM399.275 201.37l-.056 22.672.034-.003-22.687.107-.044-22.778.029-.03 22.724.032z"
    />
    <path
      fill="#3C4A21"
      d="m512.78 269.655.069 22.381h-22.787l-.14-22.368.317-.331 22.541.318z"
    />
    <path
      fill="#222D12"
      d="m489.923 269.667.14 22.368-22.671.184-.037-.036-.044-22.656 22.612.14z"
    />
    <path
      fill="#5F694F"
      d="M399.178 314.851c-6.17.02-12.343-.076-18.507.11-3.149.095-4.324-1.032-4.224-4.204.194-6.164.102-12.337.128-18.507l22.536.003.067 22.598z"
    />
    <path
      fill="#222D12"
      d="m399.232 246.772 22.781.025-.072 22.702-22.672-.015-.16.06.123-22.772z"
    />
    <path
      fill="#3C4A21"
      d="m422.044 269.421 22.633.059.028 22.667-22.707.009.046-22.735z"
    />
    <path
      fill="#475828"
      d="m399.112 292.252-22.536-.003-.036-22.767-.008.009 22.575.052.162-.06-.157 22.769z"
    />
    <path
      fill="#5F694F"
      d="m444.705 292.147-.028-22.667-.036.036 22.714.04-.045-.029.045 22.656-22.65-.036z"
    />
    <path
      fill="#3C4A21"
      d="m489.923 269.667-22.612-.14.045.029-.031-22.627 22.796-.125-.055-.085.174 22.617-.317.331z"
    />
    <path
      fill="#222D12"
      d="m467.324 246.929.031 22.627-22.714-.04.058-22.84 22.625.253z"
    />
    <path
      fill="#B7B9AE"
      d="m490.24 269.337-.174-22.617 22.577-.082 22.758.154c.038 6.405-.018 12.812.158 19.213.074 2.698-.878 3.612-3.566 3.567-6.402-.108-12.808.039-19.212.083l-22.541-.318z"
    />
    <path
      fill="#222D12"
      d="m376.522 201.367.044 22.778v22.614l-22.735.03-22.774.017-22.58.02-22.93.043-22.634-.047.036-.074-.081-22.592.375-.114 45.305-.037 22.433-.084c6.207.055 12.423-.085 18.616.238 3.728.194 4.372-1.447 4.26-4.652-.211-6.014-.102-12.039-.126-18.06l.092-.092 22.699.012z"
    />
    <path
      fill="#5F694F"
      d="m308.477 246.827 22.58-.02.014 22.682.028-.031c-6.18.015-12.363-.088-18.538.095-3.138.093-4.349-.993-4.242-4.19.207-6.175.122-12.358.158-18.536z"
    />
    <path
      fill="#536031"
      d="m353.816 292.191-22.611-.055c-.034-7.56-.07-15.119-.105-22.679l-.028.031 22.748.02-.016-.016.012 22.699z"
    />
    <path
      fill="#0F1A09"
      d="m240.267 269.515.064-22.456c.001.002.011-.384.011-.384l22.607.074-.036.074-.022 22.598-22.624.094z"
    />
    <path
      fill="#3C4A21"
      d="m240.267 269.515 22.624-.095c.041 7.591.083 15.181.126 22.772l-22.727.03-.023-22.707z"
    />
    <path
      fill="#475828"
      d="m353.831 246.789 22.735-.03.014-.065-.048 22.798.008-.009-22.736.009.016.016.011-22.719z"
    />
    <path
      fill="#637037"
      d="m331.209 178.842-.017 22.225-.174.516-22.496-.144-.516-.013c.751-7.505.415-15.019.191-22.532l.331-.39 22.681.338z"
    />
    <path
      fill="#3C4A21"
      d="M308.199 178.894c.224 7.513.56 15.027-.19 22.531l-22.482-.16.167-22.634-.051.027 22.556.236z"
    />
    <path
      fill="#7A8263"
      d="m285.694 178.632-.166 22.634-22.538.095.089-.124-.046-22.312 22.661-.293z"
    />
    <path
      fill="#3C4A21"
      d="M240.225 224.136c.09-7.516.179-15.032.27-22.547 7.529-.116 15.056-.233 22.584-.352l-.089.123.254 22.682-.376.114-22.524-.016-.119-.004z"
    />
    <path
      fill="#8B8F81"
      d="M353.731 201.448c.024 6.02-.085 12.046.126 18.06.113 3.205-.532 4.847-4.26 4.652-6.193-.323-12.409-.183-18.616-.238l.037-22.34.174-.516 22.539.382z"
    />
    <path
      fill="#7A8263"
      d="M376.566 246.76v-22.614l22.687-.106-.021 22.733.001-.001-22.653-.077-.014.065z"
    />
    <path
      fill="#3C4A21"
      d="m376.58 246.694 22.653.077-.124 22.772-22.577-.052.048-22.797z"
    />
    <path
      fill="#2E3A19"
      d="m263.244 224.043-.254-22.682 22.538-.095 22.481.159.514.013.026 22.568-45.305.037zM353.831 246.789l-.011 22.718-22.748-.019-.015-22.682 22.774-.017z"
    />
    <path fill="#2E4029" d="M298.552 564.253h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M321.444 564.253h22.892v22.892h-22.892z"/>
    <path
      fill="#0F7540"
      d="M344.336 564.253h22.892v22.892h-22.892zM367.228 564.253h22.892v22.892h-22.892z"
    />
    <path fill="#80A82B" d="M229.876 587.145h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M252.768 587.145h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M275.66 587.145h22.892v22.892H275.66z"/>
    <path fill="#81B829" d="M298.552 587.145h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M321.444 587.145h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M344.336 587.145h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M367.228 587.145h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M390.12 587.145h22.892v22.892H390.12z"/>
    <path fill="#81B829" d="M413.012 587.145h22.892v22.892h-22.892z"/>
    <path fill="#A7C56C" d="M435.904 587.145h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M458.796 587.145h22.892v22.892h-22.892z"/>
    <path
      fill="#475828"
      d="m376.663 587.407 113.436.227.002 22.338c-20.227.066-40.454.127-60.681.207-2.454.01-4.907.121-7.36.185l-22.63.016-45.67-.413.062-22.496c7.614-.023 15.228-.043 22.841-.064z"
    />
    <path
      fill="#2E3A19"
      d="m376.662 496.729-.313 45.291c-5.845.005-11.702.233-17.532-.062-4.076-.206-5.236 1.375-5.063 5.23.26 5.834.076 11.688.08 17.533l-.027.027-22.502-.017-.411-45.48.417.125c5.472-.022 10.975-.412 16.408.041 5.227.436 6.313-1.624 6.202-6.44-.3-13.014-.086-26.039-.076-39.06l22.643-.049.174 22.861z"
    />
    <path
      fill="#475828"
      d="m194.833 610.349-22.689-.381v-22.33l113.548.002.023 22.385-45.55.236-22.689.058-22.643.03zM626.338 609.952l-113.555.014v-22.328l113.555.01v22.304z"
    />
    <path
      fill="#222D12"
      d="m35.785 223.986.131-22.516c6.214-.058 12.434-.304 18.639-.098 3.264.109 4.152-.974 4.032-4.122-.237-6.209-.09-12.433-.102-18.65l.172.1 22.453-.207 22.841.096.002 22.902c-6.414-.041-12.832.036-19.24-.181-2.714-.092-3.576.734-3.49 3.471.203 6.41.098 12.829.117 19.245l-45.436.085-.119-.125z"
    />
    <path
      fill="#3C4A21"
      d="m285.715 610.025-.023-22.385c13.609-.067 27.22-.271 40.826-.109 3.697.044 4.843-.935 4.676-4.669-.269-6.031.043-12.087.11-18.133l22.502.017.016 22.722-.062 22.496.048.349-22.624.009-45.469-.297z"
    />
    <path
      fill="#222D12"
      d="m649.012 450.988-22.717.133.089.092c-.024-5.99-.24-11.987-.001-17.967.143-3.585-.851-4.985-4.651-4.801-5.975.288-11.976.051-17.966.037l-.261-22.675 23.039-.139 22.374.107.225 22.549-.131 22.664z"
    />
    <path
      fill="#B7B9AE"
      d="M353.864 42.245c-.016-6.043.06-12.089-.086-18.129-.072-2.966.647-4.513 4.056-4.495 20.019.107 40.039.106 60.058.002 3.358-.017 4.182 1.436 4.103 4.451-.158 6.04-.079 12.086-.098 18.129l.131.364-22.819-.008-22.623-.03-22.855.014.133-.298zM-9.41 360.171l.081-22.664c6.006.018 12.019-.131 18.015.117 3.531.146 4.548-1.218 4.453-4.58-.169-5.998.037-12.007.087-18.011l-.123-.278 22.809.242-.037 22.69-.18 22.593-22.455.069-22.65-.178z"
    />
    <path
      fill="#3C4A21"
      d="M739.88 292.375c6.415-.065 12.839.045 19.243-.267 3.3-.161 3.645 1.338 3.558 4.007-.16 4.904.127 9.826-.108 14.725-.149 3.102.664 4.282 3.992 4.165 6.218-.219 12.452.039 18.679.099l.068 22.536.003-.003-22.828.055-22.593-.255.002-22.53-.016-22.532z"
    />
    <path
      fill="#B7B9AE"
      d="M580.876 474.288c-6.391-.119-12.784-.167-19.171-.392-2.807-.099-3.549 1.202-3.483 3.747.118 4.516-.212 9.055.1 13.552.281 4.057-.788 5.821-5.237 5.521-5.812-.392-11.668-.129-17.506-.148l-.079-22.758.012-22.662 22.74-.188 22.644.12.066 22.652-.086.556z"
    />
    <path
      fill="#536031"
      d="m467.353 42.243 45.431.317-.026 22.495-22.785.11-22.577-.189.067-22.311-.11-.422z"
    />
    <path
      fill="#8B8F81"
      d="M580.944 133.052c6.178.071 12.359.293 18.532.148 3.109-.073 4.307.804 4.208 4.078-.186 6.173.036 12.358.088 18.537l-22.914.256-22.615-.166.12-22.797 22.581-.056z"
    />
    <path
      fill="#536031"
      d="m81.435 587.523 45.285.098v22.359l-45.285.098v-22.555zM717.017 269.501l.2 22.598c.045 7.609.09 15.217.133 22.826l-.303-.024-22.541.021-.055-22.607-.058-22.723c7.542-.032 15.083-.061 22.624-.091z"
    />
    <path
      fill="#0F1A09"
      d="m262.854 473.946-22.569.084.169-.259-.159-.265.015-22.381 22.798-.032 22.496-.013.146 22.891.002-.037-22.898.012z"
    />
    <path
      fill="#2E3A19"
      d="m172.031 496.461-22.598-.048-.014-22.498.002-22.712 22.754.033.062 22.799-.206 22.426z"
    />
    <path
      fill="#475828"
      d="m580.859 156.072 22.914-.256 22.55.282-.008 22.6-22.555-.014c-5.797-.012-11.605-.251-17.386.04-4.06.204-5.756-.902-5.507-5.27.329-5.774.024-11.586-.008-17.382z"
    />
    <path
      fill="#7A8263"
      d="m285.642 42.537 45.54.008-.245 22.497-22.583.115-22.651-.087-.061-22.533z"
    />
    <path
      fill="#5F694F"
      d="M126.738 496.735c-.005 6.206-.191 12.419.06 18.614.134 3.313-1.043 4.158-4.168 4.025-6.193-.263-12.402-.174-18.604-.23l-.102-22.428 22.835-.44-.021.459z"
    />
    <path
      fill="#3C4A21"
      d="M807.858 337.699c.062 6.419-.001 12.843.242 19.256.107 2.807-.909 3.494-3.553 3.442-6.418-.125-12.841.042-19.262.091l.109-.375-.078-22.477-.003.003 22.545.06z"
    />
    <path
      fill="#2E3A19"
      d="m353.823 587.469-.016-22.722.027-.027 22.513.017.317 22.669-22.841.063z"
    />
    <path
      fill="#3C4A21"
      d="M467.458 496.568c-.033 6.416-.184 12.834-.041 19.246.059 2.674-.708 3.906-3.466 3.476-.184-.029-.377 0-.566-.004-6.011-.102-13.892 2.062-17.516-.931-3.77-3.112-.812-11.303-1.173-17.259-.091-1.505-.077-3.017-.112-4.525l.256.147 22.361-.002.257-.148zM376.346 564.737l-22.513-.017c-.004-5.845.18-11.699-.08-17.533-.172-3.855.987-5.436 5.063-5.23 5.83.295 11.687.067 17.532.062l-.002 22.718z"
    />
    <path
      fill="#8B8F81"
      d="M13.227 315.033c-.049 6.004-.256 12.013-.087 18.011.095 3.361-.922 4.725-4.453 4.58-5.997-.248-12.009-.099-18.015-.117l-.054-22.7 22.609.226z"
    />
    <path
      fill="#222D12"
      d="m717.352 314.924-.135-22.826 22.662.277.017 22.53-22.544.019z"
    />
    <path
      fill="#7A8263"
      d="m807.858 337.699-22.545-.06-.068-22.536c6.419-.058 12.841-.019 19.255-.22 2.71-.085 3.636.853 3.555 3.558-.194 6.416-.147 12.839-.197 19.258z"
    />
    <path
      fill="#475828"
      d="M126.721 609.98v-22.359l22.689.013v22.336l-22.689.01z"
    />
    <path
      fill="#536031"
      d="M149.409 609.97v-22.336l22.735.004v22.329l-22.735.003z"
    />
    <path
      fill="#3C4A21"
      d="M512.782 587.638v22.328l-22.681.006-.002-22.338 22.683.004z"
    />
    <path
      fill="#222D12"
      d="M626.296 451.121c.014 6.55-.07 13.102.093 19.648.061 2.457-.701 3.218-3.165 3.176-6.545-.114-13.094.038-19.642.084l.172-.316.028-22.298 22.604-.202-.09-.092z"
    />
    <path
      fill="#475828"
      d="m512.843 474.053-22.676-.258.031.027.067-22.574 22.405.267.048 22.19.125.348z"
    />
    <path
      fill="#2E3A19"
      d="m285.75 473.971-.146-22.891-.041.04 22.808.035-.016 22.378-.182.278.213.256-22.636-.096z"
    />
    <path
      fill="#8B8F81"
      d="m58.546 473.884-22.595-.175.053-22.428-.087.024 22.481-.335.503 23.133-.355-.219z"
    />
    <path
      fill="#5F694F"
      d="m694.694 428.344-.337 22.696-22.616-.063-.084-22.656.001.101 22.714.062.322-.14z"
    />
    <path
      fill="#536031"
      d="m671.657 428.321.084 22.656-22.728.011.131-22.664 22.513-.003z"
    />
    <path
      fill="#8B8F81"
      d="m399.083 473.826.044 22.725-22.465.177-.174-22.863.191.128 22.404-.167z"
    />
    <path
      fill="#5F694F"
      d="m-9.458 383.224 22.559-.254.065 22.798-22.621.042-.003-22.586z"
    />
    <path
      fill="#7A8263"
      d="m103.923 496.718.103 22.427-22.644.129-.128-22.461c-.156-.204-.153-.406.009-.605 7.57.882 15.161.288 22.743.331l-.083.179z"
    />
    <path
      fill="#3C4A21"
      d="m149.419 473.915.014 22.498-22.695.322.02-.456-.028-22.384 22.689.02z"
    />
    <path
      fill="#8B8F81"
      d="m172.031 496.461.205-22.427 22.76.057-.245 22.472-22.72-.102z"
    />
    <path
      fill="#3C4A21"
      d="m217.692 496.544-.267-22.552 22.87-.486.159.265-.169.259c-.008 6.382-.138 12.767.033 19.145.073 2.709-.779 3.556-3.482 3.475-6.377-.191-12.762-.089-19.144-.106z"
    />
    <path
      fill="#475828"
      d="m-9.455 405.809 22.621-.042-.018-.018-.073 22.514.136.399-22.624-.319-.246-22.659.204.125z"
    />
    <path
      fill="#222D12"
      d="m262.854 473.946 22.897-.012c-.022 6.416-.172 12.835-.004 19.246.071 2.729-.765 3.585-3.488 3.473-6.196-.257-14.118 2.045-18.112-.969-3.785-2.857-.794-11.263-1.164-17.215-.093-1.505-.089-3.015-.129-4.523z"
    />
    <path
      fill="#8B8F81"
      d="m330.969 496.714-22.509-.25-.075-22.396-.213-.256.182-.278 22.661.453-.046 22.727zM81.111 178.494l-22.453.207-.036-22.66 22.735-.387-.088.443-.158 22.397z"
    />
    <path
      fill="#5F694F"
      d="m626.314 178.698.009-22.6c6.224-.062 12.454.025 18.668-.247 3.147-.138 4.305.728 4.169 4.044-.254 6.217-.066 12.453-.062 18.68l-.032.118-22.887.11.135-.105z"
    />
    <path
      fill="#222D12"
      d="m217.683 65.146 22.622-.283-.26.2.133.3.123 22.29-22.716.119.098-22.626z"
    />
    <path
      fill="#B7B9AE"
      d="m194.676 65.185.255 22.36-.164.481-22.73.044.1-.409c.022-6.206.154-12.414.017-18.616-.065-2.922.951-4.006 3.905-3.927 6.202.164 12.411.061 18.617.067z"
    />
    <path
      fill="#8B8F81"
      d="M535.434 42.537c6.425-.062 12.852-.033 19.273-.228 2.695-.082 3.667.856 3.585 3.569-.193 6.421-.159 12.849-.217 19.274l-22.549-.082-.092-22.533z"
    />
    <path
      fill="#637037"
      d="m535.434 42.537.093 22.534.014.018-22.696.053-.086-.088.026-22.494 22.649-.023z"
    />
    <path
      fill="#475828"
      d="m421.898 42.203 22.789.052-.124.423-.017 22.304c-6.022.037-12.049-.063-18.064.164-3.4.128-4.727-1.02-4.591-4.513.234-6.014.111-12.043.138-18.065l-.131-.365z"
    />
    <path
      fill="#3C4A21"
      d="m330.936 65.041.246-22.496 22.682-.3-.133.298-.131 22.627-22.309-.05-.355-.079z"
    />
    <path
      fill="#536031"
      d="m285.642 42.537.061 22.533.026.043-22.802.048.035-.202.117-22.481 22.563.059z"
    />
    <path
      fill="#B7B9AE"
      d="m263.08 42.478-.117 22.481-22.784.404-.133-.3.26-.2c-.04-6.006.057-12.018-.173-18.017-.127-3.3.773-4.776 4.373-4.606 6.18.292 12.381.18 18.574.238zM444.687 42.255c.019-6.027.218-12.061-.01-18.078-.133-3.518 1.139-4.642 4.553-4.463 4.508.236 9.047.235 13.555 0 3.402-.177 4.692.92 4.558 4.452-.227 6.017-.017 12.051.009 18.077l.111.421-22.901.013.125-.422z"
    />
    <path
      fill="#637037"
      d="m126.654 110.349 22.616.12.279-.185.295.158-.594 22.793-22.558.004.089-22.562-.127-.328z"
    />
    <path
      fill="#B7B9AE"
      d="m580.832 110.336-22.538.163-.299.166-.038-22.99.38.154c6.211.011 12.425.128 18.632-.014 2.902-.066 4.032.91 3.949 3.888-.173 6.208-.073 12.422-.086 18.633z"
    />
    <path
      fill="#222D12"
      d="M149.27 110.468c.05-6.422.237-12.846.086-19.263-.065-2.767.834-3.516 3.519-3.459 6.418.138 12.842-.037 19.264-.086l-.101.41.099 22.436-22.293-.065-.295-.158-.279.185z"
    />
    <path
      fill="#536031"
      d="m535.526 65.071 22.549.082.263 22.677-.38-.154-22.469.147.052-22.734-.015-.018z"
    />
    <path
      fill="#2E3A19"
      d="m694.342 224.021.167 22.729-.08-.011-22.684-.069.101-22.53 22.496-.119z"
    />
    <path
      fill="#3C4A21"
      d="m13.227 315.033-22.609-.226.012-22.581 22.529-.018-.056 22.547.124.278z"
    />
    <path
      fill="#536031"
      d="m13.16 292.208-22.529.018-.106-22.592 22.621-.366-.03.536.023 22.368.021.036z"
    />
    <path
      fill="#637037"
      d="m717.017 269.501-22.623.089.082-.138-.047-22.712.08.011c6.418.005 12.839.129 19.253-.041 2.749-.072 3.581.867 3.491 3.55-.215 6.409-.174 12.827-.236 19.241z"
    />
    <path
      fill="#536031"
      d="M649.098 178.575c6.405.025 12.812.168 19.213.02 2.689-.062 3.596.754 3.525 3.488-.165 6.401-.031 12.809-.019 19.214l-.053.134-22.779-.133.08-22.606.033-.117z"
    />
    <path
      fill="#222D12"
      d="m13.166 224.242 22.618-.256.123.125-.174 22.839-22.685-.02.126-.187-.008-22.501z"
    />
    <path
      fill="#3C4A21"
      d="m694.342 224.021-22.496.119-.082-.079.001-22.629.053-.134 22.557.115.34 22.752-.373-.144z"
    />
    <path
      fill="#2E3A19"
      d="m13.166 224.242.008 22.5-22.659-.093c-.028-6.226.043-12.454-.129-18.676-.074-2.67.255-4.151 3.552-3.99 6.398.314 12.818.197 19.228.259z"
    />
    <path
      fill="#7A8263"
      d="m103.913 155.718.189-45.169 22.68.127-.089 22.562-.008 22.525-22.6.491a.831.831 0 0 1-.172-.536z"
    />
    <path
      fill="#222D12"
      d="m330.969 496.714.046-22.728.249-.251 22.542.141.039.039c-.01 13.021-.225 26.046.076 39.06.111 4.816-.975 6.876-6.202 6.44-5.433-.453-10.936-.062-16.408-.041l-.342-22.66z"
    />
    <path
      fill="#222D12"
      d="m376.488 473.866-22.643.049-.039-.038.017-22.694 22.597-.032.259 22.844-.191-.129z"
    />
    <path
      fill="#3C4A21"
      d="m35.733 246.951.174-22.84 45.436-.085.054-.097-.043 22.892-.053 22.647-.087 22.828-22.557-.013-22.782-.08-.033.009-.122-22.883.072.13c6.037.01 12.077-.096 18.109.078 3.225.093 4.939-.69 4.75-4.366-.242-4.704-.187-9.434-.014-14.144.116-3.148-1.015-4.404-4.234-4.307-6.22.189-12.449.101-18.674.128l.004.103z"
    />
    <path
      fill="#2E3A19"
      d="m103.955 201.492-.002-22.902-.008.028 22.747.02.056 22.802.024 22.595-22.368.139a.697.697 0 0 0-.558-.17l.109-22.512z"
    />
    <path
      fill="#536031"
      d="m81.27 156.097 22.643-.379a.834.834 0 0 0 .171.536l-.14 22.365s.009-.027.008-.028l-22.842-.096.16-22.398z"
    />
    <path
      fill="#3C4A21"
      d="M81.253 496.814c-6.41-.071-12.823-.274-19.23-.151-2.751.053-3.541-.849-3.476-3.534.155-6.411.018-12.83-.001-19.245l.354.218 22.415-.158-.053 22.265c-.162.198-.165.4-.009.605z"
    />
    <path
      fill="#2E3A19"
      d="m626.544 405.668-23.039.139-22.494.031-.142-.14.056-22.655 45.198-.044.421 22.669z"
    />
    <path
      fill="#8B8F81"
      d="m626.385 451.213-22.604.202a16.252 16.252 0 0 1-.287-.494l-.217-22.557.292-.137.197.256c5.99.015 11.991.252 17.966-.037 3.8-.183 4.794 1.216 4.651 4.801-.238 5.979-.022 11.976.002 17.966z"
    />
    <path
      fill="#B7B9AE"
      d="m626.544 405.668-.421-22.669.278-.06.246.143 22.431.023.404 22.678-.284.159-.279-.167-22.375-.107z"
    />
    <path
      fill="#637037"
      d="M422.029 42.567c-.027 6.023.097 12.051-.138 18.065-.136 3.494 1.191 4.642 4.591 4.513 6.015-.227 12.042-.127 18.065-.163l.195.19-.002 22.615-22.859.045-22.539.152-22.891-.253-.18-22.61.419-.046c6.205.001 12.412-.087 18.615.044 2.9.061 4.045-1.013 3.977-3.946-.142-6.202-.062-12.409-.073-18.615l22.82.009z"
    />
    <path
      fill="#475828"
      d="M399.21 42.559c.011 6.205-.07 12.412.073 18.615.067 2.933-1.078 4.007-3.977 3.946-6.203-.131-12.41-.043-18.616-.044-.035-7.515-.069-15.031-.102-22.547l22.622.03z"
    />
    <path
      fill="#7A8263"
      d="m376.587 42.529.102 22.547-.416.046-22.248-.026-.425.074.131-22.627 22.856-.014z"
    />
    <path
      fill="#5F694F"
      d="m376.678 473.994-.259-22.844.02-22.658 22.764-.029c6.056.005 12.116-.127 18.165.068 3.37.109 4.8-.941 4.655-4.501-.245-6.046-.123-12.107-.154-18.163l22.734-.145.107 22.688-.066 22.775-22.805.042c-6.199-.02-12.404.129-18.593-.129-3.267-.136-4.161.986-4.069 4.142.181 6.189-.041 12.39-.096 18.586l-22.403.168z"
    />
    <path
      fill="#536031"
      d="m421.841 451.227 22.805-.042.012.013-.108 22.329c-.154.21-.12.383.101.52-6.412-.046-12.825-.209-19.234-.081-2.69.054-3.598-.768-3.536-3.503.147-6.408-.008-12.824-.04-19.236z"
    />
    <path
      fill="#8B8F81"
      d="m467.627 473.82-.426 22.897-22.361.002-.19-22.673c-.221-.137-.255-.311-.1-.519l22.623.392.454-.099z"
    />
    <path
      fill="#475828"
      d="m13.166 405.768-.065-22.798.14-22.621 22.455-.069 22.858.074-.059 22.805c-6.399-.062-12.801-.033-19.196-.228-2.711-.082-3.625.917-3.551 3.586.177 6.397.137 12.8.184 19.201l-22.784.031.018.019z"
    />
    <path
      fill="#3C4A21"
      d="m35.876 337.687 22.634-.215.043 22.882-22.857-.074.18-22.593z"
    />
    <path
      fill="#2E3A19"
      d="m13.104 314.755.056-22.547-.021-.035 22.704.039.033-.009-.07 22.592.108.202-22.81-.242z"
    />
    <path
      fill="#536031"
      d="m762.708 360.184-.22-22.493 22.828-.055.078 22.477-22.686.071z"
    />
    <path
      fill="#2E3A19"
      d="m739.895 337.435-22.57.345-.277-22.88.303.024 22.546-.018-.002 22.529zM581.011 405.838l.122 22.595-.296 22.757.059-.109-22.644-.12c-.01-5.984-.158-11.973.024-17.952.102-3.333-.94-4.729-4.453-4.6-5.976.218-11.967.059-17.951.058l-.497-22.666 22.816-.082 22.678-.023.142.142z"
    />
    <path
      fill="#7A8263"
      d="m603.753 473.713-22.79.021-.066-22.652-.059.109 22.656-.269c.093.166.189.331.287.494l-.028 22.297z"
    />
    <path
      fill="#475828"
      d="M535.873 428.468c5.984.001 11.975.16 17.951-.058 3.513-.129 4.555 1.268 4.453 4.6-.182 5.979-.034 11.967-.024 17.952l-22.74.187.055.065-.215-22.71.268.099.252-.135z"
    />
    <path
      fill="#5F694F"
      d="m535.5 473.811-22.782-.105-.048-22.191.216-.492 22.681.191-.055-.065-.012 22.662z"
    />
    <path
      fill="#637037"
      d="m444.563 42.677 22.901-.013-.067 22.312-.053.325-22.6-.127-.196-.191c.004-7.436.009-14.871.015-22.306zM489.973 65.165l22.785-.11.085.087-.019 22.625-22.612.097-.239-22.699z"
    />
    <path
      fill="#7A8263"
      d="m489.973 65.164.238 22.698-.246-.024-22.628.006.006-22.544.053-.325 22.577.189z"
    />
    <path
      fill="#222D12"
      d="M580.859 156.072c.032 5.796.337 11.608.007 17.383-.25 4.368 1.447 5.474 5.507 5.27 5.781-.291 11.59-.053 17.386-.041l-.043 22.604-22.802.088-22.66-.138-.05-22.612.031-22.665.01-.056 22.614.167z"
    />
    <path
      fill="#2E3A19"
      d="m558.294 110.499.07 22.609-.12 22.797-.01.056c-6.179-.012-12.362-.141-18.537.012-3.155.078-4.319-1.078-4.245-4.239.146-6.175.003-12.357-.02-18.536l.106-22.725 22.458.193.298-.167z"
    />
    <path
      fill="#5F694F"
      d="m717.048 314.9.277 22.88.039-.097-22.888-.067-.111-22.823.142.128 22.541-.021z"
    />
    <path
      fill="#2E3A19"
      d="m694.452 292.313-22.716.068.125-.393-.108-22.389 22.723-.148-.081.138c.018 7.576.037 15.15.057 22.724z"
    />
    <path
      fill="#3C4A21"
      d="m263.108 451.093-22.798.032.006.028-.033-22.715-.025-22.64c13.532-.012 27.065-.124 40.596.03 3.6.041 5.072-.965 4.906-4.757-.263-6.003-.081-12.026-.087-18.04l22.638.108.075 45.37-22.802-.014c-6.39-.002-12.784.12-19.168-.064-2.732-.079-3.548.805-3.463 3.496.201 6.385.12 12.777.155 19.166z"
    />
    <path
      fill="#222D12"
      d="m240.295 473.505-22.87.486.097-.037c.011-6.186.14-12.376-.016-18.558-.08-3.168 1.096-4.318 4.252-4.242 6.182.15 12.371.015 18.558-.002l-.006-.028-.015 22.381zM149.419 473.915l-22.689-.02-.024-.024-.012-22.677.048-22.481c7.584-.025 15.168-.048 22.751-.07l-.073 22.561-.001 22.711z"
    />
    <path
      fill="#0F1A09"
      d="m149.421 451.203.073-22.561-.077-.268 22.699.072.028 22.746.031.045-22.754-.034z"
    />
    <path
      fill="#475828"
      d="m194.996 474.091-22.76-.057-.062-22.799-.03-.045 22.628.083.053 22.569.171.249z"
    />
    <path
      fill="#536031"
      d="m308.354 65.156 22.583-.115.353.078c-.067 7.576-.132 15.153-.198 22.729l-22.672-.005-.066-22.687z"
    />
    <path
      fill="#3C4A21"
      d="m308.354 65.156.066 22.686.055.078-22.562-.047-.184-22.761-.027-.044c7.551.031 15.102.06 22.652.088z"
    />
    <path
      fill="#536031"
      d="m126.73 473.895.028 22.384c-7.611.144-15.222.29-22.835.438l.082-.177-.029-22.637 22.729-.032.025.024z"
    />
    <path
      fill="#637037"
      d="m13.147 405.749 22.784-.031-.037-.03-.076 22.629.125.375-22.87-.429c.026-7.505.05-15.009.074-22.514z"
    />
    <path
      fill="#7A8263"
      d="m490.265 451.249-.168-22.838-.088.069 22.808.04-.073-.074.142 22.578-.216.492-22.405-.267z"
    />
    <path
      fill="#222D12"
      d="m331.015 473.986-22.661-.453.017-22.378.006.001 22.705-.005.041.042.141 22.542-.249.251z"
    />
    <path
      fill="#0F1A09"
      d="m285.563 451.12.019-22.623 22.803.013-.021-.012.013 22.658-.006-.001-22.808-.035z"
    />
    <path
      fill="#7A8263"
      d="m81.315 473.943-22.415.158-.502-23.132.21.217 22.665-.071-.012 22.728.054.1z"
    />
    <path
      fill="#3C4A21"
      d="m694.481 405.701-.109 22.783-22.714-.062.201-22.595-.027.026 22.695-.03-.046-.122z"
    />
    <path
      fill="#2E3A19"
      d="m103.977 473.903.029 22.637c-7.582-.044-15.173.55-22.743-.332l.053-22.265-.054-.098 22.729.069-.014-.011z"
    />
    <path
      fill="#0F1A09"
      d="m194.825 473.843-.052-22.57c.002-7.615.003-15.229.006-22.842l.052-22.673h22.733c.002 6.171.125 12.346-.043 18.513-.087 3.18 1.114 4.289 4.249 4.207 6.167-.162 12.341-.042 18.513-.039l.033 22.715c-6.186.017-12.375.151-18.557.002-3.156-.076-4.331 1.074-4.252 4.242.156 6.182.027 12.372.016 18.558-7.567-.039-15.133-.076-22.698-.113z"
    />
    <path
      fill="#7A8263"
      d="m626.313 201.142-.134-22.339 22.887-.11-.08 22.606.041.068-22.675.305-.039-.53z"
    />
    <path
      fill="#2E3A19"
      d="m240.178 65.364 22.784-.404-.035.202-.149 22.537-22.552.181.074-.228-.122-22.288z"
    />
    <path
      fill="#637037"
      d="M217.585 87.772c7.572-.039 15.144-.078 22.716-.119l-.074.226.026 22.599-22.828-.085-.098-22.748.258.127z"
    />
    <path
      fill="#8B8F81"
      d="m217.327 87.645.099 22.749.114.207-22.525-.184-.247-22.391.164-.481 22.395.1z"
    />
    <path
      fill="#3C4A21"
      d="M194.768 88.026c.082 7.464.165 14.927.246 22.391l-22.873.094-.004-.004-.099-22.436c7.576-.016 15.153-.031 22.73-.045z"
    />
    <path
      fill="#7A8263"
      d="m512.824 87.766.019-22.625 22.697-.052-.052 22.734.027-.026-22.684-.017-.007-.014z"
    />
    <path
      fill="#2E3A19"
      d="M331.092 87.849c.065-7.576.131-15.153.198-22.729l22.311.05.424-.075-.095 22.667-22.877.079.039.008z"
    />
    <path
      fill="#637037"
      d="m262.777 87.698.15-22.537 22.802-.048.183 22.76-.385-.059-22.344.099-.406-.215z"
    />
    <path
      fill="#536031"
      d="m126.685 155.761.008-22.524 22.559-.004.297-.018-.074 22.69-22.834.155.044-.299z"
    />
    <path
      fill="#2E3A19"
      d="m149.844 110.441 22.293.065.004.004-.021 22.704-22.571.002-.297.018c.196-7.598.394-15.195.592-22.793z"
    />
    <path
      fill="#7A8263"
      d="m557.995 110.666-22.458-.193.055-.105-.077-22.572-.027.026 22.468-.147.039 22.991z"
    />
    <path
      fill="#8B8F81"
      d="m13.049 246.929 22.685.02-.003-.102c.022 7.537.042 15.074.062 22.611l-.071-.129-22.607.475.03-.536-.096-22.339z"
    />
    <path
      fill="#7A8263"
      d="m694.476 269.451-22.723.148.096-.204-.001-22.515-.103-.21 22.684.07.047 22.711z"
    />
    <path
      fill="#3C4A21"
      d="m671.846 224.14-.101 22.53.103.209-22.803-.129-.094-22.707.127.024 22.686-.006.082.079z"
    />
    <path
      fill="#475828"
      d="m13.115 269.805 22.607-.475.121 22.882-22.704-.039-.024-22.368z"
    />
    <path
      fill="#637037"
      d="m671.764 224.061-22.686.006-.051-22.7-.041-.068 22.779.133-.001 22.629z"
    />
    <path
      fill="#222D12"
      d="m103.945 178.618.14-22.365 22.601-.491c-.001-.001-.045.299-.044.298l.051 22.578-22.748-.02z"
    />
    <path
      fill="#0F1A09"
      d="m331.263 473.735-.141-22.542 22.695-.016.006.006-.017 22.694-22.543-.142zM376.44 428.491l-.02 22.659-22.597.032-.005-.006.041-22.831 22.68.134-.099.012z"
    />
    <path
      fill="#2E3A19"
      d="m81.214 292.297.087-22.828c6.179.01 12.362-.099 18.536.081 3.182.093 4.314-1.098 4.205-4.244-.215-6.171-.167-12.351-.229-18.528l.393-.016 22.539.045c-.022 6.177.071 12.357-.113 18.529-.094 3.141 1.022 4.333 4.204 4.244 6.172-.172 12.353-.049 18.531-.049l.059 22.677-22.728.055-22.672-.055-22.738.018-.074.071z"
    />
    <path
      fill="#637037"
      d="M103.812 246.778c.061 6.176.014 12.357.229 18.528.11 3.146-1.022 4.336-4.205 4.244-6.174-.18-12.357-.07-18.536-.081l.053-22.648 22.459-.043z"
    />
    <path
      fill="#222D12"
      d="m35.792 269.459-.061-22.611c6.224-.026 12.453.061 18.673-.127 3.219-.098 4.35 1.159 4.234 4.307-.173 4.71-.228 9.44.014 14.144.189 3.677-1.525 4.46-4.75 4.366-6.033-.175-12.073-.069-18.11-.079z"
    />
    <path
      fill="#8B8F81"
      d="m58.657 292.283 22.557.013.072-.069.044 22.725-22.852-.039.055.099.124-22.729z"
    />
    <path
      fill="#5F694F"
      d="m58.657 292.283-.123 22.729-22.729-.217.07-22.592 22.782.08z"
    />
    <path
      fill="#536031"
      d="m103.812 246.778-22.459.042.044-22.891 22.449.075a.697.697 0 0 1 .558.17c-.72 7.524-.384 15.057-.199 22.588l-.393.016z"
    />
    <path
      fill="#3C4A21"
      d="m126.692 178.638-.051-22.578 22.833-.154-.052-.016.12 22.888-.194 22.336-22.6.326-.056-22.802z"
    />
    <path
      fill="#0F1A09"
      d="M104.205 246.762c-.185-7.532-.521-15.065.199-22.588l22.369-.138 22.694.332.005 22.218-22.727.222-22.54-.046z"
    />
    <path
      fill="#B7B9AE"
      d="m149.466 224.368-22.694-.332-.024-22.595 22.6-.326.138.479-.083 22.321.207.202-.144.251z"
    />
    <path
      fill="#3C4A21"
      d="m626.123 382.999-45.198.044.008.008.141-22.88 22.404.118 23.014.173.154 22.62-.246-.143-.277.06z"
    />
    <path
      fill="#3C4A21"
      d="m580.87 405.698-22.677.023.002-22.635 22.739-.035-.008-.008-.056 22.655z"
    />
    <path
      fill="#8B8F81"
      d="m467.343 65.301-.006 22.543-.065.064-22.55-.071.019-.05.002-22.614 22.6.128zM353.929 87.762l.095-22.667 22.249.026c.058 7.537.118 15.074.178 22.611l.096.117-22.618-.087z"
    />
    <path
      fill="#7A8263"
      d="m421.881 87.832 22.859-.045-.018.05-.034 22.666-22.82-.043.013-22.628z"
    />
    <path
      fill="#0F1A09"
      d="m421.881 87.832-.013 22.627.024-.053-22.688-.045.137-22.377 22.54-.152z"
    />
    <path
      fill="#2E3A19"
      d="m399.342 87.984-.137 22.378.095.241-22.748-.111-.005-22.643-.096-.117 22.891.252z"
    />
    <path
      fill="#B7B9AE"
      d="m444.711 428.409-.107-22.688.085.056 22.787.033-.116 22.737.092-.057-22.741-.081z"
    />
    <path
      fill="#7A8263"
      d="M421.87 405.867c.032 6.055-.091 12.116.155 18.162.145 3.56-1.285 4.61-4.655 4.501-6.049-.196-12.109-.063-18.165-.068l.085-22.52 22.663-.29-.083.215z"
    />
    <path
      fill="#3C4A21"
      d="m399.289 405.943-.085 22.52-22.764.029.098-.012-.125-22.637 22.793-.308.083.408z"
    />
    <path
      fill="#7A8263"
      d="m444.711 428.409 22.74.08-.3 22.722-22.494-.014-.012-.013c.023-7.591.045-15.183.066-22.775z"
    />
    <path
      fill="#536031"
      d="m444.604 405.721-22.734.145.081-.213.071-22.56 22.441-.173.225 22.856-.084-.055z"
    />
    <path
      fill="#5F694F"
      d="m444.658 451.198 22.494.014.022 22.708c-7.541-.13-15.082-.26-22.623-.392l.107-22.33z"
    />
    <path
      fill="#2E3A19"
      d="m58.479 314.914 22.851.038-.048-.07.015 22.657.018 22.892-.042 22.62-22.656.006-.123.103.059-22.806-.043-22.881-.031-22.559z"
    />
    <path
      fill="#B7B9AE"
      d="M35.932 405.718c-.047-6.401-.007-12.804-.184-19.201-.074-2.669.84-3.669 3.551-3.586 6.395.194 12.797.166 19.196.228l.122-.103-.031 22.683-22.691-.05.037.029z"
    />
    <path
      fill="#222D12"
      d="m694.476 337.616 22.887.067.067 22.715-.427-.135-22.487.078.021-22.63-.061-.095z"
    />
    <path
      fill="#8B8F81"
      d="m535.375 405.803.497 22.666-.252.135-.268-.099-22.608-.058.073.074-.107-22.647 22.79-.058-.125-.013z"
    />
    <path
      fill="#475828"
      d="m558.194 383.086-.002 22.635-22.816.082.124.012-.037-22.751 22.76-.023-.029.045z"
    />
    <path
      fill="#536031"
      d="m535.537 110.473-.105 22.725-22.639.041-22.657.038-.167-.169.106-22.608-.11-22.663.246.024 22.613-.096.007.013c-.009 6.187.098 12.377-.074 18.559-.089 3.195 1.154 4.311 4.282 4.213 6.18-.194 12.369-.136 18.554-.183l-.056.106z"
    />
    <path
      fill="#8B8F81"
      d="m489.965 87.838.11 22.663-22.679-.084.06.06-.184-22.57.065-.064 22.628-.005z"
    />
    <path
      fill="#475828"
      d="m512.793 133.239 22.639-.041c.022 6.179.165 12.361.019 18.536-.075 3.161 1.09 4.317 4.245 4.239 6.175-.153 12.358-.024 18.537-.012l-.03 22.665-22.788.126-22.599-.019-.02-22.785-.003-22.709z"
    />
    <path
      fill="#2E3A19"
      d="m580.913 201.376 22.802-.088.021-.068-.128 22.879-22.585.095-.11-22.818z"
    />
    <path
      fill="#3C4A21"
      d="m580.913 201.376.11 22.817-.009-.085-22.776-.025-.057-22.637.073-.21 22.659.14z"
    />
    <path
      fill="#2E3A19"
      d="m535.415 178.753 22.788-.127.05 22.612-.072.207-22.777-.427.011-22.265z"
    />
    <path
      fill="#8B8F81"
      d="m694.365 314.793.111 22.823.062.095-22.788-.081.022-22.405a.819.819 0 0 1-.006-.571l22.599.139z"
    />
    <path
      fill="#475828"
      d="m671.86 291.989-22.826.151.053.121-.205-22.638 22.967-.228-.096.204.107 22.39z"
    />
    <path
      fill="#222D12"
      d="m353.858 428.345-.041 22.831-22.695.016-.041-.041c.008-6.174-.098-12.351.071-18.521.087-3.179-1.141-4.301-4.268-4.217-6.17.167-12.347.07-18.521.083l.021.012-.074-45.371c7.582-.044 15.163-.087 22.745-.129.022 6.175.158 12.352.017 18.522-.071 3.124 1.03 4.363 4.215 4.269 6.17-.182 12.347-.092 18.521-.116l.041.132.009 22.53z"
    />
    <path
      fill="#0F1A09"
      d="M285.672 383.032c.006 6.014-.176 12.037.087 18.04.166 3.792-1.306 4.798-4.906 4.757-13.53-.154-27.064-.042-40.596-.031l.037-.078.007-22.789 45.306.036.065.065zM331.055 383.009l-22.745.13c-7.547-.035-15.092-.071-22.638-.108l-.065-.064.214-22.565 22.42-.22 22.944.159.037 22.685-.167-.017z"
    />
    <path
      fill="#2E3A19"
      d="M240.282 428.438c-6.171-.003-12.346-.123-18.513.039-3.135.082-4.335-1.027-4.249-4.207.168-6.167.045-12.341.042-18.513l22.732-.037-.037.078.025 22.64zM126.742 428.714l-.048 22.479-22.548-.057-.229.039.141-22.714-.065.068 22.685-.155.064.34z"
    />
    <path
      fill="#3C4A21"
      d="m104.146 451.136 22.548.057.012 22.677-22.729.032.013.011.156-22.777z"
    />
    <path
      fill="#475828"
      d="m149.494 428.642-22.751.07-.063-.338-.05-22.552 22.735-.12.053 22.671.076.269z"
    />
    <path
      fill="#222D12"
      d="m194.777 428.431-.005 22.843-22.629-.082-.028-22.746-.077-.067 22.739.052z"
    />
    <path
      fill="#5F694F"
      d="m149.417 428.373-.053-22.67.031.036 22.664.062.141-.08-.161 22.656.077.067-22.699-.071z"
    />
    <path
      fill="#3C4A21"
      d="m603.608 224.099.129-22.88 22.576-.077.039.531.049 22.388-.103.02-22.751.072.061-.054z"
    />
    <path
      fill="#0F1A09"
      d="m308.42 87.843 22.672.005-.04-.007.053 22.644-22.616-.154-.014-22.41-.055-.078z"
    />
    <path
      fill="#2E3A19"
      d="m308.475 87.921.014 22.411-22.906.14-.057-22.657.385.059 22.564.047z"
    />
    <path
      fill="#475828"
      d="m35.895 405.688 22.691.05 22.611.005 22.868-.026-.041-.039-.031 22.85.065-.068-22.692.029-22.891.012-22.656-.185.076-22.628z"
    />
    <path
      fill="#2E3A19"
      d="m58.476 428.502 22.891-.012-.144 22.688.051-.062-22.665.071-.133-22.685z"
    />
    <path
      fill="#536031"
      d="m717.505 382.997-.262 22.811-22.763-.106.047.122-.145-22.693 22.639-.039.484-.095z"
    />
    <path
      fill="#3C4A21"
      d="m467.36 428.547.116-22.736-.039.035 22.795-.059-.221 22.694.088-.069c-7.581.044-15.16.089-22.739.135z"
    />
    <path
      fill="#2E3A19"
      d="M308.363 428.498c6.174-.013 12.351.084 18.521-.083 3.127-.084 4.355 1.037 4.268 4.217-.168 6.17-.063 12.347-.071 18.521l-22.705.004-.013-22.659z"
    />
    <path
      fill="#536031"
      d="m104.146 451.136-.156 22.777-22.729-.068.012-22.729-.05.062 22.695-.003.228-.039z"
    />
    <path
      fill="#222D12"
      d="m240.294 405.72-22.732.037h-22.733l-22.63-.036-.14.079.083-22.755 22.578-.131c6.217.038 12.438-.054 18.649.168 3.185.113 4.319-.974 4.221-4.19-.19-6.212-.04-12.434-.03-18.652l22.743.055-.001 22.636-.008 22.789z"
    />
    <path
      fill="#2E3A19"
      d="m172.199 405.722 22.63.036-.052 22.673-22.739-.053.161-22.656z"
    />
    <path
      fill="#222D12"
      d="m626.4 224.061-.049-22.388 22.676-.305.051 22.7-.126-.024-22.552.017z"
    />
    <path
      fill="#536031"
      d="m240.253 110.478-.026-22.599 22.552-.181.404.216c-.037 7.5-.071 15.001-.105 22.503l-22.825.061z"
    />
    <path
      fill="#2E3A19"
      d="m240.253 110.478 22.825-.061-.076.072-.067 22.697-22.677.05-22.745-.146.028-22.488-.114-.207 22.826.083z"
    />
    <path
      fill="#222D12"
      d="m217.539 110.601-.027 22.49-45.382.133-.01-.01.021-22.704 22.873-.094 22.525.185z"
    />
    <path
      fill="#637037"
      d="M535.592 110.368c-6.185.046-12.373-.011-18.554.183-3.128.098-4.371-1.017-4.282-4.213.172-6.182.065-12.372.074-18.559l22.684.017.078 22.572z"
    />
    <path
      fill="#3C4A21"
      d="m331.106 110.485-.053-22.645 22.877-.078c7.54.029 15.079.057 22.618.087l.004 22.644c-6.189.009-12.381.137-18.565-.022-3.209-.083-4.276 1.185-4.206 4.299.138 6.185 0 12.376-.023 18.563l-22.648-.059-.004-22.789z"
    />
    <path
      fill="#8B8F81"
      d="m263.078 110.417.104-22.503 22.345-.099.057 22.657.073-.045-22.656.062.077-.072z"
    />
    <path
      fill="#222D12"
      d="m149.549 133.216 22.571-.002.01.01-.014 22.667-22.694-.001.052.016.075-22.69z"
    />
    <path
      fill="#637037"
      d="m671.849 269.395-22.966.227.128-.002.019-22.858.014-.013 22.804.13.001 22.516z"
    />
    <path
      fill="#2E3A19"
      d="m626.4 224.061 22.552-.018.093 22.707-.014.013-22.634-.022-.101-22.66.104-.02z"
    />
    <path
      fill="#B7B9AE"
      d="m353.858 428.345-.009-22.528 22.564.026.125 22.637-22.68-.135z"
    />
    <path
      fill="#475828"
      d="m126.696 292.264 22.728-.055-.007-.008.099 22.458-22.658.09-.162-22.485z"
    />
    <path
      fill="#536031"
      d="m126.696 292.264.161 22.484-.253.272-22.466.052-.276-.481.163-22.381 22.671.054z"
    />
    <path
      fill="#222D12"
      d="m104.024 292.209-.163 22.382-22.58.291.048.07-.044-22.725 22.739-.018z"
    />
    <path
      fill="#637037"
      d="m126.744 246.807 22.727-.222-.099.265.037 22.647-.044.034c-6.177-.001-12.358-.123-18.53.049-3.182.089-4.298-1.103-4.204-4.244.184-6.172.091-12.352.113-18.529z"
    />
    <path
      fill="#5F694F"
      d="m149.424 292.208-.059-22.677.044-.034 22.692.005.076 22.726-22.76-.029.007.009z"
    />
    <path
      fill="#637037"
      d="m149.422 155.89 22.694.001-.009.065-.111 22.801-22.454.021-.12-22.888z"
    />
    <path
      fill="#8B8F81"
      d="m149.542 178.778 22.455-.022.182-.116-.182 22.835-22.511.118-.138-.479.194-22.336z"
    />
    <path
      fill="#222D12"
      d="m149.471 246.586-.005-22.218.143-.251-.207-.202 22.655.054.274.463-.113 22.262-.117.117-22.73.039.1-.264z"
    />
    <path
      fill="#B7B9AE"
      d="m603.478 360.288-22.404-.117-.072.128-.224-22.618 22.942-.001-.242 22.608z"
    />
    <path
      fill="#2E3A19"
      d="m603.478 360.288.242-22.609-.004.077 22.663-.085.032 22.773.081.017-23.014-.173z"
    />
    <path
      fill="#222D12"
      d="m580.933 383.05-22.739.035.028-.045-.025-22.675 22.804-.068.072-.128-.14 22.881z"
    />
    <path
      fill="#475828"
      d="m671.75 337.631 22.788.081-.022 22.63-.07-.028c-5.84.009-11.688.207-17.517-.041-3.841-.164-5.436.974-5.236 5.055.286 5.825.039 11.676.02 17.516l.115.406-22.804-.141.005-22.632-.013-22.794c7.579-.019 15.157-.035 22.734-.052z"
    />
    <path
      fill="#222D12"
      d="m649.017 337.682.013 22.793-22.619-.031-.032-22.773-.078-.109 22.741.084-.025.036z"
    />
    <path
      fill="#0F1A09"
      d="M467.272 87.908c.061 7.523.123 15.046.183 22.57l-22.768.025.034-22.666 22.551.071z"
    />
    <path
      fill="#536031"
      d="m444.687 110.503 22.768-.025-.059-.06c-.001 5.985.164 11.975-.066 17.951-.137 3.559.88 5.08 4.692 4.896 5.971-.288 11.964-.127 17.947-.156l.167.168.071 22.698-.132-.03-22.659-.006-22.736-.017c-.004-6.183-.131-12.369.039-18.546.088-3.186-1.104-4.282-4.247-4.211-6.178.139-12.362-.022-18.543-.06l-.035-22.699-.024.053 22.817.044z"
    />
    <path
      fill="#5F694F"
      d="m421.892 110.406.035 22.7-22.651.193.023-22.696-.095-.241 22.688.044z"
    />
    <path
      fill="#475828"
      d="m399.3 110.603-.023 22.695.022-.078-22.777.009-22.755.005-.011.099c.024-6.188.161-12.379.024-18.564-.069-3.114.997-4.381 4.206-4.299 6.184.159 12.376.031 18.565.022l22.749.111z"
    />
    <path
      fill="#7A8263"
      d="m444.689 405.777-.225-22.856.22.135 22.709-.029.043 22.817.039-.035-22.786-.032z"
    />
    <path
      fill="#222D12"
      d="m422.023 383.094-.071 22.56-22.663.289-.083-.406.029-22.496 22.788.053z"
    />
    <path
      fill="#0F1A09"
      d="m399.235 383.04-.029 22.496c-7.598.101-15.195.204-22.793.306l-22.564-.026-.041-.132.043-22.587-.029-.067 22.673-.059 22.751.076-.011-.007z"
    />
    <path
      fill="#0F1A09"
      d="m422.023 383.094-22.788-.054.011.009-.025-22.711c6.19 0 12.383-.118 18.568.048 3.208.086 4.276-1.178 4.208-4.295-.136-6.186.007-12.377.034-18.566l22.59.051.112 22.894-.048 22.585-.22-.135-22.442.174z"
    />
    <path
      fill="#5F694F"
      d="m81.282 314.882 22.58-.291.276.481-.07 22.491-22.77-.024-.016-22.657z"
    />
    <path
      fill="#0F1A09"
      d="m81.297 337.539 22.77.024-.033.024-.042 22.705-.002.054-22.675.084-.018-22.891zM81.197 405.744l-22.611-.005.031-22.683 22.656-.006.083-.075-.159 22.769z"
    />
    <path
      fill="#2E3A19"
      d="m717.022 383.091-22.639.039.058-.013.006-22.804.07.028 22.487-.078.018 22.828z"
    />
    <path
      fill="#0F1A09"
      d="m535.462 383.064.038 22.751-22.79.058.118-.174V383.06l-.02-.037 22.69.07-.036-.029z"
    />
    <path
      fill="#2E3A19"
      d="m558.198 360.367.025 22.675-22.76.023.035.028.019-22.761 22.681.035z"
    />
    <path
      fill="#637037"
      d="m490.206 155.975-.071-22.698 22.658-.038.003 22.71-22.59.026z"
    />
    <path
      fill="#222D12"
      d="M489.969 133.109c-5.983.029-11.976-.132-17.947.156-3.812.184-4.829-1.337-4.692-4.896.23-5.975.065-11.966.066-17.951l22.679.083-.106 22.608z"
    />
    <path
      fill="#3C4A21"
      d="m490.206 155.975 22.59-.026.02 22.784.115 22.322-45.505.361-.125-22.743.126-.074c6.414.021 12.831-.074 19.239.118 2.741.082 3.567-.842 3.494-3.533-.176-6.409-.075-12.826-.085-19.24l.131.031z"
    />
    <path
      fill="#0F1A09"
      d="m512.932 201.054-.116-22.321 22.599.019-.011 22.266.074.454-22.671-.023.125-.395z"
    />
    <path
      fill="#7A8263"
      d="m581.023 224.193 22.585-.095-.062.054c.028 6.229-.038 12.462.126 18.688.077 2.919-.975 4.042-3.935 3.974-6.227-.143-12.46-.032-18.691-.025l-.032-22.681.009.085z"
    />
    <path
      fill="#637037"
      d="m535.404 201.018 22.777.427.057 22.638-.095-.051-22.614.053-.051-22.612-.074-.455z"
    />
    <path
      fill="#222D12"
      d="m581.014 224.108.032 22.681-.222-.073-22.618.059-.063-22.744.095.051c7.592.01 15.184.018 22.776.026z"
    />
    <path
      fill="#2E3A19"
      d="m649.034 292.139.15 22.78-.363-.077-22.115-.047-.273-.129-.188.237.022-22.712 22.82.069-.053-.121z"
    />
    <path
      fill="#637037"
      d="m671.75 337.631-22.733.051.025-.037-.224-22.803.365.078 22.588.306-.021 22.405z"
    />
    <path
      fill="#B7B9AE"
      d="m649.088 292.261-22.821-.069.092.097.121-22.738-.008.14 22.539-.069-.128.001.205 22.638z"
    />
    <path
      fill="#2E3A19"
      d="m353.851 383.099-.043 22.587c-6.174.023-12.352-.066-18.521.116-3.185.094-4.286-1.146-4.215-4.269.141-6.171.005-12.347-.017-18.522l.167.015 22.629.073zM331.185 360.34l-22.944-.158.112-22.543 22.669-.016 22.793-.015.013 22.669-.034.146-22.609-.083z"
    />
    <path
      fill="#5F694F"
      d="m308.352 337.64-.111 22.542-22.42.219-.158-22.628-.033-.419c7.556.104 15.112.205 22.667.307l.055-.021z"
    />
    <path
      fill="#536031"
      d="M331.185 360.34c7.537.028 15.073.056 22.61.082l.027 22.609.029.067-22.63-.073-.036-22.685z"
    />
    <path
      fill="#7A8263"
      d="m103.918 451.175-22.695.003c.048-7.563.095-15.126.144-22.688l22.692-.029-.141 22.714z"
    />
    <path
      fill="#7A8263"
      d="m103.994 428.529.031-22.849 22.604.143.05 22.552-22.685.154z"
    />
    <path
      fill="#222D12"
      d="m126.629 405.822-22.604-.143.041.039-.157-22.784.081-22.589.002-.054 22.69.039 22.644-.044.134 22.746-.065 22.706-.031-.036-22.735.12z"
    />
    <path
      fill="#536031"
      d="m149.395 405.739.065-22.706 22.814-.103-.131.117-.083 22.755-22.665-.063z"
    />
    <path
      fill="#475828"
      d="M581.046 246.79c6.231-.007 12.464-.118 18.691.025 2.96.068 4.012-1.055 3.935-3.974-.164-6.226-.097-12.459-.126-18.688l22.751-.071.099 22.66.076 22.949.007-.14c-5.999-.014-12.004.136-17.993-.103-3.585-.143-5.069.932-4.907 4.728.255 5.987.055 11.994.048 17.992l.16.209-22.98-.504.105-22.355-.089-22.8.223.072z"
    />
    <path
      fill="#637037"
      d="m331.106 110.485.003 22.788.037-.06-45.46.036-.028-22.823-.073.045 22.905-.14 22.616.154zM103.909 382.935l.157 22.784-22.868.025.159-22.769 22.552-.04z"
    />
    <path
      fill="#8B8F81"
      d="m467.436 405.845-.043-22.817.058-.058 22.573.151-.01-.005.088 22.74.129-.069-22.795.058z"
    />
    <path
      fill="#2E3A19"
      d="m490.102 405.855-.088-22.74 22.814-.056v22.639l-22.726.157z"
    />
    <path
      fill="#3C4A21"
      d="m694.447 360.313-.006 22.804-22.727-.274c.019-5.84.266-11.691-.02-17.516-.2-4.081 1.394-5.219 5.236-5.055 5.828.248 11.677.05 17.517.041z"
    />
    <path
      fill="#7A8263"
      d="m194.72 382.915.046-22.38.132-.521 22.741.32-.08-.094c-.009 6.218-.16 12.44.03 18.652.098 3.216-1.036 4.303-4.221 4.19-6.21-.221-12.431-.129-18.648-.167z"
    />
    <path
      fill="#B7B9AE"
      d="m240.302 360.296-22.743-.055.079.093-.012-22.759 22.626.039-.007 22.788.057-.106z"
    />
    <path
      fill="#536031"
      d="m172.116 155.891.014-22.667 45.382-.133c7.581.047 15.163.096 22.745.145-.062 7.59-.124 15.18-.184 22.77-7.516-.007-15.033-.012-22.551-.018l-22.681-.04-22.735.008.01-.065z"
    />
    <path
      fill="#3C4A21"
      d="m240.071 156.006.186-22.771 22.677-.049.06.059.007 22.657-22.719.043-.211.061zM285.658 110.427l.028 22.822.032-.015-22.723.01-.06-.059.067-22.697 22.656-.061z"
    />
    <path
      fill="#7A8263"
      d="m331.109 133.273 22.648.059.01-.098.098 22.642-22.841-.088.122-22.575-.037.06z"
    />
    <path
      fill="#3C4A21"
      d="m626.473 269.691-.076-22.949 22.634.022-.019 22.858c-7.514.022-15.027.045-22.539.069z"
    />
    <path
      fill="#536031"
      d="M126.857 314.748c7.553-.03 15.106-.06 22.657-.088l-.041.353.016 22.545-22.517.327-.403-.406.035-22.459.253-.272z"
    />
    <path
      fill="#2E3A19"
      d="m149.417 292.2 22.76.028-.037-.052-.071 22.813-22.595.024s.039-.351.041-.353l-.098-22.46z"
    />
    <path
      fill="#475828"
      d="m149.372 246.85 22.73-.039-.001 22.692-22.692-.005-.037-22.648z"
    />
    <path
      fill="#222D12"
      d="m172.101 269.503.001-22.692.116-.116c7.475.013 14.951.024 22.427.035l.332 22.723-.114 22.769-22.723-.047.037.052-.076-22.724z"
    />
    <path
      fill="#475828"
      d="m172.107 155.955 22.735-.008-.386 22.783-22.277-.091-.182.116.11-22.8z"
    />
    <path
      fill="#2E3A19"
      d="m172.178 178.639 22.278.091.271.136.228-.2-.095 22.616-.009 22.809.013-.012-22.532.353-.274-.463-.061-22.494.181-22.836z"
    />
    <path
      fill="#0F1A09"
      d="m172.332 224.432 22.532-.353-.219 22.651-22.427-.035.114-22.263zM558.198 360.367l-22.682-.036-.004-.004.13-22.464 22.704-.333 22.557.211-.126-.061.224 22.618-22.803.069z"
    />
    <path
      fill="#7A8263"
      d="m603.72 337.679-22.942.001.125.06.327-22.808 22.449-.043-.012-.015.049 22.883.004-.078z"
    />
    <path
      fill="#8B8F81"
      d="m603.716 337.756-.049-22.883 22.578.03.188-.236.273.129-.404 22.765.078.109-22.664.086z"
    />
    <path
      fill="#536031"
      d="m626.301 337.562.404-22.765 22.115.047.222 22.802c-7.58-.029-15.16-.057-22.741-.084z"
    />
    <path
      fill="#2E3A19"
      d="m444.679 155.922 22.736.017.012 22.66-.126.074c-6.228-.008-12.457.074-18.682-.065-2.928-.066-4.04 1.048-3.973 3.975.143 6.225.065 12.454.077 18.683l-22.845.11-22.622.016.027-22.865c6.408.042 12.819-.027 19.221.18 2.701.087 3.604-.754 3.511-3.487-.217-6.401-.15-12.812-.198-19.22 7.621-.025 15.242-.051 22.862-.078z"
    />
    <path
      fill="#222D12"
      d="m444.679 155.922-22.861.079-22.601-.096.061.022.021-22.708-.022.078 22.651-.193c6.182.036 12.366.198 18.543.059 3.143-.071 4.335 1.025 4.247 4.211-.17 6.18-.043 12.366-.039 18.548z"
    />
    <path
      fill="#7A8263"
      d="m399.299 133.22-.021 22.707-22.756-.006v-22.692l22.777-.009z"
    />
    <path
      fill="#222D12"
      d="M376.522 133.23v22.692l.043-.044-22.79.123.091-.125-.098-22.642 22.754-.004z"
    />
    <path
      fill="#8B8F81"
      d="m444.684 383.056.048-22.585 22.514-.074.205 22.573-.058.058-22.709.028z"
    />
    <path
      fill="#2E3A19"
      d="m399.22 360.338.026 22.711-22.75-.075.012-22.558.032-.115 22.701.056-.021-.019z"
    />
    <path
      fill="#3C4A21"
      d="m376.507 360.416-.012 22.558-22.673.058-.027-22.609.034-.144 22.678.137z"
    />
    <path
      fill="#222D12"
      d="m467.246 360.397-22.514.074-.111-22.894-.115-.1c6.207.042 12.417-.023 18.62.172 3.148.099 4.36-1.025 4.287-4.227-.14-6.203.024-12.414.062-18.621l22.598.309c.003 6.02.162 12.046-.05 18.059-.124 3.501 1.219 4.606 4.604 4.513 6.013-.165 12.036.025 18.055.066l.156 22.642-.032 22.634.02.037-22.813.056.01.005c.02-6.201-.074-12.405.105-18.601.092-3.194-1.138-4.322-4.282-4.233-6.195.175-12.399.087-18.6.109zM422.031 337.525c-.028 6.189-.17 12.381-.034 18.566.068 3.117-1 4.381-4.208 4.295-6.185-.166-12.378-.048-18.568-.048l.02.019-.003-22.736 22.822-.048-.029-.048z"
    />
    <path
      fill="#8B8F81"
      d="m444.621 337.577-22.59-.051.029.048-.054-22.56 22.513-.005-.013 22.469.115.099z"
    />
    <path
      fill="#536031"
      d="m104.034 337.587 22.534-.108.403.406-.29 22.446-22.69-.039.043-22.705z"
    />
    <path
      fill="#475828"
      d="m512.807 383.023.031-22.634 22.674-.062.004.004-.019 22.761-22.69-.069z"
    />
    <path
      fill="#637037"
      d="M467.427 201.417c15.168-.12 30.336-.24 45.505-.362l-.124.394-.083 22.686-22.639-.027-22.805-.148.105-22.601.041.058z"
    />
    <path
      fill="#475828"
      d="M444.724 201.265c-.013-6.227.066-12.457-.078-18.682-.067-2.927 1.044-4.041 3.973-3.975 6.225.14 12.454.057 18.682.065l.125 22.743-.042-.057-22.715-.017.055-.077z"
    />
    <path
      fill="#B7B9AE"
      d="m512.724 224.135.083-22.686 22.671.023.05 22.612-.097-.082-22.639.071-.068.062z"
    />
    <path
      fill="#536031"
      d="m558.143 224.032.063 22.744-.076-.015-22.34.087-.358-22.845.097.082 22.614-.053z"
    />
    <path
      fill="#3C4A21"
      d="m580.823 246.717.089 22.799-22.692-.021-.093-22.734.077.015 22.619-.059z"
    />
    <path
      fill="#222D12"
      d="m626.359 292.289-22.732-.121c.007-5.998.207-12.005-.048-17.992-.161-3.796 1.322-4.871 4.907-4.728 5.989.239 11.994.089 17.993.103l-.12 22.738z"
    />
    <path
      fill="#3C4A21"
      d="m285.663 337.774-22.745-.209.084.086-.111-22.611.104-22.877 22.614-.069.079 22.837-.06 22.421.035.422z"
    />
    <path
      fill="#3C4A21"
      d="m240.246 360.402.007-22.788.086.067 22.663-.031-.084-.086c.02 6.431-.07 12.866.112 19.292.076 2.686-.732 3.624-3.492 3.553-6.426-.163-12.861-.023-19.292-.007z"
    />
    <path
      fill="#222D12"
      d="m376.507 360.416-22.679-.138-.013-22.67-.012.013 22.742.017-.007 22.663-.031.115z"
    />
    <path
      fill="#0F1A09"
      d="m353.816 337.609-22.793.015-.054-22.684 22.828-.002.007 22.683.012-.012z"
    />
    <path
      fill="#475828"
      d="m330.968 314.941.055 22.683-22.67.016-.055.022.102-22.62-.007-.182 22.575.081z"
    />
    <path
      fill="#536031"
      d="m308.399 315.041-.102 22.62c-7.556-.101-15.112-.203-22.668-.307l.059-22.424 22.711.111z"
    />
    <path
      fill="#0F1A09"
      d="m126.682 360.331.29-22.446 22.517-.327-.038.026-.018 22.756-.108-.054-22.643.045z"
    />
    <path
      fill="#637037"
      d="m172.181 360.426.093 22.504-22.814.103-.134-22.746.108.054 22.518-.324.229.409z"
    />
    <path
      fill="#8B8F81"
      d="m603.787 292.376-.107 22.512-22.45.044-.51-.065.202-22.527-.229-.192.116-.276 22.978.504z"
    />
    <path
      fill="#222D12"
      d="m558.221 269.495 22.692.021-.105 22.356-.116.276.229.193-22.655-.071-.054-.147.009-22.628z"
    />
    <path
      fill="#2E3A19"
      d="m331.146 133.214-.122 22.575.124.227-22.602.02-22.86-.061.031-22.74-.032.015 45.461-.036zM467.246 360.397c6.201-.022 12.405.066 18.601-.111 3.144-.09 4.374 1.039 4.282 4.233-.179 6.196-.085 12.4-.105 18.601l-22.573-.151-.205-22.572z"
    />
    <path
      fill="#536031"
      d="m194.766 360.535-22.585-.109-.229-.407.124-22.508-.078.036 22.896.105.004 22.362-.132.521z"
    />
    <path
      fill="#222D12"
      d="m194.898 360.014-.005-22.363-.048-.011 22.62.038.162-.103.012 22.759-22.741-.32z"
    />
    <path
      fill="#5F694F"
      d="m217.522 155.987 22.551.018.21-.06-.084 22.772-22.687.093.112-.369-.102-22.454z"
    />
    <path
      fill="#7A8263"
      d="m217.522 155.987.102 22.454-22.669.226-.228.2-.271-.136.385-22.783c7.561.012 15.121.026 22.681.039z"
    />
    <path
      fill="#536031"
      d="m285.717 133.234-.031 22.74.044-.043-22.767.02.038-.05-.007-22.657 22.723-.01z"
    />
    <path
      fill="#0F1A09"
      d="m240.198 178.717.084-22.772 22.719-.043-.038.049-.009 22.512-22.721.24-.035.014z"
    />
    <path
      fill="#536031"
      d="m172.14 292.176 22.723.047-.042-.042.035 22.735.025.035-22.894.047.081-.008.072-22.814z"
    />
    <path
      fill="#5F694F"
      d="m194.645 246.731.219-22.651-.012.012 22.693-.086.107 22.801-.267 22.631-22.406.017-.334-22.724z"
    />
    <path
      fill="#536031"
      d="m194.977 269.454 22.408-.017.126 22.76.038-.038-22.728.021.042.042.114-22.768z"
    />
    <path
      fill="#0F1A09"
      d="m194.86 201.282 22.75-.037-.135.268.019 22.542.051-.051-22.693.086.008-22.808z"
    />
    <path
      fill="#3C4A21"
      d="m558.346 337.529-22.705.332-.218-.375-.029-22.691-.012-22.549 22.83-.123.054.147.104 22.569-.024 22.69z"
    />
    <path
      fill="#2E3A19"
      d="m535.512 360.326-22.674.062-.155-22.643.199-.207 22.54-.053.218.375c-.041 7.49-.085 14.978-.128 22.466z"
    />
    <path
      fill="#B7B9AE"
      d="m558.346 337.529.024-22.692 22.349.029.51.065-.326 22.808-22.557-.21z"
    />
    <path
      fill="#222D12"
      d="m421.879 201.376 22.845-.111-.054.076.056 22.915.121 22.469-22.862.116-.091-22.817c-.006-7.549-.01-15.098-.015-22.648z"
    />
    <path
      fill="#3C4A21"
      d="m376.522 155.922 22.756.006-.061-.022.067 22.623-.027 22.864.018-.022-22.724-.033-.084-22.879.097-22.579-.042.042z"
    />
    <path
      fill="#536031"
      d="m421.879 201.376.014 22.648-22.673.018.056-22.672-.018.022 22.621-.016z"
    />
    <path
      fill="#B7B9AE"
      d="m376.565 155.878-.097 22.579-22.731.439-.129-.275.236-.192-.069-22.428 22.79-.123z"
    />
    <path
      fill="#0F1A09"
      d="m376.539 360.301.006-22.663 22.696-.013-.004-.003.003 22.736-22.701-.057z"
    />
    <path
      fill="#2E3A19"
      d="m490.073 315.109 22.758-.263-.149.229.201 22.463-.199.207c-6.02-.04-12.043-.23-18.056-.065-3.385.093-4.728-1.012-4.604-4.513.212-6.011.052-12.037.049-18.058z"
    />
    <path
      fill="#3C4A21"
      d="m444.506 337.477.013-22.469 22.997-.166-.041-.041c-.038 6.207-.202 12.418-.062 18.621.072 3.202-1.139 4.326-4.287 4.227-6.203-.196-12.413-.13-18.62-.172z"
    />
    <path
      fill="#0F1A09"
      d="m422.006 315.014.053 22.56-22.822.048.004.003-.063-22.773-.067-22.599.157-22.769 22.672.015.103-.078-.046 22.735c.004 7.619.006 15.238.009 22.858z"
    />
    <path
      fill="#222D12"
      d="m422.006 315.014-.008-22.857 22.707-.009 22.649.036.037.036.125 22.622-22.997.166-22.513.006z"
    />
    <path
      fill="#B7B9AE"
      d="m444.846 246.726-.121-22.469 22.555-.297 22.805.147.035 22.697-22.796.125-22.625-.253.147.05z"
    />
    <path
      fill="#0F1A09"
      d="m467.28 223.96-22.555.297-.056-22.915 22.715.016-.104 22.602zM490.12 246.804l-.035-22.697 22.639.028.069-.061-.15 22.564-22.577.082.054.084z"
    />
    <path
      fill="#7A8263"
      d="m512.643 246.638.15-22.564 22.639-.071.356 22.844-.387-.055-22.758-.154z"
    />
    <path
      fill="#475828"
      d="m558.212 292.122-22.83.123-22.659.167.126-.377-.069-22.381c6.404-.044 12.811-.191 19.212-.083 2.689.045 3.641-.869 3.566-3.567-.177-6.401-.121-12.808-.159-19.213l.387.055 22.341-.086.092 22.734c-.001 7.543-.004 15.086-.007 22.628z"
    />
    <path
      fill="#2E3A19"
      d="m308.399 315.041-22.711-.11-.078-22.836-.063-45.226 22.93-.043c-.036 6.179.048 12.361-.157 18.535-.107 3.197 1.104 4.283 4.242 4.19 6.175-.182 12.358-.079 18.538-.094l.106 22.678c-5.992.02-11.989.208-17.972-.003-3.595-.127-5.058.986-4.902 4.754.248 5.981.063 11.981.061 17.973l.006.182z"
    />
    <path
      fill="#5F694F"
      d="m285.546 246.87.064 45.225-22.615.068.021.03-.126-22.773.022-22.597 22.634.047z"
    />
    <path
      fill="#536031"
      d="m262.995 292.163-.104 22.877-22.769.033.145-.16.018-22.684.005-.006 22.727-.03-.022-.03z"
    />
    <path
      fill="#8B8F81"
      d="m240.122 315.072 22.769-.033.111 22.611-22.663.031-.217-22.609z"
    />
    <path
      fill="#2E3A19"
      d="m399.178 314.851.063 22.773-22.696.013-22.742-.016-.007-22.683.019-22.748-.012-22.699 22.736-.009.036 22.768c-.027 6.169.065 12.342-.129 18.507-.1 3.172 1.075 4.299 4.224 4.204 6.166-.186 12.339-.089 18.508-.11z"
    />
    <path
      fill="#222D12"
      d="m353.816 292.191-.019 22.748-22.828.002c-7.526-.027-15.051-.053-22.576-.081.002-5.992.187-11.992-.061-17.973-.156-3.768 1.307-4.881 4.902-4.754 5.983.211 11.981.023 17.972.004l22.61.054z"
    />
    <path
      fill="#3C4A21"
      d="m149.451 337.584 22.625-.073-.124 22.508c-7.507.106-15.013.214-22.519.322.007-7.586.013-15.171.018-22.757z"
    />
    <path
      fill="#5F694F"
      d="m308.546 156.036-.016 22.468-.331.39-22.556-.235.088-22.727-.044.043 22.859.061z"
    />
    <path
      fill="#475828"
      d="m171.988 314.997 22.893-.047-.036 22.69.048.011-22.895-.105-.01-22.549z"
    />
    <path
      fill="#5F694F"
      d="m194.845 337.641.036-22.69-.025-.034 22.643-.381a.637.637 0 0 1 .131.566l-.165 22.578-22.62-.039z"
    />
    <path
      fill="#3C4A21"
      d="m240.284 292.228-.018 22.684-22.637.188a.632.632 0 0 0-.131-.565l.049-22.376-.037.038 22.774.031z"
    />
    <path
      fill="#2E3A19"
      d="m285.73 155.931-.087 22.727.051-.027-22.66.294-.079-.463.009-22.511 22.766-.02z"
    />
    <path
      fill="#536031"
      d="m240.161 201.253.072-22.551 22.721-.239.079.461c.015 7.437.031 14.874.045 22.313l-22.583.353-.334-.337z"
    />
    <path
      fill="#222D12"
      d="m331.069 178.511 22.774-.081-.236.192.129.275.086 22.459-.092.092-22.538-.382.016-22.225-.139-.33zM217.548 292.159l-.049 22.376-22.643.381-.035-22.735 22.727-.022z"
    />
    <path
      fill="#222D12"
      d="m240.284 292.228-22.773-.03-.126-22.76.265-22.632c7.56.085 15.121.171 22.681.254l-.064 22.456.023 22.707-.006.005z"
    />
    <path
      fill="#2E3A19"
      d="m240.332 247.061-22.682-.256-.106-22.801-.05.05 22.731.081.12.005-.002 22.534-.011.387z"
    />
    <path
      fill="#536031"
      d="m240.225 224.136-22.731-.081-.019-22.542 22.686-.259.334.335c-.091 7.515-.181 15.031-.27 22.547z"
    />
    <path
      fill="#8B8F81"
      d="m512.682 315.075 22.712-.281.029 22.692-22.541.053-.2-22.464z"
    />
    <path
      fill="#637037"
      d="m421.983 246.842 22.862-.116-.146-.05-.059 22.84.037-.036-22.633-.059-.103.078.072-22.702-.03.045z"
    />
    <path
      fill="#3C4A21"
      d="m399.22 224.042 22.673-.018.09 22.817.03-.045-22.781-.025.022-22.733-.034.004z"
    />
    <path
      fill="#2E3A19"
      d="m353.823 201.355-.086-22.459 22.731-.439.083 22.88-.029.03-22.699-.012zM399.275 201.37l-.056 22.672.034-.003-22.687.107-.044-22.778.029-.03 22.724.032z"
    />
    <path
      fill="#3C4A21"
      d="m512.78 269.655.069 22.381h-22.787l-.14-22.368.317-.331 22.541.318z"
    />
    <path
      fill="#222D12"
      d="m489.923 269.667.14 22.368-22.671.184-.037-.036-.044-22.656 22.612.14z"
    />
    <path
      fill="#5F694F"
      d="M399.178 314.851c-6.17.02-12.343-.076-18.507.11-3.149.095-4.324-1.032-4.224-4.204.194-6.164.102-12.337.128-18.507l22.536.003.067 22.598z"
    />
    <path
      fill="#222D12"
      d="m399.232 246.772 22.781.025-.072 22.702-22.672-.015-.16.06.123-22.772z"
    />
    <path
      fill="#3C4A21"
      d="m422.044 269.421 22.633.059.028 22.667-22.707.009.046-22.735z"
    />
    <path
      fill="#475828"
      d="m399.112 292.252-22.536-.003-.036-22.767-.008.009 22.575.052.162-.06-.157 22.769z"
    />
    <path
      fill="#5F694F"
      d="m444.705 292.147-.028-22.667-.036.036 22.714.04-.045-.029.045 22.656-22.65-.036z"
    />
    <path
      fill="#3C4A21"
      d="m489.923 269.667-22.612-.14.045.029-.031-22.627 22.796-.125-.055-.085.174 22.617-.317.331z"
    />
    <path
      fill="#222D12"
      d="m467.324 246.929.031 22.627-22.714-.04.058-22.84 22.625.253z"
    />
    <path
      fill="#B7B9AE"
      d="m490.24 269.337-.174-22.617 22.577-.082 22.758.154c.038 6.405-.018 12.812.158 19.213.074 2.698-.878 3.612-3.566 3.567-6.402-.108-12.808.039-19.212.083l-22.541-.318z"
    />
    <path
      fill="#222D12"
      d="m376.522 201.367.044 22.778v22.614l-22.735.03-22.774.017-22.58.02-22.93.043-22.634-.047.036-.074-.081-22.592.375-.114 45.305-.037 22.433-.084c6.207.055 12.423-.085 18.616.238 3.728.194 4.372-1.447 4.26-4.652-.211-6.014-.102-12.039-.126-18.06l.092-.092 22.699.012z"
    />
    <path
      fill="#5F694F"
      d="m308.477 246.827 22.58-.02.014 22.682.028-.031c-6.18.015-12.363-.088-18.538.095-3.138.093-4.349-.993-4.242-4.19.207-6.175.122-12.358.158-18.536z"
    />
    <path
      fill="#536031"
      d="m353.816 292.191-22.611-.055c-.034-7.56-.07-15.119-.105-22.679l-.028.031 22.748.02-.016-.016.012 22.699z"
    />
    <path
      fill="#0F1A09"
      d="m240.267 269.515.064-22.456c.001.002.011-.384.011-.384l22.607.074-.036.074-.022 22.598-22.624.094z"
    />
    <path
      fill="#3C4A21"
      d="m240.267 269.515 22.624-.095c.041 7.591.083 15.181.126 22.772l-22.727.03-.023-22.707z"
    />
    <path
      fill="#475828"
      d="m353.831 246.789 22.735-.03.014-.065-.048 22.798.008-.009-22.736.009.016.016.011-22.719z"
    />
    <path
      fill="#637037"
      d="m331.209 178.842-.017 22.225-.174.516-22.496-.144-.516-.013c.751-7.505.415-15.019.191-22.532l.331-.39 22.681.338z"
    />
    <path
      fill="#3C4A21"
      d="M308.199 178.894c.224 7.513.56 15.027-.19 22.531l-22.482-.16.167-22.634-.051.027 22.556.236z"
    />
    <path
      fill="#7A8263"
      d="m285.694 178.632-.166 22.634-22.538.095.089-.124-.046-22.312 22.661-.293z"
    />
    <path
      fill="#3C4A21"
      d="M240.225 224.136c.09-7.516.179-15.032.27-22.547 7.529-.116 15.056-.233 22.584-.352l-.089.123.254 22.682-.376.114-22.524-.016-.119-.004z"
    />
    <path
      fill="#8B8F81"
      d="M353.731 201.448c.024 6.02-.085 12.046.126 18.06.113 3.205-.532 4.847-4.26 4.652-6.193-.323-12.409-.183-18.616-.238l.037-22.34.174-.516 22.539.382z"
    />
    <path
      fill="#7A8263"
      d="M376.566 246.76v-22.614l22.687-.106-.021 22.733.001-.001-22.653-.077-.014.065z"
    />
    <path
      fill="#3C4A21"
      d="m376.58 246.694 22.653.077-.124 22.772-22.577-.052.048-22.797z"
    />
    <path
      fill="#2E3A19"
      d="m263.244 224.043-.254-22.682 22.538-.095 22.481.159.514.013.026 22.568-45.305.037zM353.831 246.789l-.011 22.718-22.748-.019-.015-22.682 22.774-.017z"
    />
    <path fill="#2E4029" d="M298.552 564.253h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M321.444 564.253h22.892v22.892h-22.892z"/>
    <path
      fill="#0F7540"
      d="M344.336 564.253h22.892v22.892h-22.892zM367.228 564.253h22.892v22.892h-22.892z"
    />
    <path fill="#80A82B" d="M229.876 587.145h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M252.768 587.145h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M275.66 587.145h22.892v22.892H275.66z"/>
    <path fill="#81B829" d="M298.552 587.145h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M321.444 587.145h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M344.336 587.145h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M367.228 587.145h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M390.12 587.145h22.892v22.892H390.12z"/>
    <path fill="#81B829" d="M413.012 587.145h22.892v22.892h-22.892z"/>
    <path fill="#A7C56C" d="M435.904 587.145h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M458.796 587.145h22.892v22.892h-22.892z"/>
  </svg>
);
export default Quercus;