import React, {FC, SVGProps} from 'react';

const EucaliptoGlobulus: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 808.108 711.158"
    height="100%"
    fill="none"
    {...props}
  >
    <path
      fill="#A6A49A"
      d="M404.551 495.603c-.035 20.15-.064 40.3-.113 60.449-.006 2.295-.12 4.59-.184 6.884l-22.7.002c-.064-20.121-.123-40.241-.197-60.362-.009-2.313-.098-4.626-.15-6.939l.216-22.508 23.112.021.016 22.453z"
    />
    <path
      fill="#655D51"
      d="m291.668 136.687 22.33-.359 22.44.145.022 22.46c-6.048.007-12.1.15-18.142-.032-3.225-.098-4.441.989-4.329 4.278.207 6.041.077 12.094.087 18.142l-22.599.231.142-.227-.021-22.378.07-22.26z"
    />
    <path
      fill="#CCCCC5"
      d="m538.585 181.537.041 44.543-.311.326-22.128-.209-.351-22.291.385-22.427 22.075-.239.289.297z"
    />
    <path
      fill="#8D8E81"
      d="m403.713 91.822 44.861-.148.179 22.335-22.646.153-22.294-.212-.1-22.128z"
    />
    <path
      fill="#CCCCC5"
      d="m269.24 450.585-22.541.168-22.394-.121-.2-22.462.27.038 22.429-.105 22.387.229.049 22.253z"
    />
    <path
      fill="#E1DED8"
      d="m381.417 473.13-.211 22.508-22.264-.087-.033-22.425 22.242-.006.266.01z"
    />
    <path
      fill="#CCCCC5"
      d="M583.611 360.897c5.489.004 11.005.355 16.458-.089 4.918-.4 6.092 1.514 5.785 6.036-.371 5.464-.104 10.971-.113 16.461l-22.48-.002-.179-22.333.27.002.259-.075z"
    />
    <path
      fill="#E1DED8"
      d="m404.558 495.603-.016-22.452 21.654-.04.177.028-.146 22.412-21.669.052z"
    />
    <path
      fill="#8D8E81"
      d="m583.451 338.433.16 22.464-.26.074-.27-.002-22.156-.152-.099-22.281 22.295.047.33-.15z"
    />
    <path
      fill="#BEBBB0"
      d="m336.482 473.132-22.373-.081-.076-22.372 22.366.086.015 22.285.068.082z"
    />
    <path
      fill="#E1DED8"
      d="m314.033 450.679.076 22.372-22.505.068.015-22.391-.093-.121 22.517.081-.01-.009z"
    />
    <path
      fill="#CCCCC5"
      d="m471.167 450.568-22.502.237.029-.276.081-22.42 22.321.223.071 22.236z"
    />
    <path
      fill="#BEBBB0"
      d="m471.167 450.568-.071-22.237 22.53.057-.105 22.18h-22.354z"
    />
    <path
      fill="#CCCCC5"
      d="m493.521 450.568.105-22.18-.099-.304 22.529.323-.094 22.242-22.441-.081z"
    />
    <path
      fill="#EDEDE9"
      d="m515.962 450.65.094-22.243 22.43-.349-.086.429.048 22.196-22.486-.033z"
    />
    <path
      fill="#78786A"
      d="m269.215 181.22-22.44.032.03-22.143 22.257-.311.232.413-.079 22.009z"
    />
    <path
      fill="#CCCCC5"
      d="M560.793 158.852c.03 5.987-.148 11.985.162 17.957.203 3.906-1.447 4.753-4.946 4.641-5.802-.186-11.616.036-17.425.087l-.288-.295.332-22.119 22.165-.271z"
    />
    <path
      fill="#878776"
      d="m358.841 113.868.199-22.129 22.388.06-.135 22.171-22.262.375-.19-.477z"
    />
    <path
      fill="#655D51"
      d="m381.292 113.97.135-22.17 22.285.023.1 22.128-.026.381c-7.497-.123-14.995-.242-22.494-.362z"
    />
    <path
      fill="#6E6C5D"
      d="m471.074 113.937 22.411.263.201 22.135-22.645.039.162-22.148-.129-.289z"
    />
    <path
      fill="#A6A49A"
      d="m493.686 136.335-.201-22.135 22.433-.092.167 22.268-22.52.148.121-.189z"
    />
    <path
      fill="#878776"
      d="m269.062 158.798.231-22.198 22.375.088-.07 22.261-22.304.263-.232-.414z"
    />
    <path
      fill="#8D8E81"
      d="m560.793 158.852-22.164.272-.354-.345.167-22.08c-.175-.15-.185-.309-.028-.478 6.185.091 12.372.316 18.554.215 2.904-.047 4.127.731 4.009 3.846-.238 6.183-.143 12.379-.184 18.57z"
    />
    <path
      fill="#CCCCC5"
      d="M606.082 271.003c6.043.045 12.088.204 18.127.085 2.948-.058 4.156.901 4.06 3.962-.194 6.216-.12 12.441-.157 18.661l-22.148-.252-.25.151-.086-22.346.454-.261z"
    />
    <path
      fill="#878776"
      d="m605.964 293.459 22.148.252.03 22.19-22.456-.022.278-22.42z"
    />
    <path
      fill="#6E6C5D"
      d="m246.771 226.406 22.26-.302.288.421-.197 22.193-22.173-.049-.444.093.266-22.356z"
    />
    <path
      fill="#CCCCC5"
      d="M538.626 226.081c6.004.062 12.012.267 18.012.128 3.167-.073 4.437.902 4.32 4.212-.212 6-.067 12.014-.071 18.021l-.017.52-22.301-.365-.255-22.19.312-.326z"
    />
    <path
      fill="#78786A"
      d="m560.888 248.442 22.311.411.22 22.183-22.469.058-.079-22.132.017-.52z"
    />
    <path
      fill="#BEBBB0"
      d="m583.419 271.036-.22-22.183c5.995-.042 12.001.124 17.981-.2 3.894-.211 4.753 1.395 4.657 4.92-.158 5.803.138 11.619.244 17.43l-.453.261-22.209-.228z"
    />
    <path
      fill="#CCCCC5"
      d="m224.323 271.216 22.203-.023.432-.087-.28 22.488-22.252.046-.41-.048c.101-7.458.204-14.917.307-22.376z"
    />
    <path
      fill="#8D8E81"
      d="m224.45 405.844-.04-22.424 22.397.093-.278 22.293-22.079.038z"
    />
    <path
      fill="#CCCCC5"
      d="M269.177 383.1c-.019-5.848.169-11.707-.13-17.54-.198-3.863 1.331-4.842 4.924-4.73 5.834.182 11.683-.091 17.525-.173l.194.407-.08 22.139-22.486.37.053-.473z"
    />
    <path
      fill="#554"
      d="m246.528 405.807.279-22.293 22.37-.413-.052.472.081 22.206-22.287.013-.391.015z"
    />
    <path
      fill="#CCCCC5"
      d="M246.668 338.442c-6.166.013-12.336-.085-18.498.085-3.007.083-3.995-1.063-3.956-4.005.083-6.158-.124-12.319-.214-18.48l.405-.041 22.374.22-.111 22.221z"
    />
    <path
      fill="#78786A"
      d="m224.45 405.844 22.078-.037.389-.014-.114 22.31-22.429.105.076-22.364z"
    />
    <path
      fill="#878776"
      d="m291.611 316.252-22.477-.049-22.353.018-22.374-.219.02-22.361 22.253-.046 44.954.224-.07 21.994.047.439z"
    />
    <path
      fill="#BEBBB0"
      d="m426.23 450.671-.039 22.44-22.253.04-22.522-.021-.264-.01.049-22.486.242-22.423 22.334.02c.015 6.167.142 12.336-.003 18.499-.07 2.979 1.013 4.047 3.984 3.977 6.163-.145 12.332-.021 18.499-.007l-.027-.029z"
    />
    <path
      fill="#554"
      d="m269.294 159.211 22.304-.263.021 22.378-22.404-.106.079-22.009zM314.076 181.321c-.01-6.048.12-12.101-.087-18.142-.112-3.289 1.103-4.376 4.329-4.278 6.042.183 12.094.04 18.142.032l.015.015-.171 22.322-22.331.221.103-.17z"
    />
    <path
      fill="#A6A49A"
      d="m291.548 203.588-.07-22.036 22.598-.231-.102.169-.018 22.168-22.332.417-.076-.487z"
    />
    <path
      fill="#554"
      d="m336.46 158.933-.022-22.46-.04-.028 22.59.029-.066-.026-.006 22.522-22.441-.023-.015-.014z"
    />
    <path
      fill="#78786A"
      d="M269.031 226.105c.034-6 .281-12.009.022-17.997-.155-3.58 1.133-4.481 4.509-4.375 5.988.188 11.99-.072 17.986-.144l.075.488-.126 22.076.071.085-22.249.288-.288-.421z"
    />
    <path
      fill="#8D8E81"
      d="m538.628 159.123-.332 22.119-22.075.238-.443-.189.271-22.305-.01.01 22.235-.217.354.344z"
    />
    <path
      fill="#78786A"
      d="m516.221 181.48-.385 22.427c-7.447-.091-14.895-.182-22.342-.271l.039-22.102 22.245-.243.443.189z"
    />
    <path
      fill="#554"
      d="m493.493 203.635 22.343.273.351 22.291-.465.167-22.047-.164-.098-22.257-.084-.31z"
    />
    <path
      fill="#6E6C5D"
      d="m538.315 226.407.255 22.19-22.536.099-.312-22.33.465-.167 22.128.208z"
    />
    <path
      fill="#A6A49A"
      d="m269.24 450.585-.05-22.253-.167-.307 22.587.236-.086 22.346.094.122-22.378-.144z"
    />
    <path
      fill="#474632"
      d="m403.813 113.95 22.295.211.19 22.341-22.541-.086.03-22.083.026-.383z"
    />
    <path
      fill="#78786A"
      d="m426.298 136.502-.19-22.34 22.645-.153-.015-.012-.084 22.406.109.29-22.465-.191z"
    />
    <path
      fill="#A6A49A"
      d="m583.121 338.583-22.295-.047.01.004.103-22.334 22.432-.43-.05.474-.2 22.333z"
    />
    <path
      fill="#554"
      d="m605.964 293.459-.277 22.421-22.365.369.05-.474-.003-22.14 22.346-.025.249-.151z"
    />
    <path
      fill="#474632"
      d="m246.803 428.103.114-22.31 22.288-.014-.056-.003-.126 22.247.167.308-22.387-.228zM560.925 360.817l22.156.152.179 22.333.015-.015-22.387.271.05-.508-.115-21.895.102-.338z"
    />
    <path
      fill="#554"
      d="m560.826 338.536.099 22.281-.103.337c-7.457-.066-14.915-.131-22.373-.195l-.089-22.218 22.475-.202-.009-.003z"
    />
    <path
      fill="#BEBBB0"
      d="m448.775 428.108-.081 22.421-22.463.143.028.028-.013-22.429 22.513-.035.016-.128z"
    />
    <path
      fill="#8D8E81"
      d="m560.862 428.209-22.463.278.086-.429-.08-44.55 22.531-.457-.049.508.164 22.315-.189 22.335z"
    />
    <path
      fill="#CCCCC5"
      d="m561.05 405.874-.164-22.315 22.388-.271.017 22.493-22.241.093z"
    />
    <path
      fill="#E1DED8"
      d="m358.911 450.695.008 22.439-22.503-.085-.016-22.286.138-.141 22.373.073z"
    />
    <path
      fill="#E1DED8"
      d="m336.4 450.765-22.366-.086.009.009.075-22.363 22.312-.114.109 22.412-.139.142z"
    />
    <path
      fill="#CCCCC5"
      d="m314.118 428.326-.076 22.362-22.517-.081.085-22.346-.067-.091 22.383-.039.192.195z"
    />
    <path
      fill="#878776"
      d="m493.626 428.388-22.53-.057c-7.44-.073-14.881-.147-22.321-.222l-.018.126.025-22.487 22.413-.012 22.349.026-.018 22.323.1.303z"
    />
    <path
      fill="#78786A"
      d="m493.527 428.085.018-22.323-.004.03c6.173.002 12.348-.102 18.517.052 2.985.075 4.051-1.036 3.984-4.001-.14-6.169-.019-12.344-.005-18.516l22.505-.188-.136.371.08 44.549-22.43.349-22.529-.323z"
    />
    <path
      fill="#6E6C5D"
      d="m358.988 136.474-22.59-.029.24-22.27 22.203-.307.189.476-.042 22.13z"
    />
    <path
      fill="#3B3C26"
      d="m358.988 136.474.042-22.13 22.262-.374c7.498.119 14.996.239 22.494.361l-.03 22.086.045.152-22.475.078-22.404-.198.066.025z"
    />
    <path
      fill="#8D8E81"
      d="m448.655 136.403.084-22.406 22.464.23-.161 22.148-22.387.028z"
    />
    <path
      fill="#474632"
      d="m448.655 136.403 22.387-.028 22.645-.04-.122.189c-.001 6.169.105 12.34-.047 18.505-.074 3.002 1.087 4.03 4.026 3.965 6.165-.136 12.336-.021 18.505-.009l-.271 22.305-22.245.242-22.433-.182c.006-6.053-.129-12.111.075-18.157.111-3.291-1.102-4.381-4.331-4.282-6.047.184-12.104.042-18.157.036l.078-22.255-.11-.289z"
    />
    <path
      fill="#3B3C26"
      d="M516.049 158.986c-6.169-.012-12.34-.127-18.505.009-2.939.065-4.1-.963-4.026-3.965.152-6.165.047-12.336.047-18.505l22.52-.148-.12.224.074 22.395c-.001-.001.01-.01.01-.01z"
    />
    <path
      fill="#474632"
      d="m516.038 158.995-.074-22.395 22.476.098-.167 22.08-22.235.217z"
    />
    <path
      fill="#3B3C26"
      d="m605.713 293.61-22.346.025-22.481-.044-.089-22.345.152-.152 22.469-.058 22.209.228.086 22.346zM269.319 226.525l22.249-.288c.012 5.875-.151 11.758.098 17.622.155 3.645-.94 5.121-4.784 4.932-5.861-.289-11.746-.103-17.621-.118l-.14.045.198-22.193z"
    />
    <path
      fill="#554"
      d="m246.526 271.193.422-22.524 22.173.049.139-.044c-.034 5.681-.276 11.37-.028 17.038.175 3.977-.744 5.865-5.238 5.545-5.654-.403-11.355-.132-17.036-.151l-.432.087z"
    />
    <path
      fill="#3B3C26"
      d="m516.034 248.696 22.536-.099 22.301.365.079 22.132-.151.151-44.7-.064-.11-22.482.045-.003z"
    />
    <path
      fill="#474632"
      d="M246.958 271.106c5.681.02 11.383-.252 17.036.151 4.493.32 5.412-1.568 5.238-5.545-.249-5.668-.006-11.358.028-17.038 5.876.015 11.761-.171 17.622.118 3.844.189 4.939-1.287 4.784-4.932-.25-5.865-.087-11.747-.098-17.622l-.07-.084c13.509.034 27.018-.032 40.523.166 3.554.052 4.636-1.196 4.521-4.63-.196-5.857.003-11.727.036-17.592l-.093-.405 22.417.205c.005 6.17.135 12.343-.04 18.507-.086 3.021 1.012 3.988 3.969 3.918 6.165-.146 12.336.009 18.505.04l.012 22.361c-13.434-.003-26.868.081-40.301-.068-3.374-.038-4.776.902-4.635 4.474.236 6.001.066 12.017.066 18.027-13.156-.002-26.314.129-39.467-.087-4.043-.066-5.807.865-5.514 5.299.374 5.664.074 11.372.065 17.061l.071.391-44.955-.225.28-22.49z"
    />
    <path
      fill="#78786A"
      d="m291.61 383.203.08-22.139 22.265-.167 22.435.108.172 22.236-22.607.137-22.465.257.12-.432z"
    />
    <path
      fill="#655D51"
      d="m336.389 361.005-22.434-.109-.111-22.466.426.14 22.136-.13.128 22.423-.145.142z"
    />
    <path
      fill="#BEBBB0"
      d="m291.643 338.433-.032-22.181-.047-.438 22.296.307.411 22.45-.426-.14-22.202.002z"
    />
    <path
      fill="#333221"
      d="m269.206 405.779-.081-22.206 22.486-.37-.121.432.065 22.092.092.152-22.496-.101.055.001z"
    />
    <path
      fill="#78786A"
      d="m313.858 316.121-22.295-.307.07-21.995-.071-.39c7.465.104 14.93.208 22.395.31l.232 22.246-.331.136z"
    />
    <path
      fill="#E1DED8"
      d="m426.245 428.27.013 22.429c-6.167-.013-12.336-.137-18.499.007-2.971.07-4.054-.998-3.984-3.977.145-6.163.018-12.332.003-18.499l.062-22.2 22.42-.203-.015 22.443z"
    />
    <path
      fill="#A6A49A"
      d="m403.84 406.031-.062 22.199-22.334-.02-.224-22.264.325-.326 22.243-.042.052.453z"
    />
    <path
      fill="#8D8E81"
      d="m336.475 158.948 22.441.023-.041.009.166 22.291-.227.228-22.156.019-.354-.247.171-22.323z"
    />
    <path
      fill="#878776"
      d="m313.956 203.659.018-22.168 22.33-.221.353.246-.173 22.176.093.404-22.621-.437z"
    />
    <path
      fill="#554"
      d="m313.956 203.659 22.621.437c-.033 5.866-.232 11.736-.036 17.593.115 3.434-.967 4.682-4.521 4.63-13.505-.198-27.014-.132-40.523-.166l.125-22.077 22.334-.417z"
    />
    <path
      fill="#78786A"
      d="m358.922 136.449 22.404.197.073 22.354.008-.013-22.532-.006.041-.009.006-22.523z"
    />
    <path
      fill="#878776"
      d="m471.1 181.351 22.433.182-.039 22.103.082.308-22.453-.319.066-22.143-.089-.131z"
    />
    <path
      fill="#333221"
      d="m515.722 226.366.312 22.33-.045.003-22.538.142-22.307-.297-.038-22.252 22.569-.09 22.047.164z"
    />
    <path
      fill="#3B3C26"
      d="m493.674 226.202-22.569.09h.003l.053-22.23-.038-.437 22.453.32.098 22.257z"
    />
    <path
      fill="#6E6C5D"
      d="m269.15 405.777 22.496.101-.102 22.292.067.091-22.587-.236.126-22.248z"
    />
    <path
      fill="#554"
      d="m448.764 136.693-.078 22.255-44.94-.073.055-22.306-.045-.152 22.54.085 22.468.191z"
    />
    <path
      fill="#474632"
      d="m560.886 293.591 22.481.044.004 22.14-22.432.43-22.445-.379-.157-22.135 22.549-.1z"
    />
    <path
      fill="#8D8E81"
      d="m538.494 315.826 22.445.379-.103 22.334-22.475.202.101-.409-.073-22.033.105-.473z"
    />
    <path
      fill="#3B3C26"
      d="m560.936 383.052-22.531.457.136-.37-.092-22.179c7.458.064 14.916.129 22.373.195l.114 21.897z"
    />
    <path
      fill="#474632"
      d="m538.449 360.96.092 22.179-22.505.187.005.006-.043-22.373-.014-22.442 22.478-.185-.101.409.088 22.219z"
    />
    <path
      fill="#CCCCC5"
      d="m336.513 405.844-.084 22.368-22.312.113-.19-.193.088-22.354-.001.001 22.499.065z"
    />
    <path
      fill="#8D8E81"
      d="m314.016 405.778-.088 22.354-22.383.038.102-22.292-.092-.152 22.461.052z"
    />
    <path
      fill="#78786A"
      d="m471.195 405.736-22.413.012-.188.118.234-22.573 22.258.076.109 22.367z"
    />
    <path
      fill="#655D51"
      d="m471.195 405.736-.108-22.367.113-.024 22.24.017.278.01-.178 22.42.004-.03-22.349-.026z"
    />
    <path
      fill="#8D8E81"
      d="M493.54 405.792c.059-7.473.119-14.946.177-22.42l22.324-.04-.005-.006c-.014 6.172-.135 12.347.005 18.516.067 2.965-.999 4.076-3.984 4.001-6.169-.154-12.344-.049-18.517-.051z"
    />
    <path
      fill="#6E6C5D"
      d="m403.802 136.569-.055 22.307L381.4 159l-.073-22.354 22.475-.077z"
    />
    <path
      fill="#3B3C26"
      d="m381.4 158.999 22.347-.124 44.939.072c6.053.006 12.11.148 18.158-.036 3.229-.098 4.442.991 4.331 4.282-.204 6.046-.069 12.104-.075 18.157l.089.131-44.889-.049-22.516-.023-22.352-.068-.104.076.081-22.432-.009.014z"
    />
    <path
      fill="#333221"
      d="m560.886 293.591-22.549.1-22.13-.283-.284-22.227.177.001 44.699.064.087 22.345z"
    />
    <path
      fill="#474632"
      d="m493.45 248.841 22.538-.142.11 22.482-.175-.001-22.237-.276-.236-22.063z"
    />
    <path
      fill="#8D8E81"
      d="M381.335 226.361c-6.169-.031-12.34-.185-18.505-.04-2.958.07-4.055-.897-3.969-3.918.176-6.164.045-12.338.041-18.507l-.087-22.399.226-.227c7.428.05 14.857.098 22.286.147l.104-.076-.037 22.425-.059 22.595z"
    />
    <path
      fill="#878776"
      d="m381.335 226.361.058-22.594 22.45-.007-.1.185.118 22.412-.061 22.355-22.389-.05-.063.058-.013-22.359z"
    />
    <path
      fill="#3B3C26"
      d="M336.478 271.155c-.001-6.01.17-12.026-.066-18.027-.14-3.572 1.261-4.512 4.635-4.474 13.432.15 26.867.066 40.301.068l.063-.058-.169 22.366c-7.431.052-14.862.102-22.294.153l-22.468-.027-.002-.001z"
    />
    <path
      fill="#3B3C26"
      d="m313.957 293.74-22.395-.311c.009-5.69.309-11.398-.065-17.062-.293-4.434 1.471-5.365 5.514-5.299 13.153.215 26.311.084 39.467.087l.001.001-.008 22.245-22.514.339z"
    />
    <path
      fill="#BEBBB0"
      d="m358.814 181.498.087 22.399-22.417-.205.173-22.176 22.157-.018z"
    />
    <path
      fill="#655D51"
      d="M336.562 383.24c-.057-7.412-.114-14.824-.173-22.235l.144-.14c7.491.056 14.982.112 22.473.166l-.178 22.169.125.256-22.468.036.077-.252z"
    />
    <path
      fill="#474632"
      d="m314.016 405.778-22.461-.052-.065-22.092 22.466-.257.06 22.402v-.001z"
    />
    <path
      fill="#8D8E81"
      d="m314.014 405.779-.06-22.402 22.607-.136-.075.25-.017 22.299.043.054-22.498-.065z"
    />
    <path
      fill="#474632"
      d="m359.008 361.032-22.474-.167-.127-22.424.143-22.365-.067-.014 22.478.143c-.015 5.864.175 11.738-.118 17.588-.194 3.871 1.342 4.934 4.948 4.744 4.607-.242 9.24.043 13.856-.09 2.755-.08 3.796.983 3.746 3.741-.112 6.217-.013 12.438 0 18.657l-22.385.187z"
    />
    <path
      fill="#6E6C5D"
      d="m336.55 316.077-.143 22.365-22.137.13-.411-22.45.329-.135 22.362.09z"
    />
    <path
      fill="#655D51"
      d="m336.55 316.077-22.362-.091c-.076-7.416-.153-14.831-.231-22.246l22.513-.338.039.369-.026 22.293.067.013z"
    />
    <path
      fill="#BEBBB0"
      d="m336.47 405.79.017-22.299c7.489-.011 14.979-.023 22.468-.036l.024 22.19-22.509.145z"
    />
    <path
      fill="#A6A49A"
      d="m358.978 405.645-.024-22.19-.125-.255c7.488.077 14.975.153 22.463.228l.252 22.191-.325.327-22.241-.301z"
    />
    <path
      fill="#878776"
      d="m426.26 405.827-22.42.203-.053-.45.03-22.161 22.342-.11.013 22.408.088.11z"
    />
    <path
      fill="#78786A"
      d="m403.817 383.42-.03 22.16-22.243.04-.251-22.192.179-.18 22.316.059.029.113z"
    />
    <path
      fill="#6E6C5D"
      d="M381.327 181.418c-7.429-.049-14.858-.097-22.286-.147-.054-7.431-.109-14.862-.165-22.292l22.532.007-.081 22.432z"
    />
    <path
      fill="#655D51"
      d="m426.3 181.433 44.889.049-.066 22.143.038.435c-6.177-.079-12.357-.058-18.528-.278-2.967-.106-4.092.943-3.993 3.953.203 6.175.143 12.358.191 18.538l-.318.006-22.295.008h.018l.004-22.419.06-22.435z"
    />
    <path
      fill="#474632"
      d="m471.105 226.292.038 22.253-.087.39-22.343-.413-.202-22.243.318-.006 22.279.019h-.003z"
    />
    <path
      fill="#655D51"
      d="m493.45 248.841.236 22.064-.229.402-22.239-.284-.162-22.088.087-.39 22.307.296z"
    />
    <path
      fill="#333221"
      d="m471.108 226.292-22.279-.019c-.047-6.18.013-12.363-.19-18.538-.099-3.01 1.026-4.059 3.993-3.953 6.172.221 12.352.199 18.529.278l-.053 22.232z"
    />
    <path
      fill="#6E6C5D"
      d="m516.207 293.408 22.13.283.156 22.135-.105.472-22.437-.241-.113-22.293.369-.356z"
    />
    <path
      fill="#BEBBB0"
      d="m515.952 316.058 22.437.241.073 22.034-22.478.185.116-.161-.091-22.372-.057.073z"
    />
    <path
      fill="#3B3C26"
      d="m515.984 338.517.014 22.441-22.346.049-22.531-.083.046-.078-.079-22.15 45.012-.341-.116.162z"
    />
    <path
      fill="#78786A"
      d="m493.652 361.008 22.345-.049.043 22.373-22.324.04-.275-.01c.072-7.451.142-14.903.211-22.354z"
    />
    <path
      fill="#BEBBB0"
      d="m426.171 405.718-.013-22.408.131.079 22.331.01.209-.107-.234 22.574-22.424-.148z"
    />
    <path
      fill="#6E6C5D"
      d="m471.087 383.369-22.258-.076-.208.106-.04-22.362 22.586-.191-.046.078.079 22.42-.113.025z"
    />
    <path
      fill="#554"
      d="m471.2 383.345-.079-22.42 22.531.083c-.069 7.452-.139 14.903-.21 22.355l-22.242-.018zM403.843 203.761l-22.45.007.038-22.426 22.352.068.06 22.351z"
    />
    <path
      fill="#474632"
      d="m403.843 203.761-.06-22.351 22.516.023-.06 22.435-22.497.079.101-.186z"
    />
    <path
      fill="#6E6C5D"
      d="m493.686 270.905 22.237.276.284 22.228-.369.357-22.159-.223-.223-22.236.23-.402z"
    />
    <path
      fill="#78786A"
      d="m403.743 203.946 22.497-.079-.003 22.419-22.376.072-.118-22.412z"
    />
    <path
      fill="#554"
      d="m403.86 226.359 22.376-.072h-.018c-.045 7.453-.091 14.907-.135 22.361l-22.273.076-.01-.01.06-22.355z"
    />
    <path
      fill="#554"
      d="m381.24 271.03.17-22.365 22.39.049.01.01-.001 22.415-22.317.15-.252-.259z"
    />
    <path
      fill="#474632"
      d="m336.471 293.401.008-22.245 22.468.027-.072 22.281-22.365.307-.039-.37z"
    />
    <path
      fill="#554"
      d="m358.875 293.463.072-22.28c7.432-.05 14.863-.101 22.294-.153l.251.257-.151 22.29-22.466-.114z"
    />
    <path
      fill="#78786A"
      d="m381.293 383.429-22.463-.228.177-22.169c7.463-.061 14.925-.124 22.387-.186l-.074.135.154 22.267-.181.181z"
    />
    <path
      fill="#333221"
      d="M381.394 360.845c-.013-6.219-.112-12.44 0-18.657.05-2.757-.991-3.82-3.746-3.741-4.616.133-9.249-.152-13.856.09-3.606.19-5.142-.873-4.948-4.744.294-5.85.103-11.724.118-17.588l22.365-.332 22.472.11.059 22.415-.106 22.394.1.222-22.533-.033.075-.136z"
    />
    <path
      fill="#878776"
      d="m381.327 315.872-22.365.333-22.478-.142.026-22.293 22.365-.306 22.466.113.023.023-.037 22.272z"
    />
    <path
      fill="#6E6C5D"
      d="m426.159 383.31-22.342.11-.029-.112.064-22.295-.099-.221c7.528.084 15.057.167 22.585.249l-.048 22.347-.131-.078z"
    />
    <path
      fill="#655D51"
      d="m403.852 361.013-.064 22.295-22.316-.059c-.05-7.424-.101-14.846-.153-22.268l22.533.032z"
    />
    <path
      fill="#6E6C5D"
      d="m426.081 248.647.137-22.36 22.294-.008c.066 7.414.133 14.829.201 22.243l-.049.444-22.277-.171-.306-.148z"
    />
    <path
      fill="#8D8E81"
      d="m471.056 248.934.162 22.088-22.532.126-.022-22.181.049-.446 22.343.413z"
    />
    <path
      fill="#E1DED8"
      d="m515.838 293.765.113 22.293.057-.074-44.93-.108c.015-5.342.31-10.702-.039-16.02-.309-4.708.897-6.8 6.089-6.361"
    />
    <path
      fill="#78786A"
      d="m471.078 315.876 44.93.108.091 22.372-45.012.34.076-.439.034-22.057-.119-.324z"
    />
    <path
      fill="#474632"
      d="m471.166 360.847-22.586.19.133-.208.023-22.414 22.427-.158-.076.439.079 22.151z"
    />
    <path
      fill="#A6A49A"
      d="m426.289 383.389.048-22.348 22.376-.213-.133.208.04 22.362c-7.444-.002-14.887-.005-22.331-.009z"
    />
    <path
      fill="#655D51"
      d="m403.809 271.138.001-22.415 22.273-.076.303.148-.311 22.319-22.324.101.058-.077z"
    />
    <path
      fill="#878776"
      d="m381.341 293.577.151-22.29 22.318-.149-.058.077.112 22.431-.046-.035-22.453-.011-.024-.023z"
    />
    <path
      fill="#554"
      d="m403.859 338.398-.059-22.415.017-22.372.046.035 22.291.009.107-.067-.006 22.391-.04 22.472-22.356-.053z"
    />
    <path
      fill="#3B3C26"
      d="m403.859 338.398 22.355.053 22.533-.018-.009-.018-.023 22.415-22.376.213-22.585-.249c.034-7.467.069-14.931.105-22.396z"
    />
    <path
      fill="#78786A"
      d="m403.816 293.611-.017 22.372-22.472-.11.037-22.273 22.452.011z"
    />
    <path
      fill="#A6A49A"
      d="m426.075 271.115.311-22.319 22.278.171.022 22.182-.144 22.552-22.282-.114-.106.067-.079-22.539z"
    />
    <path
      fill="#878776"
      d="m426.26 293.587 22.282.114.309 22.16-.324.335-22.273-.217.006-22.392z"
    />
    <path
      fill="#878776"
      d="m471.164 338.257-22.427.158.009.018-.22-22.238.324-.335 22.347.339c-.01 7.354-.022 14.706-.033 22.058z"
    />
    <path
      fill="#6E6C5D"
      d="m426.075 271.115.078 22.539-22.291-.009c-.036-7.477-.074-14.954-.111-22.43l22.324-.1z"
    />
    <path
      fill="#474632"
      d="m448.527 316.195.22 22.238-22.532.018.04-22.473 22.272.217z"
    />
    <path fill="#8D8E81" d="M381.622 560.188h22.892v45.784h-22.892z"/>
    <path fill="#0F7540" d="M404.054 583.081h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M266.702 605.776h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M289.594 605.776h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M312.486 605.776h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M335.378 605.776h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M358.27 605.776h22.892v22.892H358.27z"/>
    <path fill="#9E937F" d="M381.162 605.776h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M404.054 605.776h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M426.946 605.776h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M449.838 605.776h22.892v22.892h-22.892z"/>
    <path fill="#A7C56C" d="M472.73 605.776h22.892v22.892H472.73z"/>
    <path fill="#81B829" d="M495.622 605.776h22.892v22.892h-22.892z"/>
    <path fill="#E4EACD" d="M518.514 605.776h22.892v22.892h-22.892z"/>
  </svg>
);

export default EucaliptoGlobulus;