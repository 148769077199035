import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';

//UTILS
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import SustainabilityActionGrid from '@/components/landing/SustainabilityActions/SustainabilityActionGrid';
import {SustainabilityActionType} from '@/domain/entities/Initiative';
import {useMediaQuery} from '@mui/material';

//TYPES
export type SustainabilityActionPanelProps = {
  sustainabilityActions: Array<SustainabilityActionType>
};


const wrapperTextSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 0,
  mb: 2
};

const SustainabilityActionPanel: FC<SustainabilityActionPanelProps> = ({sustainabilityActions}) => {

  const {t} = useTranslation();
  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});
  
  const constainerSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    p: 2,
    width: '100vw',
    mb: widescreen ? 4 : 2
  };


  return <Box sx={constainerSx}>
    <Box sx={wrapperTextSx} my={3}>
      <Typography variant='title' color='primary' align='center' gutterBottom>{t('initiatives.sustainability_actions').toUpperCase()}</Typography>
      <Typography variant='paragraph' align='center' sx={{color: 'secondary.grey', maxWidth: widescreen ? '50vw' : '100%', mb: 2}}>{t('sustainabilityActions_description')}</Typography>
    </Box>
    <SustainabilityActionGrid activeIds={sustainabilityActions.map(item => item.id)}/>
  </Box>;
};
export default SustainabilityActionPanel;