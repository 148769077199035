import React, {FC, SVGProps} from 'react';

const Default: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 808.11 711.16"
    height="100%"
    fill="none"
    {...props}
  >
    <path
      fill="#d6d7dd"
      d="M367 436.19h22.89v22.89H367zM389.9 436.19h22.89v22.89H389.9zM412.79 436.19h22.89v22.89h-22.89z"
    />
    <path
      fill="#818482"
      d="M435.68 436.19h22.89v22.89h-22.89zM458.57 436.19h22.89v22.89h-22.89z"
    />
    <path fill="#525036" d="M412.79 459.09h22.89v22.89h-22.89z"/>
    <path fill="#818482" d="M435.68 459.09h22.89v22.89h-22.89z"/>
    <path fill="#525036" d="M412.79 481.98h22.89v22.89h-22.89z"/>
    <path fill="#818482" d="M435.68 481.98h22.89v22.89h-22.89z"/>
    <path fill="#525036" d="M412.79 504.87h22.89v22.89h-22.89z"/>
    <path fill="#818482" d="M435.68 504.87h22.89v22.89h-22.89z"/>
    <path fill="#445d44" d="M412.79 527.76h22.89v22.89h-22.89z"/>
    <path fill="#818482" d="M435.68 527.76h22.89v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M412.79 550.65h22.89v22.89h-22.89zM435.68 550.65h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M298.33 573.55h22.89v22.89h-22.89z"/>
    <path fill="#4d6435" d="M321.22 573.55h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M344.11 573.55H367v22.89h-22.89z"/>
    <path fill="#93aa89" d="M367 573.55h22.89v22.89H367z"/>
    <path fill="#96b03e" d="M389.9 573.55h22.89v22.89H389.9z"/>
    <path fill="#93aa89" d="M412.79 573.55h22.89v22.89h-22.89z"/>
    <path fill="#3f5336" d="M435.68 573.55h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M458.57 573.55h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M481.46 573.55h22.89v22.89h-22.89z"/>
    <path fill="#b9c988" d="M504.36 573.55h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M527.25 573.55h22.89v22.89h-22.89z"/>
    <path fill="#eaecdd" d="M550.14 573.55h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M435.68 115.71h22.89v22.89h-22.89zM550.14 115.71h22.89v22.89h-22.89zM458.57 138.6h22.89v22.89h-22.89zM481.46 138.6h22.89v22.89h-22.89zM504.36 138.6h22.89v22.89h-22.89zM527.25 138.6h22.89v22.89h-22.89zM389.9 161.49h22.89v22.89H389.9z"
    />
    <path fill="#96b03e" d="M435.68 161.49h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M458.57 161.49h22.89v22.89h-22.89z"/>
    <path fill="#3f5336" d="M504.36 161.49h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M573.03 161.49h22.89v22.89h-22.89zM595.92 161.49h22.89v22.89h-22.89zM367 184.38h22.89v22.89H367zM389.9 184.38h22.89v22.89H389.9z"
    />
    <path fill="#96b03e" d="M435.68 184.38h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M481.46 184.38h22.89v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M527.25 184.38h22.89v22.89h-22.89zM550.14 184.38h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M412.79 207.27h22.89v22.89h-22.89zM435.68 207.27h22.89v22.89h-22.89zM504.36 207.27h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M527.25 207.27h22.89v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M573.03 207.27h22.89v22.89h-22.89zM595.92 207.27h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M367 230.17h22.89v22.89H367zM389.9 230.17h22.89v22.89H389.9zM435.68 230.17h22.89v22.89h-22.89zM458.57 230.17h22.89v22.89h-22.89z"
    />
    <path
      fill="#3f5336"
      d="M504.36 230.17h22.89v22.89h-22.89zM550.14 230.17h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M618.82 230.17h22.89v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M389.9 253.06h22.89v22.89H389.9zM458.57 253.06h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M481.46 253.06h22.89v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M527.25 253.06h22.89v22.89h-22.89zM550.14 253.06h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M573.03 253.06h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M641.71 253.06h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M389.9 275.95h22.89v22.89H389.9z"/>
    <path
      fill="#93aa89"
      d="M412.79 275.95h22.89v22.89h-22.89zM458.57 275.95h22.89v22.89h-22.89z"
    />
    <path
      fill="#3f5336"
      d="M481.46 275.95h22.89v22.89h-22.89zM504.36 275.95h22.89v22.89h-22.89zM595.92 275.95h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M618.82 275.95h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M367 298.84h22.89v22.89H367zM435.68 298.84h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M481.46 298.84h22.89v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M527.25 298.84h22.89v22.89h-22.89zM573.03 298.84h22.89v22.89h-22.89z"
    />
    <path
      fill="#96b03e"
      d="M618.82 298.84h22.89v22.89h-22.89zM367 321.73h22.89v22.89H367z"
    />
    <path
      fill="#93aa89"
      d="M389.9 321.73h22.89v22.89H389.9zM412.79 321.73h22.89v22.89h-22.89zM458.57 321.73h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M481.46 321.73h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M504.36 321.73h22.89v22.89h-22.89zM527.25 321.73h22.89v22.89h-22.89z"
    />
    <path
      fill="#3f5336"
      d="M550.14 321.73h22.89v22.89h-22.89zM573.03 321.73h22.89v22.89h-22.89z"
    />
    <path
      fill="#96b03e"
      d="M367 344.63h22.89v22.89H367zM412.79 344.63h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M435.68 344.63h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M481.46 344.63h22.89v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M527.25 344.63h22.89v22.89h-22.89zM550.14 344.63h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M389.9 367.52h22.89v22.89H389.9z"/>
    <path
      fill="#3f5336"
      d="M481.46 367.52h22.89v22.89h-22.89zM504.36 367.52h22.89v22.89h-22.89z"
    />
    <path
      fill="#96b03e"
      d="M573.03 367.52h22.89v22.89h-22.89zM595.92 367.52h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M481.46 390.41h22.89v22.89h-22.89z"/>
    <path fill="#3f5336" d="M527.25 390.41h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M458.57 413.3h22.89v22.89h-22.89zM481.46 413.3h22.89v22.89h-22.89z"
    />
    <path
      fill="#3f5336"
      d="M527.25 413.3h22.89v22.89h-22.89zM550.14 413.3h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M481.46 436.19h22.89v22.89h-22.89zM344.11 115.71H367v22.89h-22.89zM367 115.71h22.89v22.89H367zM435.68 115.71h22.89v22.89h-22.89zM389.9 138.6h22.89v22.89H389.9zM412.79 138.6h22.89v22.89h-22.89zM298.33 161.49h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M344.11 161.49H367v22.89h-22.89z"/>
    <path fill="#93aa89" d="M367 161.49h22.89v22.89H367z"/>
    <path fill="#3f5336" d="M389.9 161.49h22.89v22.89H389.9z"/>
    <path
      fill="#93aa89"
      d="M458.57 161.49h22.89v22.89h-22.89zM481.46 161.49h22.89v22.89h-22.89zM275.44 184.38h22.89v22.89h-22.89zM298.33 184.38h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M344.11 184.38H367v22.89h-22.89z"/>
    <path fill="#93aa89" d="M367 184.38h22.89v22.89H367z"/>
    <path
      fill="#96b03e"
      d="M412.79 184.38h22.89v22.89h-22.89zM435.68 184.38h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M321.22 207.27h22.89v22.89h-22.89zM344.11 207.27H367v22.89h-22.89zM367 207.27h22.89v22.89H367zM389.9 207.27h22.89v22.89H389.9z"
    />
    <path fill="#3f5336" d="M412.79 207.27h22.89v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M458.57 207.27h22.89v22.89h-22.89zM481.46 207.27h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M252.54 230.17h22.89v22.89h-22.89zM275.44 230.17h22.89v22.89h-22.89zM298.33 230.17h22.89v22.89h-22.89zM344.11 230.17H367v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M367 230.17h22.89v22.89H367z"/>
    <path
      fill="#3f5336"
      d="M389.9 230.17h22.89v22.89H389.9zM435.68 230.17h22.89v22.89h-22.89z"
    />
    <path
      fill="#96b03e"
      d="M252.54 253.06h22.89v22.89h-22.89zM298.33 253.06h22.89v22.89h-22.89z"
    />
    <path fill="#4d6435" d="M367 253.06h22.89v22.89H367z"/>
    <path
      fill="#3f5336"
      d="M412.79 253.06h22.89v22.89h-22.89zM435.68 253.06h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M458.57 253.06h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M504.36 253.06h22.89v22.89h-22.89zM252.54 275.95h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M298.33 275.95h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M321.22 275.95h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M367 275.95h22.89v22.89H367z"/>
    <path
      fill="#3f5336"
      d="M389.9 275.95h22.89v22.89H389.9zM481.46 275.95h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M252.54 298.84h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M275.44 298.84h22.89v22.89h-22.89zM344.11 298.84H367v22.89h-22.89zM367 298.84h22.89v22.89H367z"
    />
    <path
      fill="#3f5336"
      d="M412.79 298.84h22.89v22.89h-22.89zM458.57 298.84h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M275.44 321.73h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M298.33 321.73h22.89v22.89h-22.89zM321.22 321.73h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M367 321.73h22.89v22.89H367z"/>
    <path
      fill="#93aa89"
      d="M389.9 321.73h22.89v22.89H389.9zM412.79 321.73h22.89v22.89h-22.89z"
    />
    <path
      fill="#3f5336"
      d="M435.68 321.73h22.89v22.89h-22.89zM458.57 321.73h22.89v22.89h-22.89z"
    />
    <path
      fill="#96b03e"
      d="M275.44 344.63h22.89v22.89h-22.89zM321.22 344.63h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M344.11 344.63H367v22.89h-22.89z"/>
    <path fill="#4d6435" d="M367 344.63h22.89v22.89H367z"/>
    <path
      fill="#3f5336"
      d="M412.79 344.63h22.89v22.89h-22.89zM435.68 344.63h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M252.54 367.52h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M298.33 367.52h22.89v22.89h-22.89zM367 367.52h22.89v22.89H367z"
    />
    <path fill="#3f5336" d="M389.9 367.52h22.89v22.89H389.9z"/>
    <path
      fill="#96b03e"
      d="M458.57 367.52h22.89v22.89h-22.89zM481.46 367.52h22.89v22.89h-22.89z"
    />
    <path fill="#4d6435" d="M367 390.41h22.89v22.89H367z"/>
    <path fill="#3f5336" d="M412.79 390.41h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M367 413.3h22.89v22.89H367z"/>
    <path
      fill="#3f5336"
      d="M412.79 413.3h22.89v22.89h-22.89zM435.68 413.3h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M367 436.19h22.89v22.89H367zM527.25 436.19h22.89v22.89h-22.89zM481.46 436.19h22.89v22.89h-22.89zM412.79 436.19h22.89v22.89h-22.89zM504.36 413.3h22.89v22.89h-22.89zM458.57 413.3h22.89v22.89h-22.89zM435.68 413.3h22.89v22.89h-22.89zM573.03 390.41h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M527.25 390.41h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M504.36 390.41h22.89v22.89h-22.89zM481.46 390.41h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M458.57 390.41h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M389.9 390.41h22.89v22.89H389.9zM367 390.41h22.89v22.89H367zM595.92 367.52h22.89v22.89h-22.89zM595.92 344.63h22.89v22.89h-22.89zM573.03 367.52h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M527.25 367.52h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M481.46 367.52h22.89v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M435.68 367.52h22.89v22.89h-22.89zM412.79 367.52h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M550.14 344.63h22.89v22.89h-22.89zM527.25 344.63h22.89v22.89h-22.89zM481.46 344.63h22.89v22.89h-22.89zM458.57 344.63h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M435.68 344.63h22.89v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M389.9 344.63h22.89v22.89H389.9zM367 344.63h22.89v22.89H367z"
    />
    <path
      fill="#93aa89"
      d="M618.82 321.73h22.89v22.89h-22.89zM595.92 321.73h22.89v22.89h-22.89zM573.03 321.73h22.89v22.89h-22.89zM527.25 321.73h22.89v22.89h-22.89zM504.36 321.73h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M481.46 321.73h22.89v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M458.57 321.73h22.89v22.89h-22.89zM412.79 321.73h22.89v22.89h-22.89z"
    />
    <path
      fill="#96b03e"
      d="M618.82 298.84h22.89v22.89h-22.89zM573.03 298.84h22.89v22.89h-22.89zM504.36 298.84h22.89v22.89h-22.89z"
    />
    <path fill="#4d6435" d="M481.46 298.84h22.89v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M435.68 298.84h22.89v22.89h-22.89zM412.79 298.84h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M389.9 298.84h22.89v22.89H389.9z"/>
    <path fill="#93aa89" d="M618.82 275.95h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M573.03 275.95h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M550.14 275.95h22.89v22.89h-22.89zM504.36 275.95h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M481.46 275.95h22.89v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M458.57 275.95h22.89v22.89h-22.89zM367 275.95h22.89v22.89H367z"
    />
    <path fill="#96b03e" d="M618.82 253.06h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M595.92 253.06h22.89v22.89h-22.89zM527.25 253.06h22.89v22.89h-22.89zM481.46 253.06h22.89v22.89h-22.89z"
    />
    <path
      fill="#3f5336"
      d="M435.68 253.06h22.89v22.89h-22.89zM389.9 253.06h22.89v22.89H389.9z"
    />
    <path fill="#96b03e" d="M595.92 230.17h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M573.03 230.17h22.89v22.89h-22.89zM550.14 230.17h22.89v22.89h-22.89zM504.36 230.17h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M481.46 230.17h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M458.57 230.17h22.89v22.89h-22.89zM435.68 230.17h22.89v22.89h-22.89z"
    />
    <path
      fill="#3f5336"
      d="M412.79 230.17h22.89v22.89h-22.89zM389.9 230.17h22.89v22.89H389.9z"
    />
    <path
      fill="#96b03e"
      d="M595.92 207.27h22.89v22.89h-22.89zM550.14 207.27h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M527.25 207.27h22.89v22.89h-22.89z"/>
    <path fill="#4d6435" d="M481.46 207.27h22.89v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M435.68 207.27h22.89v22.89h-22.89zM412.79 207.27h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M618.82 184.38h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M573.03 184.38h22.89v22.89h-22.89zM481.46 184.38h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M458.57 184.38h22.89v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M389.9 184.38h22.89v22.89H389.9zM367 184.38h22.89v22.89H367z"
    />
    <path fill="#4d6435" d="M481.46 161.49h22.89v22.89h-22.89z"/>
    <path fill="#3f5336" d="M435.68 161.49h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M504.36 138.6h22.89v22.89h-22.89zM481.46 138.6h22.89v22.89h-22.89z"
    />
    <path
      fill="#3f5336"
      d="M435.68 138.6h22.89v22.89h-22.89zM412.79 138.6h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M481.46 115.71h22.89v22.89h-22.89zM412.79 436.19h22.89v22.89h-22.89zM321.22 436.19h22.89v22.89h-22.89zM389.9 413.3h22.89v22.89H389.9zM367 413.3h22.89v22.89H367zM344.11 413.3H367v22.89h-22.89zM458.57 390.41h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M412.79 390.41h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M389.9 390.41h22.89v22.89H389.9zM298.33 390.41h22.89v22.89h-22.89zM275.44 390.41h22.89v22.89h-22.89zM481.46 367.52h22.89v22.89h-22.89zM458.57 367.52h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M412.79 367.52h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M367 367.52h22.89v22.89H367z"/>
    <path
      fill="#96b03e"
      d="M344.11 367.52H367v22.89h-22.89zM321.22 367.52h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M435.68 344.63h22.89v22.89h-22.89zM412.79 344.63h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M344.11 344.63H367v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M298.33 344.63h22.89v22.89h-22.89zM275.44 344.63h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M481.46 321.73h22.89v22.89h-22.89zM458.57 321.73h22.89v22.89h-22.89zM412.79 321.73h22.89v22.89h-22.89zM389.9 321.73h22.89v22.89H389.9z"
    />
    <path fill="#3f5336" d="M321.22 321.73h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M252.54 321.73h22.89v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M458.57 298.84h22.89v22.89h-22.89zM389.9 298.84h22.89v22.89H389.9z"
    />
    <path fill="#93aa89" d="M367 298.84h22.89v22.89H367z"/>
    <path
      fill="#3f5336"
      d="M344.11 298.84H367v22.89h-22.89zM321.22 298.84h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M298.33 298.84h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M229.65 298.84h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M458.57 275.95h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M435.68 275.95h22.89v22.89h-22.89zM389.9 275.95h22.89v22.89H389.9z"
    />
    <path
      fill="#3f5336"
      d="M367 275.95h22.89v22.89H367zM275.44 275.95h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M252.54 275.95h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M481.46 253.06h22.89v22.89h-22.89zM412.79 253.06h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M367 253.06h22.89v22.89H367z"/>
    <path
      fill="#3f5336"
      d="M344.11 253.06H367v22.89h-22.89zM298.33 253.06h22.89v22.89h-22.89z"
    />
    <path
      fill="#96b03e"
      d="M252.54 253.06h22.89v22.89h-22.89zM481.46 230.17h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M458.57 230.17h22.89v22.89h-22.89zM435.68 230.17h22.89v22.89h-22.89zM389.9 230.17h22.89v22.89H389.9z"
    />
    <path fill="#3f5336" d="M367 230.17h22.89v22.89H367z"/>
    <path fill="#93aa89" d="M344.11 230.17H367v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M321.22 230.17h22.89v22.89h-22.89zM298.33 230.17h22.89v22.89h-22.89z"
    />
    <path
      fill="#96b03e"
      d="M481.46 207.27h22.89v22.89h-22.89zM435.68 207.27h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M412.79 207.27h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M367 207.27h22.89v22.89H367z"/>
    <path
      fill="#3f5336"
      d="M344.11 207.27H367v22.89h-22.89zM321.22 207.27h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M458.57 184.38h22.89v22.89h-22.89z"/>
    <path fill="#3f5336" d="M367 184.38h22.89v22.89H367z"/>
    <path
      fill="#96b03e"
      d="M298.33 184.38h22.89v22.89h-22.89zM275.44 184.38h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M367 161.49h22.89v22.89H367z"/>
    <path fill="#3f5336" d="M344.11 161.49H367v22.89h-22.89z"/>
    <path fill="#93aa89" d="M389.9 138.6h22.89v22.89H389.9z"/>
    <path fill="#b9c988" d="M367 138.6h22.89v22.89H367z"/>
    <path
      fill="#3f5336"
      d="M344.11 138.6H367v22.89h-22.89zM321.22 138.6h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M367 115.71h22.89v22.89H367zM229.65 275.95h22.89v22.89h-22.89zM298.33 275.95h22.89v22.89h-22.89zM252.54 298.84h22.89v22.89h-22.89zM275.44 298.84h22.89v22.89h-22.89zM321.22 298.84h22.89v22.89h-22.89zM344.11 298.84H367v22.89h-22.89zM183.87 321.73h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M229.65 321.73h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M252.54 321.73h22.89v22.89h-22.89zM298.33 321.73h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M321.22 321.73h22.89v22.89h-22.89z"/>
    <path fill="#b9c988" d="M367 321.73h22.89v22.89H367z"/>
    <path
      fill="#93aa89"
      d="M160.98 344.63h22.89v22.89h-22.89zM183.87 344.63h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M229.65 344.63h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M275.44 344.63h22.89v22.89h-22.89zM298.33 344.63h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M344.11 344.63H367v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M206.76 367.52h22.89v22.89h-22.89zM229.65 367.52h22.89v22.89h-22.89zM298.33 367.52h22.89v22.89h-22.89zM275.44 367.52h22.89v22.89h-22.89zM321.22 367.52h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M344.11 367.52H367v22.89h-22.89z"/>
    <path fill="#96b03e" d="M367 367.52h22.89v22.89H367z"/>
    <path fill="#93aa89" d="M229.65 390.41h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M252.54 390.41h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M298.33 390.41h22.89v22.89h-22.89z"/>
    <path fill="#3f5336" d="M321.22 390.41h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M389.9 390.41h22.89v22.89H389.9z"/>
    <path fill="#96b03e" d="M252.54 413.3h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M275.44 413.3h22.89v22.89h-22.89z"/>
    <path fill="#4d6435" d="M298.33 413.3h22.89v22.89h-22.89z"/>
    <path fill="#3f5336" d="M344.11 413.3H367v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M412.79 413.3h22.89v22.89h-22.89zM206.76 436.19h22.89v22.89h-22.89zM229.65 207.27h22.89v22.89h-22.89zM252.54 436.19h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M275.44 436.19h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M298.33 436.19h22.89v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M321.22 436.19h22.89v22.89h-22.89zM367 436.19h22.89v22.89H367z"
    />
    <path
      fill="#96b03e"
      d="M389.9 436.19h22.89v22.89H389.9zM229.65 138.6h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M252.54 138.6h22.89v22.89h-22.89zM206.76 161.49h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M229.65 161.49h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M252.54 161.49h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M275.44 161.49h22.89v22.89h-22.89zM389.9 275.95h22.89v22.89H389.9zM458.57 275.95h22.89v22.89h-22.89zM367 298.84h22.89v22.89H367zM412.79 298.84h22.89v22.89h-22.89zM435.68 298.84h22.89v22.89h-22.89zM321.22 321.73h22.89v22.89h-22.89zM389.9 321.73h22.89v22.89H389.9z"
    />
    <path fill="#3f5336" d="M412.79 321.73h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M481.46 321.73h22.89v22.89h-22.89z"/>
    <path
      fill="#eaecdd"
      d="M298.33 344.63h22.89v22.89h-22.89zM550.14 367.52h22.89v22.89h-22.89zM344.11 321.73H367v22.89h-22.89zM344.11 275.95H367v22.89h-22.89zM321.22 253.06h22.89v22.89h-22.89zM275.44 253.06h22.89v22.89h-22.89zM412.79 161.49h22.89v22.89h-22.89zM527.25 230.17h22.89v22.89h-22.89zM527.25 161.49h22.89v22.89h-22.89zM527.25 275.95h22.89v22.89h-22.89zM550.14 298.84h22.89v22.89h-22.89zM504.36 344.63h22.89v22.89h-22.89zM435.68 390.41h22.89v22.89h-22.89zM595.92 298.84h22.89v22.89h-22.89zM344.11 390.41H367v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M321.22 344.63h22.89v22.89h-22.89zM367 344.63h22.89v22.89H367zM389.9 344.63h22.89v22.89H389.9z"
    />
    <path
      fill="#96b03e"
      d="M435.68 344.63h22.89v22.89h-22.89zM458.57 344.63h22.89v22.89h-22.89z"
    />
    <path
      fill="#93aa89"
      d="M344.11 367.52H367v22.89h-22.89zM389.9 367.52h22.89v22.89H389.9zM412.79 367.52h22.89v22.89h-22.89z"
    />
    <path fill="#3f5336" d="M435.68 367.52h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M481.46 367.52h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M275.44 390.41h22.89v22.89h-22.89zM298.33 390.41h22.89v22.89h-22.89zM321.22 390.41h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M389.9 390.41h22.89v22.89H389.9z"/>
    <path
      fill="#3f5336"
      d="M412.79 390.41h22.89v22.89h-22.89zM458.57 390.41h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M504.36 390.41h22.89v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M275.44 413.3h22.89v22.89h-22.89zM321.22 413.3h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M367 413.3h22.89v22.89H367z"/>
    <path fill="#4d6435" d="M389.9 413.3h22.89v22.89H389.9z"/>
    <path
      fill="#3f5336"
      d="M435.68 413.3h22.89v22.89h-22.89zM458.57 413.3h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M481.46 413.3h22.89v22.89h-22.89z"/>
    <path
      fill="#93aa89"
      d="M527.25 413.3h22.89v22.89h-22.89zM275.44 436.19h22.89v22.89h-22.89z"
    />
    <path fill="#96b03e" d="M321.22 436.19h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M344.11 436.19H367v22.89h-22.89z"/>
    <path fill="#3f5336" d="M367 436.19h22.89v22.89H367z"/>
    <path fill="#96b03e" d="M389.9 436.19h22.89v22.89H389.9z"/>
    <path fill="#3f5336" d="M412.79 436.19h22.89v22.89h-22.89z"/>
    <path
      fill="#96b03e"
      d="M504.36 436.19h22.89v22.89h-22.89zM229.65 138.6h22.89v22.89h-22.89z"
    />
    <path fill="#93aa89" d="M252.54 138.6h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M298.33 459.09h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M321.22 459.09h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M298.33 459.09h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M321.22 459.09h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M252.54 161.49h22.89v22.89h-22.89z"/>
    <path fill="#93aa89" d="M275.44 161.49h22.89v22.89h-22.89z"/>
    <path fill="#96b03e" d="M550.14 459.09h22.89v22.89h-22.89z"/>
    <path
      fill="#3f5336"
      d="M595.92 413.3h22.89v22.89h-22.89zM618.82 390.41h22.89v22.89h-22.89z"
    />
    <path
      fill="none"
      stroke="#d5d7dd"
      strokeMiterlimit={10}
      d="M807.61 710.66H.5V.5h807.11v710.16Z"
    />
  </svg>
);

export default Default;