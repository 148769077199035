import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {SPECIES} from '@/config';
import Default from '@/components/logos/arboles/Default';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';

const wrapperTree: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 130
};

const wrapperIcon: SxProps = {
  width: 150,
  height: 150
};

const defaultIcon: SxProps = {
  width: 150,
  height: 150,
  pb: 2
};

//TYPES
export type SpecieIconProps = {
    id: number
};

const SpecieIcon: FC<SpecieIconProps> = ({id}) => {
  return <Box sx={wrapperTree}>
    <Box sx={wrapperIcon}>
      {
        SPECIES.find(specieItem => specieItem.xorfor_id === id)?
          SPECIES.find(specieItem => specieItem.xorfor_id === id)?.icon :
          <Box sx={defaultIcon}><Default/></Box>
      }
    </Box>
  </Box>;
};
export default SpecieIcon;