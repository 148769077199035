import Http, {HttpError, options} from '@/domain/services/Http';
import {Token} from '@/domain/services/Auth';
import {PaginatedResponse} from '@/domain/commonTypes';


export const GET = <ResponseType, RequestType>(url: string, token?: Token | null) =>
  jsonFetchHttp<ResponseType, RequestType>(url, {method: 'GET', headers: token ? {'Authorization': `Token ${token}`}: undefined});
export const POST = <ResponseType, RequestType>(url: string, body: RequestType, token?: Token | null) =>
  jsonFetchHttp<ResponseType, RequestType>(url, {method: 'POST', headers: token ? {'Authorization': `Token ${token}`}: undefined, body: body});

export const PAGINATION_GET_PARAM = 'page_size';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const GET_PAGINATED = <ResponseType, RequestType>(url: string, token?: Token | null, page_size= 100, previous_results: Array<ResponseType> =[]) => {
  const final_url = url.includes(PAGINATION_GET_PARAM) ? url : url + `?${PAGINATION_GET_PARAM}=${page_size}`;
  return jsonFetchHttp<PaginatedResponse<ResponseType>, RequestType>(final_url, {method: 'GET', headers: token ? {'Authorization': `Token ${token}`}: undefined})
    .then(result => {
      const total_results = [...previous_results, ...result.results];
      if (result.next) {
        return GET_PAGINATED(result.next, token, page_size, total_results);
      }
      return total_results;
    });
};

export const jsonFetchHttp: Http = async <ResponseType, RequestType>(url: string | URL, options?: options<RequestType>): Promise<ResponseType> => {

  const response = await fetch(url, {
    method: options?.method ?? 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...options?.headers
    },
    body: options?.body ? JSON.stringify(options.body) : undefined
  });

  if (response.status === 204) {
    return undefined as ResponseType;
  } else if (response.status >= 200 && response.status < 300 && response.ok) {
    return response.json();
  } else {
    // WARNING this can't be expressed in Typescript, but: Statuses other than 2xx are rejected with an HttpError.
    const httpError: HttpError = {
      statusCode: response.status
    };
    try {
      httpError.payload = await response.json();
    } catch {
      // Fine, can't parse response as JSON, will assume plain text
      httpError.payload = await response.text();
    }
    throw httpError;
  }
};