import React, {FC} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {lighten} from '@mui/system/colorManipulator';
import CircularProgress from '@mui/material/CircularProgress';
import {useTranslation} from 'react-i18next';

const containerSx: SxProps = {
  bgcolor: 'white',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const wrapperErrorSx: SxProps = {
  bgcolor: () => lighten('#8FB35B', 0.9),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 4,
  borderRadius: 2,
  width: '100%',
  height: '70%'
};

const Loading: FC = () => {
  
  const {t} = useTranslation();
  
  return <Box sx={containerSx}>
    <Box sx={wrapperErrorSx}>
      <Typography variant='title' sx={{color: 'primary.main', mb: 5}}>{t('loading')}</Typography>
      <CircularProgress color='primary'/>
    </Box>
  </Box>;
};

export default Loading;