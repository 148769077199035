import React, {FC} from 'react';

import SpecieDataSummary from '../Species/SpecieDataSummary';
import CarouselGreenBonus from '@/components/CarouselGreenBonus';

import {Resource} from '@/domain/entities/Initiative';
import {Specie} from '@/domain/entities/Specie';
import ResourceData from '@/components/Resources/ResourceData';
import SpecieDataDetail from '@/components/Species/SpecieDataDetail';

export interface SpeciesAndResourcesCarouselSummaryProps {
  items: Array<Specie | Resource>,
  isDetail?: boolean,
  background?: boolean
}

const SpeciesAndResourcesCarousel: FC<SpeciesAndResourcesCarouselSummaryProps> = ({items, isDetail=false, background=false}) => {
  return <>
    {
      items.length !== 1
        ? 
        <CarouselGreenBonus>
          {
            items.map(item => (
              'text' in item ?
                <ResourceData key={item.id} resource={item} background={background}
                  isDetail={isDetail}/>
                : isDetail ? <SpecieDataDetail key={item.id} specie={item} background={background}/> :
                  <SpecieDataSummary key={item.id} specie={item}/>
            ))
          }
        </CarouselGreenBonus>
    
        : 'text' in items[0] ?
          <ResourceData key={items[0].id} resource={items[0]} background={background}
            isDetail={isDetail}/>
          : isDetail ? <SpecieDataDetail key={items[0].id} specie={items[0]} background={background}/> :
            <SpecieDataSummary key={items[0].id} specie={items[0]}/>
    }
  </>;
};

export default SpeciesAndResourcesCarousel;