import AuthImpl from '@/serviceImpl/AuthImpl';
import LocalStorageImpl from '@/serviceImpl/LocalStorageImpl';
import {LoginError} from '@/domain/services/Auth';

const loginUseCase = async (username: string, password: string): Promise<void | LoginError> => {

  const login = await AuthImpl.login(username, password);

  if ( login ) {
    if ('token' in login) {
      LocalStorageImpl.setCredentials(login.token);
      return Promise.resolve();
    }
    if ('username' in login || 'password' in login || 'non_field_errors' in login) {
      return Promise.resolve(login);
    }
  } else {
    return Promise.reject();
  }


};

export default loginUseCase;