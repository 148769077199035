import React, {CSSProperties, FC, useState} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import YearTag from './YearTag';

import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useTranslation} from 'react-i18next';
import {grey} from '@mui/material/colors';
import {useMediaQuery} from '@mui/material';

const IMAGE_MOBILE = '120px';
const IMAGE_DESKTOP = '170px';

const subtitleSx: SxProps = {
  width: {
    xs: IMAGE_MOBILE,
    md: IMAGE_DESKTOP
  },
  my: 2
};
const photoSx: SxProps = {
  outline: `7px solid ${grey[100]}`,
  zIndex: 100,
  width: {
    xs: IMAGE_MOBILE,
    md: IMAGE_DESKTOP
  },
  bgcolor: 'common.white',

};

const proposalSubtitleSx: SxProps = {
  gridArea: 'proposalSubtitle',
  ...subtitleSx
};
const doneSubtitleSx: SxProps = {
  gridArea: 'doneSubtitle',
  ...subtitleSx
};
const proposalPhotoSx: SxProps = {
  gridArea: 'proposalPhoto',
  ...photoSx
};
const donePhotoSx: SxProps = {
  gridArea: 'donePhoto',
  ...photoSx
};

const labelSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  alignSelf: {
    xs: 'flex-start',
    md: 'center'
  },
  color: 'primary.grey',
  fontWeight: 400,
  mt: {
    xs: 1,
    md: 0
  },
  mb: 3,
  maxWidth: {
    xs: '200px',
    md: '500px'
  }
};

const proposalLabelSx: SxProps = {
  gridArea: 'proposalLabel',
  justifySelf: {
    xs: 'center',
    md: 'flex-end'
  },
  mr: {
    xs: 0,
    md: 2
  },
  ml: 0,
  textAlign: {
    xs: 'center',
    md: 'right'
  },
  ...labelSx
};

const doneLabelSx: SxProps = {
  gridArea: 'doneLabel',
  justifySelf: {
    xs: 'center',
    md: 'flex-start'
  },
  mr: 0,
  ml: {
    xs: 0,
    md: 2
  },
  textAlign: {
    xs: 'center',
    md: 'left'
  },
  ...labelSx
};

const yearSx: SxProps = {
  gridArea: 'year',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100
};

export interface ActionProps {
  proposalPhoto?: string,
  proposalLabel?: string,
  donePhoto?: string,
  doneLabel?: string,
  year: number,
  title: string,
  isFirstAction?: boolean,
  isLastAction?: boolean
}

const Action: FC<ActionProps> = ({
  proposalPhoto,
  proposalLabel,
  donePhoto,
  doneLabel,
  year,
  title,
  isFirstAction=false,
  isLastAction=false
}) => {

  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});

  const [isOpen, setOpen] = useState<boolean>(false);
  
  const imageStyle: CSSProperties = {
    width: widescreen ? IMAGE_DESKTOP : IMAGE_MOBILE,
    aspectRatio: '1/1', 
    display: 'block', 
    objectFit: 'cover'
  };
  
  const expandSx: SxProps = {
    fontSize: '40px',
    color: isLastAction ? 'primary.main' : 'transparent',
    position: 'relative',
    zIndex: 5200,
    mt: 2,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
  };
  
  const containerSx: SxProps = {
    display: 'grid',
    gridTemplateAreas: {
      xs: isFirstAction ? `
        'proposalSubtitle . doneSubtitle'
        'proposalPhoto year donePhoto'
        'proposalLabel linkLine doneLabel'
        ` : `
        'proposalPhoto year donePhoto'
        'proposalLabel linkLine doneLabel'
        `,
      md: isFirstAction ? `
        '. proposalSubtitle . doneSubtitle . '
        'proposalLabel proposalPhoto year donePhoto doneLabel'
        '. . linkLine . .'
        ` : `
        'proposalLabel proposalPhoto year donePhoto doneLabel'
        '. . linkLine . .'
        `,
    },
    gridTemplateColumns: {
      xs: '1fr minmax(100px, 1fr) 1fr',
      md: 'minmax(200px, 500px) auto minmax(200px, 1fr) auto minmax(200px, 500px)'
    },
    gridTemplateRows: {
      xs: isFirstAction? '50px auto auto' : 'auto auto',
      md: isFirstAction? `auto ${IMAGE_DESKTOP} 50px`: `${IMAGE_DESKTOP} 50px`
    },
    justifyItems: 'center',
  };
  
  const verticalLineSx: SxProps = {
    gridArea: 'year',
    width: '1px',
    height: isFirstAction ? '50%' : '100%',
    transform: isFirstAction ? 'translate(-50%, 100%)' : 'translate(-50%, 0%)',
    backgroundColor: 'primary.main',
    zIndex: 80
  };
  
  const linkLineSx: SxProps = {
    gridArea: 'linkLine',
    width: '1px',
    height: isLastAction? '60%' : '100%',
    //height: '100%',
    transform: 'translate(-50%, 0%)',
    backgroundColor: 'primary.main',
    zIndex: 80
  };
  
  const horizontalLineSx: SxProps = {
    gridArea: {
      xs: isFirstAction ? '2/1/3/4' : '1/1/2/4',
      md: isFirstAction ? '2/2/3/5' : '1/2/2/5'
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: {
      xs: '60%',
      md: '100%'
    },
    height: '1px',
    position: 'relative',
    top: '50%',
    left: {
      xs: '30%',
      md: '50%'
    },
    transform: 'translate(-50%, -50%)',
    bgcolor: 'primary.main',
    zIndex: 80
  };
  
  const {t} = useTranslation();
  return <Box sx={containerSx}>
    {
      isFirstAction ?
        <>
          <Typography variant="subtitle" align="center" sx={proposalSubtitleSx}>
            {t('actionPlan.proposal').toUpperCase()}
          </Typography>
          <Typography variant="subtitle" align="center" sx={doneSubtitleSx}>
            {t('actionPlan.done').toUpperCase()}
          </Typography>
        </> : undefined     
    }
    <Box sx={proposalPhotoSx}>
      <img src={proposalPhoto || '/img/missing_image.jpg'} id='propopsal' style={imageStyle} alt={title}/>
    </Box>
    <Box sx={donePhotoSx}>
      <img src={donePhoto || '/img/missing_image.jpg'} id='done' style={imageStyle} alt={title} onClick={() => setOpen(true)}/>
    </Box>

    {donePhoto && <Lightbox open={isOpen} close={() => setOpen(false)} slides={[{src: donePhoto}]}/>}

    {proposalLabel && <Typography variant="paragraph" sx={proposalLabelSx}>{proposalLabel}</Typography>}
    <Typography variant="paragraph" sx={doneLabelSx}>{doneLabel}</Typography>
    
    <Box sx={yearSx}>
      <YearTag year={year}/>
    </Box>
    
    <Box sx={horizontalLineSx}/>

    <Box sx={verticalLineSx}/>

    <Box sx={linkLineSx} id={'linkLine'}>
      <ExpandMoreIcon sx={expandSx}/>
    </Box>
  </Box>;
};

export default Action;