import React, {FC} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {SUSTAINABILITYACTIONS} from '@/config';
import {SustainabilityActionType} from '@/domain/entities/Initiative';

//TYPES
export type SustainabilityActionProps = {
  action: SustainabilityActionType
};

const SustainabilityAction: FC<SustainabilityActionProps> = ({action}) => {

  const imagePath = SUSTAINABILITYACTIONS.find(item => item.id === action.id)?.icon;

  return <Box display='flex' flexDirection='row' alignItems='center' sx={{gap: 2}}>
    <Box sx={{width: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <img src={`/img/${imagePath}`} alt={action.name} width='60px'/>
    </Box>
    {
      action.name && <Typography variant="paragraph" sx={{color: 'text.primary'}}>{action.name}</Typography>
    }
  </Box>;
};
export default SustainabilityAction;