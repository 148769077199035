import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';

const containerSx: SxProps = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
};

const circleSx: SxProps = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: {xs: '52px', md: '70px'},
  height: {xs: '52px', md: '70px'},
  borderRadius: '100%',
  bgcolor: 'primary.main',
  zIndex: 5100
};

const yearSx: SxProps = {
  mt: 0.25,
  fontWeight: 700,
  fontSize: {md: '18px'}
};

export interface YearTagProps {
  year: number
}

const YearTag: FC<YearTagProps> = ({year}) => {
    
  return <Box sx={containerSx}>
    <Box sx={circleSx}>
      <Typography variant='caption' color='common.white' sx={yearSx}>{year}</Typography>
    </Box>
  </Box>;
};

export default YearTag;