import React, {CSSProperties, FC} from 'react';

import Box from '@mui/material/Box';

import {Collaborator} from '@/domain/entities/Collaborator';
import CarouselGreenBonus from '@/components/CarouselGreenBonus';


export interface CollaboratorsCarouselProps {
  collaborators: Array<Collaborator>
}

const collaboratorImage: CSSProperties = {
  filter: 'grayscale(100%)',
  maxHeight: '100%',
  maxWidth: '100%',
  opacity: 0.5,
  objectFit: 'contain'
};

const CollaboratorsCarousel: FC<CollaboratorsCarouselProps> = ({collaborators}) => {

  return <>
    {
      collaborators && collaborators.length !== 1
        ? <CarouselGreenBonus autoplay={true}>
          {
            collaborators.map(({id, title, image}) => <Box key={id} 
              sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '120px'}}>
              <img style={collaboratorImage} src={image} alt={title}/>
            </Box>
            )
          }
        </CarouselGreenBonus>
        : <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '120px'}}>
          <img style={collaboratorImage} src={collaborators[0].image} alt={collaborators[0].title}/>
        </Box>
    }
  </>;
};

export default CollaboratorsCarousel;