import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';

//GREEN BONUS
import SustainabilityAction from '@/components/landing/SustainabilityActions/SustainabilityAction';

//UTILS
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {SUSTAINABILITYACTIONS} from '@/config';
import {useMediaQuery} from '@mui/material';

//TYPES
export type SustainabilityActionGridProps = {
   activeIds: Array<number>
};

const SustainabilityActionGrid: FC<SustainabilityActionGridProps> = ({activeIds}) => {

  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});

  const containerSx: SxProps = {
    display: 'grid',
    gridTemplateColumns: widescreen ? 'repeat(4, 1fr)' : 'repeat(1, 1fr)',
    gap: widescreen ? 2 : 1
  };
  
  return <Box sx={containerSx}>
    {
      SUSTAINABILITYACTIONS
        .map(item => <SustainabilityAction key={item.id} action={item} active={activeIds.includes(item.id)}/>)
    }
  </Box>;
};
export default SustainabilityActionGrid;