import React, {CSSProperties, FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//UTILS
import {WEATHER_CONDITIONS} from '@/config';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {Meteo} from '@/domain/entities/Meteo';

//TYPES
export type MeteorologicalDataProps = {
  meteo: Meteo,
  isHorizontal?: boolean
};

const MeteorologicalData: FC<MeteorologicalDataProps> = ({meteo, isHorizontal=true}) => {

  const meteoIcon: string | undefined = WEATHER_CONDITIONS.find(condition => condition.id === meteo.sky_state)?.icon;
  
  const containerSx: SxProps = {
    display: 'flex',
    flexDirection: isHorizontal ? 'row' : 'column',
    alignItems: isHorizontal ? 'flex-end' : 'center',
    gap: isHorizontal ? 2 : 3
  };
  
  const wrapperSx: SxProps = {
    display: 'flex',
    flexDirection: isHorizontal ? 'row' : 'column',
    alignItems: isHorizontal ? 'flex-end' : undefined,
    gap: isHorizontal ? 1 : 0
  };
  
  const imageStyle: CSSProperties = {
    width: isHorizontal ? '50px' : '75px'
  };
  
  const temperature: SxProps = {
    color: '#fab643',
    textAlign: isHorizontal ? 'left' : 'center'
  };

  
  return <Box sx={containerSx}>
    {meteo.sky_state && <img src={`/img/meteo/${meteoIcon}`} alt={meteo.sky_state || ''} style={imageStyle}/>}
    {meteo.temperature && <Box sx={wrapperSx}>
      <Typography variant="title" sx={temperature}>{meteo.temperature}º</Typography>
    </Box>}
  </Box>;
};
export default MeteorologicalData;