import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';

//GEOCOMPONENTS
import ResponsiveHeader from '@geomatico/geocomponents/Layout/ResponsiveHeader';

//MUI
import Typography from '@mui/material/Typography';
import styled from '@mui/system/styled';
import {IconButton} from '@mui/material';

//UTILS
import logoutUseCase from '@/domain/useCases/logoutUseCase';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {PerformerEmployee} from '@/domain/entities/PerformerEmployee';


const Main = styled(Box)({
  flexGrow: 1,
  padding: 0,
  position: 'relative',
  height: '100vh',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0
}) as React.ElementType;

const responsiveHeaderSx = {
  '&.MuiAppBar-root': {
    width: 'auto',
    height: '120px',
    backgroundColor: 'secondary.light',
    top: 10,
    right: 10,
    left: 10,
    borderRadius: 1,
    zIndex: 1500,
    position: 'absolute'
  },
  '& .ResponsiveHeader-toolbar': {
    backgroundColor: 'secondary.light',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '120px',
    top: 0,
    borderRadius: 1,
    pr: 0
  },
  '& .ResponsiveHeader-title': {
    fontWeight: 'bolder',
    margin: 'auto',
    textAlign: 'center',
    fontSize: '32px',
  },
  '& .ResponsiveHeader-logo': {
    height: '70px'
  }
};

export type LayoutProps = {
  mainContent: React.ReactElement,
  currentPerformer: Array<PerformerEmployee> | undefined
};

const Layout:  FC<LayoutProps> = ({mainContent, currentPerformer}) => {
  const {i18n, t} = useTranslation();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logoutUseCase();
    navigate(`/${i18n.resolvedLanguage}/login`);
  };

  return <Box sx={{position: 'relative'}}>
    <ResponsiveHeader
      logo={<img src='/img/Logo-Bikenta-RSC@2x.png' alt='Bikenta_RSC' style={{margin: '0 20px', height: '80px'}}/>}
      sx={responsiveHeaderSx}
    >
      <IconButton aria-label="login" size='large' color='secondary' onClick={handleLogOut}>
        <Typography variant='paragraph' color='primary.grey'>{t('welcome')}</Typography>
        {
          currentPerformer ?
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', bgcolor: 'common.white', p: 1, ml: 2, borderRadius: 1}}>
              {
                currentPerformer.map(({performer}) =>
                  <img key={performer.id} src={performer.logo} alt={performer.name} height="60px" style={{margin: 'auto'}}/>)
              }
            </Box> : undefined
        }
      </IconButton>
    </ResponsiveHeader>
    <Main>
      {mainContent}
    </Main>
  </Box>;
};

export default Layout;
