import React, {CSSProperties, FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';

export interface MainCoverProps {
  image: string,
  mainTitle: string,
  mainText: string
}

const containerSx: SxProps = {
  display: 'block',
  position: 'relative',
  textAlign: 'center',
  width: '100vw',
  height: '85vh',
  overflow: 'hidden',
  boxShadow: 3,
  zIndex: 3000,
};

const imageStyle: CSSProperties =  {
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: 1000,
  filter: 'brightness(80%)'
};

const overlayTextSx: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs: '100%', md: '40%'},
  px: 4,
  zIndex: 1100,
  display: 'inline-block'
};

const expandSx: SxProps = {
  fontSize: '60px',
  height: '100px',
  color: 'common.white',
  position: 'absolute',
  top: '200%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  zIndex: 1100,
};

const MainCover: FC<MainCoverProps> = ({image, mainTitle, mainText}) => {
  return <Box sx={containerSx}>
    <img src={image} alt={mainTitle} style={imageStyle}/>
    <Box sx={overlayTextSx}>
      <Typography variant='title' align='center' color='common.white' gutterBottom>{mainTitle.toUpperCase()}</Typography>
      <Typography variant='paragraph' align='center' color='common.white'>{mainText}</Typography>
      <ExpandMoreIcon sx={expandSx}/>
    </Box>
  </Box>;
};

export default MainCover;