import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import Places from '@/components/admin/InitiativesSummary/Places';

import {SummaryInitiativeData} from '@/utils/getSummaryInitiativesData';

import {useTranslation} from 'react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

//TYPES
export type TotalInitiativesProps = {
  initiativesSummary: SummaryInitiativeData
};

const TotalInitiatives: FC<TotalInitiativesProps> = ({initiativesSummary}) => {
  
  const {t} = useTranslation();
  
  return <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} elevation={3}>
    <Typography variant="subtitle" align="center"
      sx={{mt: 4, mb: 2, fontWeight: 900, color: 'secondary.main'}}>{t('initiatives.summary.total').toUpperCase()}</Typography>
    <Box>
      <Places numberOfPlaces={initiativesSummary.initiativesNumber}/>
    </Box>
    <Box sx={{bgcolor: 'secondary.light', display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 1}}>
      <Typography variant="paragraphBold" textAlign='center' sx={{lineHeight: '52px'}}>
        {t('initiatives.area').toUpperCase()}
      </Typography>
      <Typography component="span" sx={{fontWeight: 900, color: 'primary.main', lineHeight: '52px'}}>
        {initiativesSummary.totalArea} ha
      </Typography>
    </Box>
  </Paper>;
};

export default TotalInitiatives;