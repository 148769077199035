import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {Resource} from '@/domain/entities/Initiative';
import Typography from '@mui/material/Typography';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useMediaQuery} from '@mui/material';

//TYPES
export type ResourceCardProps = {
  resource: Resource,
  background?: boolean,
  isDetail?: boolean
};

const ResourceData: FC<ResourceCardProps> = ({resource, background, isDetail=false}) => {

  const {title, text, image} = resource;
  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});
  
  const containerSx: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: isDetail ? 'flex-start' : 'center',
    width: '100%',
    borderRadius: 2,
    bgcolor: background ? 'secondary.light' : 'transparent',
    p: 4,
    maxHeight: isDetail ? undefined : 166,
    minHeight: isDetail ? undefined : 150
  };

  return <Box sx={containerSx}>
    <Box display='flex' flexDirection={isDetail ? 'column' : 'row'} sx={{gap: 2, alignItems: !widescreen ? 'center' : 'flex-start'}}>
      <img src={image} alt={title} height='134px' style={{width: 'auto'}}/>
      <Box display='flex' flexDirection='column'>
        <Typography textAlign={!widescreen? 'center' : 'left'} gutterBottom>{title}</Typography>
        <Typography textAlign={!widescreen? 'center' : 'left'} color="primary.grey" variant='paragraph'>{text}</Typography>
      </Box>
    </Box>  
  </Box> 
  ;
};
export default ResourceData;