import React, {FC} from 'react';

import {Navigate, Outlet} from 'react-router-dom';
import i18n from 'i18next';
import isLoggedUseCase from '@/domain/useCases/isLoggedUseCase';

const PrivateRoute: FC = () => {
  const isLogged = isLoggedUseCase();


  return isLogged ? <Outlet /> : <Navigate to={`/${i18n.resolvedLanguage}/login`} />;
};

export default PrivateRoute;