import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//UTILS
import {SUSTAINABILITYACTIONS} from '@/config';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {SustainabilityActionType} from '@/domain/entities/Initiative';
import {useMediaQuery} from '@mui/material';

//TYPES
export type SustainabilityActionsProps = {
  action: SustainabilityActionType,
  active: boolean
};

const SustainabilityAction: FC<SustainabilityActionsProps> = ({action, active}) => {

  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});
  const actionIcon = SUSTAINABILITYACTIONS.find(item => item.id === action.id)?.icon;

  const containerSx: SxProps = {
    position: 'relative',
    aspectRatio: widescreen ? '1' : undefined,
    bgcolor: active ? 'secondary.light': 'common.white',
    border: '2px solid #F6F4F0',
    width: widescreen ? 220 : '100%'
  };

  const wrapperGridSx: SxProps = {
    p: 1,
    height: '100%',
    display: 'grid',
    gridTemplateRows: '6fr 2fr',
    placeSelf: 'center',
    placeItems: 'center',
    filter: active ? undefined : 'grayscale(100%)',
    '& .MuiSvgIcon-root': {
      fontSize: 100
    }
  };

  const wrapperFlexSx: SxProps = {
    p: 1,
    display: 'flex',
    gap: 2,
    flexDirection: 'row',
    alignItems: 'center',
    filter: active ? undefined : 'grayscale(100%)',
    '& .MuiSvgIcon-root': {
      marginLeft: 1,
      fontSize: 28
    }
  };

  const imageStyle = {
    filter: active ? undefined : 'brightness(1.3)',
    marginRight: widescreen ? undefined : 2
  };

  const checkSx: SxProps = {
    position: widescreen ? 'absolute' : 'relative',
    top: widescreen ? '5%': undefined,
    right: widescreen ? '5%' : undefined
  };

  const labelSx: SxProps = {
    color: active ? 'common.black' : 'secondary.grey',
    lineHeight: 1.5,
    flexGrow: 1
  };
  
  return <Box sx={containerSx}>
    {
      widescreen && active && <CheckCircleIcon color='primary' fontSize='large' sx={checkSx}/>
    }
    <Box sx={widescreen ? wrapperGridSx : wrapperFlexSx}>
      <img src={`/img/${actionIcon}`} alt={action.name} width={widescreen ? '100px' : '70px'} style={imageStyle}/>
      <Typography variant='subtitle' align={widescreen ? 'center' : 'left'} sx={labelSx}>{action.name.toUpperCase()}</Typography>
      {
        !widescreen && active && <CheckCircleIcon color='primary' fontSize='large' sx={checkSx}/>
      }
    </Box>

  </Box>;
};
export default SustainabilityAction;