import React, {CSSProperties, FC} from 'react';
import {Testimony} from '@/domain/entities/Initiative';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useMediaQuery} from '@mui/material';

const headerSx: SxProps = {
  mt: 4,
  py: 3,
  gridArea: 'header',
  bgcolor: 'primary.main',
  outline: '1px solid primary.main'
};

const emptySx: SxProps = {
  py: 3,
  gridArea: 'empty',
  bgcolor: 'primary.main',
  outline: '1px solid primary.main'
};

const backgroundImage: SxProps = {
  py: 3,
  gridArea: 'image',
  bgcolor: 'primary.dark',
  outline: '1px solid #81a252'
};

const empty3Sx: SxProps = {
  py: 3,
  gridArea: 'empty3',
  bgcolor: 'primary.dark',
  outline: '1px solid #81a252'
};

const titleSx: SxProps = {
  pr: 1,
  gridArea: 'title',
  bgcolor: 'primary.main',
  outline: '1px solid primary.main'
};



//TYPES
export type TestimonyItemProps = {
  testimony: Testimony
};

const TestimonyItem: FC<TestimonyItemProps> = ({testimony}) => {

  const {t} = useTranslation();
  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});
  const {title, subtitle, text, image} = testimony;

  const imageStyle: CSSProperties = {
    filter: 'grayscale(100%)',
    maxWidth: widescreen ? '200px' : '100%',
    position: 'absolute',
    right: 10,
    bottom: 0,
    margin: 0,
    padding: 0,
    float: 'right'
  };
  
  const constainerSx: SxProps = {
    width: '100vw',
    display: 'grid',
    gridTemplateAreas: widescreen ? `
    'header header header header' 'empty empty title title' 'image image text empty3'
    `:`
    'header header' 'empty title' 'image text'
    `,
    gridTemplateColumns: widescreen ? '1fr 1fr minmax(0, 2fr) 1fr' : '2fr minmax(0, 4fr)',
    gridTemplateRows: '1fr min-content auto'
  };


  const textSx: SxProps = {
    py: 2,
    pr: 1,
    bgcolor: 'primary.dark',
    fontStyle: 'italic',
    color: 'secondary.light',
    gridArea: 'text'
  };

  return <Box sx={constainerSx}>
    <Typography variant="title" textAlign="center" color="secondary.main" sx={headerSx}>{t('testimony').toUpperCase()}</Typography>
    <Box display="flex" flexDirection="column" sx={titleSx}>
      <Typography variant="subtitle" color="secondary.main">{title}</Typography>
      <Typography color="secondary.main">{subtitle}</Typography>
    </Box>
    <Box display="flex" flexDirection="column" sx={{justifyContent: 'flex-end', gridArea: 'image', position: 'relative', width: '100%'}}>
      <img src={image} alt={title} style={imageStyle}/>
    </Box>
    <Typography color="primary.grey" sx={textSx}>{text}</Typography>

    <Box sx={emptySx}/>
    <Box sx={backgroundImage}/>
    {widescreen && <Box sx={empty3Sx}/>}
  </Box>;
};
export default TestimonyItem;
