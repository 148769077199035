import React, {CSSProperties, FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//UTILS
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {EnvironmentalImpact} from '@/domain/entities/Initiative';

//TYPES
export type FaunaProps = {
  environmentalImpacts: Array<EnvironmentalImpact>,
  isHorizontal?: boolean
};

const Fauna: FC<FaunaProps> = ({environmentalImpacts, isHorizontal=true}) => {

  const impactsInPhrase = environmentalImpacts
    .map((impact, index) => index === 0 ? 
      impact.name.charAt(0).toUpperCase() + impact.name.slice(1) 
      : impact.name
    ).join(', ');
  
  const impactsInWords = environmentalImpacts
    .map(impact => impact.name.charAt(0).toUpperCase() + impact.name.slice(1))
    .join(' ');

  const containerSx: SxProps = {
    display: 'flex',
    flexDirection: isHorizontal ? 'row' : 'column',
    alignItems: isHorizontal ? 'flex-end' : 'center',
    gap: isHorizontal ? 2 : 3
  };

  const wrapperSx: SxProps = {
    display: 'flex',
    flexDirection: isHorizontal ? 'row' : 'column',
    alignItems: isHorizontal ? 'flex-end' : 'center',
    gap: isHorizontal ? 1 : 0,
    flexWrap: 'wrap',
    width: isHorizontal ? 'auto' : '100px'
  };

  const imageStyle: CSSProperties = {
    width: isHorizontal ? '50px' : '75px'
  };

  return <Box sx={containerSx}>
    {
      environmentalImpacts?.length ? <>
        <img src="/img/mariposa.png" alt={''} style={imageStyle}/>
        <Box sx={wrapperSx}>
          <Typography variant="paragraph" textAlign={isHorizontal ? 'left' : 'center'}
            sx={{color: isHorizontal ? 'primary.grey' : 'common.white'}}>
            {isHorizontal ? impactsInPhrase : impactsInWords}
          </Typography>
        </Box>
      </> : undefined
    }
  </Box>;
};
export default Fauna;