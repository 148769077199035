import React, {FC, SVGProps} from 'react';

//From ic-arbol-bikenta.svg
const IsotipoBikenta: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 808.108 711.158"
    height="100%"
    {...props}
  >
    <path fill="#CDCCCC" d="M369.717 435.702h22.892v22.891h-22.892z"/>
    <path
      fill="#CDCCCC"
      d="M392.608 435.702h22.891v22.891h-22.891zM415.499 435.702h22.893v22.891h-22.893z"
    />
    <path
      fill="#696862"
      d="M438.392 435.702h22.891v22.891h-22.891zM461.284 435.702h22.893v22.891h-22.893z"
    />
    <path fill="#303220" d="M369.717 458.593h22.892v22.893h-22.892z"/>
    <path
      fill="#36321E"
      d="M392.608 458.593h22.891v22.893h-22.891zM415.499 458.593h22.893v22.893h-22.893z"
    />
    <path
      fill="#696862"
      d="M438.392 458.593h22.891v22.893h-22.891zM461.284 458.593h22.893v22.893h-22.893z"
    />
    <path fill="#2E4029" d="M369.717 481.485h22.892v22.893h-22.892z"/>
    <path
      fill="#36321E"
      d="M392.608 481.485h22.891v22.893h-22.891zM415.499 481.485h22.893v22.893h-22.893z"
    />
    <path fill="#696862" d="M438.392 481.485h22.891v22.893h-22.891z"/>
    <path fill="#817F70" d="M461.284 481.485h22.893v22.893h-22.893z"/>
    <path fill="#2E4029" d="M369.717 504.378h22.892v22.891h-22.892z"/>
    <path
      fill="#36321E"
      d="M392.608 504.378h22.891v22.891h-22.891zM415.499 504.378h22.893v22.891h-22.893z"
    />
    <path fill="#696862" d="M438.392 504.378h22.891v22.891h-22.891z"/>
    <path fill="#817F70" d="M461.284 504.378h22.893v22.891h-22.893z"/>
    <path fill="#2E4029" d="M369.717 527.269h22.892v22.893h-22.892z"/>
    <path fill="#36321E" d="M392.608 527.269h22.891v22.893h-22.891z"/>
    <path fill="#2E4029" d="M415.499 527.269h22.893v22.893h-22.893z"/>
    <path
      fill="#696862"
      d="M438.392 527.269h22.891v22.893h-22.891zM461.284 527.269h22.893v22.893h-22.893z"
    />
    <path fill="#2E4029" d="M369.717 550.161h22.892v22.893h-22.892z"/>
    <path fill="#36321E" d="M392.608 550.161h22.891v22.893h-22.891z"/>
    <path fill="#0F7540" d="M415.499 550.161h22.893v22.893h-22.893z"/>
    <path fill="#0F7540" d="M438.392 550.161h22.891v22.893h-22.891z"/>
    <path fill="#696862" d="M461.284 550.161h22.893v22.893h-22.893z"/>
    <path fill="#80A82B" d="M301.04 573.054h22.892v22.893H301.04z"/>
    <path fill="#35471D" d="M323.931 573.054h22.892v22.893h-22.892z"/>
    <path fill="#80A82B" d="M346.824 573.054h22.893v22.893h-22.893z"/>
    <path fill="#81B829" d="M369.717 573.054h22.892v22.893h-22.892z"/>
    <path fill="#80A82B" d="M392.608 573.054h22.891v22.893h-22.891z"/>
    <path fill="#81B829" d="M415.499 573.054h22.893v22.893h-22.893z"/>
    <path fill="#0F7540" d="M438.392 573.054h22.891v22.893h-22.891z"/>
    <path fill="#80A82B" d="M461.284 573.054h22.893v22.893h-22.893z"/>
    <path fill="#81B829" d="M484.177 573.054h22.891v22.893h-22.891z"/>
    <path fill="#A7C56C" d="M507.068 573.054h22.893v22.893h-22.893z"/>
    <path fill="#81B829" d="M529.96 573.054h22.893v22.893H529.96z"/>
    <path fill="#E4EACD" d="M552.853 573.054h22.891v22.893h-22.891z"/>
    <path
      fill="#81B829"
      d="M438.392 115.212h22.891v22.892h-22.891zM507.068 115.212h22.893v22.892h-22.893zM575.743 115.212h22.893v22.892h-22.893zM461.284 138.104h22.893v22.892h-22.893zM484.177 138.104h22.891v22.892h-22.891zM529.96 138.104h22.893v22.892H529.96z"
    />
    <path
      fill="#81B829"
      d="M552.853 138.104h22.891v22.892h-22.891zM392.608 160.997h22.891v22.892h-22.891z"
    />
    <path fill="#80A82B" d="M438.392 160.997h22.891v22.892h-22.891z"/>
    <path
      fill="#81B829"
      d="M461.284 160.997h22.893v22.892h-22.893zM507.068 160.997h22.893v22.892h-22.893z"
    />
    <path fill="#0F7540" d="M529.96 160.997h22.893v22.892H529.96z"/>
    <path
      fill="#81B829"
      d="M598.636 160.997h22.893v22.892h-22.893zM621.529 160.997h22.893v22.892h-22.893zM369.717 183.888h22.892v22.893h-22.892z"
    />
    <path fill="#81B829" d="M392.608 183.888h22.891v22.893h-22.891z"/>
    <path fill="#80A82B" d="M438.392 183.888h22.891v22.893h-22.891z"/>
    <path fill="#81B829" d="M484.177 183.888h22.891v22.893h-22.891z"/>
    <path fill="#81B829" d="M507.068 183.888h22.893v22.893h-22.893z"/>
    <path fill="#80A82B" d="M552.853 183.888h22.891v22.893h-22.891z"/>
    <path fill="#80A82B" d="M575.743 183.888h22.893v22.893h-22.893z"/>
    <path fill="#81B829" d="M415.499 206.781h22.893v22.892h-22.893z"/>
    <path
      fill="#81B829"
      d="M438.392 206.781h22.891v22.892h-22.891zM507.068 206.781h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M529.96 206.781h22.893v22.892H529.96z"/>
    <path fill="#0F7540" d="M552.853 206.781h22.891v22.892h-22.891z"/>
    <path
      fill="#80A82B"
      d="M598.636 206.781h22.893v22.892h-22.893zM621.529 206.781h22.893v22.892h-22.893z"
    />
    <path
      fill="#81B829"
      d="M346.824 229.672h22.893v22.892h-22.893zM369.717 229.672h22.892v22.892h-22.892z"
    />
    <path
      fill="#81B829"
      d="M392.608 229.672h22.891v22.892h-22.891zM438.392 229.672h22.891v22.892h-22.891zM461.284 229.672h22.893v22.892h-22.893z"
    />
    <path fill="#80A82B" d="M507.068 229.672h22.893v22.892h-22.893z"/>
    <path
      fill="#0F7540"
      d="M529.96 229.672h22.893v22.892H529.96zM575.743 229.672h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M644.421 229.672h22.891v22.892h-22.891z"/>
    <path
      fill="#80A82B"
      d="M346.824 252.566h22.893v22.892h-22.893zM392.608 252.566h22.891v22.892h-22.891zM461.284 252.566h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M484.177 252.566h22.891v22.892h-22.891z"/>
    <path fill="#35471D" d="M507.068 252.566h22.893v22.892h-22.893z"/>
    <path fill="#0F7540" d="M552.853 252.566h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M575.743 252.566h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M598.636 252.566h22.893v22.892h-22.893z"/>
    <path
      fill="#81B829"
      d="M667.312 252.566h22.893v22.892h-22.893zM346.824 275.457h22.893v22.892h-22.893z"
    />
    <path fill="#80A82B" d="M392.608 275.457h22.891v22.892h-22.891z"/>
    <path
      fill="#81B829"
      d="M415.499 275.457h22.893v22.892h-22.893zM461.284 275.457h22.893v22.892h-22.893z"
    />
    <path fill="#0F7540" d="M484.177 275.457h22.891v22.892h-22.891z"/>
    <path fill="#80A82B" d="M507.068 275.457h22.893v22.892h-22.893z"/>
    <path
      fill="#0F7540"
      d="M529.96 275.457h22.893v22.892H529.96zM621.529 275.457h22.893v22.892h-22.893z"
    />
    <path
      fill="#80A82B"
      d="M644.421 275.457h22.891v22.892h-22.891zM346.824 298.348h22.893v22.892h-22.893z"
    />
    <path
      fill="#81B829"
      d="M369.717 298.348h22.892v22.892h-22.892zM438.392 298.348h22.891v22.892h-22.891z"
    />
    <path fill="#80A82B" d="M484.177 298.348h22.891v22.892h-22.891z"/>
    <path fill="#81B829" d="M507.068 298.348h22.893v22.892h-22.893z"/>
    <path
      fill="#0F7540"
      d="M552.853 298.348h22.891v22.892h-22.891zM598.636 298.348h22.893v22.892h-22.893z"
    />
    <path
      fill="#80A82B"
      d="M644.421 298.348h22.891v22.892h-22.891zM369.717 321.242h22.892v22.892h-22.892z"
    />
    <path
      fill="#81B829"
      d="M392.608 321.242h22.891v22.892h-22.891zM415.499 321.242h22.893v22.892h-22.893zM461.284 321.242h22.893v22.892h-22.893z"
    />
    <path fill="#0F7540" d="M484.177 321.242h22.891v22.892h-22.891z"/>
    <path fill="#80A82B" d="M507.068 321.242h22.893v22.892h-22.893z"/>
    <path fill="#81B829" d="M529.96 321.242h22.893v22.892H529.96z"/>
    <path fill="#81B829" d="M552.853 321.242h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M575.743 321.242h22.893v22.892h-22.893z"/>
    <path fill="#0F7540" d="M598.636 321.242h22.893v22.892h-22.893z"/>
    <path
      fill="#80A82B"
      d="M369.717 344.132h22.892v22.892h-22.892zM415.499 344.132h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M438.392 344.132h22.891v22.892h-22.891z"/>
    <path fill="#80A82B" d="M484.177 344.132h22.891v22.892h-22.891z"/>
    <path fill="#35471D" d="M507.068 344.132h22.893v22.892h-22.893z"/>
    <path fill="#0F7540" d="M552.853 344.132h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M575.743 344.132h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M346.824 367.026h22.893v22.891h-22.893z"/>
    <path fill="#81B829" d="M392.608 367.026h22.891v22.891h-22.891z"/>
    <path fill="#0F7540" d="M484.177 367.026h22.891v22.891h-22.891z"/>
    <path fill="#81B829" d="M507.068 367.026h22.893v22.891h-22.893z"/>
    <path fill="#0F7540" d="M529.96 367.026h22.893v22.891H529.96z"/>
    <path
      fill="#80A82B"
      d="M598.636 367.026h22.893v22.891h-22.893zM621.529 367.026h22.893v22.891h-22.893z"
    />
    <path fill="#81B829" d="M484.177 389.917h22.891v22.893h-22.891z"/>
    <path fill="#35471D" d="M507.068 389.917h22.893v22.893h-22.893z"/>
    <path fill="#0F7540" d="M552.853 389.917h22.891v22.893h-22.891z"/>
    <path
      fill="#81B829"
      d="M461.284 412.81h22.893v22.893h-22.893zM484.177 412.81h22.891v22.893h-22.891z"
    />
    <path fill="#81B829" d="M507.068 412.81h22.893v22.893h-22.893z"/>
    <path fill="#0F7540" d="M552.853 412.81h22.891v22.893h-22.891z"/>
    <path fill="#0F7540" d="M575.743 412.81h22.893v22.893h-22.893z"/>
    <path fill="#81B829" d="M484.177 435.702h22.891v22.891h-22.891z"/>
    <path
      fill="#81B829"
      d="M507.068 435.702h22.893v22.891h-22.893zM301.04 115.212h22.892v22.892H301.04zM369.717 115.212h22.892v22.892h-22.892zM438.392 115.212h22.891v22.892h-22.891zM323.931 138.104h22.892v22.892h-22.892zM346.824 138.104h22.893v22.892h-22.893zM392.608 138.104h22.891v22.892h-22.891zM415.499 138.104h22.893v22.892h-22.893zM255.257 160.997h22.892v22.892h-22.892z"
    />
    <path fill="#80A82B" d="M301.04 160.997h22.892v22.892H301.04z"/>
    <path
      fill="#81B829"
      d="M323.931 160.997h22.892v22.892h-22.892zM369.717 160.997h22.892v22.892h-22.892z"
    />
    <path fill="#0F7540" d="M392.608 160.997h22.891v22.892h-22.891z"/>
    <path
      fill="#81B829"
      d="M461.284 160.997h22.893v22.892h-22.893zM484.177 160.997h22.891v22.892h-22.891zM232.364 183.888h22.893v22.893h-22.893zM255.257 183.888h22.892v22.893h-22.892z"
    />
    <path fill="#80A82B" d="M301.04 183.888h22.892v22.893H301.04z"/>
    <path
      fill="#81B829"
      d="M346.824 183.888h22.893v22.893h-22.893zM369.717 183.888h22.892v22.893h-22.892z"
    />
    <path fill="#80A82B" d="M415.499 183.888h22.893v22.893h-22.893z"/>
    <path fill="#80A82B" d="M438.392 183.888h22.891v22.893h-22.891z"/>
    <path
      fill="#81B829"
      d="M278.147 206.781h22.893v22.892h-22.893zM301.04 206.781h22.892v22.892H301.04zM369.717 206.781h22.892v22.892h-22.892z"
    />
    <path fill="#81B829" d="M392.608 206.781h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M415.499 206.781h22.893v22.892h-22.893z"/>
    <path
      fill="#80A82B"
      d="M461.284 206.781h22.893v22.892h-22.893zM484.177 206.781h22.891v22.892h-22.891z"
    />
    <path
      fill="#81B829"
      d="M209.472 229.672h22.892v22.892h-22.892zM232.364 229.672h22.893v22.892h-22.893zM255.257 229.672h22.892v22.892h-22.892zM301.04 229.672h22.892v22.892H301.04z"
    />
    <path fill="#81B829" d="M323.931 229.672h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M369.717 229.672h22.892v22.892h-22.892z"/>
    <path
      fill="#0F7540"
      d="M392.608 229.672h22.891v22.892h-22.891zM438.392 229.672h22.891v22.892h-22.891z"
    />
    <path fill="#81B829" d="M507.068 229.672h22.893v22.892h-22.893z"/>
    <path
      fill="#80A82B"
      d="M209.472 252.566h22.892v22.892h-22.892zM255.257 252.566h22.892v22.892h-22.892zM323.931 252.566h22.892v22.892h-22.892z"
    />
    <path fill="#81B829" d="M346.824 252.566h22.893v22.892h-22.893z"/>
    <path fill="#35471D" d="M369.717 252.566h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M415.499 252.566h22.893v22.892h-22.893z"/>
    <path fill="#0F7540" d="M438.392 252.566h22.891v22.892h-22.891z"/>
    <path fill="#80A82B" d="M461.284 252.566h22.893v22.892h-22.893z"/>
    <path
      fill="#81B829"
      d="M529.96 252.566h22.893v22.892H529.96zM209.472 275.457h22.892v22.892h-22.892z"
    />
    <path fill="#80A82B" d="M255.257 275.457h22.892v22.892h-22.892z"/>
    <path
      fill="#81B829"
      d="M278.147 275.457h22.893v22.892h-22.893zM323.931 275.457h22.892v22.892h-22.892z"
    />
    <path fill="#0F7540" d="M346.824 275.457h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M369.717 275.457h22.892v22.892h-22.892z"/>
    <path
      fill="#0F7540"
      d="M392.608 275.457h22.891v22.892h-22.891zM484.177 275.457h22.891v22.892h-22.891z"
    />
    <path
      fill="#80A82B"
      d="M507.068 275.457h22.893v22.892h-22.893zM209.472 298.348h22.892v22.892h-22.892z"
    />
    <path
      fill="#81B829"
      d="M232.364 298.348h22.893v22.892h-22.893zM301.04 298.348h22.892v22.892H301.04z"
    />
    <path fill="#80A82B" d="M346.824 298.348h22.893v22.892h-22.893z"/>
    <path fill="#81B829" d="M369.717 298.348h22.892v22.892h-22.892z"/>
    <path
      fill="#0F7540"
      d="M415.499 298.348h22.893v22.892h-22.893zM461.284 298.348h22.893v22.892h-22.893z"
    />
    <path
      fill="#80A82B"
      d="M507.068 298.348h22.893v22.892h-22.893zM232.364 321.242h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M255.257 321.242h22.892v22.892h-22.892z"/>
    <path
      fill="#81B829"
      d="M278.147 321.242h22.893v22.892h-22.893zM323.931 321.242h22.892v22.892h-22.892z"
    />
    <path fill="#0F7540" d="M346.824 321.242h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M369.717 321.242h22.892v22.892h-22.892z"/>
    <path
      fill="#81B829"
      d="M392.608 321.242h22.891v22.892h-22.891zM415.499 321.242h22.893v22.892h-22.893z"
    />
    <path
      fill="#0F7540"
      d="M438.392 321.242h22.891v22.892h-22.891zM461.284 321.242h22.893v22.892h-22.893z"
    />
    <path
      fill="#80A82B"
      d="M232.364 344.132h22.893v22.892h-22.893zM278.147 344.132h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M301.04 344.132h22.892v22.892H301.04z"/>
    <path fill="#80A82B" d="M346.824 344.132h22.893v22.892h-22.893z"/>
    <path fill="#35471D" d="M369.717 344.132h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M415.499 344.132h22.893v22.892h-22.893z"/>
    <path fill="#0F7540" d="M438.392 344.132h22.891v22.892h-22.891z"/>
    <path fill="#80A82B" d="M209.472 367.026h22.892v22.891h-22.892z"/>
    <path fill="#81B829" d="M255.257 367.026h22.892v22.891h-22.892z"/>
    <path fill="#0F7540" d="M346.824 367.026h22.893v22.891h-22.893z"/>
    <path fill="#81B829" d="M369.717 367.026h22.892v22.891h-22.892z"/>
    <path fill="#0F7540" d="M392.608 367.026h22.891v22.891h-22.891z"/>
    <path
      fill="#80A82B"
      d="M461.284 367.026h22.893v22.891h-22.893zM484.177 367.026h22.891v22.891h-22.891z"
    />
    <path fill="#81B829" d="M346.824 389.917h22.893v22.893h-22.893z"/>
    <path fill="#35471D" d="M369.717 389.917h22.892v22.893h-22.892z"/>
    <path fill="#0F7540" d="M415.499 389.917h22.893v22.893h-22.893z"/>
    <path
      fill="#81B829"
      d="M323.931 412.81h22.892v22.893h-22.892zM346.824 412.81h22.893v22.893h-22.893zM369.717 412.81h22.892v22.893h-22.892z"
    />
    <path fill="#0F7540" d="M415.499 412.81h22.893v22.893h-22.893z"/>
    <path fill="#0F7540" d="M438.392 412.81h22.891v22.893h-22.891z"/>
    <path
      fill="#81B829"
      d="M346.824 435.702h22.893v22.891h-22.893zM369.717 435.702h22.892v22.891h-22.892zM552.853 435.702h22.891v22.891h-22.891zM484.177 435.702h22.891v22.891h-22.891zM415.499 435.702h22.893v22.891h-22.893z"
    />
    <path fill="#81B829" d="M529.96 412.81h22.893v22.893H529.96z"/>
    <path
      fill="#81B829"
      d="M507.068 412.81h22.893v22.893h-22.893zM461.284 412.81h22.893v22.893h-22.893zM438.392 412.81h22.891v22.893h-22.891zM598.636 389.917h22.893v22.893h-22.893z"
    />
    <path fill="#80A82B" d="M552.853 389.917h22.891v22.893h-22.891z"/>
    <path
      fill="#81B829"
      d="M529.96 389.917h22.893v22.893H529.96zM484.177 389.917h22.891v22.893h-22.891z"
    />
    <path fill="#0F7540" d="M461.284 389.917h22.893v22.893h-22.893z"/>
    <path fill="#81B829" d="M392.608 389.917h22.891v22.893h-22.891z"/>
    <path
      fill="#81B829"
      d="M369.717 389.917h22.892v22.893h-22.892zM621.529 367.026h22.893v22.891h-22.893zM621.529 344.132h22.893v22.892h-22.893zM598.636 367.026h22.893v22.891h-22.893z"
    />
    <path fill="#80A82B" d="M552.853 367.026h22.891v22.891h-22.891z"/>
    <path fill="#81B829" d="M507.068 367.026h22.893v22.891h-22.893z"/>
    <path fill="#81B829" d="M484.177 367.026h22.891v22.891h-22.891z"/>
    <path fill="#80A82B" d="M438.392 367.026h22.891v22.891h-22.891z"/>
    <path fill="#80A82B" d="M415.499 367.026h22.893v22.891h-22.893z"/>
    <path fill="#81B829" d="M575.743 344.132h22.893v22.892h-22.893z"/>
    <path
      fill="#81B829"
      d="M552.853 344.132h22.891v22.892h-22.891zM484.177 344.132h22.891v22.892h-22.891zM461.284 344.132h22.893v22.892h-22.893z"
    />
    <path fill="#0F7540" d="M438.392 344.132h22.891v22.892h-22.891z"/>
    <path fill="#80A82B" d="M392.608 344.132h22.891v22.892h-22.891z"/>
    <path fill="#80A82B" d="M369.717 344.132h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M644.421 321.242h22.891v22.892h-22.891z"/>
    <path
      fill="#81B829"
      d="M621.529 321.242h22.893v22.892h-22.893zM598.636 321.242h22.893v22.892h-22.893zM552.853 321.242h22.891v22.892h-22.891z"
    />
    <path fill="#81B829" d="M529.96 321.242h22.893v22.892H529.96z"/>
    <path fill="#80A82B" d="M484.177 321.242h22.891v22.892h-22.891z"/>
    <path
      fill="#0F7540"
      d="M461.284 321.242h22.893v22.892h-22.893zM415.499 321.242h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M346.824 321.242h22.893v22.892h-22.893z"/>
    <path
      fill="#80A82B"
      d="M644.421 298.348h22.891v22.892h-22.891zM598.636 298.348h22.893v22.892h-22.893zM529.96 298.348h22.893v22.892H529.96z"
    />
    <path fill="#81B829" d="M507.068 298.348h22.893v22.892h-22.893z"/>
    <path fill="#35471D" d="M484.177 298.348h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M438.392 298.348h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M415.499 298.348h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M392.608 298.348h22.891v22.892h-22.891z"/>
    <path
      fill="#81B829"
      d="M323.931 298.348h22.892v22.892h-22.892zM644.421 275.457h22.891v22.892h-22.891z"
    />
    <path fill="#80A82B" d="M598.636 275.457h22.893v22.892h-22.893z"/>
    <path
      fill="#81B829"
      d="M575.743 275.457h22.893v22.892h-22.893zM529.96 275.457h22.893v22.892H529.96z"
    />
    <path fill="#0F7540" d="M507.068 275.457h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M484.177 275.457h22.891v22.892h-22.891z"/>
    <path
      fill="#0F7540"
      d="M461.284 275.457h22.893v22.892h-22.893zM369.717 275.457h22.892v22.892h-22.892z"
    />
    <path
      fill="#80A82B"
      d="M346.824 275.457h22.893v22.892h-22.893zM644.421 252.566h22.891v22.892h-22.891z"
    />
    <path
      fill="#81B829"
      d="M621.529 252.566h22.893v22.892h-22.893zM552.853 252.566h22.891v22.892h-22.891z"
    />
    <path fill="#80A82B" d="M507.068 252.566h22.893v22.892h-22.893z"/>
    <path fill="#81B829" d="M484.177 252.566h22.891v22.892h-22.891z"/>
    <path
      fill="#0F7540"
      d="M438.392 252.566h22.891v22.892h-22.891zM392.608 252.566h22.891v22.892h-22.891z"
    />
    <path
      fill="#80A82B"
      d="M346.824 252.566h22.893v22.892h-22.893zM621.529 229.672h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M598.636 229.672h22.893v22.892h-22.893z"/>
    <path
      fill="#81B829"
      d="M575.743 229.672h22.893v22.892h-22.893zM529.96 229.672h22.893v22.892H529.96z"
    />
    <path fill="#0F7540" d="M507.068 229.672h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M484.177 229.672h22.891v22.892h-22.891z"/>
    <path
      fill="#81B829"
      d="M461.284 229.672h22.893v22.892h-22.893zM438.392 229.672h22.891v22.892h-22.891z"
    />
    <path
      fill="#0F7540"
      d="M415.499 229.672h22.893v22.892h-22.893zM392.608 229.672h22.891v22.892h-22.891z"
    />
    <path
      fill="#80A82B"
      d="M621.529 206.781h22.893v22.892h-22.893zM575.743 206.781h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M552.853 206.781h22.891v22.892h-22.891z"/>
    <path fill="#80A82B" d="M507.068 206.781h22.893v22.892h-22.893z"/>
    <path fill="#35471D" d="M484.177 206.781h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M438.392 206.781h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M415.499 206.781h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M644.421 183.888h22.891v22.893h-22.891z"/>
    <path fill="#81B829" d="M598.636 183.888h22.893v22.893h-22.893z"/>
    <path fill="#0F7540" d="M507.068 183.888h22.893v22.893h-22.893z"/>
    <path fill="#81B829" d="M484.177 183.888h22.891v22.893h-22.891z"/>
    <path fill="#0F7540" d="M461.284 183.888h22.893v22.893h-22.893z"/>
    <path fill="#80A82B" d="M392.608 183.888h22.891v22.893h-22.891z"/>
    <path fill="#80A82B" d="M369.717 183.888h22.892v22.893h-22.892z"/>
    <path fill="#81B829" d="M507.068 160.997h22.893v22.892h-22.893z"/>
    <path fill="#35471D" d="M484.177 160.997h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M438.392 160.997h22.891v22.892h-22.891z"/>
    <path fill="#81B829" d="M529.96 138.104h22.893v22.892H529.96z"/>
    <path fill="#81B829" d="M507.068 138.104h22.893v22.892h-22.893z"/>
    <path fill="#81B829" d="M484.177 138.104h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M438.392 138.104h22.891v22.892h-22.891z"/>
    <path fill="#0F7540" d="M415.499 138.104h22.893v22.892h-22.893z"/>
    <path fill="#81B829" d="M507.068 115.212h22.893v22.892h-22.893z"/>
    <path
      fill="#81B829"
      d="M484.177 115.212h22.891v22.892h-22.891zM415.499 435.702h22.893v22.891h-22.893zM346.824 435.702h22.893v22.891h-22.893zM278.147 435.702h22.893v22.891h-22.893zM392.608 412.81h22.891v22.893h-22.891z"
    />
    <path
      fill="#81B829"
      d="M369.717 412.81h22.892v22.893h-22.892zM323.931 412.81h22.892v22.893h-22.892z"
    />
    <path
      fill="#81B829"
      d="M301.04 412.81h22.892v22.893H301.04zM461.284 389.917h22.893v22.893h-22.893z"
    />
    <path fill="#80A82B" d="M415.499 389.917h22.893v22.893h-22.893z"/>
    <path
      fill="#81B829"
      d="M392.608 389.917h22.891v22.893h-22.891zM346.824 389.917h22.893v22.893h-22.893z"
    />
    <path fill="#0F7540" d="M323.931 389.917h22.892v22.893h-22.892z"/>
    <path
      fill="#81B829"
      d="M255.257 389.917h22.892v22.893h-22.892zM232.364 389.917h22.893v22.893h-22.893zM484.177 367.026h22.891v22.891h-22.891zM461.284 367.026h22.893v22.891h-22.893z"
    />
    <path fill="#80A82B" d="M415.499 367.026h22.893v22.891h-22.893z"/>
    <path
      fill="#81B829"
      d="M369.717 367.026h22.892v22.891h-22.892zM346.824 367.026h22.893v22.891h-22.893z"
    />
    <path
      fill="#80A82B"
      d="M301.04 367.026h22.892v22.891H301.04zM278.147 367.026h22.893v22.891h-22.893z"
    />
    <path fill="#81B829" d="M438.392 344.132h22.891v22.892h-22.891z"/>
    <path
      fill="#81B829"
      d="M415.499 344.132h22.893v22.892h-22.893zM346.824 344.132h22.893v22.892h-22.893zM323.931 344.132h22.892v22.892h-22.892z"
    />
    <path fill="#0F7540" d="M301.04 344.132h22.892v22.892H301.04z"/>
    <path
      fill="#80A82B"
      d="M255.257 344.132h22.892v22.892h-22.892zM232.364 344.132h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M507.068 321.242h22.893v22.892h-22.893z"/>
    <path
      fill="#81B829"
      d="M484.177 321.242h22.891v22.892h-22.891zM461.284 321.242h22.893v22.892h-22.893zM415.499 321.242h22.893v22.892h-22.893zM392.608 321.242h22.891v22.892h-22.891z"
    />
    <path fill="#80A82B" d="M346.824 321.242h22.893v22.892h-22.893z"/>
    <path
      fill="#0F7540"
      d="M323.931 321.242h22.892v22.892h-22.892zM278.147 321.242h22.893v22.892h-22.893z"
    />
    <path fill="#81B829" d="M209.472 321.242h22.892v22.892h-22.892z"/>
    <path
      fill="#80A82B"
      d="M507.068 298.348h22.893v22.892h-22.893zM461.284 298.348h22.893v22.892h-22.893zM392.608 298.348h22.891v22.892h-22.891z"
    />
    <path fill="#81B829" d="M369.717 298.348h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M346.824 298.348h22.893v22.892h-22.893z"/>
    <path
      fill="#0F7540"
      d="M301.04 298.348h22.892v22.892H301.04zM278.147 298.348h22.893v22.892h-22.893z"
    />
    <path fill="#80A82B" d="M255.257 298.348h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M186.58 298.348h22.892v22.892H186.58z"/>
    <path fill="#A7C56C" d="M507.068 275.457h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M461.284 275.457h22.893v22.892h-22.893z"/>
    <path
      fill="#81B829"
      d="M438.392 275.457h22.891v22.892h-22.891zM392.608 275.457h22.891v22.892h-22.891z"
    />
    <path fill="#0F7540" d="M369.717 275.457h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M346.824 275.457h22.893v22.892h-22.893z"/>
    <path
      fill="#0F7540"
      d="M323.931 275.457h22.892v22.892h-22.892zM232.364 275.457h22.893v22.892h-22.893z"
    />
    <path
      fill="#80A82B"
      d="M209.472 275.457h22.892v22.892h-22.892zM507.068 252.566h22.893v22.892h-22.893z"
    />
    <path
      fill="#81B829"
      d="M484.177 252.566h22.891v22.892h-22.891zM415.499 252.566h22.893v22.892h-22.893z"
    />
    <path fill="#80A82B" d="M369.717 252.566h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M346.824 252.566h22.893v22.892h-22.893z"/>
    <path
      fill="#0F7540"
      d="M301.04 252.566h22.892v22.892H301.04zM255.257 252.566h22.892v22.892h-22.892z"
    />
    <path
      fill="#80A82B"
      d="M209.472 252.566h22.892v22.892h-22.892zM484.177 229.672h22.891v22.892h-22.891z"
    />
    <path
      fill="#81B829"
      d="M461.284 229.672h22.893v22.892h-22.893zM438.392 229.672h22.891v22.892h-22.891zM392.608 229.672h22.891v22.892h-22.891z"
    />
    <path fill="#0F7540" d="M369.717 229.672h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M346.824 229.672h22.893v22.892h-22.893z"/>
    <path fill="#81B829" d="M323.931 229.672h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M301.04 229.672h22.892v22.892H301.04z"/>
    <path fill="#0F7540" d="M278.147 229.672h22.893v22.892h-22.893z"/>
    <path fill="#0F7540" d="M255.257 229.672h22.892v22.892h-22.892z"/>
    <path
      fill="#80A82B"
      d="M484.177 206.781h22.891v22.892h-22.891zM438.392 206.781h22.891v22.892h-22.891z"
    />
    <path fill="#81B829" d="M415.499 206.781h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M369.717 206.781h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M346.824 206.781h22.893v22.892h-22.893z"/>
    <path
      fill="#0F7540"
      d="M301.04 206.781h22.892v22.892H301.04zM278.147 206.781h22.893v22.892h-22.893z"
    />
    <path fill="#80A82B" d="M507.068 183.888h22.893v22.893h-22.893z"/>
    <path fill="#81B829" d="M461.284 183.888h22.893v22.893h-22.893z"/>
    <path fill="#0F7540" d="M369.717 183.888h22.892v22.893h-22.892z"/>
    <path fill="#81B829" d="M346.824 183.888h22.893v22.893h-22.893z"/>
    <path fill="#0F7540" d="M323.931 183.888h22.892v22.893h-22.892z"/>
    <path
      fill="#80A82B"
      d="M255.257 183.888h22.892v22.893h-22.892zM232.364 183.888h22.893v22.893h-22.893z"
    />
    <path fill="#81B829" d="M369.717 160.997h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M346.824 160.997h22.893v22.892h-22.893z"/>
    <path fill="#0F7540" d="M301.04 160.997h22.892v22.892H301.04z"/>
    <path fill="#81B829" d="M392.608 138.104h22.891v22.892h-22.891z"/>
    <path fill="#A7C56C" d="M369.717 138.104h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M346.824 138.104h22.893v22.892h-22.893z"/>
    <path
      fill="#0F7540"
      d="M301.04 138.104h22.892v22.892H301.04zM278.147 138.104h22.893v22.892h-22.893z"
    />
    <path
      fill="#81B829"
      d="M369.717 115.212h22.892v22.892h-22.892zM346.824 115.212h22.893v22.892h-22.893zM186.58 275.457h22.892v22.892H186.58zM255.257 275.457h22.892v22.892h-22.892zM323.931 275.457h22.892v22.892h-22.892zM209.472 298.349h22.892v22.893h-22.892zM232.364 298.349h22.893v22.893h-22.893zM278.148 298.349h22.893v22.893h-22.893z"
    />
    <path
      fill="#81B829"
      d="M301.04 298.349h22.892v22.893H301.04zM140.796 321.242h22.892v22.892h-22.892z"
    />
    <path fill="#80A82B" d="M186.58 321.242h22.892v22.892H186.58z"/>
    <path
      fill="#81B829"
      d="M209.472 321.242h22.892v22.892h-22.892zM255.257 321.242h22.892v22.892h-22.892z"
    />
    <path fill="#0F7540" d="M278.148 321.242h22.893v22.892h-22.893z"/>
    <path fill="#81B829" d="M346.824 321.242h22.893v22.892h-22.893z"/>
    <path fill="#A7C56C" d="M369.717 321.242h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M117.904 344.132h22.893v22.892h-22.893z"/>
    <path fill="#81B829" d="M140.796 344.132h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M186.58 344.132h22.892v22.892H186.58z"/>
    <path
      fill="#81B829"
      d="M232.364 344.132h22.893v22.892h-22.893zM255.257 344.132h22.892v22.892h-22.892z"
    />
    <path fill="#80A82B" d="M301.04 344.132h22.892v22.892H301.04z"/>
    <path fill="#80A82B" d="M323.931 344.132h22.892v22.892h-22.892z"/>
    <path
      fill="#81B829"
      d="M163.687 367.026h22.893v22.891h-22.893zM186.58 367.026h22.892v22.891H186.58zM255.257 367.026h22.892v22.891h-22.892zM232.364 367.026h22.893v22.891h-22.893z"
    />
    <path fill="#81B829" d="M278.148 367.026h22.893v22.891h-22.893z"/>
    <path fill="#0F7540" d="M301.04 367.026h22.892v22.891H301.04z"/>
    <path
      fill="#80A82B"
      d="M346.824 367.026h22.893v22.891h-22.893zM369.717 367.026h22.892v22.891h-22.892z"
    />
    <path
      fill="#81B829"
      d="M186.58 389.917h22.892v22.893H186.58zM209.472 389.917h22.892v22.893h-22.892z"
    />
    <path fill="#80A82B" d="M255.257 389.917h22.892v22.893h-22.892z"/>
    <path
      fill="#0F7540"
      d="M278.148 389.917h22.893v22.893h-22.893zM323.931 389.917h22.892v22.893h-22.892z"
    />
    <path fill="#81B829" d="M392.608 389.917h22.891v22.893h-22.891z"/>
    <path fill="#80A82B" d="M209.472 412.81h22.892v22.893h-22.892z"/>
    <path fill="#81B829" d="M232.364 412.81h22.893v22.893h-22.893z"/>
    <path fill="#35471D" d="M255.257 412.81h22.892v22.893h-22.892z"/>
    <path fill="#0F7540" d="M301.04 412.81h22.892v22.893H301.04z"/>
    <path fill="#0F7540" d="M323.931 412.81h22.892v22.893h-22.892z"/>
    <path fill="#80A82B" d="M346.824 412.81h22.893v22.893h-22.893z"/>
    <path
      fill="#81B829"
      d="M415.499 412.81h22.893v22.893h-22.893zM163.687 435.702h22.893v22.891h-22.893zM209.472 435.702h22.892v22.891h-22.892z"
    />
    <path fill="#0F7540" d="M232.364 435.702h22.893v22.891h-22.893z"/>
    <path fill="#80A82B" d="M255.257 435.702h22.892v22.891h-22.892z"/>
    <path
      fill="#0F7540"
      d="M278.148 435.702h22.893v22.891h-22.893zM369.717 435.702h22.892v22.891h-22.892z"
    />
    <path
      fill="#80A82B"
      d="M392.608 435.702h22.891v22.891h-22.891zM232.364 458.593h22.893v22.893h-22.893z"
    />
    <path
      fill="#81B829"
      d="M255.257 458.593h22.892v22.893h-22.892zM209.472 481.485h22.892v22.893h-22.892z"
    />
    <path fill="#0F7540" d="M232.364 481.485h22.893v22.893h-22.893z"/>
    <path fill="#80A82B" d="M255.257 481.485h22.892v22.893h-22.892z"/>
    <path
      fill="#81B829"
      d="M278.148 481.485h22.893v22.893h-22.893zM323.931 275.457h22.892v22.892h-22.892zM392.608 275.457h22.891v22.892h-22.891zM461.284 275.457h22.893v22.892h-22.893zM346.824 298.349h22.893v22.893h-22.893zM369.717 298.349h22.892v22.893h-22.892zM415.499 298.349h22.893v22.893h-22.893z"
    />
    <path
      fill="#81B829"
      d="M438.392 298.349h22.893v22.893h-22.893zM278.148 321.242h22.893v22.892h-22.893z"
    />
    <path fill="#80A82B" d="M323.931 321.242h22.892v22.892h-22.892z"/>
    <path
      fill="#81B829"
      d="M346.824 321.242h22.893v22.892h-22.893zM392.608 321.242h22.891v22.892h-22.891z"
    />
    <path fill="#0F7540" d="M415.499 321.242h22.893v22.892h-22.893z"/>
    <path fill="#81B829" d="M484.177 321.242h22.891v22.892h-22.891z"/>
    <path fill="#81B829" d="M507.068 321.242h22.893v22.892h-22.893z"/>
    <path
      fill="#E4EACD"
      d="M255.257 344.132h22.892v22.892h-22.892zM575.743 367.026h22.893v22.891h-22.893zM301.04 321.242h22.892v22.892H301.04zM301.04 275.457h22.892v22.892H301.04zM278.147 252.566h22.893v22.892h-22.893zM232.364 252.566h22.893v22.892h-22.893zM323.931 206.781h22.892v22.892h-22.892zM415.499 160.997h22.893v22.892h-22.893zM552.853 229.672h22.891v22.892h-22.891zM552.853 160.997h22.891v22.892h-22.891zM552.853 275.457h22.891v22.892h-22.891z"
    />
    <path
      fill="#E4EACD"
      d="M575.743 298.348h22.893v22.892h-22.893zM529.96 344.133h22.893v22.893H529.96zM438.392 389.917h22.893v22.893h-22.893zM621.529 298.348h22.893v22.892h-22.893zM301.04 389.917h22.892v22.893H301.04zM323.931 435.702h22.892v22.891h-22.892z"
    />
    <path fill="#81B829" d="M278.148 344.132h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M323.931 344.132h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M369.717 344.132h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M392.608 344.132h22.891v22.892h-22.891z"/>
    <path fill="#80A82B" d="M438.392 344.132h22.893v22.892h-22.893z"/>
    <path fill="#80A82B" d="M461.284 344.132h22.893v22.892h-22.893z"/>
    <path fill="#81B829" d="M301.04 367.026h22.892v22.891H301.04z"/>
    <path
      fill="#81B829"
      d="M323.931 367.026h22.892v22.891h-22.892zM392.608 367.026h22.891v22.891h-22.891zM415.499 367.026h22.893v22.891h-22.893z"
    />
    <path fill="#0F7540" d="M438.392 367.026h22.893v22.891h-22.893z"/>
    <path fill="#80A82B" d="M484.177 367.026h22.891v22.891h-22.891z"/>
    <path fill="#80A82B" d="M507.068 367.026h22.893v22.891h-22.893z"/>
    <path
      fill="#81B829"
      d="M232.364 389.917h22.893v22.893h-22.893zM255.257 389.917h22.892v22.893h-22.892z"
    />
    <path
      fill="#81B829"
      d="M278.148 389.917h22.893v22.893h-22.893zM323.931 389.917h22.892v22.893h-22.892zM346.824 389.917h22.893v22.893h-22.893z"
    />
    <path fill="#80A82B" d="M392.608 389.917h22.891v22.893h-22.891z"/>
    <path
      fill="#0F7540"
      d="M415.499 389.917h22.893v22.893h-22.893zM461.284 389.917h22.893v22.893h-22.893z"
    />
    <path fill="#81B829" d="M529.96 389.917h22.893v22.893H529.96z"/>
    <path
      fill="#80A82B"
      d="M232.364 412.81h22.893v22.893h-22.893zM278.148 412.81h22.893v22.893h-22.893zM346.824 412.81h22.893v22.893h-22.893z"
    />
    <path fill="#81B829" d="M369.717 412.81h22.892v22.893h-22.892z"/>
    <path fill="#35471D" d="M392.608 412.81h22.891v22.893h-22.891z"/>
    <path fill="#0F7540" d="M438.392 412.81h22.893v22.893h-22.893z"/>
    <path fill="#0F7540" d="M461.284 412.81h22.893v22.893h-22.893z"/>
    <path fill="#80A82B" d="M484.177 412.81h22.891v22.893h-22.891z"/>
    <path
      fill="#81B829"
      d="M552.853 412.81h22.891v22.893h-22.891zM232.364 435.702h22.893v22.891h-22.893z"
    />
    <path fill="#80A82B" d="M278.148 435.702h22.893v22.891h-22.893z"/>
    <path
      fill="#81B829"
      d="M301.04 435.702h22.892v22.891H301.04zM346.824 435.702h22.893v22.891h-22.893z"
    />
    <path fill="#0F7540" d="M369.717 435.702h22.892v22.891h-22.892z"/>
    <path fill="#80A82B" d="M392.608 435.702h22.891v22.891h-22.891z"/>
    <path
      fill="#0F7540"
      d="M415.499 435.702h22.893v22.891h-22.893zM507.068 435.702h22.893v22.891h-22.893z"
    />
    <path
      fill="#80A82B"
      d="M529.96 435.702h22.893v22.891H529.96zM232.364 458.593h22.893v22.893h-22.893z"
    />
    <path fill="#81B829" d="M255.257 458.593h22.892v22.893h-22.892z"/>
    <path fill="#80A82B" d="M255.257 481.485h22.892v22.893h-22.892z"/>
    <path fill="#81B829" d="M278.148 481.485h22.893v22.893h-22.893z"/>
    <path fill="#80A82B" d="M575.743 458.593h22.893v22.893h-22.893z"/>
    <path
      fill="#0F7540"
      d="M621.529 412.81h22.893v22.893h-22.893zM644.421 389.917h22.891v22.893h-22.891z"
    />
  </svg>
);

export default IsotipoBikenta;
