import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import PlaceIcon from '../../logos/PlaceIcon';
import Typography from '@mui/material/Typography';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';

//TYPES
export type PlacesProps = {
  numberOfPlaces: number
};

const containerSx: SxProps = {
  position: 'relative',
  mx: 4
};

const wrapperIconSx: SxProps = {
  my: 2,
  mx: 10
};

const wrapperNumberSx: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};

const Places: FC<PlacesProps> = ({numberOfPlaces}) => {
  return <Box sx={containerSx}>
    <Box sx={wrapperIconSx}>
      <PlaceIcon/>
    </Box>
    <Box sx={wrapperNumberSx}>
      <Typography variant='supertitle' color='primary' align='center' sx={{fontSize: '7vw'}}>{numberOfPlaces}</Typography>
    </Box>
  </Box>;
};
export default Places;