import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useMediaQuery} from '@mui/material';

//TYPES
export type CommitmentProps = {
  title: string,
  description: string,
  video?: string
};

const backgroundImage: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: 'auto',
  backgroundImage: 'url(/img/bosque.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const overlayContent: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: {
    xs: '100%',
    md: '50%'
  },
  m: 5
};

const descriptionSx: SxProps = {
  mx: 'auto',
  my: 5,
  fontWeight: 600,
  fontSize: '20px',
};

const Commitment: FC<CommitmentProps> = ({title, description, video}) => {

  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});

  const getEmbedUrl = (youtubeUrl: string) => {
    const urlObj = new URL(youtubeUrl);
    const videoId = urlObj.searchParams.get('v');
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return <Box sx={backgroundImage} id='backgroundImage'>
    <Box sx={overlayContent}>
      <Typography align='center' color='common.white' sx={descriptionSx}>{description}</Typography>
      {video && <iframe src={getEmbedUrl(video)} style={{marginBottom: '100px', maxWidth: '600px'}} width={widescreen ? '60%' : '100%'} height={widescreen? '400px' : '200px'}
        allowFullScreen title={title} referrerPolicy="strict-origin-when-cross-origin"></iframe>}
    </Box>
  </Box>;
};
export default Commitment;