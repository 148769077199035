import React, {useState} from 'react';

import {getInitiative} from '@/domain/useCases/greenBonusUseCase';

import {useAsync} from 'react-use';
import {Navigate, useParams} from 'react-router-dom';

import i18n from 'i18next';
import Loading from '@/components/Loading';
import Layout from '@/components/landing/Layout';
import {INITIAL_MAPSTYLE} from '@/config';

const LandingView = () => {
  const {initiativeId} = useParams();
  const [mapStyleId, setMapStyleId] = useState<string>(INITIAL_MAPSTYLE.id);

  const initiative = useAsync(async () =>
    initiativeId && getInitiative(parseInt(initiativeId)), [initiativeId]);


  if (initiative.loading) return <Loading/>;

  if (initiative.error) return <Navigate to={`/${i18n.resolvedLanguage}/404`} />;

  if (initiative.value) return <Layout initiative={initiative.value} mapStyle={mapStyleId} mapStyleId={mapStyleId} onStyleChange={setMapStyleId}/>;

  return <Navigate to={`/${i18n.resolvedLanguage}/404`} />;
};

export default LandingView;
