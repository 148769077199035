import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Action from './Action';

import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useTranslation} from 'react-i18next';
import {Execution, Plan} from '@/domain/entities/Initiative';

const containerSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  bgcolor: 'common.white',
  pb: 8,
  maxWidth: {
    xs: '100vw',
    md: '60vw'
  },
  mt: 4
};

const titleSx: SxProps = {
  color: 'primary.main',
  mb: 2
};

const descriptionSx: SxProps = {
  color: 'primary.grey',
  px: 4,
  mb: 2
};

export interface ActionPlanProps {
  actionPlan:  Plan
}

type ActionType = {
  id: number,
  title: string,
  description: string,
  year: number,
  interventions: {
    id: number,
    title: string,
    text: string,
    planned_year: number,
    image: string,
    executions: Execution
  }[]
}

const ActionPlan: FC<ActionPlanProps> = ({actionPlan}) => {

  const {t} = useTranslation();
  
  const actions: ActionType = actionPlan && {
    ...actionPlan,
    interventions: actionPlan?.interventions?.flatMap(intervention => {
      return intervention.executions.map((execution) => ({
        ...intervention,
        executions: execution
      }));
    })
  };

  return <Box sx={containerSx}>
    <Typography variant='title' sx={titleSx}>{t('actionPlan.actionPlan').toUpperCase()}</Typography>
    <Typography variant='subtitle' align='center'>{actionPlan.title}</Typography>
    <Typography variant='paragraph' align='center' sx={descriptionSx}>{actionPlan.description}</Typography>
    <Box sx={{width: {xs: '95vw', md: '60vw'}}}>
      {
        actions.interventions.map((action, index) => <Action key={index}
          title={action.title}
          proposalPhoto={action.image}
          proposalLabel={action.text}
          donePhoto={action.executions?.image}
          doneLabel={action.executions?.comments}
          year={action.planned_year}
          isFirstAction={index === 0}
          isLastAction={index === actions.interventions.length-1}
        />)
      }
    </Box>
  </Box>;
};

export default ActionPlan;


