import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import {SummaryInitiativeData} from '@/utils/getSummaryInitiativesData';
import CollaboratorsCarousel from '@/components/CollaboratorsCarousel';
import Certificates from '@/components/Certificates';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

//TYPES
export type CertificatesCollaboratorsProps = {
    initiativesSummary: SummaryInitiativeData
};

const CertificatesCollaborators: FC<CertificatesCollaboratorsProps> = ({initiativesSummary}) => {
  const {t} = useTranslation();

  return <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}} elevation={3}>
    <Typography variant="subtitle" align="center"
      sx={{mt: 4, mb: 2, fontWeight: 900, color: 'secondary.main'}}>{t('initiatives.summary.certifications').toUpperCase()}</Typography>
    <Box mx={4} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 1, pb:3}}>
      <Certificates certificates={initiativesSummary.certificationValues}/>
      {
        initiativesSummary.certificationValues && initiativesSummary.collaborators.length > 0 && <>
          <Divider/>
          <Typography variant="subtitle" align="center" sx={{mt: 3, mb: 1, fontWeight: 900, color: 'secondary.main'}}>
            {t('collaborators').toUpperCase()}
          </Typography>
        </> 
      }
      <CollaboratorsCarousel collaborators={initiativesSummary.collaborators}/>
    </Box>
  </Paper>;
};
export default CertificatesCollaborators;