import React, {CSSProperties, FC} from 'react';

import Box from '@mui/material/Box';

import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useTranslation} from 'react-i18next';
import {Specie} from '@/domain/entities/Specie';
import StandOutData from '@/components/Species/StandOutData';
import {EQUIVALENCE_TONS_CARS} from '@/config';
import SpecieIcon from '@/components/Species/SpecieIcon';
import Typography from '@mui/material/Typography';
import {useMediaQuery} from '@mui/material';

export interface SpecieDetailProps {
  specie: Specie,
  background?: boolean
}

const dataSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  m: 1,
  px: 2,
};

const imageStyle: CSSProperties = {
  width: 50,
  alignSelf: 'center',
};

const labelSx: SxProps = {
  fontStyle: 'italic'
};

const numberOfCarsSx: SxProps = {
  fontWeight: 900,
  color: 'primary.grey',
  alignSelf: 'center',
  width: 50,
  fontSize: 16
};

const SpecieDataDetail: FC<SpecieDetailProps> = ({specie, background=false}) => {

  const {t} = useTranslation();
  
  const xsDevice = useMediaQuery('(max-width: 599px)', {noSsr: true});
  const smDevice = useMediaQuery('(min-width: 600px) and (max-width: 959px)', {noSsr: true});
  const mdDevice = useMediaQuery('(min-width: 960px) and (max-width: 1279px)', {noSsr: true});
  const lgDevice = useMediaQuery('(min-width: 1280px) and (max-width: 1919px)', {noSsr: true});
  const xlDevice = useMediaQuery('(min-width: 1920px)', {noSsr: true});

  const containerSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: background ? 'secondary.light' : 'transparent',
    padding: 2,
    height: {
      xs: 450,
      sm: 410,
      md: 475,
      lg: 440,
      xl: 400
    },
  };
  
  const TOTAL_NUMBER_OF_CARS = specie.co2/EQUIVALENCE_TONS_CARS;
  
  const MAX_NUM_CARS = {
    xs: 17,
    sm: 14,
    md: 11,
    lg: 15,
    xl: 15
  };
  
  const cardsOfDevice = xsDevice
    ? Math.min(parseFloat(TOTAL_NUMBER_OF_CARS.toFixed()), MAX_NUM_CARS.xs)   
    : smDevice 
      ? Math.min(parseFloat(TOTAL_NUMBER_OF_CARS.toFixed()), MAX_NUM_CARS.sm)   
      : mdDevice
        ? Math.min(parseFloat(TOTAL_NUMBER_OF_CARS.toFixed()), MAX_NUM_CARS.md) 
        : lgDevice
          ? Math.min(parseFloat(TOTAL_NUMBER_OF_CARS.toFixed()), MAX_NUM_CARS.lg) 
          : xlDevice
            ? Math.min(parseFloat(TOTAL_NUMBER_OF_CARS.toFixed()), MAX_NUM_CARS.xl)
            : 0;

  return <Box sx={containerSx}>
    <Box display='flex' flexDirection='row'>
      <SpecieIcon id={specie.id}/>
      <Box sx={dataSx}>
        <Typography sx={labelSx}>{specie.label}</Typography>
        <Typography color="primary.grey" gutterBottom>{specie.description}</Typography>
      </Box>
    </Box>
    <Box display='flex' flexDirection='row'>
      <StandOutData title={t('species.absorbs_co2').toUpperCase()} data={specie.co2} units={t('species.tons').toUpperCase()}/>
      <StandOutData title={t('species.equals_to').toUpperCase()} data={parseFloat(TOTAL_NUMBER_OF_CARS.toFixed())} units={t('species.cars').toUpperCase()}/>
    </Box>
    <Box sx={{display:'grid', width: '100%', gap: 1,  gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 50px))', alignContent: 'center', justifyContent: 'center', my: 3}}>
      {
        Array.from({length: cardsOfDevice}).map((_, index) =>
          <img key={index} src="/img/car.png" alt='' style={imageStyle}/>)
      }
      {
        Math.floor(TOTAL_NUMBER_OF_CARS) > cardsOfDevice ?
          <Typography sx={numberOfCarsSx}>[...{(TOTAL_NUMBER_OF_CARS - cardsOfDevice).toFixed()}]</Typography> : undefined
      }
    </Box>
  </Box>;
};

export default SpecieDataDetail;