import React, {FC, useState} from 'react';
import {Collaborator} from '@/domain/entities/Collaborator';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '@mui/material';

export interface CollaboratorsProps {
  collaborators: Array<Collaborator>
}

const sectionSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  p: 4,
  width: '100vw',
  bgcolor: '#d5d5d5'
};

const titleSx: SxProps = {
  color: 'common.white',
  textAlign: 'center',
  mb: 2
};

const imageContainer: SxProps = {
  width: '190px',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 2,
  gap: 0.5,
  bgcolor: 'common.white'
};

const Collaborators: FC<CollaboratorsProps> = ({collaborators}) => {

  const {t} = useTranslation();

  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});
  const [hoveredId, setHoveredId] = useState<number | undefined>(undefined);
  
  const handleMouseEnter = (id: number) => setHoveredId(id);
  const handleMouseLeave = () => setHoveredId(undefined);
  
  const containerSx: SxProps = {
    display: widescreen ? 'flex' : 'grid',
    flexWrap: 'wrap',
    gridTemplateColumns: widescreen ? undefined : 'repeat(2, 1fr)',
    justifyContent: 'center',
    justifyItems: 'center',
    gap: 2,
    my: 1,
    mx: 3
  };

  return <Box sx={sectionSx}>
    <Typography variant='title' sx={titleSx}>{t('weCollaborate').toUpperCase()}</Typography>
    <Box sx={containerSx}>
      {
        collaborators.map(({id, title, image}) => <Box key={id} sx={imageContainer}>
          <img src={image} alt={title} 
            onMouseEnter={() => handleMouseEnter(id)} onMouseLeave={handleMouseLeave}
            style={{
              maxHeight: '80px',
              maxWidth: '100%',
              filter: hoveredId === id ? 'grayscale(0%)' : 'grayscale(100%)',
              opacity: hoveredId === id ? 1 : 0.5
            }}
          />
        </Box>
        )
      }
    </Box>
  </Box>;
};

export default Collaborators;