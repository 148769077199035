import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import SustainabilityAction from './SustainabilityAction';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';
import {SustainabilityActionType} from '@/domain/entities/Initiative';

export type ServicesProps = {
  sustainabilityActions: Array<SustainabilityActionType>
};

const SustainabilityActionList: FC<ServicesProps> = ({sustainabilityActions}) => {

  const {t} = useTranslation();

  return <Box sx={{display: 'flex', flexDirection: 'column'}}>
    {
      sustainabilityActions.length ?
        sustainabilityActions?.map(action => <SustainabilityAction key={action.id} action={action}/>) :
        <Typography variant='paragraph' color='error'>{t('initiatives.withoutSustainabilityActions')}</Typography>
    }
  </Box>;
};
export default SustainabilityActionList;