import React, {FC, SVGProps} from 'react';

const PinusSylvestris: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 808.108 711.158"
    height="100%"
    fill="none"
    {...props}
  >
    <path
      fill="#A4AA93"
      d="m381.922 625.266.151-13.02 22.405.004.155 13.015-22.711.001z"
    />
    <path
      fill="#848273"
      d="m404.478 612.25-22.405-.004-.307-23.771c.067-33.189.132-66.379.204-99.568.009-4.305.052-8.611.079-12.916l22.409.081.02 136.178z"
    />
    <path
      fill="#A4AA93"
      d="M336.461 21.767c.032-6.117.225-12.239.032-18.349-.104-3.28.937-4.366 4.274-4.321 13.672.187 27.348.122 41.023.149l.205 22.466c-6.073.044-12.152.256-18.217.066-3.446-.108-4.788.943-4.642 4.531.247 6.065.075 12.147.079 18.221l-22.725-.042-.029-22.721z"
    />
    <path
      fill="#BBC2AF"
      d="m290.788 453.316.367-45.378 22.457-.122c.083 7.623.164 15.245.245 22.867-.025 6.106-.196 12.216-.014 18.315.097 3.256-.954 4.463-4.291 4.352-6.099-.202-12.21-.027-18.315-.009l-.449-.025z"
    />
    <path
      fill="#64684F"
      d="m200.282 339.659.054-22.53 22.442-.112 23.002.151-.128 22.527-22.742.258-22.628-.294z"
    />
    <path
      fill="#A4AA93"
      d="m404.459 476.072-22.409-.081-.297.123.085-22.747.066-22.686 22.669-.113.26 22.864-.374 22.64zM472.902 67.265l-.28 22.623c-7.478.042-14.957.085-22.437.126l-.153-45.464 22.651.05.081-.075.138 22.74zM518.122 453.192l-22.771-.019-.079-22.539.373-22.806 22.389.129.088 45.235zM518.132 317.093l.322 22.65-.488.024-22.249-.018-.167-22.746 22.489.174.093-.084z"
    />
    <path
      fill="#64684F"
      d="M563.523 271.774c.026 6.09-.17 12.191.161 18.264.202 3.7-1.21 4.48-4.575 4.367-6.079-.204-12.174.024-18.261.072l.126-.304.004-22.682 22.545.283z"
    />
    <path
      fill="#A4AA93"
      d="m222.778 317.017-22.442.112-.005-22.686 22.517-.165-.07 22.739z"
    />
    <path
      fill="#BBC2AF"
      d="m518.034 407.958-22.389-.129-.332.113.268-22.65 22.489-.313.234.476c-.091 7.5-.181 15.002-.27 22.503z"
    />
    <path
      fill="#D3D8CF"
      d="m404.833 453.431-.26-22.864.09.032 22.47-.033.343 22.615-.357.337-22.286-.087z"
    />
    <path
      fill="#A4AA93"
      d="m359.142 453.275-.033 22.65-22.587.415-.017-.495c.005-6.099.162-12.203-.045-18.295-.114-3.358 1.169-4.368 4.387-4.276 6.093.173 12.196.019 18.295.001z"
    />
    <path
      fill="#D3D8CF"
      d="M518.454 339.744c5.94 0 11.886.159 17.818-.06 3.442-.127 4.872.891 4.708 4.539-.275 6.108-.144 12.235-.183 18.353l-22.328-.169-.504-22.639.489-.024zM245.706 385.145l.002-22.716 22.731.213-.127 22.495-22.606.008zM450.083 453.485l-.044 22.53c-5.933.008-11.874-.16-17.797.089-3.66.154-5.155-.9-4.996-4.803.241-5.916-.056-11.855-.126-17.783l.356-.336c7.536.1 15.071.201 22.607.303z"
    />
    <path
      fill="#A4AA93"
      d="m518.471 362.406 22.327.169c.023 6.3-.054 12.603.115 18.899.075 2.807-.912 3.79-3.731 3.756-6.291-.077-12.586.131-18.879.223l-.234-.475-.083-22.437.485-.135z"
    />
    <path
      fill="#D3D8CF"
      d="m313.61 407.815-22.456.122c-.058-7.583-.116-15.166-.176-22.749l.24-.027 22.361-.004.259 22.74-.228-.082z"
    />
    <path
      fill="#A4AA93"
      d="m268.489 112.611.006-22.614 22.502-.088-.105 22.795-22.403-.093z"
    />
    <path
      fill="#BBC2AF"
      d="m450.186 90.014 22.436-.127.354 22.794-.425-.036-22.58-.055-.063-22.681.278.105zM268.489 112.611l22.403.093.133 22.644-22.43.121-.452-.176.346-22.682z"
    />
    <path
      fill="#D3D8CF"
      d="M245.601 157.854c.025-6.251.14-12.503.036-18.752-.046-2.769.982-3.809 3.756-3.764 6.249.102 12.501-.016 18.751-.045l.451.176-.4 22.557-22.488.211-.106-.383z"
    />
    <path
      fill="#A4AA93"
      d="m472.979 135.166 22.464.265.033 22.647-22.43.044-.511-.065.088-22.546.356-.345z"
    />
    <path
      fill="#D3D8CF"
      d="M200.257 180.765c-.006-6.245.1-12.493-.065-18.734-.08-3.049 1.091-4.078 4.067-3.997 6.241.169 12.488.088 18.732.109l-.261 22.748-22.473-.126z"
    />
    <path
      fill="#BBC2AF"
      d="m222.729 180.892.262-22.749c7.537-.095 15.074-.191 22.61-.288l.106.382-.141 22.473-22.522.106-.315.076zM404.662 21.722l.04-22.495 22.524-.051.101 22.649-22.799.25.134-.353z"
    />
    <path
      fill="#D3D8CF"
      d="M291.049 25.811c-.087-3.033 1.044-4.116 4.051-4.025 6.253.189 12.513.129 18.769.17l-.161 22.484-22.531.142"
    />
    <path
      fill="#BBC2AF"
      d="M313.707 44.44c.054-7.495.107-14.99.162-22.484 7.531-.062 15.062-.125 22.592-.189l.028 22.721.057.174-22.613.01-.226-.232z"
    />
    <path
      fill="#D3D8CF"
      d="m472.684 44.6-22.651-.05-.002-.002-.045-22.536.089-.344c6.073.046 12.151.274 18.216.07 3.569-.12 4.684 1.182 4.545 4.643-.246 6.066-.122 12.146-.152 18.219z"
    />
    <path
      fill="#BBC2AF"
      d="m291.176 44.582 22.531-.142.225.23-.132 22.575-22.551.16-.331-.339c.087-7.494.172-14.989.258-22.484z"
    />
    <path
      fill="#BBC2AF"
      d="m290.997 89.909-22.502.088c-.038-5.766.137-11.544-.187-17.294-.231-4.101.83-5.844 5.325-5.56 5.738.362 11.522-.016 17.286-.076l.33.338-.079 22.604-.173-.1z"
    />
    <path
      fill="#A4AA93"
      d="m540.825 226.354.347 22.529-.497.088-22.41-.055-.097-22.686 22.657.124z"
    />
    <path
      fill="#969D7A"
      d="m222.717 271.631.362-22.62 22.482-.158c-.017 7.55-.035 15.099-.051 22.649l-22.323.143-.47-.014z"
    />
    <path
      fill="#D3D8CF"
      d="m563.523 271.774-22.546-.282.023.097-.325-22.618.497-.088c5.94.013 11.885.191 17.816-.024 3.469-.126 4.84.943 4.684 4.562-.265 6.108-.121 12.235-.149 18.353z"
    />
    <path
      fill="#BBC2AF"
      d="m495.361 180.871 22.692-.002c.112 7.551.226 15.101.339 22.651l-.409-.031-22.256-.039c-.123-7.526-.245-15.052-.366-22.579zM222.985 226.272l22.494-.151.233 22.83-.151-.098-22.481.159-.095-22.74z"
    />
    <path
      fill="#64684F"
      d="m450.083 453.485-22.607-.303-.343-22.615.473.084 22.477-.105 22.676.041.023.023.021 22.509-22.72.366z"
    />
    <path
      fill="#58633B"
      d="m472.803 453.119-.021-22.509 22.49.025.079 22.539-22.548-.055z"
    />
    <path
      fill="#868D5C"
      d="M359.215 44.529c-.004-6.075.169-12.156-.079-18.221-.146-3.589 1.196-4.639 4.642-4.531 6.066.19 12.144-.022 18.217-.066l-.109.231.078 22.529-22.744.063-.005-.005zM336.49 44.488l22.725.042.005.005.008 22.687-22.729-.029.048-22.531-.057-.174z"
    />
    <path
      fill="#A4AA93"
      d="M336.492 430.58c-7.545.034-15.091.067-22.636.102-.081-7.622-.162-15.244-.245-22.867l.226.082 22.62.053c.013 7.544.024 15.087.035 22.63zM268.44 362.641l-22.731-.213h-.007l-.05-22.733.128-22.527-.122-.13 22.607.087.247 22.461-.161 22.724c-.002-.001.089.331.089.331z"
    />
    <path
      fill="#D3D8CF"
      d="m245.652 339.695.05 22.733-22.724.016-.068-22.491 22.742-.258z"
    />
    <path
      fill="#4E5339"
      d="m222.778 317.017.07-22.739.183.099 22.558-.091.067 22.752.122.13-23-.151z"
    />
    <path
      fill="#868D5C"
      d="m473.047 158.122-.133 22.582-.338.18c-6.098-.027-12.204.089-18.296-.138-3.364-.125-4.369 1.199-4.278 4.405.172 6.095.029 12.2.016 18.3l-22.669.056c-.001-6.246-.111-12.494.045-18.736.075-3.026-1.054-4.093-4.051-4.024-6.242.144-12.49.024-18.736.012l.007-22.647 22.693-.147 45.229.093.511.064z"
    />
    <path
      fill="#8C8D6C"
      d="m495.361 180.871.363 22.58-.408.095-22.526-.018.047-.141-.263-22.502.339-.18c7.483.054 14.966.11 22.448.166z"
    />
    <path
      fill="#58633B"
      d="m404.572 430.567-22.669.113.05-.132-.074-22.625 22.82.025-.038 22.651-.089-.032z"
    />
    <path
      fill="#BBC2AF"
      d="m359.238 453.326.036-22.689-.033.014 22.712-.104-.05.132-.066 22.687-22.599-.04z"
    />
    <path
      fill="#868D5C"
      d="m450.032 44.55.153 45.464-.277-.104-22.565.017-.012-22.695-.067-22.627.118-.118 22.648.061.002.002z"
    />
    <path
      fill="#626B43"
      d="m495.272 430.634-22.49-.025-.022-.022.02-22.698 22.533.052.332-.113-.373 22.806z"
    />
    <path
      fill="#58633B"
      d="m495.55 317.003.167 22.746-.458.03c-5.571-.007-11.172.333-16.706-.123-5.006-.413-6.156 1.569-5.871 6.143.346 5.547.025 11.136-.007 16.707l-22.648-.048.011-22.677c.008-6.243-.088-12.49.067-18.729.075-3.005-1.034-4.119-4.042-4.047-6.24.15-12.486.045-18.73.044l.101-22.698 22.709-.062 22.571.134c.019 6.289.128 12.579.017 18.865-.049 2.788.993 3.83 3.782 3.781 6.286-.111 12.577-.002 18.865.017l.172-.083z"
    />
    <path
      fill="#D3D8CF"
      d="m518.156 294.531-.117 22.645-22.49-.173-.173.085.161-22.722-.007.078 22.703-.279-.077.366z"
    />
    <path
      fill="#848273"
      d="m517.966 339.767.504 22.639-.484.135-22.411-.205-.317-22.556.459-.031 22.249.018zM540.973 294.173l-22.817.357.077-.366-.034-22.386 22.8-.189-.023-.097-.003 22.681z"
    />
    <path
      fill="#4E5339"
      d="m495.312 407.942-22.533-.052-.039-.016-.193-22.616.362-.117 22.383-.141.289.291-.269 22.651z"
    />
    <path
      fill="#4E5339"
      d="m495.575 362.336 22.411.205.083 22.438-22.489.313-.288-.29.283-22.666z"
    />
    <path
      fill="#BBC2AF"
      d="m404.663 430.599.038-22.651-.03.017 22.627.002.308 22.684-.473-.084-22.47.032z"
    />
    <path
      fill="#848273"
      d="m268.349 362.309.161-22.723 22.566.162.013 22.457-.014.462-22.726-.358z"
    />
    <path
      fill="#BBC2AF"
      d="m313.579 385.158-22.36.004c-.047-7.498-.095-14.997-.144-22.495l.014-.461 22.662.264.252 22.749-.424-.061z"
    />
    <path
      fill="#868D5C"
      d="m336.459 407.951-22.62-.053-.259-22.74.423.061 45.176-.038.065 22.622-.096.193-22.689-.045z"
    />
    <path
      fill="#6A7041"
      d="m291.025 135.348-.133-22.644.105-22.795.173.1c7.563-.055 15.125-.11 22.688-.163l-.049.23c.001 6.116.154 12.236-.056 18.344-.116 3.379 1.203 4.374 4.414 4.278 6.11-.183 12.229-.052 18.345-.051l.031 22.617-22.686.204c-7.61-.041-15.221-.08-22.832-.12z"
    />
    <path
      fill="#64684F"
      d="m427.343 89.927 22.565-.017.062 22.681-.095-.077c-7.509.053-15.02.105-22.53.156l-22.726.001-22.698-.112.009-22.611-.051-.029 22.762.027 22.702-.019z"
    />
    <path
      fill="#A4AA93"
      d="m472.551 112.646.428 22.52-.355.344-22.528-.249-.222-22.748.097.079 22.58.054z"
    />
    <path
      fill="#868D5C"
      d="m291.025 135.348 22.832.118-.007 22.491-22.721.154-22.531.01-.404-.096.401-22.557c7.477-.039 14.954-.08 22.43-.12z"
    />
    <path
      fill="#969D7A"
      d="m245.567 180.71.141-22.473 22.488-.21.402.096c-.112 7.548-.223 15.095-.333 22.643l-22.698-.056z"
    />
    <path
      fill="#58633B"
      d="m450.097 135.26 22.528.25-.088 22.547-45.229-.092.049-22.373-.017-.349 22.757.017z"
    />
    <path
      fill="#868D5C"
      d="m245.567 180.71 22.698.056.195.038-.121 22.692-22.767.033-22.525-.127-.003-22.586 22.523-.106z"
    />
    <path
      fill="#78824B"
      d="m381.964 44.472-.078-22.529 22.776-.22-.133.352.105 22.451c-7.557-.019-15.113-.037-22.67-.054z"
    />
    <path
      fill="#868D5C"
      d="m404.634 44.525-.105-22.451 22.798-.249-.047.092.102 22.57-.117.117-22.631-.079z"
    />
    <path
      fill="#A4AA93"
      d="m427.382 44.487-.102-22.57 22.705.094.045 22.537-22.648-.061z"
    />
    <path
      fill="#8C8D6C"
      d="m336.547 44.661-.048 22.532-22.719.07.021-.018.132-22.575c7.539-.002 15.076-.006 22.614-.009z"
    />
    <path
      fill="#868D5C"
      d="m313.859 89.844-22.688.164.079-22.604 22.552-.16-.021.018.078 22.582z"
    />
    <path
      fill="#4E5339"
      d="m518.167 226.23.097 22.686-.182-.024-22.601.024-.036-22.675.121-.117 22.601.105v.001z"
    />
    <path
      fill="#64684F"
      d="m541 271.589-22.8.188-.025-.151-.094-22.734.183.024 22.411.055.325 22.618z"
    />
    <path
      fill="#6A7041"
      d="m245.508 271.5.052-22.648.151.098 22.692.038-.059 22.578-22.533.225-.303-.291z"
    />
    <path
      fill="#64684F"
      d="m245.59 294.286-22.558.091.155-22.733 22.323-.143.301.29c-.075 7.498-.148 14.996-.221 22.495z"
    />
    <path
      fill="#A4AA93"
      d="m518.166 226.228-22.601-.104-.248-22.578.408-.095 22.257.038c.06 7.58.122 15.16.184 22.739z"
    />
    <path
      fill="#78824B"
      d="m268.403 248.99-22.692-.039-.233-22.83.094-22.592 22.767-.033.059-.007-.011 22.791.005 22.577.011.133z"
    />
    <path
      fill="#626B43"
      d="m427.606 430.65-.308-22.684.054-.127 22.771-.016-.035.027-.005 22.696-22.477.104z"
    />
    <path
      fill="#58633B"
      d="m450.083 430.546.004-22.695 22.652.023.039.016-.02 22.698c-7.558-.015-15.116-.028-22.675-.042z"
    />
    <path
      fill="#8C8D6C"
      d="m381.964 44.472 22.67.053-.006 22.709.002-.002-22.7.005-22.702-.014-.008-22.687 22.744-.064z"
    />
    <path
      fill="#969D7A"
      d="m359.228 67.222 22.701.014-.052 22.683.051.029-45.531.14-22.589-.013.049-.23-.078-22.582 22.719-.07 22.73.029zM336.459 407.951l22.689.043.094 22.657.033-.014-22.782-.056-.034-22.63z"
    />
    <path
      fill="#394025"
      d="m245.59 294.286.221-22.495 22.533-.224 22.779-.051 22.692.103c-.004 6.257.129 12.519-.071 18.77-.098 3.062.948 4.024 3.974 3.948 6.25-.157 12.509.031 18.764.077l.017 22.641-22.56.184-22.761-.036-22.914-.08-22.607-.087-.067-22.75z"
    />
    <path
      fill="#4E5339"
      d="m268.264 317.124 22.913.079-.101 22.545-22.566-.162-.246-22.462z"
    />
    <path
      fill="#969D7A"
      d="m404.614 158.11-.007 22.647.044.055-22.693.029c-6.06-.018-12.125.117-18.177-.112-3.441-.131-4.777.961-4.643 4.531.227 6.051.042 12.117.03 18.177l.047.109-22.697-.068-.043-22.747.002-22.628.038-.038 22.741.004 22.742-.002 22.688-.074-.072.117z"
    />
    <path
      fill="#58633B"
      d="m427.348 203.506 22.67-.055 22.819-.064-.047.141-.011 22.667-.035.035-22.689-.009-22.701-.02.014-22.697-.02.002z"
    />
    <path
      fill="#4E5339"
      d="m427.357 135.593-.049 22.371-22.693.146.07-.115.012-22.531-.069-.108 22.729.237z"
    />
    <path
      fill="#6A7041"
      d="M404.607 180.758c6.246.012 12.494.132 18.736-.012 2.997-.069 4.126.998 4.051 4.024-.156 6.242-.046 12.491-.045 18.736l.02-.001c-6.239-.002-12.479-.108-18.714.038-2.981.07-4.138-.987-4.062-4.016.157-6.235.055-12.476.059-18.714l-.045-.055z"
    />
    <path
      fill="#78824B"
      d="m472.837 203.387-22.819.064c.012-6.101.155-12.205-.017-18.301-.09-3.206.914-4.53 4.278-4.405 6.093.226 12.198.11 18.296.138l.262 22.504z"
    />
    <path
      fill="#4E5339"
      d="m472.779 226.195.011-22.667 22.526.018.248 22.578-.12.117-22.665-.046z"
    />
    <path
      fill="#8C8D6C"
      d="m359.241 430.651-.094-22.657.095-.191 22.658.133-.022-.013.074 22.625-22.711.103z"
    />
    <path
      fill="#6A7041"
      d="m404.701 407.948-22.82-.025.022.013.005-22.734 22.736-.004.028 22.766.029-.016z"
    />
    <path
      fill="#78824B"
      d="m404.628 67.234.006-22.709 22.631.08c.021 7.543.044 15.085.066 22.628l-22.703.001z"
    />
    <path
      fill="#6A7041"
      d="m404.628 67.234 22.703-.001.012 22.695-22.703.02-.011-22.715-.001.001z"
    />
    <path
      fill="#626B43"
      d="m427.354 226.202 22.701.02.003 22.702.025 22.806.059 22.56-22.709.062-.184-.045c.014-7.536.026-15.073.039-22.61l.066-45.495z"
    />
    <path
      fill="#394025"
      d="m495.575 362.336-.283 22.665-22.385.14-.233-22.635c.032-5.571.353-11.16.007-16.707-.285-4.573.864-6.555 5.871-6.143 5.535.456 11.135.116 16.706.123l.317 22.557z"
    />
    <path
      fill="#8C8D6C"
      d="m450.142 294.29-.059-22.561 22.632-.182 22.738.152.078 22.744.007-.078-22.825.058-22.571-.133z"
    />
    <path
      fill="#78824B"
      d="m427.433 294.352-.101 22.698.01-.009-22.687.037-22.677.03-.177-.177.079-22.556 22.791-.087 22.577.02.185.044z"
    />
    <path
      fill="#6A7041"
      d="M427.332 317.05c6.244 0 12.49.105 18.73-.044 3.008-.072 4.117 1.042 4.042 4.047-.156 6.24-.06 12.486-.067 18.729l-22.691-.016-.004-22.724-.01.008z"
    />
    <path
      fill="#78824B"
      d="m472.713 294.424 22.825-.058-.161 22.722c-6.289-.02-12.579-.129-18.866-.017-2.789.049-3.831-.993-3.782-3.781.111-6.287.003-12.578-.016-18.866zM427.346 339.765l22.691.016-.011 22.677.017-.014-22.7.028-.009-22.715.012.008z"
    />
    <path
      fill="#6A7041"
      d="m472.674 362.506.233 22.635-.359.116c-7.468.017-14.937.035-22.407.051a30.84 30.84 0 0 1-.092-.159l-.006-22.704-.017.014 22.648.047z"
    />
    <path
      fill="#868D5C"
      d="m495.531 294.443-.078-22.745 22.722-.073.025.151.034 22.388-22.703.279z"
    />
    <path
      fill="#848273"
      d="m450.142 385.309 22.406-.053.192 22.617-22.652-.023.035-.027.019-22.514z"
    />
    <path
      fill="#58633B"
      d="m404.671 407.964-.028-22.766.004-.004 22.691-.015.008.008.006 22.653-.054.127-22.627-.003z"
    />
    <path
      fill="#64684F"
      d="m291.076 339.748.101-22.545 22.761.036-.069 22.469-.089 22.73-.03.03-22.662-.264-.012-22.456z"
    />
    <path
      fill="#78824B"
      d="m404.643 385.198-22.735.003-22.729-.022-45.176.038-.251-22.749.03-.03 22.729.011c6.233.004 12.468-.098 18.697.059 2.997.075 4.115-1.008 4.036-4.021-.162-6.229-.064-12.464-.072-18.697l-.097-22.781.225.016 22.501-.097.177.177c-.02 6.126.101 12.258-.115 18.378-.117 3.332 1.119 4.436 4.386 4.335 6.121-.19 12.253-.054 18.38-.054l.014-.004.023 22.7-.018 22.731-.005.007z"
    />
    <path
      fill="#58633B"
      d="m359.178 385.18 22.729.022-.006 22.734-22.659-.132c-.02-7.543-.042-15.083-.064-22.624z"
    />
    <path
      fill="#4E5339"
      d="M313.809 90.075c7.53.004 15.059.009 22.59.012.031 7.527.061 15.054.09 22.582l.023-.023c-6.116-.001-12.235-.132-18.345.051-3.211.096-4.53-.899-4.414-4.278.211-6.107.058-12.228.056-18.344zM336.544 135.263l-.032-22.616-.023.023 22.739-.009-.157 22.561-22.61.218.083-.177z"
    />
    <path
      fill="#969D7A"
      d="m313.85 157.958.007-22.491 22.686-.203-.082.175.052 22.628-.038.038c-7.541-.05-15.083-.099-22.625-.147z"
    />
    <path
      fill="#6A7041"
      d="m359.228 112.66-22.739.009-.091-22.582 45.53-.139-.009 22.612-22.691.1z"
    />
    <path
      fill="#8C8D6C"
      d="m359.228 112.66 22.692-.1 22.699.111.008 22.686.068.107-22.774-.089-22.524.179-.326-.333.157-22.561z"
    />
    <path
      fill="#868D5C"
      d="m404.628 135.356-.008-22.686 22.726-.001-.005 22.573.017.349c-7.578-.077-15.154-.156-22.73-.235z"
    />
    <path
      fill="#969D7A"
      d="m427.34 135.243.005-22.573c7.511-.052 15.021-.103 22.53-.156l.221 22.747-22.756-.018z"
    />
    <path
      fill="#78824B"
      d="m404.63 67.232.011 22.715-22.762-.027.051-22.683 22.7-.005z"
    />
    <path
      fill="#58633B"
      d="m313.85 157.958 22.625.146-.002 22.628-22.531.106-22.818-.04-.112-.026.118-22.66 22.72-.154z"
    />
    <path
      fill="#78824B"
      d="m291.13 158.112-.117 22.66-22.553.032-.195-.038c.109-7.548.22-15.096.332-22.643l22.533-.011z"
    />
    <path
      fill="#6A7041"
      d="m268.46 180.804 22.553-.032.111.026-.051 22.689.058.052-22.733-.05-.059.007.121-22.692z"
    />
    <path
      fill="#626B43"
      d="m518.175 271.626-22.722.073-22.737-.151.051-22.632h-.009l22.722-.001 22.601-.024.094 22.735z"
    />
    <path
      fill="#58633B"
      d="m495.48 248.915-22.722.001-.013-22.686.035-.035 22.665.046.035 22.674zM291.123 271.517l-22.779.051.059-22.579c0 .001-.011-.132-.012-.131 7.567.075 15.135.149 22.702.222l.03 22.437z"
    />
    <path
      fill="#64684F"
      d="m268.398 203.489 22.732.049-.133 22.591-22.61.151.011-22.791z"
    />
    <path
      fill="#8C8D6C"
      d="m268.387 226.28 22.61-.151.187.212-.082 22.322-.009.417-22.702-.223-.004-22.577z"
    />
    <path
      fill="#64684F"
      d="m427.352 407.839-.006-22.653 22.703-.037.092.159-.018 22.515-22.771.016z"
    />
    <path
      fill="#6A7041"
      d="m291.123 271.517-.029-22.437.009-.415c7.523.069 15.046.136 22.569.203l22.856.097-.099 22.634-22.614.02-22.692-.102z"
    />
    <path
      fill="#969D7A"
      d="m313.87 339.709.069-22.469 22.56-.184-.121.144.173 22.584-.124-.069-22.557-.006z"
    />
    <path
      fill="#4E5339"
      d="m336.498 317.056-.017-22.641-.08-.321 22.814.25.084 22.684-.223-.016-22.7.189.122-.145z"
    />
    <path
      fill="#969D7A"
      d="m313.815 271.62 22.615-.02-.028 22.493.08.321c-6.256-.046-12.514-.234-18.764-.077-3.026.076-4.072-.887-3.974-3.948.2-6.251.067-12.512.071-18.769z"
    />
    <path
      fill="#78824B"
      d="m427.354 226.202-.065 45.493-22.646-.005c-.006-6.059-.168-12.124.044-18.175.12-3.441-.969-4.78-4.537-4.647-6.05.225-12.116.041-18.175.03-.024-6.12-.191-12.245-.013-18.36.095-3.265-.985-4.488-4.319-4.373-6.114.212-12.24.072-18.361.078l-.068-22.696-.047-.109c6.058.019 12.121-.114 18.17.115 3.447.131 4.771-.983 4.641-4.542-.221-6.049-.035-12.112-.02-18.17l22.693-.029c-.004 6.239.098 12.48-.059 18.714-.076 3.029 1.08 4.086 4.062 4.016 6.235-.146 12.476-.04 18.714-.038l-.014 22.698z"
    />
    <path
      fill="#848273"
      d="m290.997 226.13.133-22.591-.058-.051c6.071-.002 12.148-.165 18.211.057 3.426.125 4.783-.888 4.645-4.497-.232-6.06-.014-12.138.014-18.208l22.531-.106.042 22.747-.079 22.688-22.627.047-22.626.128-.186-.214z"
    />
    <path
      fill="#8C8D6C"
      d="m336.514 158.066-.052-22.628 22.61-.217.325.332-.142 22.517-22.741-.004z"
    />
    <path
      fill="#6A7041"
      d="m381.997 158.069-.075-22.694 22.774.089-.011 22.532-22.688.073z"
    />
    <path
      fill="#868D5C"
      d="M381.957 180.843c-.015 6.057-.201 12.121.02 18.17.13 3.559-1.194 4.673-4.641 4.542-6.049-.229-12.112-.096-18.17-.115.012-6.06.197-12.127-.03-18.178-.134-3.57 1.202-4.662 4.643-4.531 6.053.229 12.119.094 18.178.112z"
    />
    <path
      fill="#626B43"
      d="m336.436 226.167.08-22.687 22.697.068.068 22.696-.13-.026-22.533.046-.182-.097z"
    />
    <path
      fill="#6A7041"
      d="m472.744 226.23.013 22.686h.009l-22.709.007-.003-22.702 22.69.009z"
    />
    <path
      fill="#4E5339"
      d="m450.058 248.923 22.709-.007-.051 22.631-22.633.181-.025-22.805z"
    />
    <path
      fill="#8C8D6C"
      d="m404.643 271.69 22.646.005-.04 22.611-22.579-.019-.027-22.597z"
    />
    <path
      fill="#868D5C"
      d="m404.643 271.69.027 22.597-22.791.086-.052.043.009-22.897-.092-22.535.23-.087c6.059.012 12.125.196 18.175-.029 3.568-.133 4.657 1.206 4.537 4.647-.211 6.052-.048 12.116-.043 18.175z"
    />
    <path
      fill="#58633B"
      d="m427.341 317.041.004 22.724-.011-.007-22.692.004-.013.004.026-22.689 22.686-.036z"
    />
    <path
      fill="#6A7041"
      d="m404.654 317.077-.026 22.689c-6.127 0-12.259-.136-18.38.054-3.267.101-4.503-1.002-4.386-4.335.215-6.12.095-12.252.115-18.378l22.677-.03z"
    />
    <path
      fill="#A4AA93"
      d="m359.299 317.027-.084-22.684.096.056 22.516.018.053-.044-.079 22.557-22.502.097z"
    />
    <path
      fill="#626B43"
      d="m404.642 339.762 22.692-.004.009 22.715.01.01-22.688-.021-.023-22.7z"
    />
    <path
      fill="#58633B"
      d="m450.049 385.149-22.703.037-.008-.008.015-22.695-.01-.01 22.7-.028.006 22.704z"
    />
    <path
      fill="#6A7041"
      d="m427.353 362.483-.015 22.695-22.691.015.018-22.731 22.688.021z"
    />
    <path
      fill="#868D5C"
      d="m313.87 339.709 22.557.005.083 22.736-22.729-.011.089-22.73z"
    />
    <path
      fill="#58633B"
      d="m336.51 362.451-.083-22.736.123.069 22.621.008c.009 6.233-.089 12.468.072 18.697.078 3.013-1.039 4.096-4.036 4.021-6.229-.157-12.464-.055-18.697-.059z"
    />
    <path
      fill="#6A7041"
      d="m359.171 339.792-22.621-.008-.173-22.584 22.699-.188.095 22.78z"
    />
    <path
      fill="#4E5339"
      d="M313.943 180.838c-.029 6.07-.246 12.148-.014 18.208.138 3.608-1.219 4.622-4.645 4.497-6.063-.222-12.14-.059-18.211-.057l.051-22.689 22.819.041z"
    />
    <path
      fill="#A4AA93"
      d="m291.184 226.342 22.626-.128-.139 22.654-22.569-.203.082-22.323z"
    />
    <path
      fill="#848273"
      d="m336.401 294.093.028-22.493.098-22.635-.026-.058 22.604-.093.276.277-.107 22.45.037 22.859-.097-.057-22.813-.25z"
    />
    <path
      fill="#64684F"
      d="m313.671 248.868.139-22.654 22.627-.047.181.096-.117 22.644.026.058-22.856-.097z"
    />
    <path
      fill="#848273"
      d="M359.281 226.244c6.121-.007 12.247.133 18.361-.078 3.334-.115 4.414 1.108 4.319 4.373-.178 6.115-.011 12.24.013 18.36l-.228.086-22.365.107-.276-.276.046-22.597.13.025z"
    />
    <path
      fill="#4E5339"
      d="m359.152 226.217-.046 22.597-22.605.092.117-22.644c7.512-.014 15.023-.029 22.534-.045z"
    />
    <path
      fill="#A4AA93"
      d="m359.382 249.091 22.365-.107c.029 7.512.06 15.024.091 22.536l-22.563.02.107-22.449z"
    />
    <path
      fill="#8C8D6C"
      d="m359.275 271.541 22.563-.02-.01 22.896c-7.506-.005-15.012-.012-22.517-.018l-.036-22.858z"
    />
    <path fill="#0F7540" d="M404.837 580.088h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M381.945 580.088h22.892v22.892h-22.892z"/>
    <path
      fill="#36321E"
      d="M381.945 557.196h22.892v22.892h-22.892zM381.945 534.304h22.892v22.892h-22.892z"
    />
    <path fill="#0F7540" d="M427.729 580.088h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M290.377 602.98h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M313.269 602.98h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M336.161 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M359.053 602.98h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M381.945 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M404.837 602.98h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M427.729 602.98h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M450.621 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M473.514 602.98h22.892v22.892h-22.892z"/>
    <path fill="#A7C56C" d="M496.406 602.98h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M519.297 602.98h22.892v22.892h-22.892z"/>
    <path fill="#E4EACD" d="M542.189 602.98h22.892v22.892h-22.892z"/>
  </svg>
);
export default PinusSylvestris;