import React, {FC, SVGProps} from 'react';

const PinusPinea: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 808.108 711.158"
    height="100%"
    fill="none"
    {...props}
  >
    <path fill="#696862" d="M391.311 347.716h23.392v23.517h-23.392z"/>
    <path
      fill="#91A85A"
      d="m345.728 95.412 22.834.017.107-.107.132.075-.076 22.985-.112 23.073-22.879.053-22.934-.124-.053-22.892c6.791-.022 13.581-.063 20.371-.051 1.468.002 2.59-.012 2.549-2.046-.138-6.976-.139-13.956-.192-20.934l.253-.049z"
    />
    <path
      fill="#393C17"
      d="M529.509 325.199c-14.167.023-28.334.043-42.501.073-1.146.002-2.291.071-3.437.11l-22.946-.195-.016-22.908 22.903-.033 23.082.012 22.922-.029-.007 22.97z"
    />
    <path
      fill="#C8C5B6"
      d="m230.835 164.432-.058-22.937 22.985-.086 22.932.044-.111 22.934-22.82.063-22.928-.018z"
    />
    <path
      fill="#5E643E"
      d="m276.721 302.305-22.915.075-23.039-.093-.084.052-.008-22.963 46.055-.051-.013 23.01.004-.03z"
    />
    <path
      fill="#4B5624"
      d="m552.587 164.377-.008 23.022-.078-.133-.146.047-22.78.068.06-22.943 22.78.013.172-.074z"
    />
    <path
      fill="#D6D6D6"
      d="m230.675 279.377.009 22.963-23.004-.08.09-22.946-.047.023 22.982.058-.03-.018z"
    />
    <path
      fill="#5E643E"
      d="m207.769 279.314-.09 22.946c-6.959.016-13.919-.016-20.876.085-1.628.024-2.094-.446-2.072-2.073.094-6.958.055-13.918.065-20.878l22.973-.08z"
    />
    <path
      fill="#ACADA3"
      d="m575.61 256.453-.192 22.8-22.898.122-.017-22.937 22.921-.177.186.192z"
    />
    <path
      fill="#4B5624"
      d="m230.657 210.386.14-22.937 22.915-.036-.124 22.948-22.726-.044-.205.069z"
    />
    <path
      fill="#ACADA3"
      d="m253.713 187.413-22.915.036.037-23.017 22.929.019-.051 22.962zM598.42 233.299c.028 6.96.002 13.921.123 20.88.032 1.836-.675 2.219-2.339 2.209-6.864-.043-13.729.033-20.593.065l-.185-.193.055-22.899 22.939-.062z"
    />
    <path
      fill="#C8C5B6"
      d="m207.642 256.323-22.91.145.037-.156-.019-22.919 22.893-.141.195.19.054 22.942-.25-.061z"
    />
    <path
      fill="#ACADA3"
      d="m184.749 233.394.019 22.919c-6.962.017-13.923-.002-20.884.079-1.58.018-2.178-.439-2.157-2.093.078-6.293.076-12.589.001-18.883-.019-1.632.535-2.133 2.138-2.113 6.96.088 13.921.07 20.883.091z"
    />
    <path
      fill="#677544"
      d="m598.42 233.299-22.94.062.001.031.094-22.848.037-.144-.117-.091c6.876.021 13.752.102 20.627.021 1.822-.021 2.449.412 2.41 2.343-.14 6.874-.091 13.751-.112 20.626z"
    />
    <path
      fill="#C8C5B6"
      d="M207.642 233.253c.04-6.777.153-13.556.077-20.332-.023-2.016.603-2.614 2.599-2.581 6.778.11 13.56.045 20.34.046l.206-.069-.194 22.997-22.833.129-.195-.19z"
    />
    <path
      fill="#ACADA3"
      d="M552.578 187.399c6.888.001 13.777.053 20.664-.032 1.752-.021 2.31.514 2.288 2.278-.087 6.887-.036 13.776-.036 20.664l.117.091-.037.143-23.075-.178-.146-23.052.146-.047.079.133z"
    />
    <path
      fill="#C8C5B6"
      d="m414.615 72.285 23.014.263-.02 22.784c-6.871.048-13.743.014-20.61.191-2.11.054-2.501-.706-2.471-2.616.105-6.778.04-13.558.04-20.338a.246.246 0 0 1 .047-.284z"
    />
    <path
      fill="#393C17"
      d="M299.677 325.276c-6.988.008-13.976-.034-20.963.06-1.629.022-2.082-.449-2.06-2.068.095-6.987.057-13.975.067-20.963l-.003.03 23.03-.023-.114 22.938.043.026zM368.562 95.43l.146-22.92 22.944-.107-.031.09c0 6.776-.053 13.553.036 20.329.025 1.905-.427 2.72-2.529 2.678-6.773-.135-13.551-.082-20.326-.101l-.132-.075-.108.106z"
    />
    <path
      fill="#6F7551"
      d="M322.731 72.519c6.965-.019 13.931.01 20.895-.092 1.639-.024 2.092.476 2.077 2.091-.063 6.964.005 13.93.025 20.895l-.253.049-22.832.049.025-.154.063-22.838z"
    />
    <path
      fill="#C8C5B6"
      d="m322.731 72.519-.063 22.838-22.965.198-.029-.256c0-6.757.063-13.515-.038-20.271-.029-1.947.489-2.661 2.542-2.62 6.849.135 13.702.088 20.553.111z"
    />
    <path
      fill="#87935A"
      d="m529.509 325.199.007-22.97-.034.076h22.902l.137-.046.132.058c-.047 6.877-.13 13.754-.113 20.63.004 1.566-.146 2.424-2.108 2.383-6.972-.146-13.948-.102-20.923-.131z"
    />
    <path
      fill="#979684"
      d="m391.652 72.402-22.944.107c-.017-6.865.048-13.732-.101-20.594-.046-2.123.75-2.494 2.638-2.464 6.768.107 13.539.057 20.309.066l.098 22.885z"
    />
    <path
      fill="#5E643E"
      d="m322.74 348.252-.001-22.903-.034.019 22.903-.122.042 22.916.02.249-22.93-.159z"
    />
    <path
      fill="#393C17"
      d="m276.695 141.453-22.932-.044.017-22.951 22.923-.084-.133.077.044.147c.028 7.618.054 15.237.081 22.855z"
    />
    <path
      fill="#ACADA3"
      d="m437.61 95.331.02-22.784h22.955l-.019 22.856-22.953.17-.003-.242z"
    />
    <path
      fill="#91A85A"
      d="m460.565 95.404.019-22.856 22.981.032.025 22.794-23.082.211.057-.181z"
    />
    <path
      fill="#979684"
      d="m483.591 95.373-.025-22.794 22.925-.03.086 22.845-23.024.165.038-.186z"
    />
    <path
      fill="#C8C5B6"
      d="m506.576 95.395-.086-22.845c6.947-.032 13.896-.013 20.842-.129 1.801-.03 2.26.642 2.242 2.33-.073 6.853-.031 13.708-.032 20.561l.001.265-23.013-.093.046-.089z"
    />
    <path
      fill="#87935A"
      d="M276.703 118.374c0-6.87.07-13.74-.044-20.608-.032-1.921.592-2.375 2.42-2.36 6.864.053 13.729-.058 20.594-.107l.029.256-.087 22.827-23.002.217-.044-.147.134-.078z"
    />
    <path
      fill="#979684"
      d="m230.767 302.287 23.039.093c-.013 6.78-.085 13.56-.001 20.339.023 1.871-.308 2.707-2.453 2.657-6.108-.144-12.223-.094-18.333-.021-1.622.019-2.393-.256-2.357-2.157.129-6.969.084-13.94.105-20.911z"
    />
    <path
      fill="#D6D6D6"
      d="m552.52 279.375 22.898-.122c.024 6.887-.01 13.776.109 20.662.033 1.929-.664 2.438-2.496 2.416-6.792-.08-13.585-.021-20.378-.013l-.132-.058-.137.045.087-22.942.049.012z"
    />
    <path
      fill="#87935A"
      d="M529.542 95.312c6.881.045 13.763.16 20.643.09 1.904-.019 2.394.562 2.378 2.414-.058 6.879.056 13.76.105 20.641l-.137.052-.116-.089-22.815-.116-.057-22.726-.001-.266z"
    />
    <path
      fill="#4B5624"
      d="M368.801 95.398c6.776.019 13.553-.034 20.326.101 2.102.042 2.554-.773 2.529-2.678-.088-6.775-.035-13.552-.036-20.329l22.947.077c0 6.779.065 13.56-.04 20.338-.03 1.91.362 2.67 2.471 2.616 6.867-.177 13.74-.144 20.61-.191l.003.243.016 22.791-46.064.142-22.84-.124.078-22.986zM299.616 118.381l.087-22.827 22.966-.198-.025.154.104 22.982.053 22.893-.243.061-22.819.022-.123-23.087z"
    />
    <path
      fill="#393C17"
      d="m345.735 141.509 22.879-.053-.009-.003.075 22.931-23.036.023.091-22.898z"
    />
    <path
      fill="#677544"
      d="m345.735 141.509-.091 22.898.024-.017-23.01.032-.101-22.976.244-.061 22.934.124z"
    />
    <path
      fill="#979684"
      d="m368.725 118.383 22.841.124.041 22.881-23.002.065.009.003.111-23.073z"
    />
    <path
      fill="#677544"
      d="m322.747 118.492-.104-22.982 22.833-.049c.052 6.978.053 13.957.191 20.934.04 2.034-1.081 2.048-2.549 2.046-6.79-.011-13.581.029-20.371.051z"
    />
    <path
      fill="#6F7551"
      d="m460.625 325.187-23.034.187-22.966-.072.012-23.008 22.937.005 23.114-.058-.078.038.015 22.908z"
    />
    <path
      fill="#979684"
      d="m483.512 302.246-.017-22.953.153.075 22.96-.005-.014 22.894-23.082-.011z"
    />
    <path
      fill="#677544"
      d="m299.616 118.381.121 23.086-.015 22.915-22.887.022-.251-.016.111-22.934-.081-22.855 23.002-.218z"
    />
    <path
      fill="#979684"
      d="m253.713 187.413.051-22.962 22.82-.063.251.016-.058 23.024-.127 22.98-22.789-.006-.271-.042.123-22.947z"
    />
    <path
      fill="#87935A"
      d="m276.731 279.326-46.055.051.029.017.05-23.041h-.017l45.984-.016-.024 23.011.033-.022z"
    />
    <path
      fill="#979684"
      d="m299.748 302.312-23.03.024.013-23.01-.034.022 22.992-.028.019 23.031.04-.039z"
    />
    <path
      fill="#6F7551"
      d="m552.413 164.451-22.781-.014-.192-.049.017-22.839 22.967-.157-.011 23.059z"
    />
    <path
      fill="#4B5624"
      d="m552.424 141.394-22.966.156.076-.176-.043-22.848.109-.223 22.815.116.009 22.975z"
    />
    <path
      fill="#6F7551"
      d="m552.354 187.314.146 23.052.031-.024-22.947.042-22.992-.042.009-22.973 22.973.013 22.78-.068z"
    />
    <path
      fill="#87935A"
      d="m529.573 187.381-22.974-.013.066-23.01 22.775.03.192.049-.059 22.944z"
    />
    <path
      fill="#677544"
      d="m230.754 256.353-.05 23.041-22.982-.057-.08-23.013.249.062 22.863-.033z"
    />
    <path
      fill="#393C17"
      d="m575.48 233.361-.055 22.899-22.921.177-22.968-.092.006-22.996-.033.033 22.946-.125 23.026.134-.001-.03z"
    />
    <path
      fill="#C8C5B6"
      d="m529.536 256.346 22.968.092.017 22.937-.049-.012-22.955-.047-.001.035.02-23.005z"
    />
    <path
      fill="#677544"
      d="m230.67 233.314.194-22.997 22.726.044.271.042-.032 22.903-23.017.095-.142-.087z"
    />
    <path
      fill="#6F7551"
      d="m230.754 256.353-22.863.032-.054-22.942 22.833-.129.142.087-.075 22.952h.017z"
    />
    <path
      fill="#87935A"
      d="m575.481 233.392-23.026-.134.076-22.916-.031.024 23.075.178-.094 22.848z"
    />
    <path
      fill="#24210C"
      d="m529.516 279.317 22.955.047-.087 22.942h-22.902l.034-22.989zM368.635 348.337l-22.986-.176-.042-22.916.029.032 22.911.024.088 23.036z"
    />
    <path
      fill="#5E643E"
      d="m368.635 348.337-.088-23.036.257-.05 22.819.042.028-.033-.019 23.008.035 22.911-22.989.024-.043-22.866z"
    />
    <path
      fill="#24210C"
      d="m437.629 118.365-.016-22.791 22.953-.17-.057.18.018 22.671-22.903.186.005-.076z"
    />
    <path
      fill="#4B5624"
      d="m460.526 118.255-.018-22.671 23.082-.211-.038.186.005 22.867-22.921.094-.11-.265z"
    />
    <path
      fill="#393C17"
      d="m483.558 118.426-.005-22.867 23.024-.165-.046.09.03 22.982.015-.033-23.043.04.025-.047z"
    />
    <path
      fill="#5E643E"
      d="m506.56 118.467-.03-22.983 23.013.093.057 22.726-.108.222-22.932-.058z"
    />
    <path
      fill="#91A85A"
      d="m391.607 141.388-.041-22.881 46.063-.142-.006.076-.048 22.924c-6.702.015-13.405.115-20.104-.001-2.201-.038-2.979.497-2.931 2.843.137 6.697.001 13.4-.027 20.101l-22.866-.008-.04-22.912z"
    />
    <path
      fill="#24210C"
      d="m299.723 164.382.015-22.915 22.819-.022.1 22.976.039-.027-22.987-.031.014.019z"
    />
    <path
      fill="#5E643E"
      d="m345.644 164.407 23.036-.023-.016-.021c-.008 6.776-.096 13.553.024 20.327.038 2.166-.654 2.777-2.774 2.737-6.774-.127-13.551-.06-20.327-.068l.08-22.968-.023.016z"
    />
    <path
      fill="#87935A"
      d="m391.607 141.388.04 22.913-22.983.061.016.021-.075-22.931 23.002-.064z"
    />
    <path
      fill="#121109"
      d="m345.667 164.39-.079 22.968.069.011-22.981.017.021-22.991-.039.027 23.009-.032z"
    />
    <path
      fill="#D6D6D6"
      d="m437.573 302.299-22.937-.005-.029.01-.092-22.997.184-22.935 22.866.034.126 22.912-.118 22.981z"
    />
    <path
      fill="#677544"
      d="m437.573 302.299.118-22.981 22.808.034.194-.064-.005 22.953-23.115.058z"
    />
    <path
      fill="#393C17"
      d="m414.636 302.294-.012 23.008-.005.005-22.969-.046-.028.033.174-22.961 22.81-.028.03-.011z"
    />
    <path
      fill="#ACADA3"
      d="m483.649 279.369-.143-22.901.119-.244 22.917.072.031 23.081.036-.013-22.96.005z"
    />
    <path
      fill="#979684"
      d="m506.572 279.377-.031-23.081.062-22.911 22.938-.035-.006 22.996-.021 23.006-22.942.025z"
    />
    <path
      fill="#6F7551"
      d="m391.651 325.26 22.969.046-.042 22.991-22.945-.029.018-23.008z"
    />
    <path
      fill="#4B5624"
      d="m276.777 187.428.058-23.024 22.887-.022-.014-.018-.045 23.073-22.886-.009z"
    />
    <path
      fill="#121109"
      d="m276.777 187.428 22.887.008-.001 22.927-22.926-.014-.087.06.127-22.981z"
    />
    <path
      fill="#5E643E"
      d="m253.829 233.306 22.841.031.043.003-.014 23.019.022-.023-45.984.016.075-22.952 23.017-.094z"
    />
    <path
      fill="#4B5624"
      d="m299.688 279.32-22.992.028.024-23.011-.022.023 22.983-.037-.022 22.974.029.023z"
    />
    <path
      fill="#677544"
      d="m322.72 302.419-23.013-.068-.019-23.031-.028-.023 22.993.022-.001 22.913.068.187z"
    />
    <path
      fill="#979684"
      d="m529.441 164.389-22.775-.03-.175.044.095-22.974-.034.041 22.982-.096-.076.176-.017 22.839z"
    />
    <path
      fill="#121109"
      d="m529.534 141.374-22.982.095.023-23.036-.015.033 22.931.059.043 22.849z"
    />
    <path
      fill="#4B5624"
      d="m506.666 164.359-.066 23.01-.009 22.973-46.051-.018.151-22.982c6.783.015 13.569-.041 20.351.085 2.065.038 2.589-.71 2.563-2.654-.09-6.783-.027-13.568-.022-20.353l22.908-.016.175-.045z"
    />
    <path
      fill="#393C17"
      d="m460.54 210.323 46.051.019 22.993.041-.075 22.999.033-.033-22.938.035-23.11.01-22.882.087-.033-.267.01-22.807-.049-.084z"
    />
    <path
      fill="#C8C5B6"
      d="m529.509 233.383.075-22.999 22.947-.042-.076 22.916-22.946.125z"
    />
    <path
      fill="#4B5624"
      d="m322.652 302.232.001-22.912.018-22.99-.031-22.941.073-.072 22.93.031.105 22.983-.003 23-.086 23.003.019-.047-23.026-.055z"
    />
    <path
      fill="#393C17"
      d="m368.547 325.301-22.911-.024.043-22.99-.019.047 22.901.061.243 22.856-.257.05z"
    />
    <path
      fill="#979684"
      d="m368.804 325.251-.243-22.856.185-.189 22.765.068.149-.005.136.063-.174 22.961-22.818-.042z"
    />
    <path
      fill="#677544"
      d="m437.575 141.365.048-22.924 22.904-.185.11.264.025 22.81-23.087.035z"
    />
    <path
      fill="#ACADA3"
      d="m460.662 141.329-.025-22.81 22.921-.094-.025.047-.037 22.865.044.11-22.911-.006.033-.112z"
    />
    <path
      fill="#677544"
      d="m483.495 141.338.037-22.864 23.042-.04-.023 23.036.034-.041-23.09-.091z"
    />
    <path
      fill="#4B5624"
      d="m368.664 164.362 22.983-.061 22.866.007.183.177-.084 22.938.036-.029c-6.871-.009-13.742.033-20.611-.061-1.851-.025-2.503.541-2.473 2.436.107 6.869.07 13.74.088 20.61l-.003 22.973-22.944-.015-.126.065c.023-6.864-.029-13.729.113-20.59.045-2.158-.879-2.49-2.72-2.47-6.768.072-13.538.018-20.307.011l-.002.018-.006-23.001-.069-.011c6.775.008 13.553-.06 20.326.068 2.12.04 2.812-.571 2.774-2.737-.121-6.775-.032-13.552-.024-20.328z"
    />
    <path
      fill="#4B5624"
      d="m437.575 141.365 23.086-.036-.033.112.077 22.928-.196.065-22.915-.026-22.898.078-.183-.178c.028-6.701.164-13.404.027-20.101-.048-2.346.73-2.881 2.931-2.843 6.699.116 13.402.017 20.104.001z"
    />
    <path
      fill="#5E643E"
      d="m299.663 210.363.001-22.927.045-23.072 22.987.031-.021 22.991.026 22.954-23.038.023z"
    />
    <path
      fill="#87935A"
      d="m322.702 210.34-.027-22.954 22.981-.017.006 23.001-22.959-.026-.001-.004z"
    />
    <path
      fill="#5E643E"
      d="m460.643 256.417-.144 22.936-22.808-.034-.126-22.912.016-22.975 22.998-.217.033.267.031 22.935zM414.607 302.304l-22.81.028-.136-.063-.149.005.133-22.951 22.87-.015.092 22.996z"
    />
    <path
      fill="#677544"
      d="m437.581 233.431-.016 22.975-22.866-.034-.258-.062.198-22.857 22.942-.022z"
    />
    <path
      fill="#4B5624"
      d="m414.515 279.307-22.87.015-.067.047.016-23.004 22.846-.055.259.061-.184 22.936z"
    />
    <path
      fill="#979684"
      d="m460.643 256.417-.031-22.937 22.882-.086.131 22.83-.119.244-22.863-.051z"
    />
    <path
      fill="#4B5624"
      d="m483.625 256.224-.131-22.83 23.109-.01-.062 22.912-22.916-.072zM299.663 210.363l23.039-.023.002.004.01 22.973-.072.072-22.981-.026-22.947-.022-.043-.003.067-22.989 22.925.014z"
    />
    <path
      fill="#87935A"
      d="m276.713 233.341 22.947.022.006 22.99.017-.03-22.983.037.013-23.019z"
    />
    <path
      fill="#6F7551"
      d="m322.671 256.33-.018 22.99-22.993-.022.022-22.974-.017.03 23.006-.024z"
    />
    <path
      fill="#393C17"
      d="m483.495 141.338 23.091.091-.095 22.974-22.908.016-.047-.046.003-22.926-.044-.109z"
    />
    <path
      fill="#6F7551"
      d="m460.691 187.341-.151 22.983.049.084-45.917-.094-.025-22.919-.036.029 22.925-.052 23.02.016.135-.047z"
    />
    <path
      fill="#393C17"
      d="M483.583 164.419c-.005 6.784-.068 13.57.022 20.353.026 1.944-.499 2.692-2.563 2.654-6.782-.125-13.567-.07-20.351-.085l-.134.047-.048-22.954.196-.065 22.832.004.046.046z"
    />
    <path
      fill="#24210C"
      d="m414.672 210.313 45.917.094-.01 22.807-22.997.216-22.942.022-.041-.192-.036-22.839.109-.108z"
    />
    <path
      fill="#979684"
      d="m368.561 302.394-22.901-.061.085-23.003 22.926.019.075 22.855-.185.19zM345.749 256.331l-.105-22.983.067.056 22.868-.001.126-.065.006 22.96-22.962.033z"
    />
    <path
      fill="#24210C"
      d="m322.671 256.33-23.005.023-.006-22.99 22.981.026.03 22.941z"
    />
    <path
      fill="#5E643E"
      d="m322.713 233.317-.01-22.973 22.959.026.002-.018.046 23.051-.067-.056-22.93-.03z"
    />
    <path
      fill="#87935A"
      d="m345.749 256.331 22.962-.033-.046-.009-.001 23.066.007-.005-22.926-.019.004-23z"
    />
    <path
      fill="#24210C"
      d="m483.537 164.373-22.832-.004-.077-22.928 22.912.006-.003 22.926z"
    />
    <path
      fill="#393C17"
      d="m368.71 256.298-.006-22.96 22.944.015 22.949-.092.041.192-.198 22.857-22.847.055-22.929-.076.046.009z"
    />
    <path
      fill="#24210C"
      d="m414.648 187.395.025 22.919-.11.108-22.911-.042c-.017-6.87.02-13.741-.088-20.61-.03-1.895.622-2.462 2.473-2.436 6.869.094 13.74.052 20.611.061z"
    />
    <path
      fill="#121109"
      d="m437.537 187.372-22.925.052.084-22.938 22.898-.078-.057 22.964zM391.651 210.38l22.911.042.036 22.84-22.949.091.002-22.973z"
    />
    <path
      fill="#6F7551"
      d="m345.71 233.403-.046-23.051c6.769.007 13.539.061 20.307-.011 1.841-.02 2.765.312 2.72 2.47-.142 6.861-.09 13.726-.113 20.59-7.622.002-15.245.002-22.868.002z"
    />
    <path
      fill="#87935A"
      d="m437.537 187.372.057-22.964 22.915.026.048 22.954-23.02-.016z"
    />
    <path
      fill="#979684"
      d="m368.665 256.289 22.929.076-.016 23.004-22.913-.015v-23.065z"
    />
    <path fill="#2E4029" d="M368.343 599.576h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M368.343 576.743h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M368.343 553.868h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M368.343 530.986h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M390.935 576.743h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M390.935 553.868h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M390.935 530.986h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M391.311 508.593h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M391.311 485.718h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M391.311 462.836h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M413.902 508.593h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M413.902 485.718h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M413.902 462.836h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M391.311 440.066h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M391.311 417.191h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M391.311 394.309h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M413.902 440.066h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M413.902 417.191h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M436.768 394.799h22.892v22.892h-22.892z"/>
    <path
      fill="#D6D6D6"
      d="M460.008 394.799H482.9v22.892h-22.892zM414.414 394.799h22.892v22.892h-22.892zM437.011 417.797h22.892v22.892h-22.892zM437.011 440.794h22.892v22.892h-22.892z"
    />
    <path fill="#D6D6D6" d="M482.714 372.236h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M459.852 372.196h22.892v22.892h-22.892z"/>
    <path fill="#91A85A" d="M459.852 349.783h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M482.862 349.434h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M505.665 349.35h22.892v22.892h-22.892z"/>
    <path fill="#87935A" d="M505.616 372.241h22.892v22.892h-22.892z"/>
    <path fill="#87935A" d="M528.495 349.405h22.892v22.892h-22.892z"/>
    <path fill="#91A85A" d="M528.495 372.283h22.892v22.892h-22.892z"/>
    <path fill="#2E4029" d="M482.862 325.187h22.892v24.377h-22.892z"/>
    <path fill="#696862" d="M391.311 370.966h22.892v23.642h-22.892z"/>
    <path fill="#ACADA3" d="M414.267 348.341h22.892v22.892h-22.892z"/>
    <path fill="#D6D6D6" d="M345.936 348.341h22.892v22.892h-22.892z"/>
    <path fill="#36321E" d="M391.236 599.576h22.892v22.892h-22.892z"/>
    <path
      fill="#0F7540"
      d="M414.127 599.576h22.892v22.892h-22.892zM437.019 599.576h22.892v22.892h-22.892z"
    />
    <path fill="#80A82B" d="M299.667 622.468h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M322.559 622.468h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M345.451 622.468h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M368.343 622.468h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M391.236 622.468h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M414.127 622.468h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M437.019 622.468h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M459.911 622.468h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M482.803 622.468h22.892v22.892h-22.892z"/>
    <path fill="#A7C56C" d="M505.695 622.468h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M528.588 622.468h22.892v22.892h-22.892z"/>
    <path fill="#E4EACD" d="M551.48 622.468h22.892v22.892H551.48z"/>
  </svg>
);
export default PinusPinea;