import {BaseInitiative} from '@/domain/entities/Initiative';
import {FeatureCollection, MultiPolygon} from 'geojson';

export const asFeatureCollection = (availableInitiatives: Array<BaseInitiative>): FeatureCollection<MultiPolygon> => ({
  'type': 'FeatureCollection',
  'features': availableInitiatives.flatMap(availableInitiative =>
    availableInitiative.parcels_rsc.features.map(feature => ({
      'id': feature.id,
      'type': feature.type,
      'geometry': feature.geometry,
      'properties': {
        'name': availableInitiative.name,
        'description': availableInitiative.description,
        'place': availableInitiative.place,
        'objective': availableInitiative.objective,
        'investment': availableInitiative.investment,
        'year': availableInitiative.year,
        'owners': availableInitiative.owners,
        'area': availableInitiative.area,
        'sustainability_actions': availableInitiative.sustainability_actions,
        'bbox': availableInitiative.bbox,
        'status': availableInitiative.status
      }
    })))
});
