import React, {FC} from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Paper from '@mui/material/Paper';

//TYPES
export type PerformersCardProps = {
  logoPerformers: Array<string>,
  height?: string
};

const PerformersCard: FC<PerformersCardProps> = ({logoPerformers, height='85px'}) => {
  return <Paper sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', m: 1, height: height, p: 1, gap: 2}} elevation={0}>
    {
      logoPerformers && logoPerformers.length > 0 ?
        logoPerformers.map((logo, index) => <img key={index} src={`${logo}`} alt={logo} style={{maxHeight: height}}/>)
        : <AccountCircleIcon sx={{fontSize: '40px'}}/>
    }
  </Paper>;
};
export default PerformersCard;