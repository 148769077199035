import React, {FC} from 'react';
import {BrowserRouter, Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom';
import i18n from 'i18next';
import MapView from '../views/Map';
import LoginView from '../views/Login';
import PrivateRoute from './PrivateRoute';
import LandingView from '@/views/Landing';
import Error from '@/components/Error';

const LangSetter: FC = () => {
  const {lang} = useParams();
  if (i18n.resolvedLanguage !== lang) {
    i18n.changeLanguage(lang);
  }
  return <Outlet/>;
};

const AppRoutes: FC = () =>
  <BrowserRouter >
    <Routes>
      <Route path=":lang" element={<LangSetter/>} >
        {/*<Route path="" element={<Navigate to="map" />} />*/}
        <Route path="" element={<Navigate to="map" />} />
        <Route path="map" element={<PrivateRoute/>} >
          <Route path="" element={<MapView/>} />
        </Route>
        <Route path="login" element={<LoginView/>} />
        <Route path="initiative/:initiativeId" element={<LandingView />}/>
        <Route path="404" element={<Error/>}/>
        <Route path="*" element={<Navigate to={`/${i18n.resolvedLanguage}/404`} />}/>
      </Route>
      <Route path="*" element={<Navigate to={i18n.resolvedLanguage}/>}/>
    </Routes>
  </BrowserRouter>;

export default AppRoutes;
