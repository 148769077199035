import {BaseInitiative, Initiative} from '@/domain/entities/Initiative';
import centerOfMass from '@turf/center-of-mass';

export const getPoisData = (initiatives: Array<Initiative | BaseInitiative> | undefined) => {
  if(initiatives && initiatives.length) {

    return initiatives
      .filter(init => init.parcels_rsc.features.length > 0)
      .map(initiative => ({
        logoPerformers: 'performers' in initiative ? initiative.performers.map(p => p.logo) : undefined,
        initiativeId: initiative.id,
        centroids: initiative.parcels_rsc.features.map(feature => ({
          lat: centerOfMass(feature).geometry.coordinates[1],
          lon: centerOfMass(feature).geometry.coordinates[0],
        }))
      }
      ));

  } else return [];
};