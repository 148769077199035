import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useTranslation} from 'react-i18next';
import {Specie} from '@/domain/entities/Specie';
import SpecieIcon from '@/components/Species/SpecieIcon';


const labelSx: SxProps = {
  fontStyle: 'italic'
};

const dataSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  m: 1,
  px: 2,
};

const existenceDataSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: 1
};

export interface SpecieSummaryProps {
  specie: Specie
}

const SpecieDataSummary: FC<SpecieSummaryProps> = ({specie}) => {

  const {t} = useTranslation();
  const currentYear = new Date().getFullYear();
  const plantedYear = currentYear-specie.t;

  const containerSx: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 2,
    p: 1,
    backgroundColor: 'transparent'
  };
  
  return <Box sx={containerSx}>
    <SpecieIcon id={specie.id}/>
    <Box sx={dataSx}>
      <Typography sx={labelSx}>{specie.label}</Typography>
      <Typography color="primary.grey"
        gutterBottom>CO₂ {t('species.that_offsets')} {specie.co2} {t('species.tons')}
      </Typography>
      <Box sx={existenceDataSx}>
        <Typography>{t('species.planting_year')}</Typography>
        <Typography color='primary.main'>{plantedYear}</Typography>
      </Box>
      <Box sx={existenceDataSx}>
        <Typography>{t('species.number_of_trees')}</Typography>
        <Typography color='primary.main'>{specie.n}</Typography>
      </Box>
    </Box>
  </Box>;
};

export default SpecieDataSummary;