import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';

//GREEN BONUS
import Certificates from '@/components/Certificates';
import OwnersCarrousel from '@/components/admin/PlacesDetail/OwnersCarrousel';
import SustainabilityActionList from '@/components/admin/InitiativesSummary/SustainabilityActionList';
import SpeciesAndResourcesCarousel from '@/components/SpeciesAndResources/SpeciesAndResourcesCarousel';
import Fauna from '@/components/Fauna';
import MeteorologicalData from '@/components/MeteorologicalData';

//UTILS
import {secondaryColor} from '@/themes/theme';
import {lighten} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';

//TYPES
import {Initiative} from '@/domain/entities/Initiative';
import {formatNumber} from '@/utils/formatNumber';
import Link from '@mui/material/Link';

export type PlaceDetailProps = {
  initiative: Initiative
};

const cardHeaderSx: SxProps = {
  bgcolor: 'secondary.light', 
  borderBottom: `1px solid ${lighten(secondaryColor, 0.85)}`
};

const buttonHeaderSx: SxProps = {
  color:'primary.grey', 
  mr: 1
};

const titleSx: SxProps = {
  mt: 0,
  py: 1,
  fontWeight: 900,
  color: 'secondary.main'
};

const cardActionsSx: SxProps = {
  bgcolor: 'primary.main',
  pl: 2,
  py: 1
};

const containerSx: SxProps = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: 2
};

const PlaceDetail: FC<PlaceDetailProps> = ({initiative}) => {

  const {t} = useTranslation();

  const fieldGroupSx: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    mb: 2,
    gap: 1,
  };

  const valueSx: SxProps = {
    color: 'grey.400',
    fontWeight: 600,
    fontSize: 18
  };

  const investmentSx: SxProps = {
    ...fieldGroupSx,
    flexDirection: 'column',
    alignItems: 'flex-start',
    ...valueSx
  };

  const investmentValueSx: SxProps = {
    fontWeight: 900,
    color: 'primary.main'
  };
  
  const editInfoSx: SxProps = {
    color: lighten('#8FB35B', 0.5)
  };

  const editIconSx: SxProps = {
    color: lighten('#8FB35B', 0.5)
  };

  const items = initiative.species ? [...initiative.species, ...initiative.resources] : initiative.resources;

  return <Card elevation={3} sx={{my: 5}} id={`place-${initiative.id}`}>
    {/*HEADER--------------------------------------------------------------------------------------------------------*/}
    <CardHeader sx={cardHeaderSx} elevation={5}
      action={initiative.document ?
        <Button startIcon={<GetAppIcon sx={buttonHeaderSx}/>}>
          <Link href={initiative.document ? initiative.document : '#'} underline='none'>
            <Typography color='primary'>PDF | IMG | Certificado</Typography>
          </Link>
        </Button> : undefined
      }
      title={
        <Box display='flex' flexDirection='row' gap={1}>
          <Typography variant='title' sx={{fontWeight: 300}}>{t('initiatives.space')}:</Typography>
          <Typography variant='title'>{initiative.name}</Typography>
        </Box>
      }
    />
    <CardContent sx={containerSx}>
      {/*FIRST COLUMN------------------------------------------------------------------------------------------------*/}
      <Box my={1} mx={2} pr={1} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gridArea: '1/1/2/2', borderRight: '1px solid lightgrey'}}>
        <Box sx={fieldGroupSx}>
          <Typography variant='paragraph' color='common.black' align='center'>{t('initiatives.title')}:</Typography>
          <Typography align='left' sx={valueSx}>{initiative.title}</Typography>
        </Box>
        <Box sx={fieldGroupSx}>
          <Typography variant='paragraph' color='common.black' align='center'>{t('initiatives.place')}:</Typography>
          <Typography align='left' sx={valueSx}>{initiative.place}</Typography>
        </Box>
        <Box sx={fieldGroupSx}>
          <Typography variant='paragraph' color='common.black' align='center'>{t('initiatives.objective')}:</Typography>
          <Typography align='left' sx={valueSx}>{initiative.objective}</Typography>
        </Box>
        <Box sx={investmentSx}>
          <Typography variant='paragraph' color='common.black'>{t('initiatives.investment')}:</Typography>
          <Typography variant={'supertitle'} sx={investmentValueSx}>{formatNumber(initiative.investment)} €</Typography>
        </Box>
        {
          initiative.owners.length > 0
            ? <Box display="flex" flexDirection='column' sx={{mb: 1}}>
              <Typography sx={titleSx}>{t('initiatives.owners').toUpperCase()}</Typography>
              <OwnersCarrousel owners={initiative.owners} orientation='horizontal'/>
            </Box>
            : undefined
        }
      </Box>
      
      {/*SECOND COLUMN-----------------------------------------------------------------------------------------------*/}
      <Box mx={2} mb={2} sx={{gridArea: '1/2/2/3', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', borderRight: '1px solid lightgrey'}}>
        <Box display='flex' flexDirection='column' sx={{order: (initiative.resources?.length > 0 || initiative.species?.length > 0)? 1 : 3}}>
          <Typography sx={titleSx}>
            {
              initiative.species?.length && initiative.resources?.length ? t('species_and_resources').toUpperCase()
                : initiative.species?.length ? t('species.title').toUpperCase()
                  : initiative.resources?.length ? t('resources.title').toUpperCase()
                    : undefined
            }
          </Typography>
          {
            items?.length
              ? <SpeciesAndResourcesCarousel items={items}/>
              : undefined
          }
        </Box>

        {
          <Box sx={{order: (initiative.resources?.length > 0 || initiative.species?.length > 0) ? 3 : 1, mt: 3}}>
            {
              (initiative.meteo.temperature || initiative.meteo.sky_state || initiative.environmental_impacts?.length) 
                ? <>
                  <Typography gutterBottom sx={titleSx}>
                    {t('initiatives.environmental_impact').toUpperCase()}
                  </Typography>
                </>
                : undefined
            }
            <Box display="flex" flexDirection="column" sx={{gap: 2}}>
              <MeteorologicalData meteo={initiative.meteo}/>
              <Fauna environmentalImpacts={initiative.environmental_impacts}/>
            </Box>
          </Box>
        }
      </Box>
      
      {/*THIRD COLUMN------------------------------------------------------------------------------------------------*/}
      <Box mx={2} sx={{gap: 2, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gridArea: '1/3/2/4', height: '100%'}}>
        { 
          initiative.certification_type && initiative.certification_type.length > 0 ? <Box>
            <Typography sx={titleSx}>
              {t('initiatives.summary.certifications').toUpperCase()}
            </Typography>
            <Certificates certificates={initiative.certification_type}/>
          </Box> : undefined
        }
        <Box>
          <Typography gutterBottom sx={titleSx}>
            {t('initiatives.sustainability_actions').toUpperCase()}
          </Typography>
          {
            initiative.sustainability_actions.length ?
              <SustainabilityActionList 
                sustainabilityActions={initiative.sustainability_actions.sort((a,b) => a.name.localeCompare(b.name))}/> :
              <Typography color='error'>{t('initiatives.withoutSustainabilityActions')}</Typography>
          }
        </Box>

      </Box>
    </CardContent>

    {/*FOOTER--------------------------------------------------------------------------------------------------------*/}
    <CardActions sx={cardActionsSx}>
      <Typography variant='body2' color='common.white'>{t('initiatives.footerDescription')}</Typography>
      <Button href={`/es/initiative/${initiative.id}`} startIcon={<VisibilityIcon sx={{color:'common.white'}}/>}>
        <Typography variant='paragraphBold' color='common.white'>{t('seeWeb')}</Typography>
      </Button>

      <Divider orientation='vertical' sx={{ height: 15, bgcolor:'common.white' }} />

      <Button startIcon={<EditIcon sx={editIconSx}/>} disabled={true} >
        <Typography variant="paragraphBold" sx={editInfoSx}>{t('editInformation')}</Typography>
      </Button>
    </CardActions>
  </Card>;
};
export default PlaceDetail;
