import Auth from '@/domain/services/Auth';
import {ApiToken, LoginError} from '@/domain/services/Auth';
import {POST} from '@/serviceImpl/HttpFetch';

const API_URL = process.env.BASE_URL;

const login = async (username: string, password: string) =>
  POST<Promise<ApiToken | LoginError>, {username: string, password: string}>(
    `${API_URL}/api-token-auth/`,
    {username, password}
  );

const AuthImpl: Auth = {
  login
};

export default AuthImpl;