import GreenBonusImpl from '@/serviceImpl/GreenBonusImpl';
import getCredentialsUseCase from '@/domain/useCases/getCredentialsUseCase';
import {Performer} from '@/domain/entities/Performer';
import {BaseInitiative, Initiative} from '@/domain/entities/Initiative';
import {PerformerEmployee} from '@/domain/entities/PerformerEmployee';


export const getAvailableInitiatives = async (): Promise<Array<BaseInitiative> | undefined> => {
  const token = getCredentialsUseCase();
  if(token){
    return await GreenBonusImpl.availableInitiatives(token);
  }
};

export const getInitiative = async (id: number): Promise<Initiative | void> => await GreenBonusImpl.initiative(id);

export const getInitiatives = async (): Promise<Array<Initiative> | undefined> => {
  const token = getCredentialsUseCase();
  if(token){
    return await GreenBonusImpl.initiatives(token);
  }
};

export const performers = async (): Promise<Array<Performer> | void> => {
  const token = getCredentialsUseCase();
  if(token){
    return await GreenBonusImpl.performers(token);
  }
};

export const getCurrentPerformer = async (): Promise<Array<PerformerEmployee> | undefined> => {
  const token = getCredentialsUseCase();
  if(token){
    return await GreenBonusImpl.performerEmployees(token);
  }
};

export const interestedInInitiative = async (id: number): Promise<void> => {
  const token = getCredentialsUseCase();
  if(token){
    return await GreenBonusImpl.interestedInInitiative(id, undefined, token);
  }
};

