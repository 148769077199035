import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import Typography from '@mui/material/Typography';
import {lighten} from '@mui/system/colorManipulator';
import IsotipoBikenta from '@/components/logos/IsotipoBikenta';
import {useTranslation} from 'react-i18next';

const containerSx: SxProps = {
  bgcolor: 'white',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const wrapperErrorSx: SxProps = {
  bgcolor: () => lighten('#8FB35B', 0.9),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 4,
  borderRadius: 2,
  width: '100%',
  height: '70%'
};

const wrapperIconSx: SxProps = {
  width: {xs: '50%', md: '30%'},
  mb: 4
};

const Error: FC = () => {

  const {t} = useTranslation();

  return <Box sx={containerSx}>
    <Box sx={wrapperErrorSx}>
      <Typography variant='title' sx={{color: 'primary.main'}} gutterBottom>{t('error404.title')}</Typography>
      <Box sx={wrapperIconSx}>
        <IsotipoBikenta/>
      </Box>
      <Typography variant='title' sx={{color: 'primary.main'}} gutterBottom>¡Ups!</Typography>
      <Typography variant='subtitle' sx={{color: 'common.black', width: '70%'}} align='center'>
        {t('error404.message')}
      </Typography>
    </Box>
  </Box>;
};

export default Error;