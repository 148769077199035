import React, {FC, SVGProps} from 'react';

const EucaliptoNintens: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 808.108 711.158"
    height="100%"
    fill="none"
    {...props}
  >
    <path fill="#0F7540" d="M395.43 579.146h22.892v22.892H395.43z"/>
    <path fill="#80A82B" d="M258.078 601.842h22.892v22.892h-22.892z"/>
    <path fill="#35471D" d="M280.97 601.842h22.892v22.892H280.97z"/>
    <path fill="#80A82B" d="M303.862 601.842h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M326.754 601.842h22.892v22.892h-22.892z"/>
    <path fill="#80A82B" d="M349.646 601.842h22.892v22.892h-22.892z"/>
    <path fill="#9E937F" d="M372.538 601.842h22.892v22.892h-22.892z"/>
    <path fill="#0F7540" d="M395.43 601.842h22.892v22.892H395.43z"/>
    <path fill="#80A82B" d="M418.322 601.842h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M441.214 601.842h22.892v22.892h-22.892z"/>
    <path fill="#A7C56C" d="M464.106 601.842h22.892v22.892h-22.892z"/>
    <path fill="#81B829" d="M486.998 601.842h22.892v22.892h-22.892z"/>
    <path fill="#E4EACD" d="M509.89 601.842h22.892v22.892H509.89z"/>
    <path
      fill="#837826"
      d="m460.76 109.832-.021 21.897c-6.486.013-12.974.091-19.459-.001-1.913-.027-2.522.553-2.488 2.478.115 6.485.063 12.972.075 19.459l.015.093-43.999-.133.059-21.806c6.67-.016 13.34-.079 20.01-.015 1.56.015 2.016-.445 1.995-2.001-.089-6.669-.056-13.34-.068-20.01l21.884-.094 21.997.133z"
    />
    <path
      fill="#737354"
      d="m263.26 373.144-21.94.028-.089-21.833.047-22.051-.028.027 22.053-.043 21.915.026-.017 21.97c-6.397 0-12.795.075-19.189-.041-2.091-.038-2.886.471-2.83 2.729.159 6.392.07 12.791.078 19.188z"
    />
    <path
      fill="#DCBD9C"
      d="m372.716 601.985-.065-75.171 22.76.034-.042 75.137h-22.653z"
    />
    <path
      fill="#B4B392"
      d="m394.924 21.994 22.041.044-.046 21.871-.099 22.024-21.924-.113-.013-21.904.076-21.963-.035.041z"
    />
    <path
      fill="#888E68"
      d="m307.222 65.926 21.829-.066 22.024.042-.158 21.953-21.844.026-21.929-.038.006.006.072-21.923z"
    />
    <path
      fill="#B8C669"
      d="m285.305 197.624-.079-21.89 21.917-.111 21.977.037-.022 21.95-21.876.071c-7.305-.02-14.611-.039-21.917-.057z"
    />
    <path
      fill="#DCBD9C"
      d="m241.288 307.345.073-21.932 21.91-.013 21.944.053-.07 21.942-21.875.021-22.062.065.08-.136z"
    />
    <path
      fill="#8E9D98"
      d="m482.772 307.41 21.931.001-.013 43.803-21.876.098-.053-22.021.038-21.92-.027.039z"
    />
    <path
      fill="#B4B392"
      d="M263.32 241.542c-.022-6.639.01-13.278-.103-19.915-.03-1.781.698-2.092 2.255-2.08 6.548.051 13.096-.001 19.644-.016l.245.039-.226 21.921-21.815.051z"
    />
    <path
      fill="#888E68"
      d="M373.037 21.994c-.005-6.493.051-12.987-.054-19.478-.029-1.818.324-2.563 2.357-2.513 5.758.141 11.524.141 17.281 0 2.034-.05 2.385.697 2.356 2.514-.104 6.491-.049 12.985-.054 19.478l.034-.04-21.953.001.033.038z"
    />
    <path
      fill="#C2C4B7"
      d="m482.791 219.582 21.881.023.121 21.835-.164.167-21.822-.054-.049-21.996.033.025z"
    />
    <path
      fill="#9E937F"
      d="m263.32 241.542 21.815-.051.096 21.918-21.863.093-.185-.103.137-21.857z"
    />
    <path
      fill="#737354"
      d="M460.853 175.646c6.562.003 13.125.069 19.685-.031 1.783-.027 2.311.469 2.278 2.268-.123 6.559-.081 13.121-.101 19.682l.027-.018-21.933.033-.022-21.948.066.014z"
    />
    <path
      fill="#A09E4C"
      d="m285.305 197.624 21.917.056-.047 21.855-21.815.036-.245-.039.19-21.908z"
    />
    <path
      fill="#444B25"
      d="M219.416 329.273c-.027-6.662-.005-13.325-.117-19.986-.027-1.587.487-1.962 1.999-1.947 6.663.067 13.327.014 19.991.004l-.079.136.041 21.834.028-.026-21.863-.015z"
    />
    <path
      fill="#B4B392"
      d="m219.416 329.273 21.863.015-.047 22.051c-6.373-.024-12.748-.119-19.12-.029-2.022.029-2.872-.348-2.807-2.645.183-6.459.093-12.927.111-19.392z"
    />
    <path
      fill="#B8C669"
      d="m263.272 285.4-21.91.013c-.024-6.643.01-13.288-.112-19.93-.034-1.824.757-2.05 2.276-2.041 6.552.038 13.105-.02 19.658-.043l.184.103c-.033 7.299-.065 14.599-.096 21.898z"
    />
    <path
      fill="#8E9D98"
      d="m394.998 439.095-.124 21.946-21.948.071.062-.133.109-21.884 21.783.053.118-.053z"
    />
    <path
      fill="#B4B392"
      d="m263.252 461.197-21.9-.187-.107-21.822.082-.224 21.928.143-.03-.03.022 21.854c.05.087.051.176.005.266z"
    />
    <path
      fill="#A09E4C"
      d="m351.075 65.902-22.024-.042.081-21.845 21.895-.15.026.254-.004 21.758.026.025z"
    />
    <path
      fill="#B4B392"
      d="M395.032 504.956c6.483-.002 12.966.035 19.448-.034 1.66-.017 2.501.305 2.467 2.236a492.508 492.508 0 0 0 0 17.53c.034 1.925-.797 2.257-2.462 2.241-6.482-.06-12.965-.013-19.447-.005l-.1-.029-.044-21.981.138.042zM328.971 526.891l-21.736.039-.013-22.014 21.761.034.252-.002-.042 21.964-.222-.021zM351.027 43.865l.094-21.841 21.916-.031-.032-.038.129 21.99-.252.007-21.828.166-.027-.253z"
    />
    <path
      fill="#DCBD9C"
      d="m394.882 482.973.149 21.983-.138-.041-21.849.016-.109.042.087-21.963-.003-.007 21.863-.03z"
    />
    <path
      fill="#444B25"
      d="m416.919 43.91.046-21.871c6.663-.02 13.327.009 19.989-.095 1.581-.025 1.968.478 1.949 1.995-.082 6.663-.044 13.327-.049 19.991l-.057-.061-21.878.041z"
    />
    <path
      fill="#B8C669"
      d="m328.984 504.949-21.761-.034-.023-21.904 21.826.02-.042 21.918zM394.882 482.973l-21.864.03-.093-21.891 21.948-.071.009 21.932z"
    />
    <path
      fill="#9E937F"
      d="M438.867 153.664c-.012-6.486.039-12.974-.075-19.459-.034-1.925.575-2.505 2.488-2.478 6.485.091 12.972.014 19.459.001l.012 22.008-21.884-.072z"
    />
    <path
      fill="#444B25"
      d="m438.867 153.664 21.883.072.103 21.91-.066-.014-21.929-.059.024-21.816-.015-.093z"
    />
    <path
      fill="#8E9D98"
      d="M482.902 373.215c-.04 6.474-.163 12.949-.073 19.422.028 2.018-.477 2.66-2.566 2.611-6.473-.153-12.952-.081-19.429-.097l.019.022-.012-21.957 21.833.025.228-.026z"
    />
    <path
      fill="#9E937F"
      d="m241.246 439.188-21.866-.147.006.012.033-21.875 21.894-.173-.035.22.05 21.739-.082.224z"
    />
    <path
      fill="#605B32"
      d="m438.928 65.965 21.847.002-.025 21.82-21.813-.021-.111.074.111-21.91-.009.035z"
    />
    <path
      fill="#888E68"
      d="m285.227 87.914 21.922-.066-.006-.006-.021 21.948-21.792-.013-.243.036.14-21.899z"
    />
    <path
      fill="#8E9D98"
      d="m438.936 87.765 21.813.021.01 22.046-21.996-.133c.059-7.312.116-14.623.173-21.934z"
    />
    <path
      fill="#605B32"
      d="m438.883 153.758-.024 21.816-.009.211-21.877-.027-22.014.011-21.961-.207.011-21.859 21.876-.078 43.998.133z"
    />
    <path
      fill="#A09E4C"
      d="m394.884 153.624-21.876.078-21.913.003-.012-21.981 22.021-.073 21.898-.026-.06.192-.058 21.807z"
    />
    <path
      fill="#C2C4B7"
      d="m438.936 87.765-.173 21.934-21.885.094.035.039-.137-21.962.269-.033 21.778.002.113-.074z"
    />
    <path
      fill="#737354"
      d="m351.071 461.023-21.983-.088.016-21.803 21.871-.007.056 21.94.04-.042z"
    />
    <path
      fill="#837826"
      d="m329.104 439.132-.016 21.803-21.91.023-.035-21.854-.02-.039 21.982-.022-.001.089z"
    />
    <path
      fill="#A09E4C"
      d="m307.143 439.103.035 21.854-22.022-.013.029-21.845 21.958.004z"
    />
    <path
      fill="#737354"
      d="m285.186 439.099-.029 21.845-21.909-.013-.022-21.854 22.001-.029-.041.051z"
    />
    <path
      fill="#B4B392"
      d="m372.926 461.112.093 21.891.003.007-21.888-.069-.131.071.068-21.989-.04.041 21.956-.085-.061.133z"
    />
    <path
      fill="#888E68"
      d="m307.069 417.159.049-21.854.025-22.082 21.953.011.041 21.971-.019 21.905.048.133-21.974-.123-.123.039z"
    />
    <path
      fill="#737354"
      d="m307.144 373.222-.025 22.082c-6.569-.023-13.138-.111-19.705-.027-1.83.024-2.293-.566-2.268-2.321.095-6.568.042-13.139.044-19.708l-.009-.045 21.963.019z"
    />
    <path
      fill="#605B32"
      d="M263.26 373.144c-.008-6.397.081-12.796-.077-19.189-.056-2.258.738-2.767 2.83-2.729 6.395.115 12.793.041 19.189.041l.043-.01-.065 21.947.009.045-21.929-.105z"
    />
    <path
      fill="#B4B392"
      d="m285.232 417.137-.005 21.91-22.002.029.03.03-.014-21.935-.052-.194 22.043.16z"
    />
    <path
      fill="#888E68"
      d="m307.036 153.732.095-21.965.001-.03 21.953.02-.005 21.949-21.87-.019-.174.045z"
    />
    <path
      fill="#737354"
      d="m285.33 109.778 21.792.013.059-.021-.047 21.968-.001.03c-6.559-.018-13.119-.045-19.678-.048-1.222-.001-2.305.162-2.27-1.723.124-6.739.108-13.48.145-20.219z"
    />
    <path
      fill="#9E937F"
      d="m241.251 329.314-.041-21.834 22.062-.066.033 21.857-22.054.043z"
    />
    <path
      fill="#C2C4B7"
      d="m416.919 43.91 21.878-.041.131 22.096.008-.037-22.008-.035-.109.042.1-22.025z"
    />
    <path
      fill="#9E937F"
      d="m394.896 65.821 21.924.113.108-.042.119 21.944-.269.033-21.805-.01-.009-21.928-.068-.11z"
    />
    <path
      fill="#B4B392"
      d="m350.916 87.855.159-21.953-.025-.025 21.993.015-.039-.039-.068 22.039-21.818-.017-.202-.02zM307.122 109.791l.021-21.948 21.929.038-.046 21.85-21.845.04-.059.02z"
    />
    <path
      fill="#A09E4C"
      d="m329.026 109.729.046-21.849 21.844-.025.201.02.049 21.891-.227.044-21.803-.028-.11-.053z"
    />
    <path
      fill="#605B32"
      d="m329.099 197.609.022-21.95-.015.013 21.954-.002-.029-.022-.137 21.943-21.788.025-.007-.007z"
    />
    <path
      fill="#605B32"
      d="m307.176 219.534.047-21.855 21.876-.07.007.007-.021 21.797-21.935.128.026-.007z"
    />
    <path
      fill="#737354"
      d="m307.211 153.687 21.87.019.024 21.967.015-.013-21.977-.037.068-21.936z"
    />
    <path
      fill="#C2C4B7"
      d="m482.709 285.39.063 22.02.027-.039-22.016-.036-.049-21.887 21.975-.058z"
    />
    <path
      fill="#A09E4C"
      d="m504.629 241.606.075 21.762-21.932.059.032.039-.051-21.96.054.046 21.822.054z"
    />
    <path
      fill="#B4B392"
      d="m482.709 285.39-21.975.058.082-.007-.02-21.961 22.008-.014-.032-.039-.063 21.963z"
    />
    <path
      fill="#605B32"
      d="m285.146 307.394.07-21.942-.006.01 21.96.003-.042 21.919-21.913-.008-.069.018z"
    />
    <path
      fill="#A09E4C"
      d="M263.272 285.4c.032-7.299.063-14.599.096-21.898l21.864-.093-.037.038.016 22.015.006-.01-21.945-.052z"
    />
    <path
      fill="#605B32"
      d="m482.762 329.29.053 22.021-.064-.038-21.872.02-.032-21.936 21.915-.067z"
    />
    <path
      fill="#737354"
      d="m285.361 219.57 21.815-.036-.026.007.002 21.936.006 21.976-21.964-.006.037-.038-.096-21.918.226-21.921zM460.879 351.293l21.872-.02-.077 21.968-21.833-.025-.055.037-.051-22.023.144.063z"
    />
    <path
      fill="#888E68"
      d="m438.859 175.573 21.929.059.022 21.948.056.13-21.998-.075-.018-21.85.009-.212z"
    />
    <path
      fill="#DCBD9C"
      d="m395.146 417.167-.266 21.981-21.783-.052-.213.002.101-21.934 21.927-.05.234.053z"
    />
    <path
      fill="#737354"
      d="m263.241 417.171.014 21.935-21.928-.143-.05-21.739 21.964-.053zM373.043 65.892l-21.993-.015.004-21.758 21.828-.166.161 21.939z"
    />
    <path
      fill="#DCBD9C"
      d="m373.044 504.93 21.849-.016.044 21.981-21.961.141.024-.166.044-21.94z"
    />
    <path
      fill="#9E937F"
      d="M373.044 504.93 373 526.871l-21.899-.035-.004.048-.124-21.95s.227.029.226.03l21.737.009.108-.043z"
    />
    <path
      fill="#B8C669"
      d="m350.972 504.934.124 21.95-21.902.028.042-21.964 21.736-.014zM372.935 504.972l-21.737-.009-.064-22.023 21.888.069-.087 21.963z"
    />
    <path
      fill="#888E68"
      d="m372.997 175.561 21.961.206-.016 21.899-21.81.027-.136-21.981.001-.151z"
    />
    <path
      fill="#A09E4C"
      d="m394.942 197.667.016-21.899 22.014-.01-.074 21.874-21.894-.027-.062.062z"
    />
    <path
      fill="#B4B392"
      d="m416.898 197.631.074-21.874 21.877.028.018 21.851.024-.027-22.009.004.016.018z"
    />
    <path
      fill="#837826"
      d="m351.095 153.706 21.913-.003-.011 21.859v.15l-21.967-.063.029.022.036-21.965z"
    />
    <path
      fill="#9E937F"
      d="m351.095 153.706-.036 21.965-21.954.002-.024-21.967.004-21.949.001.004 21.977-.002.019-.033.013 21.98z"
    />
    <path
      fill="#B8C669"
      d="m372.992 109.77.112 21.882-22.021.072-.019.033-.125-21.948.227-.044 21.865.047-.039-.042z"
    />
    <path
      fill="#837826"
      d="m394.973 87.86 21.805.01.137 21.962-21.979.062.05-.195-.013-21.839z"
    />
    <path
      fill="#B8C669"
      d="m329.166 417.243-.06 21.799-21.982.022.068-21.944 21.974.123z"
    />
    <path
      fill="#A09E4C"
      d="m285.245 351.257 21.895-.005c7.316.057 14.631.114 21.947.169l.014 21.808-.004.004-21.953-.011-21.964-.019.065-21.946z"
    />
    <path
      fill="#444B25"
      d="m350.997 417.079-21.879.031.019-21.905 21.803-.01.057 21.884z"
    />
    <path
      fill="#A09E4C"
      d="m350.94 395.195-21.803.01-.04-21.971.004-.004 21.864-.012.243 21.944-.268.033z"
    />
    <path
      fill="#8E9D98"
      d="m307.181 109.769 21.845-.04.11.052-.049 21.978-.001-.004-21.953-.019.048-21.967z"
    />
    <path
      fill="#9E937F"
      d="m285.215 307.376 21.913.008-.025.053.116 21.782-.121.179-21.92-.059.037-21.963z"
    />
    <path
      fill="#8E9D98"
      d="m329.086 351.422-21.947-.17-.04-21.854.121-.179 21.929.171-.044 21.849-.019.183z"
    />
    <path
      fill="#737354"
      d="m394.973 87.86.013 21.839-21.993.072.039.043-.096-21.921.068-22.039 21.96.079.009 21.927zM329.087 131.76l.049-21.978 21.804.028.125 21.948-21.978.002z"
    />
    <path
      fill="#8E9D98"
      d="m351.03 175.648 21.966.063.135 21.981-.158-.066-21.838.011-.243-.047c.047-7.313.093-14.627.138-21.942z"
    />
    <path
      fill="#737354"
      d="m329.085 219.413.021-21.797 21.788-.025.243.047-.135 21.926-21.908.053-.009-.204z"
    />
    <path
      fill="#8E9D98"
      d="m307.152 241.477-.002-21.936 21.935-.128.008.204.064 21.819-22.005.041z"
    />
    <path
      fill="#737354"
      d="m460.735 285.448.049 21.887.031.048-21.928-.086-.004-21.951 21.935.095-.083.007z"
    />
    <path
      fill="#444B25"
      d="m307.128 307.385.042-21.919-.043-.093 21.882.037.134 21.919-22.04.108.025-.052z"
    />
    <path
      fill="#B8C669"
      d="m285.194 263.448 21.964.006-.038.034.008 21.885.043.093-21.96-.003-.017-22.015z"
    />
    <path
      fill="#DCBD9C"
      d="m438.867 197.635 21.998.075-.073 21.837-21.874-.003-.18.088.153-22.024-.024.027z"
    />
    <path
      fill="#837826"
      d="m307.152 241.477 22.006-.041-.075.124.054 21.787-22.017.14.038-.034-.006-21.976z"
    />
    <path
      fill="#605B32"
      d="m438.918 219.544-.085 21.978.056-.02-21.993.034-.001-21.978.079.047 21.764.028.18-.089z"
    />
    <path
      fill="#444B25"
      d="m460.827 263.488-21.968-.057.039.028-.008-21.958-.056.02 21.924.019.069 21.948z"
    />
    <path
      fill="#C2C4B7"
      d="m438.884 351.131 21.851.098.051 22.023-21.838-.024-.214.034.093-21.876.057-.255z"
    />
    <path
      fill="#444B25"
      d="m394.955 395.185-.043 21.929-21.927.05.013-.02-.018-21.972.013-.012 21.976.049-.014-.024z"
    />
    <path
      fill="#888E68"
      d="m438.948 373.229-.075 21.967-21.974.131.021-.233-.071-21.845 21.885.015.214-.035z"
    />
    <path
      fill="#605B32"
      d="m416.849 373.248.071 21.845-21.966.092.013.023.008-22.007 21.929-.025-.055.072zM373.132 197.693l21.81-.027.062-.062.015 21.968-.196-.043-21.751.049-.097-21.951.157.066z"
    />
    <path
      fill="#C2C4B7"
      d="m395.019 219.572-.015-21.968 21.894.027-.016-.019.092 21.993-.079-.047-21.876.014z"
    />
    <path
      fill="#888E68"
      d="m416.974 219.605-.092-21.993 22.009-.004-.153 22.024-21.764-.027z"
    />
    <path
      fill="#B4B392"
      d="m372.979 395.172.018 21.972-22-.065-.057-21.884.267-.034 21.772.011z"
    />
    <path
      fill="#888E68"
      d="m350.964 373.218-21.863.012-.014-21.808.018-.181 21.925.029.078 21.992-.144-.044z"
    />
    <path
      fill="#C2C4B7"
      d="m372.979 395.172-21.772-.01-.242-21.944.143.044 21.844.008.041 21.89-.014.012z"
    />
    <path
      fill="#737354"
      d="m307.104 307.438 22.04-.108-.008-.034-.039 21.996.052.099-21.929-.17-.116-21.783z"
    />
    <path
      fill="#DCBD9C"
      d="m329.158 241.437-.064-21.82 21.908-.053.065-.029-.094 22.04-21.89-.015.075-.123z"
    />
    <path
      fill="#888E68"
      d="m438.887 307.297-21.977.076-.038.047.038-21.952.073-22.057 21.915.048-.039-.028.023 21.914.005 21.952zM307.12 263.488l22.018-.139-.03.168.113 21.964-.132.044-.079-.115-21.882-.037-.008-21.885z"
    />
    <path
      fill="#A09E4C"
      d="m438.735 373.263-21.885-.015.056-.072.017-21.889-.004.004 21.909.096-.093 21.876z"
    />
    <path
      fill="#444B25"
      d="m416.909 307.373-.012 21.909-21.947.023.013-21.961 21.909.077.037-.048zM329.083 241.561l21.89.015.002-.02.014 21.852-21.883.109.03-.168-.053-21.788z"
    />
    <path
      fill="#B4B392"
      d="m395.019 219.572 21.876-.014.001 21.978.032-.015-22-.025-.105-21.968.196.044z"
    />
    <path
      fill="#C2C4B7"
      d="m438.898 263.459-21.916-.048-.018-.008-.036-21.883-.032.015 21.993-.034.009 21.958z"
    />
    <path
      fill="#B4B392"
      d="m372.992 395.159-.04-21.89s.11-.037.109-.038l21.933-.028-.018-.003-.008 22.007-21.976-.048z"
    />
    <path
      fill="#837826"
      d="m394.823 219.529.105 21.968.028-.018-21.905-.024.023-.028-.138-21.892.136.045 21.751-.051z"
    />
    <path
      fill="#DCBD9C"
      d="m351.03 351.269-.028-22.012-.022.04 21.988.024.155-.066-.06 43.977-.11.037-21.844-.008-.079-21.992z"
    />
    <path
      fill="#605B32"
      d="m329.136 307.296 21.839.161-.008-.042.012 21.882.022-.04-21.905.034.04-21.995z"
    />
    <path
      fill="#B4B392"
      d="m372.936 219.534.138 21.892-22.099.13-.002.02.094-22.04 21.869-.002z"
    />
    <path
      fill="#9E937F"
      d="m416.872 307.421-21.909-.077.025.029.057-21.964 21.864.06-.037 21.952z"
    />
    <path
      fill="#737354"
      d="m416.91 285.469-21.864-.06-.16.044.063-22.001-.062-.066 22.078.018.018.008-.073 22.057z"
    />
    <path
      fill="#C2C4B7"
      d="m350.975 307.456.083-22.042-.013.013 21.871-.002.209-.024.025 21.975-.237-.011-21.947.05.009.041zM329.107 263.517l21.883-.109.166.164-.112 21.856.013-.013-21.837.066-.113-21.964z"
    />
    <path
      fill="#737354"
      d="m394.963 307.344-.013 21.961.084.1-21.912-.151-.155.066-.055-21.956.237.011 21.839-.003-.025-.028z"
    />
    <path
      fill="#9E937F"
      d="m373.122 329.254 21.912.151.008 21.851-.047 21.947-21.933.028.06-43.977z"
    />
    <path
      fill="#C2C4B7"
      d="m350.975 241.556 22.099-.13-.023.028.033 22.083-.043-.055-21.884.09-.167-.164-.015-21.852z"
    />
    <path
      fill="#888E68"
      d="m416.964 263.404-22.078-.018.07-21.908-.028.018 22 .025.036 21.883z"
    />
    <path
      fill="#DCBD9C"
      d="m394.956 241.478-.07 21.908.061.066-21.863.085-.033-22.083 21.905.024z"
    />
    <path
      fill="#444B25"
      d="m394.988 307.373-21.839.003-.025-21.975 21.761.052.16-.044-.057 21.964z"
    />
    <path
      fill="#605B32"
      d="m394.885 285.453-21.761-.052-.209.025.125-21.943.043.055 21.864-.085-.062 22z"
    />
  </svg>
);
export default EucaliptoNintens;