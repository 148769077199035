import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useMediaQuery} from '@mui/material';
import {CERTIFICATION_TYPES} from '@/domain/entities/Initiative';
import Certificates from '@/components/Certificates';
import {formatNumber} from '@/utils/formatNumber';

export interface BasicDataProps {
  name: string,
  certification_type: Array<CERTIFICATION_TYPES> | null,
  title: string,
  performer: string,
  place: string,
  objective: string,
  investment: string,
}

const BasicData: FC<BasicDataProps> = ({
  name,
  certification_type,
  title,
  performer,
  place,
  objective,
  investment,
}) => {
  const {t} = useTranslation();
  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});

  const fieldGroupSx: SxProps = {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'column',
    alignItems: widescreen ? 'flex-start' : 'center',
    mb: 2,
    gap: 0,
  };

  const valueSx: SxProps = {
    color: 'grey.400',
    fontWeight: 600,
    fontSize: 18
  };

  const investmentSx: SxProps = {
    ...fieldGroupSx,
    flexDirection: 'column',
    alignItems: widescreen ? 'flex-start' : 'center',
    ...valueSx
  };

  
  const investmentValueSx: SxProps = {
    fontWeight: 700,
    color: 'primary.main'
  };
  
  const certificationsWrapperSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bgcolor: 'common.white',
    height: '100%',
    p: {xs: 4, md: 2}
  };
  
  const gridContainerSx: SxProps = {
    bgcolor: '#F6F4F0',
    width: '100%',
    display: 'grid',
    gridTemplateAreas: {
      xs: `
      'title'
      'data1'
      'data2'
      'certificates'
      `,
      md: `
      'title title title'
      'data1 data2 certificates'
      `
    },
    gridTemplateColumns: {xs: '1fr', md: 'repeat(3, 33%)'},
    gridTemplateRows: {xs: '1fr', md: '100px, auto'},
    gap: 2,
    borderRadius: 1,
    p: 2
  };

  const titleContainerSx: SxProps = {
    gridArea: 'title',
    justifySelf: 'center',
    my: 2
  };
  
  const data1ContainerSx: SxProps = {
    gridArea: 'data1',
    pl: 2
  };

  const data2ContainerSx: SxProps = {
    gridArea: 'data2',
    pl: 2
  };
  
  const certificatesContainerSx: SxProps = {
    gridArea: 'certificates',
    width: '100%',
    mr: {xs: 0, md: 2}
  };
  
  return <Box sx={gridContainerSx} id='gridContainer'>
    <Box sx={titleContainerSx}>
      <Typography variant='title' color='primary.main' align='center'>{name.toUpperCase()}</Typography>
    </Box>
    <Box sx={data1ContainerSx}>
      <Box sx={fieldGroupSx}>
        <Typography variant='paragraph' color='common.black' align='center'>{t('initiatives.title')}:</Typography>
        <Typography align='center' sx={valueSx}>{title}</Typography>
      </Box>
      <Box sx={fieldGroupSx}>
        <Typography variant="paragraph" color="common.black" align="center">{t('initiatives.performer')}:</Typography>
        <Typography align="center" sx={valueSx}>{performer}</Typography>
      </Box>
      <Box sx={fieldGroupSx}>
        <Typography variant='paragraph' color='common.black' align='center'>{t('initiatives.place')}:</Typography>
        <Typography align='center' sx={valueSx}>{place}</Typography>
      </Box>
    </Box>
    <Box sx={data2ContainerSx}>
      <Box sx={fieldGroupSx}>
        <Typography variant='paragraph' color='common.black' align='center'>{t('initiatives.objective')}:</Typography>
        <Typography align='center' sx={valueSx}>{objective}</Typography>
      </Box>
      <Box sx={investmentSx}>
        <Typography variant='paragraph' color='common.black'>{t('initiatives.investment')}:</Typography>
        <Typography variant='title' sx={investmentValueSx}>{formatNumber(investment)} €</Typography>
      </Box>
    </Box>
    {
      certification_type && certification_type.length
        ? <Box sx={certificatesContainerSx}>
          <Box sx={certificationsWrapperSx}>
            <Typography variant="subtitle" color="common.black" align="center">
              {t('initiatives.certification').toUpperCase().concat(certification_type.length > 1 ? 'S' : '')}
            </Typography>
            <Certificates certificates={certification_type}/>
          </Box>
        </Box>
        : undefined
    }
  </Box>;
};

export default BasicData;