import React, {useCallback, useEffect, useState} from 'react';

//BONOS VERDES
import Layout from '../../components/admin/Layout';
import MainContent from './MainContent';

//UTILS
import {INITIAL_MAPSTYLE} from '@/config';
import {
  getAvailableInitiatives,
  getCurrentPerformer,
  getInitiatives,
  interestedInInitiative
} from '@/domain/useCases/greenBonusUseCase';
import {BaseInitiative, Initiative} from '@/domain/entities/Initiative';
import {PerformerEmployee} from '@/domain/entities/PerformerEmployee';

const Index = () => {
  
  const [availableInitiatives, setAvailableInitiatives] = useState<Array<BaseInitiative> | undefined>();
  const [initiatives, setInitiatives] = useState<Array<Initiative> | undefined>();
  const [currentPerformer, setCurrentPerformer] = useState<Array<PerformerEmployee> | undefined>();

  const fetchAvailableInitatives = useCallback(() => {
    getAvailableInitiatives().then(setAvailableInitiatives);
  }, []);

  const fetchInitatives = useCallback(() => {
    getInitiatives().then(setInitiatives);
  }, []);
  
  const fetchCurrentPerformer = useCallback(() => {
    getCurrentPerformer().then(setCurrentPerformer);
  }, []);

  useEffect(fetchAvailableInitatives, [fetchAvailableInitatives]);
  useEffect(fetchInitatives, [fetchInitatives]);
  useEffect(fetchCurrentPerformer, [fetchCurrentPerformer]);

  const [mapStyleId, setMapStyleId] = useState<string>(INITIAL_MAPSTYLE.id);

  const handleMapStyleChange = (mapStyleId: string) => setMapStyleId(mapStyleId);
  
  const onContact = (initiativeId: number) => interestedInInitiative(initiativeId).then(fetchAvailableInitatives);

  const mainContent = <MainContent
    initiatives={initiatives}
    availableInitiatives={availableInitiatives}
    mapStyle={mapStyleId}
    mapStyleId={mapStyleId}
    onStyleChange={handleMapStyleChange}
    onContactClick={onContact}
  />;

  return <Layout
    mainContent={mainContent}
    currentPerformer={currentPerformer}
  />;
};

export default Index;
