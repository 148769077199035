import createTheme from '@mui/material/styles/createTheme';
import {lighten} from '@mui/system/colorManipulator';
import {PaletteMode} from '@mui/material';
import {grey} from '@mui/material/colors';

const primaryColor = '#8FB35B';
export const secondaryColor = '#36311E';
const font = ['Montserrat, sans-serif'].join(',');

const theme = (mode?: PaletteMode) => createTheme({
  palette: {
    mode: mode ? mode : 'light',
    primary: {
      main: primaryColor,
      dark: '#81a252',
      grey: grey[500]
    },
    secondary: {
      main: secondaryColor,
      light: '#F6F4F0',
      grey: grey[400]
    },
    text: {
      primary: '#151515'
    }
  },
  typography: {
    fontFamily: font,
    supertitle: {
      fontSize: '50px',
      fontWeight: 900,
      lineHeight: 'normal'
    },
    title: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '1px'
    },
    subtitle: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 'normal',
      color: '#3e4c5b',
      letterSpacing: '0.5px'
    },
    paragraph: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px'
    },
    paragraphBold: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '32px',
      color: grey[400],
      letterSpacing: '1px'
    },
    details: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          supertitle: 'h1',
          title: 'h2',
          subtitle: 'h3',
          paragraph: 'body1',
          paragraphBold: 'body1',
          details: 'caption'
        }
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        /* Firefox Scrollbar */
        'body': {
          scrollbarColor: `${lighten(primaryColor, 0.5)} transparent`, /* scroll thumb and track */
          scrollbarWidth: '2px'
        },
        /* Chrome Scrollbar */
        '*::-webkit-scrollbar': {
          width: '0.2em',
          height: '0.15em'
        },
        '*::-webkit-scrollbar-track': {
          WebkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: lighten(primaryColor, 0.5),
          opacity: 0.2,
          outline: '0px solid slategrey'
        }
      }
    }
  }
});

export default theme;
