import {APP_NAME} from '@/config';
import LocalStorage from '@/domain/services/LocalStorage';


const getCredentials = () => {
  const accessToken = localStorage.getItem(`${APP_NAME}.accessToken`);

  return accessToken || null;
};

const setCredentials = (accessToken: string) => {
  localStorage.setItem(`${APP_NAME}.accessToken`, accessToken);
};

const removeCredentials = () => {
  localStorage.removeItem(`${APP_NAME}.accessToken`);
};

const LocalStorageImpl: LocalStorage = {
  getCredentials,
  setCredentials,
  removeCredentials
};

export default LocalStorageImpl;